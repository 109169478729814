@import "../../Styles/variables";

.price {
  font-size: 2.5rem;
  font-weight: $f-semi-bold;
  ;
}

.promoCodeMessage {
  background-color: #f8f8f8;
  padding: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e541ed;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: $f-bold;
  color: #000000;
  margin-left: 3.2rem;
}

.finalAmount {
  font-size: 3.8rem;
  font-weight: $f-bold;
  color: #e541ed;
}

@media (max-width: 500px) {
  .wrapper .content-wrapper {
    .lineSeprator {
      display: none;
    }

    .total-amount {
      justify-content: space-between;

      span {
        width: fit-content;
      }

      .price {
        min-width: 100px;
      }
    }

    .promoCodeMessage {
      margin-left: 0rem;
    }

    .promoCodeWrapper {
      flex-direction: column;

      .price {
        text-align: right;
      }
    }

    .promocodeField {
      flex-direction: column;
      align-items: normal;
      text-align: start;

      span {
        margin-left: 0 !important;
      }
    }

    .payable-amount {
      justify-content: space-between;

      span {
        min-width: 100px;
      }
    }

    .finalAmount {
      font-size: 2rem;
    }
  }
}

.payment-paypage {
  // width: 100%;
  margin-bottom: 2%;
  font-size: 1.3rem;
  background-color: #f0f0f0;
  border: 1px solid #d5d4d4;
}

img.razorpay-logo {
  width: 100px;
  padding: 14px 6px;
}

img.stripe-logo {
  width: 100px;
  padding: 14px 6px;
}

button.button.button-linear-gradient-primary.payment-paypage.mx-2.p-0 {
  background: linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%);
  padding: 13px 13px !important;
  border: none !important;
}

button.button.button-linear-gradient-primary.payment-paypage.mx-lg-2.p-0 {
  background: linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%);
  padding: 13px 13px !important;
  border: none !important;
}

input.w-0 {
  width: 20px !important;
}
@charset "UTF-8";

/**************************************************************

	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|	|___ edu-common
	|	|___ forms
	|	|___ text-animation
	|
	|
	|___Header Styles
	|	|___ header-common
	|	|___ headertop
	|	|___ offertopbar
	|	|___ headermid
	|	|___ social-share
	|	|___ header
	|	|___ nav
	|	|___ mega-menu
	|	|___ mobilemenu
	|	|___ offcanvas
	|	|___ one-page-navigation
	|
	|
	|___Elements Styles
	|	|___ button 
	|	|___ section-title
	|	|___ breadcrumb
	|	|___ card
	|	|___ course-meta
	|	|___ service
	|	|___ feature
	|	|___ about
	|	|___ callto-action
	|	|___ accordion
	|	|___ image-gallery
	|	|___ counterup
	|	|___ pricingtable
	|	|___ badge
	|	|___ team
	|	|___ social
	|	|___ pagination
	|	|___ backtotop
	|	|___ newsletterform
	|	|___ brand
	|	|___ portfolio
	|	|___ contact
	|	|___ testimonial
	|	|___ advance-tab
	|	|___ category
	|	|___ instagram
	|	|___ split
	|	|___ countdown
	|	|___ progressbar
	|	|___ category-box
	|	|___ course-filter
	|	|___ modal
	|	|___ list
	|	|___ search
	|	|___ video
	|	|___ 404

	|___Blog Styles
	|	|___ blog 
	|	|___ post-default 
	|	|___ sidebar 
	|	|___ blog-details 
	|	|___ unit-test 

	|___Shop Styles
	|	|___ shop 
	|	|___ product-details 
	|	|___ my-account 
	|	|___ cart 
	|	|___ checkout 
	|	|___ minicart 

	|___Template Styles
	|	|___ banner 
	|	|___ course-details 
	|	|___ course-sidebar 
	|	|___ contact 
	|	|___ sidebar 
	|	|___ course-action-bottom 


	|___Footer Styles
	|	|___ footer 
	|	|___ copyright 
	|	|___ back-to-top 
	|	|___ dark 
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/

/**************************************
    Default Styles
***************************************/

/*-- Variables --*/

/* @import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400&display=swap"); */

.react-multi-carousel-list {
  padding-bottom: 3rem;
}


.PaymentButton--dark.svelte-ekc7fv.svelte-ekc7fv {
  color: #fff !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
  margin: 0px !important;
}

.css-qbdosj-Input {
  margin: 0px !important;
  padding: 0px !important;
}

.rbt-search-style-1 .search-btn i {
  font-size: 16px;
  font-weight: 600;
}

button.search-trigger-active.rbt-round-btn.d-md-none {
  font-size: 16px;
}

.select-country-input {
  font-size: 13px !important;
  padding: 10px !important;
  height: 42px;
}

.row.mt-4.mb-4.px-4.m-0.breadcrumb-border {
  border-bottom: 1px solid #cdc5c5;
  padding-bottom: 14px;
}


:root {
  --color-primary: #5956e9;
  --color-secondary: #e541ed;
  --color-lightblue: #7c79f4;
  --color-lightviolet: #e369e9;
  --color-pink: #db7093;
  --color-heading: #192335;
  --color-body: #666666;
  --color-footer: #89909f;
  --color-white: #ffffff;
  --color-white-off: #ffffffab;
  --color-bodyest: #273041;
  --color-dark: #27272e;
  --color-darker: #192335;
  --color-black: #111113;
  --color-grey: rgba(207, 207, 207, 0.24);
  --color-grey2: #f4f5f7;
  --color-gray: #a1a9ac;
  --color-gray-light: #f6f6f6;
  --color-gray-lighter: #ebebeb;
  --color-light: #edeffc;
  --color-lighter: #f2f5f9;
  --primary-opacity: #2f57ef21;
  --secondary-opacity: #b966e721;
  --coral-opacity: #e9967a21;
  --violet-opacity: #80008021;
  --pink-opacity: #db709321;
  --white-opacity: #ffffff21;
  --danger-opacity: #ff000310;
  --warning-opacity: #ff8f3c10;
  --success-opacity: #3eb75e10;
  --black-opacity: rgba(0, 0, 0, 0.04);
  --heading-opacity: #19233550;
  --color-success: #3eb75e;
  --color-danger: #ff0003;
  --color-warning: #ff8f3c;
  --color-info: #1ba2db;
  --color-primary-alt: #d1ebe6;
  --color-primary-light: #f1f7ff;
  --color-primary-lighter: #eeeeff;
  --color-secondary-alt: #fef4f4;
  --color-extra: rgb(249, 249, 255);
  --color-extra2: #f9f9ff;
  --color-border: #e6e3f1;
  --color-border-2: #e6e3f14f;
  --color-badge-1: #303f5b;
  --color-badge-2: #f2c94c;
  --color-facebook: #3b5997;
  --color-twitter: #1ba1f2;
  --color-youtube: #ed4141;
  --color-linkedin: #0077b5;
  --color-pinterest: #e60022;
  --color-instagram: #c231a1;
  --color-vimeo: #00adef;
  --color-twitch: #6441a3;
  --color-discord: #7289da;
  --radius-10: 10px;
  --radius-big: 16px;
  --radius-small: 6px;
  --border-width: 2px;
  --radius: 6px;
  --f-light: 300;
  --f-regular: 400;
  --f-medium: 500;
  --f-semi-bold: 600;
  --f-bold: 700;
  --f-extra-bold: 800;
  --f-black: 900;
  --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
  --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  --shadow-1: 0px 6px 34px rgba(215, 216, 222, 0.41);
  --shadow-slide-item: 0px 0 10px 0 rgba(215, 216, 222, 0.2);
  --shadow-2: 0 15px 34px 0 rgba(175, 181, 204, 0.32);
  --shadow-3: 0 5px 15px 0 rgba(85, 74, 101, 0.37);
  --shadow-5: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
  --shadow-7: 0px 15px 30px -2px rgba(0, 0, 0, 0.1);
  --shadow-8: -5px 18px 60px 0 rgba(210, 210, 234, 0.4);
  --shadow-9: 0 13px 48px 0 rgba(215, 216, 222, 0.44);
  --shadow-10: 0 13px 14px 0 rgba(129, 104, 145, 0.05);
  --shadow-11: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  --transition: 0.3s;
  --transition-2: 256ms ease-in-out;
  --transition-3: transform 0.85s cubic-bezier(0.23, 1, 0.32, 1),
    background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  --transition-4: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --font-primary: "Euclid Circular";
  --font-secondary: "Euclid Circular";
  --font-alt: "Source Serif Pro", serif;
  --font-size-b1: 24px;
  --font-size-b2: 18px;
  --font-size-b3: 15px;
  --font-size-b4: 12px;
  --line-height-b1: 1.5;
  --line-height-b2: 1.67;
  --line-height-b3: 1.67;
  --line-height-b4: 1;
  --h1: 48px;
  --h2: 42px;
  --h3: 34px;
  --h4: 26px;
  --h5: 20px;
  --h6: 16px;
  --div-gap-1: 10px;
  --gradient-1: linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 255) 100%);
  --gradient-4: linear-gradient(180deg, #fff, #f6f9ff);
  --gradient-2: linear-gradient(0deg,
      rgb(255, 255, 255) 0%,
      rgb(240, 242, 255) 100%);
  --gradient-3: linear-gradient(90deg, #8da9fc 0%, #b48dd5 100%);
  --gradient-4: linear-gradient(180deg, #eff1ff 0%, #ffffff 100%);
  --gradient-5: linear-gradient(rgb(239, 241, 255) 0%, rgb(239, 241, 255) 100%);
  --gradient-6: linear-gradient(to right,
      rgba(255, 255, 255, 0.15),
      rgba(251, 199, 226, 0.15),
      rgba(220, 217, 254, 0.15));
  --gradient-7: linear-gradient(218.15deg,
      var(--color-secondary) 0%,
      var(--color-primary) 100%);
  --gradient-8: linear-gradient(270deg, #9e77ff 0%, #4460ff 100%);
  --gradient-9: linear-gradient(180deg, #6827ff 0%, #e3f0ff 100%);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

a {
  color: var(--font-primary);
  text-decoration: none;
  outline: none;
  transition: 0.3s;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  /* color: var(--color-primary); */
}

a:focus {
  outline: none;
}

address {
  margin: 0 0 24px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

mark {
  background: var(--color-primary);
  color: #ffffff;
}

code,
kbd,
pre,
samp {
  font-size: var(--font-size-b3);
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  color: var(--color-primary);
}

kbd,
ins {
  color: #ffffff;
}

pre {
  /* font-family: "Courier 10 Pitch", Courier, monospace; */
  font-size: var(--font-size-b3);
  margin: 10px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: var(--color-body);
  background: var(--color-lighter);
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl {
  margin-top: 0;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

dt {
  font-weight: bold;
  color: var(--color-heading);
}

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

li>ul,
li>ol {
  margin: 0;
}

ol ul {
  margin-bottom: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid var(--color-border);
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

caption,
th,
td {
  font-weight: normal;
}

th {
  font-weight: 500;
}

td,
.wp-block-calendar tfoot td {
  border: 1px solid var(--color-border);
  padding: 7px 10px;
}

del {
  color: #333;
}

ins {
  background: rgba(255, 47, 47, 0.4);
  text-decoration: none;
}

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
}

table a,
table a:link,
table a:visited {
  text-decoration: underline;
}

dt {
  font-weight: bold;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

caption {
  caption-side: top;
}

kbd {
  background: var(--color-heading);
}

dfn,
cite,
em {
  font-style: italic;
}

.width-20 {
  width: 20px;
}

.width-50 {
  width: 50px;
}

.width-70 {
  width: 70px;
}

/* BlockQuote  */

blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote {
  font-size: var(--font-size-b1);
  font-style: italic;
  font-weight: var(--f-light);
  margin: 24px 40px;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite,
blockquote small {
  font-size: var(--font-size-b3);
  font-weight: normal;
}

blockquote strong,
blockquote b {
  font-weight: 700;
}

/**
 * Typography
 */

/*
	font-family: 'Source Serif Pro', serif; 
*/

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  font-size: 16px;
  line-height: 1.5;
  /* font-family: var(--font-primary); */
  color: var(--color-body);
  font-weight: 400;
  scroll-behavior: auto;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 16px;
    line-height: 1.5;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 8px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  word-break: break-word;
  /* font-family: var(--font-secondary); */
  line-height: 1.4074;
  color: var(--color-heading);
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: 1.19;
}

h2,
.h2 {
  font-size: var(--h2);
  line-height: 1.23;
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: 1.14;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.25;
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: 1.24;
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: 1.25;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  h1,
  .h1 {
    font-size: 38px;
  }

  h2,
  .h2 {
    font-size: 32px;
  }

  h3,
  .h3 {
    font-size: 28px;
  }

  h4,
  .h4 {
    font-size: 24px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {

  h1,
  .h1 {
    font-size: 34px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--f-medium);
}

h4,
.h4,
h5,
.h5 {
  font-weight: var(--f-semi-bold);
}

h6,
.h6 {
  font-weight: var(--f-medium);
}

.bold {
  font-weight: var(--f-semi-bold);
}

.normal-text {
  font-weight: var(--f-regular);
}

/* p {
    font-size: 15px;
    line-height: 1.45;
    margin-bottom: 10px;
} */

/* @media only screen and (min-width: 1370px) {
    p {
        font-size: 16px;
    }
} */

p.has-large-font-size {
  line-height: 1.5;
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  p.has-large-font-size {
    font-size: 16px;
    line-height: 28px;
  }
}

p.has-medium-font-size {
  font-size: 20px;
  line-height: 36px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  p.has-medium-font-size {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  p.has-medium-font-size {
    font-size: 16px;
    line-height: 28px;
  }
}

p.has-small-font-size {
  font-size: 13px;
}

p.has-very-light-gray-color {
  color: var(--color-white);
}

p.has-background {
  padding: 20px 30px;
}

p.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  p.b1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  p.b1 {
    font-size: 16px;
    line-height: 28px;
  }
}

p.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

@media only screen and (max-width: 767px) {
  p.b2 {
    font-size: 16px;
    line-height: 28px;
  }
}

p.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

@media only screen and (max-width: 767px) {
  p.b3 {
    font-size: 16px;
    line-height: 28px;
  }
}

p.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4);
}

@media only screen and (max-width: 767px) {
  p.b4 {
    font-size: 16px;
    line-height: 28px;
  }
}

p:last-child {
  margin-bottom: 0;
}

.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

@media only screen and (max-width: 767px) {
  .b1 {
    font-size: 16px;
    line-height: 28px;
  }
}

.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

@media only screen and (max-width: 767px) {
  .b2 {
    font-size: 16px;
    line-height: 28px;
  }
}

.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

@media only screen and (max-width: 767px) {
  .b3 {
    font-size: 16px;
    line-height: 28px;
  }
}

.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4);
}

@media only screen and (max-width: 767px) {
  .b4 {
    font-size: 16px;
    line-height: 28px;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 20px;
  width: 100%;
}

table a,
table a:link,
table a:visited {
  text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--heading-color);
}

var {
  /* font-family: "Courier 10 Pitch", Courier, monospace; */
}

.container-fluid {
  max-width: 1600px;
}

/*---------------------------
	List Style 
---------------------------*/

ul,
ol {
  padding-left: 18px;
}

ul {
  list-style: disc;
  margin-bottom: 30px;
  padding-left: 20px;
}

ul.liststyle.bullet li {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-body);
  position: relative;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  ul.liststyle.bullet li {
    padding-left: 19px;
  }
}

ul.liststyle.bullet li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: var(--color-body);
  left: 0;
  top: 10px;
}

ul.liststyle.bullet li+li {
  margin-top: 8px;
}

ul li a {
  text-align: left;
  text-decoration: none;
  color: var(--color-heading);
}

ul li a:hover {
  color: #c945ec !important;
}

ul ul {
  margin-bottom: 0;
}

ol {
  margin-bottom: 30px;
}

ol li {
  color: var(--color-body);
  margin-top: 10px;
  margin-bottom: 10px;
}

ol li a {
  color: var(--heading-color);
  text-decoration: none;
}

ol li a:hover {
  color: var(--color-primary);
}

ol ul {
  padding-left: 30px;
}

.nav-pills {
  margin-bottom: 30px;
}

.nav-pills li {
  margin: 0;
}

.nav-pills li button {
  font-size: 16px;
  line-height: 15px;
  font-weight: 500;
  color: var(--color-heading);
  padding: 15px 30px;
  transition: 0.3s;
}

.nav-pills li button:hover {
  color: var(--color-heading);
}

.entry-content a {
  text-decoration: underline;
}

/*=====================
    All Extend Here
=======================*/

/* -----------------------------
    Hover Dropdown Extend  
--------------------------------*/

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu,
.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu,
.rbt-user-wrapper .rbt-user-menu-list-wrapper,
.rbt-category-menu-wrapper .category-dropdown-menu,
ul.rbt-dropdown-menu li .sub-menu {
  position: absolute;
  top: 100%;
  right: 0;
  visibility: hidden;
  clip: rect(0px, 200vw, 0, 0px);
  opacity: 0;
  transition: opacity 0.4s linear, clip 0.6s linear, visibility 0s 0.4s;
  transform: translateZ(0);
}

.rbt-header .mainmenu-nav .mainmenu>li .submenu>li:hover>.submenu,
.rbt-header .mainmenu-nav .mainmenu li.with-megamenu:hover .rbt-megamenu,
.rbt-header .mainmenu-nav .mainmenu li.has-dropdown:hover>.submenu,
.rbt-user-wrapper:hover .rbt-user-menu-list-wrapper,
.rbt-category-menu-wrapper:hover .category-dropdown-menu,
ul.rbt-dropdown-menu li:hover .sub-menu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  clip: rect(0px, 100vw, 200vh, -30px);
  transition: clip 0.6s linear, opacity 0.4s linear;
}

/*-----------------------------
    Rbt Background Gradient
-------------------------------*/

.rbt-page-banner-wrapper .rbt-banner-image,
.bg-gradient-9 {
  position: relative;
  z-index: 1;
  background: linear-gradient(270deg,
      var(--color-secondary) 0%,
      var(--color-primary) 100%) !important;
}

.rbt-page-banner-wrapper .rbt-banner-image::after,
.bg-gradient-9::after {
  background: linear-gradient(white 0%, rgba(255, 255, 255, 0.1) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
}

/*----------------------
    Bg Image  
------------------------*/

.bg_image,
.bgImagePosition {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.copyright-style-1 .copyright-link,
.footer-widget .ft-link,
.rbt-single-widget ul.rbt-sidebar-list-wrapper,
.rbt-widget-details .rbt-course-details-list-wrapper,
.rbt-widget-details .rbt-default-sidebar-list,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list,
.rbt-sorting-list .course-switch-layout,
.rbt-single-product .product-feature,
.comment-list ul.children,
.comment-list,
.blog-meta,
.course-feature-list,
.plan-offer-list,
.rbt-list-style-3,
.rbt-list-style-1,
.filter-tab-button,
.advance-pricing .pricing-right .plan-offer-list,
.pricing-table .pricing-body .list-item,
.pricing-billing-duration ul,
.rbt-meta,
.social-share-transparent,
.rbt-user-menu-list-wrapper .user-list-wrapper,
.rbt-secondary-menu,
ul.rbt-dropdown-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rbt-minicart-wrapper .minicart-item,
.comment-list .comment .reply-edit a.comment-reply-link,
.about-author .media-body .author-info .title a,
.tagcloud a,
.blog-content-wrapper .social-share-block .post-like a i,
.blog-meta li a,
.rbt-cat-box-1.image-overlaping-content::after,
.rbt-cat-box-1 .inner,
.rbt-cat-box-1,
.rbt-testimonial-box .inner,
ul.testimonial-thumb-wrapper li .thumb::after,
ul.testimonial-thumb-wrapper li .thumb img,
.form-group input[type="submit"],
.rbt-contact-form.contact-form-style-1 .callto-action-wrapper span a,
.rbt-team-modal-thumb .rbt-team-thumbnail .thumb::before,
.rbt-team-modal-thumb .rbt-team-thumbnail .thumb::after,
.rbt-team-modal-thumb .rbt-team-thumbnail .thumb img,
.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb::before,
.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb::after,
.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb img,
.team .thumbnail img,
.service-card-5.variation-2 .inner .icon img,
.rbt-card .rbt-card-body .rbt-card-title a,
.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu li a,
.social-share-transparent li a::before,
.social-share-transparent li a,
.rbt-user-menu-list-wrapper .user-list-wrapper li a,
.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-list-wrapper li a,
.form-group textarea,
.form-group input,
.rbt-author-meta .rbt-author-info a,
#scrollUp,
ul li a,
ol li a {
  transition: var(--transition);
}

ul.rbt-dropdown-menu li .sub-menu li a,
ul.rbt-dropdown-menu li a .right-icon,
ul.rbt-dropdown-menu li a {
  transition: var(--transition-4);
}

/*----------------------
    Text Color  
------------------------*/

.color-body {
  color: var(--color-body) !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.color-lightblue {
  color: var(--color-lightblue) !important;
}

.color-lightviolet {
  color: var(--color-lightviolet) !important;
}

.color-pink {
  color: var(--color-pink) !important;
}

.color-white {
  color: var(--color-white) !important;
}

.color-white-off {
  color: var(--color-white-off) !important;
}

.color-white-off {
  color: var(--color-white-off) !important;
}

.heading-opacity {
  color: var(--heading-opacity) !important;
}

.color-danger {
  color: var(--color-danger) !important;
}

.color-warning {
  color: var(--color-warning) !important;
}

.color-success {
  color: var(--color-success) !important;
}

/*----------------------
    Background Color  
------------------------*/

.bg-color-primary {
  background-color: var(--color-primary) !important;
}

.bg-color-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-color-coral {
  background-color: var(--color-coral) !important;
}

.bg-color-violet {
  background-color: var(--color-violet) !important;
}

.bg-color-pink {
  background-color: var(--color-pink) !important;
}

.bg-color-heading {
  background-color: var(--color-heading) !important;
}

.bg-color-white {
  background-color: var(--color-white) !important;
}

.bg-color-body {
  background-color: var(--color-body) !important;
}

.bg-color-white {
  background-color: var(--color-white) !important;
}

.bg-color-white-off {
  background-color: var(--color-white-off) !important;
}

.bg-color-bodyest {
  background-color: var(--color-bodyest) !important;
}

.bg-color-dark {
  background-color: var(--color-dark) !important;
}

.bg-color-darker {
  background-color: var(--color-darker) !important;
}

.bg-color-black {
  background-color: var(--color-black) !important;
}

.bg-color-grey {
  background-color: var(--color-grey) !important;
}

.bg-color-grey2 {
  background-color: var(--color-grey2) !important;
}

.bg-color-gray {
  background-color: var(--color-gray) !important;
}

.bg-color-gray-light {
  background-color: var(--color-gray-light) !important;
}

.bg-color-gray-lighter {
  background-color: var(--color-gray-lighter) !important;
}

.bg-color-light {
  background-color: var(--color-light) !important;
}

.bg-color-lighter {
  background-color: var(--color-lighter) !important;
}

.bg-color-white {
  background-color: var(--color-white) !important;
}

.bg-color-primary-opacity {
  background: var(--primary-opacity) !important;
}

.bg-color-secondary-opacity {
  background: var(--secondary-opacity) !important;
}

.bg-color-coral-opacity {
  background: var(--coral-opacity) !important;
}

.bg-color-violet-opacity {
  background: var(--violet-opacity) !important;
}

.bg-color-pink-opacity {
  background: var(--pink-opacity) !important;
}

.bg-color-white-opacity {
  background-color: var(--white-opacity) !important;
}

.bg-color-black-opacity {
  background-color: var(--black-opacity) !important;
}

.bg-color-heading-opacity {
  background-color: var(--heading-opacity) !important;
}

.bg-color-danger-opacity {
  background: var(--danger-opacity) !important;
}

.bg-color-warning-opacity {
  background-color: var(--warning-opacity) !important;
}

.bg-color-success-opacity {
  background-color: var(--success-opacity) !important;
}

.bg-color-success {
  background-color: var(--color-success) !important;
}

.bg-color-danger {
  background-color: var(--color-danger) !important;
}

.bg-color-warning {
  background-color: var(--color-warning) !important;
}

.bg-color-info {
  background-color: var(--color-info) !important;
}

.bg-color-lightblue {
  background-color: var(--color-lightblue) !important;
}

.bg-color-lightviolet {
  background-color: var(--color-lightviolet) !important;
}

/*-----------------------------
    Rbt Background Gradient
-------------------------------*/

.rbt-gradient {
  position: relative;
}

.rbt-gradient::before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 100%;
}

.rbt-gradient.bottom-theme-gradient {
  bottom: 0;
  height: 800;
}

.rbt-gradient.bottom-theme-gradient::before {
  background: var(--gradient-3);
}

.bg-gradient-1 {
  background: var(--gradient-1) !important;
}

.bg-gradient-2 {
  background: var(--gradient-2) !important;
}

.bg-gradient-3 {
  background: var(--gradient-3) !important;
}

.bg-gradient-4 {
  background: var(--gradient-4) !important;
}

.bg-gradient-5 {
  background: var(--gradient-5) !important;
}

.bg-gradient-6 {
  background: var(--gradient-6) !important;
}

.bg-gradient-7 {
  background: var(--gradient-7) !important;
}

.bg-gradient-8 {
  background: var(--gradient-8) !important;
}

.bg-gradient-10 {
  background: linear-gradient(141.76deg,
      #f5d9d5 0.59%,
      #f5eab4 39.43%,
      #b76cea 100%) !important;
}

.bg-gradient-11 {
  background: linear-gradient(180deg, #eedefd 0%, #ffffff 100%) !important;
}

.bg-gradient-12 {
  background: linear-gradient(208.29deg,
      #f5d9d5 0%,
      #f5eab4 42.92%,
      #99d9f5 100%) !important;
}

.bg-gradient-13 {
  background: linear-gradient(180deg,
      rgba(218, 251, 255, 0) 0%,
      #dafbff 100%) !important;
}

.bg-gradient-14 {
  background: var(--gradient-9) !important;
}

.bg-gradient-15 {
  background: linear-gradient(151.71deg, #29c986 0%, #2fc8e5 100%) !important;
}

.bg-gradient-16 {
  background: linear-gradient(151.71deg, #ff652d 0%, #ffa426 100%) !important;
}

.bg-gradient-17 {
  background: linear-gradient(151.71deg, #30c4ff 0%, #7259ff 100%) !important;
}

.theme-mainbg-gradient {
  background-color: var(--color-light);
  position: relative;
  /* background-image: url(../images/bg/bg-g1.webp); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*-------------------------------------
    Background Opacity With Text Colors  
---------------------------------------*/

.bg-primary-opacity {
  background: var(--primary-opacity) !important;
}

.bg-secondary-opacity {
  background: var(--secondary-opacity) !important;
}

.bg-coral-opacity {
  background: var(--coral-opacity) !important;
  color: var(--color-coral) !important;
}

.bg-violet-opacity {
  background: var(--violet-opacity) !important;
  color: var(--color-violet) !important;
}

.bg-pink-opacity {
  background: var(--pink-opacity) !important;
  color: var(--color-pink) !important;
}

.bg-white-opacity {
  background: var(--white-opacity) !important;
  color: var(--color-white) !important;
}

.bg-warning-opacity {
  background: var(--warning-opacity) !important;
  color: var(--color-warning) !important;
}

/*---------------------- 
    Common Css 
--------------------------*/

.liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}

.height-auto {
  height: auto !important;
}

.max-width-auto {
  max-width: inherit !important;
}

.edu_bounce_loop {
  will-change: transform;
  -webkit-animation-name: edu_bounce_loop;
  animation-name: edu_bounce_loop;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes edu_bounce_loop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes edu_bounce_loop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes edu_rotatation_round {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes edu_rotatation_round {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.rbt-rotatation-round {
  will-change: transform;
  -webkit-animation-name: edu_rotatation_round;
  animation-name: edu_rotatation_round;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes bounceSlide {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce-slide {
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: bounceSlide;
}

/*==============================
 *  Utilities
=================================*/

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.fix {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.color-box-inner .title {
  text-transform: capitalize;
}

.color-box-inner .color-box {
  width: 100%;
  height: 100px;
  box-shadow: var(--shadow-1);
}

/*===============================
    Background Color 
=================================*/

.bg-color-primary {
  background: var(--color-primary);
}

.bg-color-primary-alt {
  background: var(--color-primary-alt);
}

.bg-color-secondary {
  background: var(--color-secondary);
}

.bg-color-secondary-alt {
  background: var(--color-secondary-alt);
}

.bg-color-light {
  background: var(--color-light);
}

.bg-color-extra2 {
  background: var(--color-extra2);
}

.bg-color-dark {
  background: var(--color-dark);
}

.bg-color-heading {
  background: var(--color-heading);
}

.bg-color-darker {
  background: var(--color-darker);
}

.bg-color-white {
  background: var(--color-white);
}

.bg-color-1 {
  background: #010001;
}

.bg-color-black {
  background: #111113;
}

/*---------------------
    Height and width 
------------------------*/

.fullscreen {
  min-height: 100vh;
  width: 100%;
}

/*===================
Custom Row
======================*/

.row--0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row--0>[class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;
}

.row--5>[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row--10>[class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row--15 {
  margin-left: -15px;
  margin-right: -15px;
}

.row--15>[class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--20>[class*="col"],
.row--20>[class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .row--20>[class*="col"],
  .row--20>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .row--20>[class*="col"],
  .row--20>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .row--20>[class*="col"],
  .row--20>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {

  .row--20>[class*="col"],
  .row--20>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--25 {
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--25 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--25>[class*="col"],
.row--25>[class*="col-"] {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .row--25>[class*="col"],
  .row--25>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .row--25>[class*="col"],
  .row--25>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .row--25>[class*="col"],
  .row--25>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {

  .row--25>[class*="col"],
  .row--25>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--30 {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--30>[class*="col"],
.row--30>[class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .row--30>[class*="col"],
  .row--30>[class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .row--30>[class*="col"],
  .row--30>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .row--30>[class*="col"],
  .row--30>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {

  .row--30>[class*="col"],
  .row--30>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--40 {
  margin-left: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--40 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--40>[class*="col"],
.row--40>[class*="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .row--40>[class*="col"],
  .row--40>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .row--40>[class*="col"],
  .row--40>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .row--40>[class*="col"],
  .row--40>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {

  .row--40>[class*="col"],
  .row--40>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--45 {
  margin-left: -45px;
  margin-right: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--45 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--45>[class*="col"],
.row--45>[class*="col-"] {
  padding-left: 45px;
  padding-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .row--45>[class*="col"],
  .row--45>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .row--45>[class*="col"],
  .row--45>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .row--45>[class*="col"],
  .row--45>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {

  .row--45>[class*="col"],
  .row--45>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--60 {
  margin-left: -60px;
  margin-right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--60 {
    margin-left: -60px;
    margin-right: -60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--60 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--60>[class*="col"],
.row--60>[class*="col-"] {
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .row--60>[class*="col"],
  .row--60>[class*="col-"] {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .row--60>[class*="col"],
  .row--60>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .row--60>[class*="col"],
  .row--60>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {

  .row--60>[class*="col"],
  .row--60>[class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*===========================
    Input Placeholder
=============================*/

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*=============================
	Overlay styles 
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay],
[data-gradient-overlay] {
  position: relative;
}

[data-overlay]>div,
[data-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>*,
[data-gradient-overlay]>div,
[data-gradient-overlay]>* {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before,
[data-gradient-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-overlay]:before {
  background: var(--color-primary);
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-gradient-overlay]:before {
  background: linear-gradient(90deg,
      var(--color-primary),
      var(--color-secondary));
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before,
[data-gradient-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before,
[data-gradient-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before,
[data-gradient-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before,
[data-gradient-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before,
[data-gradient-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before,
[data-gradient-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before,
[data-gradient-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before,
[data-gradient-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before,
[data-gradient-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before,
[data-gradient-overlay="10"]:before {
  opacity: 1;
}

.opacity-7 {
  opacity: 0.7;
}

/*------------------------------
    Scroll Up 
--------------------------------*/

#scrollUp {
  width: 70px;
  height: 80px;
  right: 100px;
  bottom: 60px;
  text-align: center;
  z-index: 9811 !important;
  text-decoration: none;
  background: #fff;
  line-height: 80px;
  color: #757589;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  background: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}

#scrollUp::before {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: rgba(0, 2, 72, 0.1);
  content: "";
  position: absolute;
  z-index: -1;
  transform-style: preserve-3d;
  transform: rotateY(-10deg);
  filter: blur(50px);
}

#scrollUp::after {
  background: #ffffff;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform-style: preserve-3d;
  transform: rotateY(-10deg);
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 30px;
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
}

#scrollUp span.text {
  position: relative;
  display: inline-block;
  margin-top: 7px;
}

@media only screen and (max-width: 767px) {
  #scrollUp span.text {
    margin-top: 3px;
  }
}

#scrollUp span.text::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 7px 5px;
  border-color: transparent transparent var(--color-primary) transparent;
  position: absolute;
  content: "";
  left: 50%;
  top: 21%;
  transform: translateX(-50%);
}

#scrollUp:hover span.text {
  color: var(--color-primary);
}

/*--------------------
    Contact Form 
----------------------*/

.form-message {
  margin-bottom: 0;
  text-align: center;
}

.form-message.error {
  margin-top: 20px;
  color: #f80707;
}

.form-message.success {
  margin-top: 20px;
  color: #0d8d2d;
}

.rbt-scroll-max-height {
  max-height: 500px !important;
  overflow-y: scroll;
}

.rbt-scroll::-webkit-scrollbar {
  width: 10px;
}

.rbt-scroll::-webkit-scrollbar-thumb {
  background-color: #e8e6e9 !important;
  outline: 0;
  border-radius: 10px;
}

.rbt-scroll::-webkit-scrollbar-track {
  box-shadow: 0 0 1px #cecdcd;
  background: #f6f6f6 !important;
  padding: 1px;
  border-radius: 8px;
}

/*=========================
    Section Separation 
==========================*/

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.position-to-top {
  position: relative;
  margin-top: -300px;
}

.mt_dec--80 {
  margin-top: -80px;
}

.mt_dec--100 {
  margin-top: -100px;
}

/* .rbt-section-gap {
    padding: 75px 0;
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-section-gap {
        padding: 55px 0;
    }
} */

/* @media only screen and (max-width: 767px) {
    .rbt-section-gap {
        padding: 50px 0;
    }
} */

/* .rbt-section-gap2 {
    padding: 80px 0;
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-section-gap2 {
        padding: 80px 0;
    }
} */

/* @media only screen and (max-width: 767px) {
    .rbt-section-gap2 {
        padding: 80px 0;
    }
} */

/* .rbt-section-gapBottom {
    padding-bottom: 120px;
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-section-gapBottom {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .rbt-section-gapBottom {
        padding-bottom: 80px;
    }
}

.rbt-section-gap2Bottom {
    padding-bottom: 80px;
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-section-gap2Bottom {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .rbt-section-gap2Bottom {
        padding-bottom: 80px;
    }
}

.rbt-section-gapTop {
    padding-top: 120px;
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-section-gapTop {
        padding-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .rbt-section-gapTop {
        padding-top: 80px;
    }
}

.rbt-section-gapTopHalf {
    padding-top: 60px;
} */

/* .rbt-section-gap2Top {
    padding-top: 80px;
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-section-gap2Top {
        padding-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .rbt-section-gap2Top {
        padding-top: 80px;
    }
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .pb--165,
  .pb--85 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {

  .pb--165,
  .pb--85 {
    padding-bottom: 60px;
  }
}

.mt_dec--120 {
  margin-top: -120px !important;
}

.mt_dec--60 {
  margin-top: -60px !important;
}

.mt_dec--50 {
  margin-top: -50px !important;
}

.mt_dec--30 {
  margin-top: -30px !important;
}

.ml_dec_20 {
  margin-left: -20px !important;
}

.mt_dec--half {
  margin-bottom: -156px;
}

.default-callto-action-overlap {
  padding-top: 276px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-callto-action-overlap {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .default-callto-action-overlap {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120 {
    padding: 80px 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .ptb--120 {
    padding: 60px 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .pb--165,
  .pb--120,
  .pb--85,
  .pb--165 {
    padding-bottom: 80px !important;
  }
}

@media only screen and (max-width: 767px) {

  .pb--165,
  .pb--120,
  .pb--85,
  .pb--165 {
    padding-bottom: 60px !important;
  }
}

@media only screen and (max-width: 767px) {
  .pb--70 {
    padding-bottom: 60px !important;
  }
}

.pl--0 {
  padding-left: 0 !important;
}

.pr--0 {
  padding-right: 0 !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.pt--260 {
  padding-top: 260px;
}

.pt--250 {
  padding-top: 250px;
}

.pb--240 {
  padding-bottom: 240px;
}

.pb--220 {
  padding-bottom: 220px;
}

.plr--270 {
  padding: 0 270px;
}

.plr--300 {
  padding: 0 300px;
}

.plr--340 {
  padding: 0 340px;
}

.plr--0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ptb--300 {
  padding: 300px 0;
}

.ptb--340 {
  padding: 340px 0;
}

.ptb--450 {
  padding: 450px 0;
}

.plr_dec--15 {
  margin: 0 -15px;
}

.pt--300 {
  padding-top: 300px;
}

.pl--300 {
  padding-left: 300px;
}

.ptb--5 {
  padding: 5px 0 !important;
}

.plr--5 {
  padding: 0 5px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mb--5 {
  margin-bottom: 5px !important;
}

.mr--5 {
  margin-right: 5px !important;
}

.ml--5 {
  margin-left: 5px !important;
}

.ptb--10 {
  padding: 10px 0 !important;
}

.plr--10 {
  padding: 0 10px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.ptb--15 {
  padding: 15px 0 !important;
}

.plr--15 {
  padding: 0 15px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.mr--15 {
  margin-right: 15px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.ptb--20 {
  padding: 20px 0 !important;
}

.plr--20 {
  padding: 0 20px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.ptb--25 {
  padding: 25px 0 !important;
}

.plr--25 {
  padding: 0 25px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.mr--25 {
  margin-right: 25px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.ptb--30 {
  padding: 30px 0 !important;
}

.plr--30 {
  padding: 0 30px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.ptb--35 {
  padding: 35px 0 !important;
}

.plr--35 {
  padding: 0 35px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.mr--35 {
  margin-right: 35px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.ptb--40 {
  padding: 40px 0 !important;
}

.plr--40 {
  padding: 0 40px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.ptb--45 {
  padding: 45px 0 !important;
}

.plr--45 {
  padding: 0 45px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.mr--45 {
  margin-right: 45px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.ptb--50 {
  padding: 50px 0 !important;
}

.plr--50 {
  padding: 0 50px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.ptb--55 {
  padding: 55px 0 !important;
}

.plr--55 {
  padding: 0 55px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.mr--55 {
  margin-right: 55px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.ptb--60 {
  padding: 60px 0 !important;
}

.plr--60 {
  padding: 0 60px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.ptb--65 {
  padding: 65px 0 !important;
}

.plr--65 {
  padding: 0 65px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.mr--65 {
  margin-right: 65px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.ptb--70 {
  padding: 70px 0 !important;
}

.plr--70 {
  padding: 0 70px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.ptb--75 {
  padding: 75px 0 !important;
}

.plr--75 {
  padding: 0 75px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.mr--75 {
  margin-right: 75px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.ptb--80 {
  padding: 80px 0 !important;
}

.plr--80 {
  padding: 0 80px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.ptb--85 {
  padding: 85px 0 !important;
}

.plr--85 {
  padding: 0 85px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.mr--85 {
  margin-right: 85px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.ptb--90 {
  padding: 90px 0 !important;
}

.plr--90 {
  padding: 0 90px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.ptb--95 {
  padding: 95px 0 !important;
}

.plr--95 {
  padding: 0 95px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.mr--95 {
  margin-right: 95px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.ptb--100 {
  padding: 100px 0 !important;
}

.plr--100 {
  padding: 0 100px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.ptb--105 {
  padding: 105px 0 !important;
}

.plr--105 {
  padding: 0 105px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.mr--105 {
  margin-right: 105px !important;
}

.ml--105 {
  margin-left: 105px !important;
}

.ptb--110 {
  padding: 110px 0 !important;
}

.plr--110 {
  padding: 0 110px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.mr--110 {
  margin-right: 110px !important;
}

.ml--110 {
  margin-left: 110px !important;
}

.ptb--115 {
  padding: 115px 0 !important;
}

.plr--115 {
  padding: 0 115px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.mr--115 {
  margin-right: 115px !important;
}

.ml--115 {
  margin-left: 115px !important;
}

.ptb--120 {
  padding: 120px 0 !important;
}

.plr--120 {
  padding: 0 120px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.mr--120 {
  margin-right: 120px !important;
}

.ml--120 {
  margin-left: 120px !important;
}

.ptb--125 {
  padding: 125px 0 !important;
}

.plr--125 {
  padding: 0 125px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.mr--125 {
  margin-right: 125px !important;
}

.ml--125 {
  margin-left: 125px !important;
}

.ptb--130 {
  padding: 130px 0 !important;
}

.plr--130 {
  padding: 0 130px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.mr--130 {
  margin-right: 130px !important;
}

.ml--130 {
  margin-left: 130px !important;
}

.ptb--135 {
  padding: 135px 0 !important;
}

.plr--135 {
  padding: 0 135px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.mr--135 {
  margin-right: 135px !important;
}

.ml--135 {
  margin-left: 135px !important;
}

.ptb--140 {
  padding: 140px 0 !important;
}

.plr--140 {
  padding: 0 140px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.mr--140 {
  margin-right: 140px !important;
}

.ml--140 {
  margin-left: 140px !important;
}

.ptb--145 {
  padding: 145px 0 !important;
}

.plr--145 {
  padding: 0 145px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.mr--145 {
  margin-right: 145px !important;
}

.ml--145 {
  margin-left: 145px !important;
}

.ptb--150 {
  padding: 150px 0 !important;
}

.plr--150 {
  padding: 0 150px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.mr--150 {
  margin-right: 150px !important;
}

.ml--150 {
  margin-left: 150px !important;
}

.ptb--155 {
  padding: 155px 0 !important;
}

.plr--155 {
  padding: 0 155px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.mr--155 {
  margin-right: 155px !important;
}

.ml--155 {
  margin-left: 155px !important;
}

.ptb--160 {
  padding: 160px 0 !important;
}

.plr--160 {
  padding: 0 160px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.mr--160 {
  margin-right: 160px !important;
}

.ml--160 {
  margin-left: 160px !important;
}

.ptb--165 {
  padding: 165px 0 !important;
}

.plr--165 {
  padding: 0 165px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.mr--165 {
  margin-right: 165px !important;
}

.ml--165 {
  margin-left: 165px !important;
}

.ptb--170 {
  padding: 170px 0 !important;
}

.plr--170 {
  padding: 0 170px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.mr--170 {
  margin-right: 170px !important;
}

.ml--170 {
  margin-left: 170px !important;
}

.ptb--175 {
  padding: 175px 0 !important;
}

.plr--175 {
  padding: 0 175px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.mr--175 {
  margin-right: 175px !important;
}

.ml--175 {
  margin-left: 175px !important;
}

.ptb--180 {
  padding: 180px 0 !important;
}

.plr--180 {
  padding: 0 180px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.mr--180 {
  margin-right: 180px !important;
}

.ml--180 {
  margin-left: 180px !important;
}

.ptb--185 {
  padding: 185px 0 !important;
}

.plr--185 {
  padding: 0 185px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.mr--185 {
  margin-right: 185px !important;
}

.ml--185 {
  margin-left: 185px !important;
}

.ptb--190 {
  padding: 190px 0 !important;
}

.plr--190 {
  padding: 0 190px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.mr--190 {
  margin-right: 190px !important;
}

.ml--190 {
  margin-left: 190px !important;
}

.ptb--195 {
  padding: 195px 0 !important;
}

.plr--195 {
  padding: 0 195px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.mr--195 {
  margin-right: 195px !important;
}

.ml--195 {
  margin-left: 195px !important;
}

.ptb--200 {
  padding: 200px 0 !important;
}

.plr--200 {
  padding: 0 200px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

.mr--200 {
  margin-right: 200px !important;
}

.ml--200 {
  margin-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0;
  }

  .plr_lp--5 {
    padding: 0 5px;
  }

  .pt_lp--5 {
    padding-top: 5px;
  }

  .pb_lp--5 {
    padding-bottom: 5px;
  }

  .pl_lp--5 {
    padding-left: 5px;
  }

  .pr_lp--5 {
    padding-right: 5px;
  }

  .mt_lp--5 {
    margin-top: 5px;
  }

  .mb_lp--5 {
    margin-bottom: 5px;
  }

  .ptb_lp--10 {
    padding: 10px 0;
  }

  .plr_lp--10 {
    padding: 0 10px;
  }

  .pt_lp--10 {
    padding-top: 10px;
  }

  .pb_lp--10 {
    padding-bottom: 10px;
  }

  .pl_lp--10 {
    padding-left: 10px;
  }

  .pr_lp--10 {
    padding-right: 10px;
  }

  .mt_lp--10 {
    margin-top: 10px;
  }

  .mb_lp--10 {
    margin-bottom: 10px;
  }

  .ptb_lp--15 {
    padding: 15px 0;
  }

  .plr_lp--15 {
    padding: 0 15px;
  }

  .pt_lp--15 {
    padding-top: 15px;
  }

  .pb_lp--15 {
    padding-bottom: 15px;
  }

  .pl_lp--15 {
    padding-left: 15px;
  }

  .pr_lp--15 {
    padding-right: 15px;
  }

  .mt_lp--15 {
    margin-top: 15px;
  }

  .mb_lp--15 {
    margin-bottom: 15px;
  }

  .ptb_lp--20 {
    padding: 20px 0;
  }

  .plr_lp--20 {
    padding: 0 20px;
  }

  .pt_lp--20 {
    padding-top: 20px;
  }

  .pb_lp--20 {
    padding-bottom: 20px;
  }

  .pl_lp--20 {
    padding-left: 20px;
  }

  .pr_lp--20 {
    padding-right: 20px;
  }

  .mt_lp--20 {
    margin-top: 20px;
  }

  .mb_lp--20 {
    margin-bottom: 20px;
  }

  .ptb_lp--25 {
    padding: 25px 0;
  }

  .plr_lp--25 {
    padding: 0 25px;
  }

  .pt_lp--25 {
    padding-top: 25px;
  }

  .pb_lp--25 {
    padding-bottom: 25px;
  }

  .pl_lp--25 {
    padding-left: 25px;
  }

  .pr_lp--25 {
    padding-right: 25px;
  }

  .mt_lp--25 {
    margin-top: 25px;
  }

  .mb_lp--25 {
    margin-bottom: 25px;
  }

  .ptb_lp--30 {
    padding: 30px 0;
  }

  .plr_lp--30 {
    padding: 0 30px;
  }

  .pt_lp--30 {
    padding-top: 30px;
  }

  .pb_lp--30 {
    padding-bottom: 30px;
  }

  .pl_lp--30 {
    padding-left: 30px;
  }

  .pr_lp--30 {
    padding-right: 30px;
  }

  .mt_lp--30 {
    margin-top: 30px;
  }

  .mb_lp--30 {
    margin-bottom: 30px;
  }

  .ptb_lp--35 {
    padding: 35px 0;
  }

  .plr_lp--35 {
    padding: 0 35px;
  }

  .pt_lp--35 {
    padding-top: 35px;
  }

  .pb_lp--35 {
    padding-bottom: 35px;
  }

  .pl_lp--35 {
    padding-left: 35px;
  }

  .pr_lp--35 {
    padding-right: 35px;
  }

  .mt_lp--35 {
    margin-top: 35px;
  }

  .mb_lp--35 {
    margin-bottom: 35px;
  }

  .ptb_lp--40 {
    padding: 40px 0;
  }

  .plr_lp--40 {
    padding: 0 40px;
  }

  .pt_lp--40 {
    padding-top: 40px;
  }

  .pb_lp--40 {
    padding-bottom: 40px;
  }

  .pl_lp--40 {
    padding-left: 40px;
  }

  .pr_lp--40 {
    padding-right: 40px;
  }

  .mt_lp--40 {
    margin-top: 40px;
  }

  .mb_lp--40 {
    margin-bottom: 40px;
  }

  .ptb_lp--45 {
    padding: 45px 0;
  }

  .plr_lp--45 {
    padding: 0 45px;
  }

  .pt_lp--45 {
    padding-top: 45px;
  }

  .pb_lp--45 {
    padding-bottom: 45px;
  }

  .pl_lp--45 {
    padding-left: 45px;
  }

  .pr_lp--45 {
    padding-right: 45px;
  }

  .mt_lp--45 {
    margin-top: 45px;
  }

  .mb_lp--45 {
    margin-bottom: 45px;
  }

  .ptb_lp--50 {
    padding: 50px 0;
  }

  .plr_lp--50 {
    padding: 0 50px;
  }

  .pt_lp--50 {
    padding-top: 50px;
  }

  .pb_lp--50 {
    padding-bottom: 50px;
  }

  .pl_lp--50 {
    padding-left: 50px;
  }

  .pr_lp--50 {
    padding-right: 50px;
  }

  .mt_lp--50 {
    margin-top: 50px;
  }

  .mb_lp--50 {
    margin-bottom: 50px;
  }

  .ptb_lp--55 {
    padding: 55px 0;
  }

  .plr_lp--55 {
    padding: 0 55px;
  }

  .pt_lp--55 {
    padding-top: 55px;
  }

  .pb_lp--55 {
    padding-bottom: 55px;
  }

  .pl_lp--55 {
    padding-left: 55px;
  }

  .pr_lp--55 {
    padding-right: 55px;
  }

  .mt_lp--55 {
    margin-top: 55px;
  }

  .mb_lp--55 {
    margin-bottom: 55px;
  }

  .ptb_lp--60 {
    padding: 60px 0;
  }

  .plr_lp--60 {
    padding: 0 60px;
  }

  .pt_lp--60 {
    padding-top: 60px;
  }

  .pb_lp--60 {
    padding-bottom: 60px;
  }

  .pl_lp--60 {
    padding-left: 60px;
  }

  .pr_lp--60 {
    padding-right: 60px;
  }

  .mt_lp--60 {
    margin-top: 60px;
  }

  .mb_lp--60 {
    margin-bottom: 60px;
  }

  .ptb_lp--65 {
    padding: 65px 0;
  }

  .plr_lp--65 {
    padding: 0 65px;
  }

  .pt_lp--65 {
    padding-top: 65px;
  }

  .pb_lp--65 {
    padding-bottom: 65px;
  }

  .pl_lp--65 {
    padding-left: 65px;
  }

  .pr_lp--65 {
    padding-right: 65px;
  }

  .mt_lp--65 {
    margin-top: 65px;
  }

  .mb_lp--65 {
    margin-bottom: 65px;
  }

  .ptb_lp--70 {
    padding: 70px 0;
  }

  .plr_lp--70 {
    padding: 0 70px;
  }

  .pt_lp--70 {
    padding-top: 70px;
  }

  .pb_lp--70 {
    padding-bottom: 70px;
  }

  .pl_lp--70 {
    padding-left: 70px;
  }

  .pr_lp--70 {
    padding-right: 70px;
  }

  .mt_lp--70 {
    margin-top: 70px;
  }

  .mb_lp--70 {
    margin-bottom: 70px;
  }

  .ptb_lp--75 {
    padding: 75px 0;
  }

  .plr_lp--75 {
    padding: 0 75px;
  }

  .pt_lp--75 {
    padding-top: 75px;
  }

  .pb_lp--75 {
    padding-bottom: 75px;
  }

  .pl_lp--75 {
    padding-left: 75px;
  }

  .pr_lp--75 {
    padding-right: 75px;
  }

  .mt_lp--75 {
    margin-top: 75px;
  }

  .mb_lp--75 {
    margin-bottom: 75px;
  }

  .ptb_lp--80 {
    padding: 80px 0;
  }

  .plr_lp--80 {
    padding: 0 80px;
  }

  .pt_lp--80 {
    padding-top: 80px;
  }

  .pb_lp--80 {
    padding-bottom: 80px;
  }

  .pl_lp--80 {
    padding-left: 80px;
  }

  .pr_lp--80 {
    padding-right: 80px;
  }

  .mt_lp--80 {
    margin-top: 80px;
  }

  .mb_lp--80 {
    margin-bottom: 80px;
  }

  .ptb_lp--85 {
    padding: 85px 0;
  }

  .plr_lp--85 {
    padding: 0 85px;
  }

  .pt_lp--85 {
    padding-top: 85px;
  }

  .pb_lp--85 {
    padding-bottom: 85px;
  }

  .pl_lp--85 {
    padding-left: 85px;
  }

  .pr_lp--85 {
    padding-right: 85px;
  }

  .mt_lp--85 {
    margin-top: 85px;
  }

  .mb_lp--85 {
    margin-bottom: 85px;
  }

  .ptb_lp--90 {
    padding: 90px 0;
  }

  .plr_lp--90 {
    padding: 0 90px;
  }

  .pt_lp--90 {
    padding-top: 90px;
  }

  .pb_lp--90 {
    padding-bottom: 90px;
  }

  .pl_lp--90 {
    padding-left: 90px;
  }

  .pr_lp--90 {
    padding-right: 90px;
  }

  .mt_lp--90 {
    margin-top: 90px;
  }

  .mb_lp--90 {
    margin-bottom: 90px;
  }

  .ptb_lp--95 {
    padding: 95px 0;
  }

  .plr_lp--95 {
    padding: 0 95px;
  }

  .pt_lp--95 {
    padding-top: 95px;
  }

  .pb_lp--95 {
    padding-bottom: 95px;
  }

  .pl_lp--95 {
    padding-left: 95px;
  }

  .pr_lp--95 {
    padding-right: 95px;
  }

  .mt_lp--95 {
    margin-top: 95px;
  }

  .mb_lp--95 {
    margin-bottom: 95px;
  }

  .ptb_lp--100 {
    padding: 100px 0;
  }

  .plr_lp--100 {
    padding: 0 100px;
  }

  .pt_lp--100 {
    padding-top: 100px;
  }

  .pb_lp--100 {
    padding-bottom: 100px;
  }

  .pl_lp--100 {
    padding-left: 100px;
  }

  .pr_lp--100 {
    padding-right: 100px;
  }

  .mt_lp--100 {
    margin-top: 100px;
  }

  .mb_lp--100 {
    margin-bottom: 100px;
  }

  .plr--120 {
    padding: 0 80px !important;
  }

  .mt_lp_dec--30 {
    margin-top: -30px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl_lg--0 {
    padding-left: 0 !important;
  }

  .mt_lg_dec--30 {
    margin-top: -30px !important;
  }

  .ptb_lg--5 {
    padding: 5px 0 !important;
  }

  .plr_lg--5 {
    padding: 0 5px !important;
  }

  .pt_lg--5 {
    padding-top: 5px !important;
  }

  .pb_lg--5 {
    padding-bottom: 5px !important;
  }

  .pl_lg--5 {
    padding-left: 5px !important;
  }

  .pr_lg--5 {
    padding-right: 5px !important;
  }

  .mt_lg--5 {
    margin-top: 5px !important;
  }

  .mb_lg--5 {
    margin-bottom: 5px !important;
  }

  .ml_lg--5 {
    margin-left: 5px !important;
  }

  .ptb_lg--10 {
    padding: 10px 0 !important;
  }

  .plr_lg--10 {
    padding: 0 10px !important;
  }

  .pt_lg--10 {
    padding-top: 10px !important;
  }

  .pb_lg--10 {
    padding-bottom: 10px !important;
  }

  .pl_lg--10 {
    padding-left: 10px !important;
  }

  .pr_lg--10 {
    padding-right: 10px !important;
  }

  .mt_lg--10 {
    margin-top: 10px !important;
  }

  .mb_lg--10 {
    margin-bottom: 10px !important;
  }

  .ml_lg--10 {
    margin-left: 10px !important;
  }

  .ptb_lg--15 {
    padding: 15px 0 !important;
  }

  .plr_lg--15 {
    padding: 0 15px !important;
  }

  .pt_lg--15 {
    padding-top: 15px !important;
  }

  .pb_lg--15 {
    padding-bottom: 15px !important;
  }

  .pl_lg--15 {
    padding-left: 15px !important;
  }

  .pr_lg--15 {
    padding-right: 15px !important;
  }

  .mt_lg--15 {
    margin-top: 15px !important;
  }

  .mb_lg--15 {
    margin-bottom: 15px !important;
  }

  .ml_lg--15 {
    margin-left: 15px !important;
  }

  .ptb_lg--20 {
    padding: 20px 0 !important;
  }

  .plr_lg--20 {
    padding: 0 20px !important;
  }

  .pt_lg--20 {
    padding-top: 20px !important;
  }

  .pb_lg--20 {
    padding-bottom: 20px !important;
  }

  .pl_lg--20 {
    padding-left: 20px !important;
  }

  .pr_lg--20 {
    padding-right: 20px !important;
  }

  .mt_lg--20 {
    margin-top: 20px !important;
  }

  .mb_lg--20 {
    margin-bottom: 20px !important;
  }

  .ml_lg--20 {
    margin-left: 20px !important;
  }

  .ptb_lg--25 {
    padding: 25px 0 !important;
  }

  .plr_lg--25 {
    padding: 0 25px !important;
  }

  .pt_lg--25 {
    padding-top: 25px !important;
  }

  .pb_lg--25 {
    padding-bottom: 25px !important;
  }

  .pl_lg--25 {
    padding-left: 25px !important;
  }

  .pr_lg--25 {
    padding-right: 25px !important;
  }

  .mt_lg--25 {
    margin-top: 25px !important;
  }

  .mb_lg--25 {
    margin-bottom: 25px !important;
  }

  .ml_lg--25 {
    margin-left: 25px !important;
  }

  .ptb_lg--30 {
    padding: 30px 0 !important;
  }

  .plr_lg--30 {
    padding: 0 30px !important;
  }

  .pt_lg--30 {
    padding-top: 30px !important;
  }

  .pb_lg--30 {
    padding-bottom: 30px !important;
  }

  .pl_lg--30 {
    padding-left: 30px !important;
  }

  .pr_lg--30 {
    padding-right: 30px !important;
  }

  .mt_lg--30 {
    margin-top: 30px !important;
  }

  .mb_lg--30 {
    margin-bottom: 30px !important;
  }

  .ml_lg--30 {
    margin-left: 30px !important;
  }

  .ptb_lg--35 {
    padding: 35px 0 !important;
  }

  .plr_lg--35 {
    padding: 0 35px !important;
  }

  .pt_lg--35 {
    padding-top: 35px !important;
  }

  .pb_lg--35 {
    padding-bottom: 35px !important;
  }

  .pl_lg--35 {
    padding-left: 35px !important;
  }

  .pr_lg--35 {
    padding-right: 35px !important;
  }

  .mt_lg--35 {
    margin-top: 35px !important;
  }

  .mb_lg--35 {
    margin-bottom: 35px !important;
  }

  .ml_lg--35 {
    margin-left: 35px !important;
  }

  .ptb_lg--40 {
    padding: 40px 0 !important;
  }

  .plr_lg--40 {
    padding: 0 40px !important;
  }

  .pt_lg--40 {
    padding-top: 40px !important;
  }

  .pb_lg--40 {
    padding-bottom: 40px !important;
  }

  .pl_lg--40 {
    padding-left: 40px !important;
  }

  .pr_lg--40 {
    padding-right: 40px !important;
  }

  .mt_lg--40 {
    margin-top: 40px !important;
  }

  .mb_lg--40 {
    margin-bottom: 40px !important;
  }

  .ml_lg--40 {
    margin-left: 40px !important;
  }

  .ptb_lg--45 {
    padding: 45px 0 !important;
  }

  .plr_lg--45 {
    padding: 0 45px !important;
  }

  .pt_lg--45 {
    padding-top: 45px !important;
  }

  .pb_lg--45 {
    padding-bottom: 45px !important;
  }

  .pl_lg--45 {
    padding-left: 45px !important;
  }

  .pr_lg--45 {
    padding-right: 45px !important;
  }

  .mt_lg--45 {
    margin-top: 45px !important;
  }

  .mb_lg--45 {
    margin-bottom: 45px !important;
  }

  .ml_lg--45 {
    margin-left: 45px !important;
  }

  .ptb_lg--50 {
    padding: 50px 0 !important;
  }

  .plr_lg--50 {
    padding: 0 50px !important;
  }

  .pt_lg--50 {
    padding-top: 50px !important;
  }

  .pb_lg--50 {
    padding-bottom: 50px !important;
  }

  .pl_lg--50 {
    padding-left: 50px !important;
  }

  .pr_lg--50 {
    padding-right: 50px !important;
  }

  .mt_lg--50 {
    margin-top: 50px !important;
  }

  .mb_lg--50 {
    margin-bottom: 50px !important;
  }

  .ml_lg--50 {
    margin-left: 50px !important;
  }

  .ptb_lg--55 {
    padding: 55px 0 !important;
  }

  .plr_lg--55 {
    padding: 0 55px !important;
  }

  .pt_lg--55 {
    padding-top: 55px !important;
  }

  .pb_lg--55 {
    padding-bottom: 55px !important;
  }

  .pl_lg--55 {
    padding-left: 55px !important;
  }

  .pr_lg--55 {
    padding-right: 55px !important;
  }

  .mt_lg--55 {
    margin-top: 55px !important;
  }

  .mb_lg--55 {
    margin-bottom: 55px !important;
  }

  .ml_lg--55 {
    margin-left: 55px !important;
  }

  .ptb_lg--60 {
    padding: 60px 0 !important;
  }

  .plr_lg--60 {
    padding: 0 60px !important;
  }

  .pt_lg--60 {
    padding-top: 60px !important;
  }

  .pb_lg--60 {
    padding-bottom: 60px !important;
  }

  .pl_lg--60 {
    padding-left: 60px !important;
  }

  .pr_lg--60 {
    padding-right: 60px !important;
  }

  .mt_lg--60 {
    margin-top: 60px !important;
  }

  .mb_lg--60 {
    margin-bottom: 60px !important;
  }

  .ml_lg--60 {
    margin-left: 60px !important;
  }

  .ptb_lg--65 {
    padding: 65px 0 !important;
  }

  .plr_lg--65 {
    padding: 0 65px !important;
  }

  .pt_lg--65 {
    padding-top: 65px !important;
  }

  .pb_lg--65 {
    padding-bottom: 65px !important;
  }

  .pl_lg--65 {
    padding-left: 65px !important;
  }

  .pr_lg--65 {
    padding-right: 65px !important;
  }

  .mt_lg--65 {
    margin-top: 65px !important;
  }

  .mb_lg--65 {
    margin-bottom: 65px !important;
  }

  .ml_lg--65 {
    margin-left: 65px !important;
  }

  .ptb_lg--70 {
    padding: 70px 0 !important;
  }

  .plr_lg--70 {
    padding: 0 70px !important;
  }

  .pt_lg--70 {
    padding-top: 70px !important;
  }

  .pb_lg--70 {
    padding-bottom: 70px !important;
  }

  .pl_lg--70 {
    padding-left: 70px !important;
  }

  .pr_lg--70 {
    padding-right: 70px !important;
  }

  .mt_lg--70 {
    margin-top: 70px !important;
  }

  .mb_lg--70 {
    margin-bottom: 70px !important;
  }

  .ml_lg--70 {
    margin-left: 70px !important;
  }

  .ptb_lg--75 {
    padding: 75px 0 !important;
  }

  .plr_lg--75 {
    padding: 0 75px !important;
  }

  .pt_lg--75 {
    padding-top: 75px !important;
  }

  .pb_lg--75 {
    padding-bottom: 75px !important;
  }

  .pl_lg--75 {
    padding-left: 75px !important;
  }

  .pr_lg--75 {
    padding-right: 75px !important;
  }

  .mt_lg--75 {
    margin-top: 75px !important;
  }

  .mb_lg--75 {
    margin-bottom: 75px !important;
  }

  .ml_lg--75 {
    margin-left: 75px !important;
  }

  .ptb_lg--80 {
    padding: 80px 0 !important;
  }

  .plr_lg--80 {
    padding: 0 80px !important;
  }

  .pt_lg--80 {
    padding-top: 80px !important;
  }

  .pb_lg--80 {
    padding-bottom: 80px !important;
  }

  .pl_lg--80 {
    padding-left: 80px !important;
  }

  .pr_lg--80 {
    padding-right: 80px !important;
  }

  .mt_lg--80 {
    margin-top: 80px !important;
  }

  .mb_lg--80 {
    margin-bottom: 80px !important;
  }

  .ml_lg--80 {
    margin-left: 80px !important;
  }

  .ptb_lg--85 {
    padding: 85px 0 !important;
  }

  .plr_lg--85 {
    padding: 0 85px !important;
  }

  .pt_lg--85 {
    padding-top: 85px !important;
  }

  .pb_lg--85 {
    padding-bottom: 85px !important;
  }

  .pl_lg--85 {
    padding-left: 85px !important;
  }

  .pr_lg--85 {
    padding-right: 85px !important;
  }

  .mt_lg--85 {
    margin-top: 85px !important;
  }

  .mb_lg--85 {
    margin-bottom: 85px !important;
  }

  .ml_lg--85 {
    margin-left: 85px !important;
  }

  .ptb_lg--90 {
    padding: 90px 0 !important;
  }

  .plr_lg--90 {
    padding: 0 90px !important;
  }

  .pt_lg--90 {
    padding-top: 90px !important;
  }

  .pb_lg--90 {
    padding-bottom: 90px !important;
  }

  .pl_lg--90 {
    padding-left: 90px !important;
  }

  .pr_lg--90 {
    padding-right: 90px !important;
  }

  .mt_lg--90 {
    margin-top: 90px !important;
  }

  .mb_lg--90 {
    margin-bottom: 90px !important;
  }

  .ml_lg--90 {
    margin-left: 90px !important;
  }

  .ptb_lg--95 {
    padding: 95px 0 !important;
  }

  .plr_lg--95 {
    padding: 0 95px !important;
  }

  .pt_lg--95 {
    padding-top: 95px !important;
  }

  .pb_lg--95 {
    padding-bottom: 95px !important;
  }

  .pl_lg--95 {
    padding-left: 95px !important;
  }

  .pr_lg--95 {
    padding-right: 95px !important;
  }

  .mt_lg--95 {
    margin-top: 95px !important;
  }

  .mb_lg--95 {
    margin-bottom: 95px !important;
  }

  .ml_lg--95 {
    margin-left: 95px !important;
  }

  .ptb_lg--100 {
    padding: 100px 0 !important;
  }

  .plr_lg--100 {
    padding: 0 100px !important;
  }

  .pt_lg--100 {
    padding-top: 100px !important;
  }

  .pb_lg--100 {
    padding-bottom: 100px !important;
  }

  .pl_lg--100 {
    padding-left: 100px !important;
  }

  .pr_lg--100 {
    padding-right: 100px !important;
  }

  .mt_lg--100 {
    margin-top: 100px !important;
  }

  .mb_lg--100 {
    margin-bottom: 100px !important;
  }

  .ml_lg--100 {
    margin-left: 100px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--170 {
    padding: 80px 0 !important;
  }

  .mt_md_dec--30 {
    margin-top: -30px !important;
  }

  .pl_md--0 {
    padding-left: 0 !important;
  }

  .ptb_md--0 {
    padding: 0 !important;
  }

  .pl_md--0 {
    padding-left: 0 !important;
  }

  .pr_md--0 {
    padding-right: 0 !important;
  }

  .pt_md--0 {
    padding-top: 0 !important;
  }

  .pb_md--0 {
    padding-bottom: 0 !important;
  }

  .mr_md--0 {
    margin-right: 0 !important;
  }

  .ml_md--0 {
    margin-left: 0 !important;
  }

  .mt_md--0 {
    margin-top: 0 !important;
  }

  .mb_md--0 {
    margin-bottom: 0 !important;
  }

  .ptb_md--250 {
    padding: 250px 0 !important;
  }

  .ptb_md--5 {
    padding: 5px 0 !important;
  }

  .plr_md--5 {
    padding: 0 5px !important;
  }

  .pt_md--5 {
    padding-top: 5px !important;
  }

  .pb_md--5 {
    padding-bottom: 5px !important;
  }

  .pl_md--5 {
    padding-left: 5px !important;
  }

  .pr_md--5 {
    padding-right: 5px !important;
  }

  .mt_md--5 {
    margin-top: 5px !important;
  }

  .mb_md--5 {
    margin-bottom: 5px !important;
  }

  .ptb_md--10 {
    padding: 10px 0 !important;
  }

  .plr_md--10 {
    padding: 0 10px !important;
  }

  .pt_md--10 {
    padding-top: 10px !important;
  }

  .pb_md--10 {
    padding-bottom: 10px !important;
  }

  .pl_md--10 {
    padding-left: 10px !important;
  }

  .pr_md--10 {
    padding-right: 10px !important;
  }

  .mt_md--10 {
    margin-top: 10px !important;
  }

  .mb_md--10 {
    margin-bottom: 10px !important;
  }

  .ptb_md--15 {
    padding: 15px 0 !important;
  }

  .plr_md--15 {
    padding: 0 15px !important;
  }

  .pt_md--15 {
    padding-top: 15px !important;
  }

  .pb_md--15 {
    padding-bottom: 15px !important;
  }

  .pl_md--15 {
    padding-left: 15px !important;
  }

  .pr_md--15 {
    padding-right: 15px !important;
  }

  .mt_md--15 {
    margin-top: 15px !important;
  }

  .mb_md--15 {
    margin-bottom: 15px !important;
  }

  .ptb_md--20 {
    padding: 20px 0 !important;
  }

  .plr_md--20 {
    padding: 0 20px !important;
  }

  .pt_md--20 {
    padding-top: 20px !important;
  }

  .pb_md--20 {
    padding-bottom: 20px !important;
  }

  .pl_md--20 {
    padding-left: 20px !important;
  }

  .pr_md--20 {
    padding-right: 20px !important;
  }

  .mt_md--20 {
    margin-top: 20px !important;
  }

  .mb_md--20 {
    margin-bottom: 20px !important;
  }

  .ptb_md--25 {
    padding: 25px 0 !important;
  }

  .plr_md--25 {
    padding: 0 25px !important;
  }

  .pt_md--25 {
    padding-top: 25px !important;
  }

  .pb_md--25 {
    padding-bottom: 25px !important;
  }

  .pl_md--25 {
    padding-left: 25px !important;
  }

  .pr_md--25 {
    padding-right: 25px !important;
  }

  .mt_md--25 {
    margin-top: 25px !important;
  }

  .mb_md--25 {
    margin-bottom: 25px !important;
  }

  .ptb_md--30 {
    padding: 30px 0 !important;
  }

  .plr_md--30 {
    padding: 0 30px !important;
  }

  .pt_md--30 {
    padding-top: 30px !important;
  }

  .pb_md--30 {
    padding-bottom: 30px !important;
  }

  .pl_md--30 {
    padding-left: 30px !important;
  }

  .pr_md--30 {
    padding-right: 30px !important;
  }

  .mt_md--30 {
    margin-top: 30px !important;
  }

  .mb_md--30 {
    margin-bottom: 30px !important;
  }

  .ptb_md--35 {
    padding: 35px 0 !important;
  }

  .plr_md--35 {
    padding: 0 35px !important;
  }

  .pt_md--35 {
    padding-top: 35px !important;
  }

  .pb_md--35 {
    padding-bottom: 35px !important;
  }

  .pl_md--35 {
    padding-left: 35px !important;
  }

  .pr_md--35 {
    padding-right: 35px !important;
  }

  .mt_md--35 {
    margin-top: 35px !important;
  }

  .mb_md--35 {
    margin-bottom: 35px !important;
  }

  .ptb_md--40 {
    padding: 40px 0 !important;
  }

  .plr_md--40 {
    padding: 0 40px !important;
  }

  .pt_md--40 {
    padding-top: 40px !important;
  }

  .pb_md--40 {
    padding-bottom: 40px !important;
  }

  .pl_md--40 {
    padding-left: 40px !important;
  }

  .pr_md--40 {
    padding-right: 40px !important;
  }

  .mt_md--40 {
    margin-top: 40px !important;
  }

  .mb_md--40 {
    margin-bottom: 40px !important;
  }

  .ptb_md--45 {
    padding: 45px 0 !important;
  }

  .plr_md--45 {
    padding: 0 45px !important;
  }

  .pt_md--45 {
    padding-top: 45px !important;
  }

  .pb_md--45 {
    padding-bottom: 45px !important;
  }

  .pl_md--45 {
    padding-left: 45px !important;
  }

  .pr_md--45 {
    padding-right: 45px !important;
  }

  .mt_md--45 {
    margin-top: 45px !important;
  }

  .mb_md--45 {
    margin-bottom: 45px !important;
  }

  .ptb_md--50 {
    padding: 50px 0 !important;
  }

  .plr_md--50 {
    padding: 0 50px !important;
  }

  .pt_md--50 {
    padding-top: 50px !important;
  }

  .pb_md--50 {
    padding-bottom: 50px !important;
  }

  .pl_md--50 {
    padding-left: 50px !important;
  }

  .pr_md--50 {
    padding-right: 50px !important;
  }

  .mt_md--50 {
    margin-top: 50px !important;
  }

  .mb_md--50 {
    margin-bottom: 50px !important;
  }

  .ptb_md--55 {
    padding: 55px 0 !important;
  }

  .plr_md--55 {
    padding: 0 55px !important;
  }

  .pt_md--55 {
    padding-top: 55px !important;
  }

  .pb_md--55 {
    padding-bottom: 55px !important;
  }

  .pl_md--55 {
    padding-left: 55px !important;
  }

  .pr_md--55 {
    padding-right: 55px !important;
  }

  .mt_md--55 {
    margin-top: 55px !important;
  }

  .mb_md--55 {
    margin-bottom: 55px !important;
  }

  .ptb_md--60 {
    padding: 60px 0 !important;
  }

  .plr_md--60 {
    padding: 0 60px !important;
  }

  .pt_md--60 {
    padding-top: 60px !important;
  }

  .pb_md--60 {
    padding-bottom: 60px !important;
  }

  .pl_md--60 {
    padding-left: 60px !important;
  }

  .pr_md--60 {
    padding-right: 60px !important;
  }

  .mt_md--60 {
    margin-top: 60px !important;
  }

  .mb_md--60 {
    margin-bottom: 60px !important;
  }

  .ptb_md--65 {
    padding: 65px 0 !important;
  }

  .plr_md--65 {
    padding: 0 65px !important;
  }

  .pt_md--65 {
    padding-top: 65px !important;
  }

  .pb_md--65 {
    padding-bottom: 65px !important;
  }

  .pl_md--65 {
    padding-left: 65px !important;
  }

  .pr_md--65 {
    padding-right: 65px !important;
  }

  .mt_md--65 {
    margin-top: 65px !important;
  }

  .mb_md--65 {
    margin-bottom: 65px !important;
  }

  .ptb_md--70 {
    padding: 70px 0 !important;
  }

  .plr_md--70 {
    padding: 0 70px !important;
  }

  .pt_md--70 {
    padding-top: 70px !important;
  }

  .pb_md--70 {
    padding-bottom: 70px !important;
  }

  .pl_md--70 {
    padding-left: 70px !important;
  }

  .pr_md--70 {
    padding-right: 70px !important;
  }

  .mt_md--70 {
    margin-top: 70px !important;
  }

  .mb_md--70 {
    margin-bottom: 70px !important;
  }

  .ptb_md--75 {
    padding: 75px 0 !important;
  }

  .plr_md--75 {
    padding: 0 75px !important;
  }

  .pt_md--75 {
    padding-top: 75px !important;
  }

  .pb_md--75 {
    padding-bottom: 75px !important;
  }

  .pl_md--75 {
    padding-left: 75px !important;
  }

  .pr_md--75 {
    padding-right: 75px !important;
  }

  .mt_md--75 {
    margin-top: 75px !important;
  }

  .mb_md--75 {
    margin-bottom: 75px !important;
  }

  .ptb_md--80 {
    padding: 80px 0 !important;
  }

  .plr_md--80 {
    padding: 0 80px !important;
  }

  .pt_md--80 {
    padding-top: 80px !important;
  }

  .pb_md--80 {
    padding-bottom: 80px !important;
  }

  .pl_md--80 {
    padding-left: 80px !important;
  }

  .pr_md--80 {
    padding-right: 80px !important;
  }

  .mt_md--80 {
    margin-top: 80px !important;
  }

  .mb_md--80 {
    margin-bottom: 80px !important;
  }

  .ptb_md--85 {
    padding: 85px 0 !important;
  }

  .plr_md--85 {
    padding: 0 85px !important;
  }

  .pt_md--85 {
    padding-top: 85px !important;
  }

  .pb_md--85 {
    padding-bottom: 85px !important;
  }

  .pl_md--85 {
    padding-left: 85px !important;
  }

  .pr_md--85 {
    padding-right: 85px !important;
  }

  .mt_md--85 {
    margin-top: 85px !important;
  }

  .mb_md--85 {
    margin-bottom: 85px !important;
  }

  .ptb_md--90 {
    padding: 90px 0 !important;
  }

  .plr_md--90 {
    padding: 0 90px !important;
  }

  .pt_md--90 {
    padding-top: 90px !important;
  }

  .pb_md--90 {
    padding-bottom: 90px !important;
  }

  .pl_md--90 {
    padding-left: 90px !important;
  }

  .pr_md--90 {
    padding-right: 90px !important;
  }

  .mt_md--90 {
    margin-top: 90px !important;
  }

  .mb_md--90 {
    margin-bottom: 90px !important;
  }

  .ptb_md--95 {
    padding: 95px 0 !important;
  }

  .plr_md--95 {
    padding: 0 95px !important;
  }

  .pt_md--95 {
    padding-top: 95px !important;
  }

  .pb_md--95 {
    padding-bottom: 95px !important;
  }

  .pl_md--95 {
    padding-left: 95px !important;
  }

  .pr_md--95 {
    padding-right: 95px !important;
  }

  .mt_md--95 {
    margin-top: 95px !important;
  }

  .mb_md--95 {
    margin-bottom: 95px !important;
  }

  .ptb_md--100 {
    padding: 100px 0 !important;
  }

  .plr_md--100 {
    padding: 0 100px !important;
  }

  .pt_md--100 {
    padding-top: 100px !important;
  }

  .pb_md--100 {
    padding-bottom: 100px !important;
  }

  .pl_md--100 {
    padding-left: 100px !important;
  }

  .pr_md--100 {
    padding-right: 100px !important;
  }

  .mt_md--100 {
    margin-top: 100px !important;
  }

  .mb_md--100 {
    margin-bottom: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mt_sm_dec--30 {
    margin-top: -30px !important;
  }

  .ptb_sm--250 {
    padding: 250px 0 !important;
  }

  .ptb--170 {
    padding: 80px 0 !important;
  }

  .ptb_sm--0 {
    padding: 0 !important;
  }

  .pl_sm--0 {
    padding-left: 0 !important;
  }

  .pr_sm--0 {
    padding-right: 0 !important;
  }

  .pt_sm--0 {
    padding-top: 0 !important;
  }

  .pb_sm--0 {
    padding-bottom: 0 !important;
  }

  .mr_sm--0 {
    margin-right: 0 !important;
  }

  .ml_sm--0 {
    margin-left: 0 !important;
  }

  .mt_sm--0 {
    margin-top: 0 !important;
  }

  .mb_sm--0 {
    margin-bottom: 0 !important;
  }

  .pt_sm--150 {
    padding-top: 150px !important;
  }

  .pb_sm--110 {
    padding-bottom: 110px !important;
  }

  .ptb_sm--60 {
    padding: 60px 0 !important;
  }

  .ptb_sm--5 {
    padding: 5px 0 !important;
  }

  .plr_sm--5 {
    padding: 0 5px !important;
  }

  .pt_sm--5 {
    padding-top: 5px !important;
  }

  .pb_sm--5 {
    padding-bottom: 5px !important;
  }

  .pl_sm--5 {
    padding-left: 5px !important;
  }

  .pr_sm--5 {
    padding-right: 5px !important;
  }

  .mt_sm--5 {
    margin-top: 5px !important;
  }

  .ml_sm--5 {
    margin-left: 5px !important;
  }

  .mr_sm--5 {
    margin-right: 5px !important;
  }

  .mb_sm--5 {
    margin-bottom: 5px !important;
  }

  .ptb_sm--10 {
    padding: 10px 0 !important;
  }

  .plr_sm--10 {
    padding: 0 10px !important;
  }

  .pt_sm--10 {
    padding-top: 10px !important;
  }

  .pb_sm--10 {
    padding-bottom: 10px !important;
  }

  .pl_sm--10 {
    padding-left: 10px !important;
  }

  .pr_sm--10 {
    padding-right: 10px !important;
  }

  .mt_sm--10 {
    margin-top: 10px !important;
  }

  .ml_sm--10 {
    margin-left: 10px !important;
  }

  .mr_sm--10 {
    margin-right: 10px !important;
  }

  .mb_sm--10 {
    margin-bottom: 10px !important;
  }

  .ptb_sm--15 {
    padding: 15px 0 !important;
  }

  .plr_sm--15 {
    padding: 0 15px !important;
  }

  .pt_sm--15 {
    padding-top: 15px !important;
  }

  .pb_sm--15 {
    padding-bottom: 15px !important;
  }

  .pl_sm--15 {
    padding-left: 15px !important;
  }

  .pr_sm--15 {
    padding-right: 15px !important;
  }

  .mt_sm--15 {
    margin-top: 15px !important;
  }

  .ml_sm--15 {
    margin-left: 15px !important;
  }

  .mr_sm--15 {
    margin-right: 15px !important;
  }

  .mb_sm--15 {
    margin-bottom: 15px !important;
  }

  .ptb_sm--20 {
    padding: 20px 0 !important;
  }

  .plr_sm--20 {
    padding: 0 20px !important;
  }

  .pt_sm--20 {
    padding-top: 20px !important;
  }

  .pb_sm--20 {
    padding-bottom: 20px !important;
  }

  .pl_sm--20 {
    padding-left: 20px !important;
  }

  .pr_sm--20 {
    padding-right: 20px !important;
  }

  .mt_sm--20 {
    margin-top: 20px !important;
  }

  .ml_sm--20 {
    margin-left: 20px !important;
  }

  .mr_sm--20 {
    margin-right: 20px !important;
  }

  .mb_sm--20 {
    margin-bottom: 20px !important;
  }

  .ptb_sm--25 {
    padding: 25px 0 !important;
  }

  .plr_sm--25 {
    padding: 0 25px !important;
  }

  .pt_sm--25 {
    padding-top: 25px !important;
  }

  .pb_sm--25 {
    padding-bottom: 25px !important;
  }

  .pl_sm--25 {
    padding-left: 25px !important;
  }

  .pr_sm--25 {
    padding-right: 25px !important;
  }

  .mt_sm--25 {
    margin-top: 25px !important;
  }

  .ml_sm--25 {
    margin-left: 25px !important;
  }

  .mr_sm--25 {
    margin-right: 25px !important;
  }

  .mb_sm--25 {
    margin-bottom: 25px !important;
  }

  .ptb_sm--30 {
    padding: 30px 0 !important;
  }

  .plr_sm--30 {
    padding: 0 30px !important;
  }

  .pt_sm--30 {
    padding-top: 30px !important;
  }

  .pb_sm--30 {
    padding-bottom: 30px !important;
  }

  .pl_sm--30 {
    padding-left: 30px !important;
  }

  .pr_sm--30 {
    padding-right: 30px !important;
  }

  .mt_sm--30 {
    margin-top: 30px !important;
  }

  .ml_sm--30 {
    margin-left: 30px !important;
  }

  .mr_sm--30 {
    margin-right: 30px !important;
  }

  .mb_sm--30 {
    margin-bottom: 30px !important;
  }

  .ptb_sm--35 {
    padding: 35px 0 !important;
  }

  .plr_sm--35 {
    padding: 0 35px !important;
  }

  .pt_sm--35 {
    padding-top: 35px !important;
  }

  .pb_sm--35 {
    padding-bottom: 35px !important;
  }

  .pl_sm--35 {
    padding-left: 35px !important;
  }

  .pr_sm--35 {
    padding-right: 35px !important;
  }

  .mt_sm--35 {
    margin-top: 35px !important;
  }

  .ml_sm--35 {
    margin-left: 35px !important;
  }

  .mr_sm--35 {
    margin-right: 35px !important;
  }

  .mb_sm--35 {
    margin-bottom: 35px !important;
  }

  .ptb_sm--40 {
    padding: 40px 0 !important;
  }

  .plr_sm--40 {
    padding: 0 40px !important;
  }

  .pt_sm--40 {
    padding-top: 40px !important;
  }

  .pb_sm--40 {
    padding-bottom: 40px !important;
  }

  .pl_sm--40 {
    padding-left: 40px !important;
  }

  .pr_sm--40 {
    padding-right: 40px !important;
  }

  .mt_sm--40 {
    margin-top: 40px !important;
  }

  .ml_sm--40 {
    margin-left: 40px !important;
  }

  .mr_sm--40 {
    margin-right: 40px !important;
  }

  .mb_sm--40 {
    margin-bottom: 40px !important;
  }

  .ptb_sm--45 {
    padding: 45px 0 !important;
  }

  .plr_sm--45 {
    padding: 0 45px !important;
  }

  .pt_sm--45 {
    padding-top: 45px !important;
  }

  .pb_sm--45 {
    padding-bottom: 45px !important;
  }

  .pl_sm--45 {
    padding-left: 45px !important;
  }

  .pr_sm--45 {
    padding-right: 45px !important;
  }

  .mt_sm--45 {
    margin-top: 45px !important;
  }

  .ml_sm--45 {
    margin-left: 45px !important;
  }

  .mr_sm--45 {
    margin-right: 45px !important;
  }

  .mb_sm--45 {
    margin-bottom: 45px !important;
  }

  .ptb_sm--50 {
    padding: 50px 0 !important;
  }

  .plr_sm--50 {
    padding: 0 50px !important;
  }

  .pt_sm--50 {
    padding-top: 50px !important;
  }

  .pb_sm--50 {
    padding-bottom: 50px !important;
  }

  .pl_sm--50 {
    padding-left: 50px !important;
  }

  .pr_sm--50 {
    padding-right: 50px !important;
  }

  .mt_sm--50 {
    margin-top: 50px !important;
  }

  .ml_sm--50 {
    margin-left: 50px !important;
  }

  .mr_sm--50 {
    margin-right: 50px !important;
  }

  .mb_sm--50 {
    margin-bottom: 50px !important;
  }

  .ptb_sm--55 {
    padding: 55px 0 !important;
  }

  .plr_sm--55 {
    padding: 0 55px !important;
  }

  .pt_sm--55 {
    padding-top: 55px !important;
  }

  .pb_sm--55 {
    padding-bottom: 55px !important;
  }

  .pl_sm--55 {
    padding-left: 55px !important;
  }

  .pr_sm--55 {
    padding-right: 55px !important;
  }

  .mt_sm--55 {
    margin-top: 55px !important;
  }

  .ml_sm--55 {
    margin-left: 55px !important;
  }

  .mr_sm--55 {
    margin-right: 55px !important;
  }

  .mb_sm--55 {
    margin-bottom: 55px !important;
  }

  .ptb_sm--60 {
    padding: 60px 0 !important;
  }

  .plr_sm--60 {
    padding: 0 60px !important;
  }

  .pt_sm--60 {
    padding-top: 60px !important;
  }

  .pb_sm--60 {
    padding-bottom: 60px !important;
  }

  .pl_sm--60 {
    padding-left: 60px !important;
  }

  .pr_sm--60 {
    padding-right: 60px !important;
  }

  .mt_sm--60 {
    margin-top: 60px !important;
  }

  .ml_sm--60 {
    margin-left: 60px !important;
  }

  .mr_sm--60 {
    margin-right: 60px !important;
  }

  .mb_sm--60 {
    margin-bottom: 60px !important;
  }

  .ptb_sm--65 {
    padding: 65px 0 !important;
  }

  .plr_sm--65 {
    padding: 0 65px !important;
  }

  .pt_sm--65 {
    padding-top: 65px !important;
  }

  .pb_sm--65 {
    padding-bottom: 65px !important;
  }

  .pl_sm--65 {
    padding-left: 65px !important;
  }

  .pr_sm--65 {
    padding-right: 65px !important;
  }

  .mt_sm--65 {
    margin-top: 65px !important;
  }

  .ml_sm--65 {
    margin-left: 65px !important;
  }

  .mr_sm--65 {
    margin-right: 65px !important;
  }

  .mb_sm--65 {
    margin-bottom: 65px !important;
  }

  .ptb_sm--70 {
    padding: 70px 0 !important;
  }

  .plr_sm--70 {
    padding: 0 70px !important;
  }

  .pt_sm--70 {
    padding-top: 70px !important;
  }

  .pb_sm--70 {
    padding-bottom: 70px !important;
  }

  .pl_sm--70 {
    padding-left: 70px !important;
  }

  .pr_sm--70 {
    padding-right: 70px !important;
  }

  .mt_sm--70 {
    margin-top: 70px !important;
  }

  .ml_sm--70 {
    margin-left: 70px !important;
  }

  .mr_sm--70 {
    margin-right: 70px !important;
  }

  .mb_sm--70 {
    margin-bottom: 70px !important;
  }

  .ptb_sm--75 {
    padding: 75px 0 !important;
  }

  .plr_sm--75 {
    padding: 0 75px !important;
  }

  .pt_sm--75 {
    padding-top: 75px !important;
  }

  .pb_sm--75 {
    padding-bottom: 75px !important;
  }

  .pl_sm--75 {
    padding-left: 75px !important;
  }

  .pr_sm--75 {
    padding-right: 75px !important;
  }

  .mt_sm--75 {
    margin-top: 75px !important;
  }

  .ml_sm--75 {
    margin-left: 75px !important;
  }

  .mr_sm--75 {
    margin-right: 75px !important;
  }

  .mb_sm--75 {
    margin-bottom: 75px !important;
  }

  .ptb_sm--80 {
    padding: 80px 0 !important;
  }

  .plr_sm--80 {
    padding: 0 80px !important;
  }

  .pt_sm--80 {
    padding-top: 80px !important;
  }

  .pb_sm--80 {
    padding-bottom: 80px !important;
  }

  .pl_sm--80 {
    padding-left: 80px !important;
  }

  .pr_sm--80 {
    padding-right: 80px !important;
  }

  .mt_sm--80 {
    margin-top: 80px !important;
  }

  .ml_sm--80 {
    margin-left: 80px !important;
  }

  .mr_sm--80 {
    margin-right: 80px !important;
  }

  .mb_sm--80 {
    margin-bottom: 80px !important;
  }

  .ptb_sm--85 {
    padding: 85px 0 !important;
  }

  .plr_sm--85 {
    padding: 0 85px !important;
  }

  .pt_sm--85 {
    padding-top: 85px !important;
  }

  .pb_sm--85 {
    padding-bottom: 85px !important;
  }

  .pl_sm--85 {
    padding-left: 85px !important;
  }

  .pr_sm--85 {
    padding-right: 85px !important;
  }

  .mt_sm--85 {
    margin-top: 85px !important;
  }

  .ml_sm--85 {
    margin-left: 85px !important;
  }

  .mr_sm--85 {
    margin-right: 85px !important;
  }

  .mb_sm--85 {
    margin-bottom: 85px !important;
  }

  .ptb_sm--90 {
    padding: 90px 0 !important;
  }

  .plr_sm--90 {
    padding: 0 90px !important;
  }

  .pt_sm--90 {
    padding-top: 90px !important;
  }

  .pb_sm--90 {
    padding-bottom: 90px !important;
  }

  .pl_sm--90 {
    padding-left: 90px !important;
  }

  .pr_sm--90 {
    padding-right: 90px !important;
  }

  .mt_sm--90 {
    margin-top: 90px !important;
  }

  .ml_sm--90 {
    margin-left: 90px !important;
  }

  .mr_sm--90 {
    margin-right: 90px !important;
  }

  .mb_sm--90 {
    margin-bottom: 90px !important;
  }

  .ptb_sm--95 {
    padding: 95px 0 !important;
  }

  .plr_sm--95 {
    padding: 0 95px !important;
  }

  .pt_sm--95 {
    padding-top: 95px !important;
  }

  .pb_sm--95 {
    padding-bottom: 95px !important;
  }

  .pl_sm--95 {
    padding-left: 95px !important;
  }

  .pr_sm--95 {
    padding-right: 95px !important;
  }

  .mt_sm--95 {
    margin-top: 95px !important;
  }

  .ml_sm--95 {
    margin-left: 95px !important;
  }

  .mr_sm--95 {
    margin-right: 95px !important;
  }

  .mb_sm--95 {
    margin-bottom: 95px !important;
  }

  .ptb_sm--100 {
    padding: 100px 0 !important;
  }

  .plr_sm--100 {
    padding: 0 100px !important;
  }

  .pt_sm--100 {
    padding-top: 100px !important;
  }

  .pb_sm--100 {
    padding-bottom: 100px !important;
  }

  .pl_sm--100 {
    padding-left: 100px !important;
  }

  .pr_sm--100 {
    padding-right: 100px !important;
  }

  .mt_sm--100 {
    margin-top: 100px !important;
  }

  .ml_sm--100 {
    margin-left: 100px !important;
  }

  .mr_sm--100 {
    margin-right: 100px !important;
  }

  .mb_sm--100 {
    margin-bottom: 100px !important;
  }

  .pl_sm--0 {
    padding-left: 0;
  }

  .pr_sm--0 {
    padding-right: 0;
  }

  .pt_sm--0 {
    padding-top: 0;
  }

  .pb_sm--0 {
    padding-bottom: 0;
  }

  .mr_sm--0 {
    margin-right: 0;
  }

  .ml_sm--0 {
    margin-left: 0;
  }

  .mt_sm--0 {
    margin-top: 0;
  }

  .mb_sm--0 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0 !important;
  }

  .plr_mobile--5 {
    padding: 0 5px !important;
  }

  .pt_mobile--5 {
    padding-top: 5px !important;
  }

  .pb_mobile--5 {
    padding-bottom: 5px !important;
  }

  .pl_mobile--5 {
    padding-left: 5px !important;
  }

  .pr_mobile--5 {
    padding-right: 5px !important;
  }

  .mt_mobile--5 {
    margin-top: 5px !important;
  }

  .mb_mobile--5 {
    margin-bottom: 5px !important;
  }

  .ptb_mobile--10 {
    padding: 10px 0 !important;
  }

  .plr_mobile--10 {
    padding: 0 10px !important;
  }

  .pt_mobile--10 {
    padding-top: 10px !important;
  }

  .pb_mobile--10 {
    padding-bottom: 10px !important;
  }

  .pl_mobile--10 {
    padding-left: 10px !important;
  }

  .pr_mobile--10 {
    padding-right: 10px !important;
  }

  .mt_mobile--10 {
    margin-top: 10px !important;
  }

  .mb_mobile--10 {
    margin-bottom: 10px !important;
  }

  .ptb_mobile--15 {
    padding: 15px 0 !important;
  }

  .plr_mobile--15 {
    padding: 0 15px !important;
  }

  .pt_mobile--15 {
    padding-top: 15px !important;
  }

  .pb_mobile--15 {
    padding-bottom: 15px !important;
  }

  .pl_mobile--15 {
    padding-left: 15px !important;
  }

  .pr_mobile--15 {
    padding-right: 15px !important;
  }

  .mt_mobile--15 {
    margin-top: 15px !important;
  }

  .mb_mobile--15 {
    margin-bottom: 15px !important;
  }

  .ptb_mobile--20 {
    padding: 20px 0 !important;
  }

  .plr_mobile--20 {
    padding: 0 20px !important;
  }

  .pt_mobile--20 {
    padding-top: 20px !important;
  }

  .pb_mobile--20 {
    padding-bottom: 20px !important;
  }

  .pl_mobile--20 {
    padding-left: 20px !important;
  }

  .pr_mobile--20 {
    padding-right: 20px !important;
  }

  .mt_mobile--20 {
    margin-top: 20px !important;
  }

  .mb_mobile--20 {
    margin-bottom: 20px !important;
  }

  .ptb_mobile--25 {
    padding: 25px 0 !important;
  }

  .plr_mobile--25 {
    padding: 0 25px !important;
  }

  .pt_mobile--25 {
    padding-top: 25px !important;
  }

  .pb_mobile--25 {
    padding-bottom: 25px !important;
  }

  .pl_mobile--25 {
    padding-left: 25px !important;
  }

  .pr_mobile--25 {
    padding-right: 25px !important;
  }

  .mt_mobile--25 {
    margin-top: 25px !important;
  }

  .mb_mobile--25 {
    margin-bottom: 25px !important;
  }

  .ptb_mobile--30 {
    padding: 30px 0 !important;
  }

  .plr_mobile--30 {
    padding: 0 30px !important;
  }

  .pt_mobile--30 {
    padding-top: 30px !important;
  }

  .pb_mobile--30 {
    padding-bottom: 30px !important;
  }

  .pl_mobile--30 {
    padding-left: 30px !important;
  }

  .pr_mobile--30 {
    padding-right: 30px !important;
  }

  .mt_mobile--30 {
    margin-top: 30px !important;
  }

  .mb_mobile--30 {
    margin-bottom: 30px !important;
  }

  .ptb_mobile--35 {
    padding: 35px 0 !important;
  }

  .plr_mobile--35 {
    padding: 0 35px !important;
  }

  .pt_mobile--35 {
    padding-top: 35px !important;
  }

  .pb_mobile--35 {
    padding-bottom: 35px !important;
  }

  .pl_mobile--35 {
    padding-left: 35px !important;
  }

  .pr_mobile--35 {
    padding-right: 35px !important;
  }

  .mt_mobile--35 {
    margin-top: 35px !important;
  }

  .mb_mobile--35 {
    margin-bottom: 35px !important;
  }

  .ptb_mobile--40 {
    padding: 40px 0 !important;
  }

  .plr_mobile--40 {
    padding: 0 40px !important;
  }

  .pt_mobile--40 {
    padding-top: 40px !important;
  }

  .pb_mobile--40 {
    padding-bottom: 40px !important;
  }

  .pl_mobile--40 {
    padding-left: 40px !important;
  }

  .pr_mobile--40 {
    padding-right: 40px !important;
  }

  .mt_mobile--40 {
    margin-top: 40px !important;
  }

  .mb_mobile--40 {
    margin-bottom: 40px !important;
  }

  .ptb_mobile--45 {
    padding: 45px 0 !important;
  }

  .plr_mobile--45 {
    padding: 0 45px !important;
  }

  .pt_mobile--45 {
    padding-top: 45px !important;
  }

  .pb_mobile--45 {
    padding-bottom: 45px !important;
  }

  .pl_mobile--45 {
    padding-left: 45px !important;
  }

  .pr_mobile--45 {
    padding-right: 45px !important;
  }

  .mt_mobile--45 {
    margin-top: 45px !important;
  }

  .mb_mobile--45 {
    margin-bottom: 45px !important;
  }

  .ptb_mobile--50 {
    padding: 50px 0 !important;
  }

  .plr_mobile--50 {
    padding: 0 50px !important;
  }

  .pt_mobile--50 {
    padding-top: 50px !important;
  }

  .pb_mobile--50 {
    padding-bottom: 50px !important;
  }

  .pl_mobile--50 {
    padding-left: 50px !important;
  }

  .pr_mobile--50 {
    padding-right: 50px !important;
  }

  .mt_mobile--50 {
    margin-top: 50px !important;
  }

  .mb_mobile--50 {
    margin-bottom: 50px !important;
  }

  .ptb_mobile--55 {
    padding: 55px 0 !important;
  }

  .plr_mobile--55 {
    padding: 0 55px !important;
  }

  .pt_mobile--55 {
    padding-top: 55px !important;
  }

  .pb_mobile--55 {
    padding-bottom: 55px !important;
  }

  .pl_mobile--55 {
    padding-left: 55px !important;
  }

  .pr_mobile--55 {
    padding-right: 55px !important;
  }

  .mt_mobile--55 {
    margin-top: 55px !important;
  }

  .mb_mobile--55 {
    margin-bottom: 55px !important;
  }

  .ptb_mobile--60 {
    padding: 60px 0 !important;
  }

  .plr_mobile--60 {
    padding: 0 60px !important;
  }

  .pt_mobile--60 {
    padding-top: 60px !important;
  }

  .pb_mobile--60 {
    padding-bottom: 60px !important;
  }

  .pl_mobile--60 {
    padding-left: 60px !important;
  }

  .pr_mobile--60 {
    padding-right: 60px !important;
  }

  .mt_mobile--60 {
    margin-top: 60px !important;
  }

  .mb_mobile--60 {
    margin-bottom: 60px !important;
  }

  .ptb_mobile--65 {
    padding: 65px 0 !important;
  }

  .plr_mobile--65 {
    padding: 0 65px !important;
  }

  .pt_mobile--65 {
    padding-top: 65px !important;
  }

  .pb_mobile--65 {
    padding-bottom: 65px !important;
  }

  .pl_mobile--65 {
    padding-left: 65px !important;
  }

  .pr_mobile--65 {
    padding-right: 65px !important;
  }

  .mt_mobile--65 {
    margin-top: 65px !important;
  }

  .mb_mobile--65 {
    margin-bottom: 65px !important;
  }

  .ptb_mobile--70 {
    padding: 70px 0 !important;
  }

  .plr_mobile--70 {
    padding: 0 70px !important;
  }

  .pt_mobile--70 {
    padding-top: 70px !important;
  }

  .pb_mobile--70 {
    padding-bottom: 70px !important;
  }

  .pl_mobile--70 {
    padding-left: 70px !important;
  }

  .pr_mobile--70 {
    padding-right: 70px !important;
  }

  .mt_mobile--70 {
    margin-top: 70px !important;
  }

  .mb_mobile--70 {
    margin-bottom: 70px !important;
  }

  .ptb_mobile--75 {
    padding: 75px 0 !important;
  }

  .plr_mobile--75 {
    padding: 0 75px !important;
  }

  .pt_mobile--75 {
    padding-top: 75px !important;
  }

  .pb_mobile--75 {
    padding-bottom: 75px !important;
  }

  .pl_mobile--75 {
    padding-left: 75px !important;
  }

  .pr_mobile--75 {
    padding-right: 75px !important;
  }

  .mt_mobile--75 {
    margin-top: 75px !important;
  }

  .mb_mobile--75 {
    margin-bottom: 75px !important;
  }

  .ptb_mobile--80 {
    padding: 80px 0 !important;
  }

  .plr_mobile--80 {
    padding: 0 80px !important;
  }

  .pt_mobile--80 {
    padding-top: 80px !important;
  }

  .pb_mobile--80 {
    padding-bottom: 80px !important;
  }

  .pl_mobile--80 {
    padding-left: 80px !important;
  }

  .pr_mobile--80 {
    padding-right: 80px !important;
  }

  .mt_mobile--80 {
    margin-top: 80px !important;
  }

  .mb_mobile--80 {
    margin-bottom: 80px !important;
  }

  .ptb_mobile--85 {
    padding: 85px 0 !important;
  }

  .plr_mobile--85 {
    padding: 0 85px !important;
  }

  .pt_mobile--85 {
    padding-top: 85px !important;
  }

  .pb_mobile--85 {
    padding-bottom: 85px !important;
  }

  .pl_mobile--85 {
    padding-left: 85px !important;
  }

  .pr_mobile--85 {
    padding-right: 85px !important;
  }

  .mt_mobile--85 {
    margin-top: 85px !important;
  }

  .mb_mobile--85 {
    margin-bottom: 85px !important;
  }

  .ptb_mobile--90 {
    padding: 90px 0 !important;
  }

  .plr_mobile--90 {
    padding: 0 90px !important;
  }

  .pt_mobile--90 {
    padding-top: 90px !important;
  }

  .pb_mobile--90 {
    padding-bottom: 90px !important;
  }

  .pl_mobile--90 {
    padding-left: 90px !important;
  }

  .pr_mobile--90 {
    padding-right: 90px !important;
  }

  .mt_mobile--90 {
    margin-top: 90px !important;
  }

  .mb_mobile--90 {
    margin-bottom: 90px !important;
  }

  .ptb_mobile--95 {
    padding: 95px 0 !important;
  }

  .plr_mobile--95 {
    padding: 0 95px !important;
  }

  .pt_mobile--95 {
    padding-top: 95px !important;
  }

  .pb_mobile--95 {
    padding-bottom: 95px !important;
  }

  .pl_mobile--95 {
    padding-left: 95px !important;
  }

  .pr_mobile--95 {
    padding-right: 95px !important;
  }

  .mt_mobile--95 {
    margin-top: 95px !important;
  }

  .mb_mobile--95 {
    margin-bottom: 95px !important;
  }

  .ptb_mobile--100 {
    padding: 100px 0 !important;
  }

  .plr_mobile--100 {
    padding: 0 100px !important;
  }

  .pt_mobile--100 {
    padding-top: 100px !important;
  }

  .pb_mobile--100 {
    padding-bottom: 100px !important;
  }

  .pl_mobile--100 {
    padding-left: 100px !important;
  }

  .pr_mobile--100 {
    padding-right: 100px !important;
  }

  .mt_mobile--100 {
    margin-top: 100px !important;
  }

  .mb_mobile--100 {
    margin-bottom: 100px !important;
  }
}

.p--10 {
  padding: 10px !important;
}

.p--20 {
  padding: 20px !important;
}

.p--30 {
  padding: 30px !important;
}

.p--40 {
  padding: 40px !important;
}

.p--50 {
  padding: 50px !important;
}

.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.slick-gutter-5 .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.slick-gutter-10 .slick-slide {
  padding-left: 10px;
  padding-right: 10px;
}

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-gutter-15 .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.slick-gutter-20 .slick-slide {
  padding-left: 20px;
  padding-right: 20px;
}

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.slick-gutter-25 .slick-slide {
  padding-left: 25px;
  padding-right: 25px;
}

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.slick-gutter-30 .slick-slide {
  padding-left: 30px;
  padding-right: 30px;
}

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px;
}

.slick-gutter-35 .slick-slide {
  padding-left: 35px;
  padding-right: 35px;
}

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px;
}

.slick-gutter-40 .slick-slide {
  padding-left: 40px;
  padding-right: 40px;
}

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px;
}

.slick-gutter-45 .slick-slide {
  padding-left: 45px;
  padding-right: 45px;
}

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.slick-gutter-50 .slick-slide {
  padding-left: 50px;
  padding-right: 50px;
}

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px;
}

.slick-gutter-55 .slick-slide {
  padding-left: 55px;
  padding-right: 55px;
}

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px;
}

.slick-gutter-60 .slick-slide {
  padding-left: 60px;
  padding-right: 60px;
}

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px;
}

.slick-gutter-65 .slick-slide {
  padding-left: 65px;
  padding-right: 65px;
}

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px;
}

.slick-gutter-70 .slick-slide {
  padding-left: 70px;
  padding-right: 70px;
}

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px;
}

.slick-gutter-75 .slick-slide {
  padding-left: 75px;
  padding-right: 75px;
}

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px;
}

.slick-gutter-80 .slick-slide {
  padding-left: 80px;
  padding-right: 80px;
}

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px;
}

.slick-gutter-85 .slick-slide {
  padding-left: 85px;
  padding-right: 85px;
}

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px;
}

.slick-gutter-90 .slick-slide {
  padding-left: 90px;
  padding-right: 90px;
}

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px;
}

.slick-gutter-95 .slick-slide {
  padding-left: 95px;
  padding-right: 95px;
}

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px;
}

.slick-gutter-100 .slick-slide {
  padding-left: 100px;
  padding-right: 100px;
}

/*------------------------
    Edu Common Styles  
--------------------------*/

/*-------------------------
    Rbt Default
-------------------------*/

.rbt-index-upper {
  position: relative;
  z-index: 1;
}

.sticky-top {
  top: 80px;
  z-index: 9;
}

.rbt-single-group {
  margin: -5px;
}

.rbt-single-group .rbt-single-list {
  margin: 5px;
}

@media only screen and (max-width: 767px) {
  .transform-sm-none {
    transform: none !important;
  }
}

/*-------------------------
    Rbt Round Btn
-------------------------*/

.rbt-round-btn {
  width: 40px;
  height: 40px;
  line-height: 41px;
  text-align: center;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  background: transparent;
  padding: 0;
  border: 0 none;
  display: block;
}

@media only screen and (max-width: 767px) {
  .rbt-round-btn {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.rbt-round-btn i {
  margin-right: 0;
}

.rbt-round-btn::after {
  background: var(--color-gray-light);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.4s;
  opacity: 0;
  transform: scale(0.8);
  border-radius: 100%;
  z-index: -1;
}

.rbt-round-btn.btn-white-off {
  color: var(--color-white-off);
}

.rbt-round-btn:hover,
.rbt-round-btn.open {
  color: var(--color-primary);
}

.rbt-round-btn:hover::after,
.rbt-round-btn.open::after {
  opacity: 1;
  transform: scale(1);
}

/*------------------------------
    Rbt Hover Style
-------------------------------*/

.rbt-hover {
  transition: var(--transition-2);
}

.rbt-hover:hover {
  transform: scale(1.02);
}

.rbt-hover-02 {
  transition: var(--transition-2);
}

.rbt-hover-02:hover {
  transform: translateY(-10px);
}

.rbt-hover-03 {
  transition: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);
}

.rbt-hover-03:hover {
  transform: translateY(-3px);
}

.rbt-link-hover a {
  position: relative;
}

.rbt-link-hover a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s;
}

.rbt-link-hover a:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

/*------------------------------
    Rbt Theme Gradient
-------------------------------*/

.theme-gradient {
  background: linear-gradient(90deg,
      var(--color-primary),
      var(--color-secondary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
  font-weight: var(--f-semi-bold);
}

.rbt-accordion-style .card .card-header button[aria-expanded="true"]::before {
  background: linear-gradient(90deg,
      var(--color-primary),
      var(--color-secondary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
  white-space: nowrap;
}

.theme-gradient.new-big-heading-gradient {
  background: linear-gradient(180deg, #e4e2fa 0%, rgba(57, 78, 244, 0.03) 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
  white-space: nowrap;
}

.rbt-gradient-border {
  background: linear-gradient(-145deg, #cfa2e8, #637fea) !important;
  z-index: 10;
}

.rbt-gradient-border::before {
  content: "";
  z-index: -1;
  top: 1px;
  left: 1px;
  position: absolute;
  background: #fff;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: var(--radius);
}

/*------------------------------
    Rbt Slider Gutter
-------------------------------*/

.gutter-swiper-30 {
  margin: -15px;
}

.gutter-swiper-30 .single-slide {
  padding: 15px;
}

.gutter-swiper-20 {
  margin: -10px;
}

.gutter-swiper-20 .single-slide {
  padding: 10px;
}

.gutter-swiper-10 {
  margin: -5px;
}

.gutter-swiper-10 .single-slide {
  padding: 5px;
}

/*------------------------------
    Rbt rating
-------------------------------*/

.rating a {
  display: inline-block;
}

.rating a i {
  color: var(--color-warning);
  font-style: normal;
}

/*------------------------------
    Rbt Shape Style
-------------------------------*/

.theme-shape {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.theme-shape::before {
  position: absolute;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(45deg,
      var(--color-primary),
      var(--color-secondary));
  opacity: 0.2;
  filter: blur(100px);
  content: "";
}

.theme-shape::after {
  position: absolute;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  opacity: 0.2;
  filter: blur(100px);
  content: "";
  left: auto;
  top: -250px;
  right: -250px;
  bottom: auto;
  background-image: linear-gradient(45deg,
      var(--color-violet),
      var(--color-pink));
}

.rbt-round-bottom-shape {
  position: relative;
  z-index: 1;
}

.rbt-round-bottom-shape::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  /* background: url(../images/bg/banner-bg-shape-1.png); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 148px;
  z-index: -1;
}

.edu-bg-shade {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: 6px;
  padding: 30px;
}

.card-info {
  display: inline-block;
}

.card-info .inner {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: 5px;
  padding: 20px 30px;
}

.card-info .inner .name {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-heading);
}

.card-info .inner .name span {
  font-size: 14px;
  color: var(--color-body);
  font-weight: 400;
}

.card-info .inner .rating-wrapper span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

@media only screen and (max-width: 575px) {
  .card-info .inner .rating-wrapper span {
    margin-left: 0;
  }
}

.card-info .inner .rating-wrapper i {
  color: #ffa41b;
}

.card-info .notify-icon {
  width: 100px;
  border-radius: 100%;
  position: absolute;
  top: -50px;
  left: -50px;
  text-align: center;
  height: 100px;
}

.card-info .notify-icon .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card-info .notify-icon .icon svg {
  color: var(--color-white);
  width: 28px;
}

.card-info .notify-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*-------------------------
    Card Author Meta 
-------------------------*/

.rbt-author-meta {
  display: flex;
  align-items: center;
}

.rbt-author-meta .rbt-avater {
  margin-right: 10px;
}

.rbt-author-meta .rbt-avater a {
  display: block;
}

.rbt-author-meta .rbt-avater img {
  width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  border: 2px solid var(--primary-opacity);
  padding: 2px;
}

.rbt-author-meta .rbt-author-info {
  font-size: 14px;
}

.rbt-author-meta .rbt-author-info a {
  color: var(--color-heading);
  font-weight: 500;
}

.rbt-author-meta .rbt-author-info a:hover {
  color: var(--color-primary);
}

/*-----------------------------
    Rbt Border Style  
------------------------------*/

hr {
  background-color: var(--color-border-2);
  opacity: 1;
}

.rbt-border-none {
  border: 0 none !important;
}

.rbt-border {
  border: 1px solid var(--color-border) !important;
}

.rbt-border-primary {
  border: 1px solid var(--color-primary) !important;
}

.rbt-border-dashed {
  border: 2px dashed var(--color-border) !important;
}

.rbt-border-with-box {
  padding: 30px;
  border-radius: var(--radius);
  background: var(--color-white);
  overflow: hidden;
  border: 1px solid var(--color-border);
}

@media only screen and (max-width: 767px) {
  .rbt-border-with-box {
    padding: 15px;
  }
}

.rbt-border-bottom {
  border-bottom: 1px solid var(--color-border);
}

.rbt-border-top {
  border-top: 1px solid var(--color-border);
}

.rbt-border-bottom-light {
  border-bottom: 1px solid var(--color-border-2);
}

.border-bottom-4 {
  border-bottom: 4px solid var(--color-primary);
}

.border-top-bar-primary-color {
  border-top: 5px solid var(--color-primary);
}

.border-top-bar-secondary-color {
  border-top: 5px solid var(--color-secondary);
}

.rbt-separator {
  position: relative;
}

.border-color-primary {
  border-color: var(--color-primary);
}

.border-color-secondary {
  border-color: var(--color-secondary);
}

.border-color-coral {
  border-color: var(--color-coral);
}

.border-color-violet {
  border-color: var(--color-violet);
}

.border-color-pink {
  border-color: var(--color-pink);
}

.border-color-card-1 {
  border-color: var(--color-card-1);
}

.border-color-card-2 {
  border-color: var(--color-card-2);
}

.border-color-card-3 {
  border-color: var(--color-card-3);
}

.border-color-card-4 {
  border-color: var(--color-card-4);
}

/*-----------------------------
    Rbt Border Radius  
-------------------------------*/

.square {
  border-radius: 0 !important;
}

.rbt-radius {
  border-radius: 6px !important;
}

.radius {
  border-radius: 6px !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-round {
  border-radius: 500px !important;
}

/*-----------------------------
    Rbt Shadow
-------------------------------*/

.rbt-shadow-box {
  border-radius: var(--radius);
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  padding: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-shadow-box {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-shadow-box {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-shadow-box {
    padding: 20px;
  }
}

.bg-no-shadow {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: var(--shadow-1);
}

.shadow-2 {
  box-shadow: var(--shadow-2);
}

.shadow-3 {
  box-shadow: var(--shadow-3);
}

.shadow-4 {
  box-shadow: var(--shadow-1);
}

.shadow-5 {
  box-shadow: var(--shadow-5);
}

.shadow-6 {
  box-shadow: var(--shadow-1);
}

.shadow-7 {
  box-shadow: var(--shadow-7);
}

.shadow-8 {
  box-shadow: var(--shadow-8);
}

/*--------------------------
    Font Weight 
---------------------------*/

.w-300 {
  font-weight: 300 !important;
}

.w-400 {
  font-weight: 400 !important;
}

.w-500 {
  font-weight: 500 !important;
}

.w-600 {
  font-weight: 600 !important;
}

.w-700 {
  font-weight: 700 !important;
}

.w-800 {
  font-weight: 800 !important;
}

.w-900 {
  font-weight: 900 !important;
}

/*-----------------------------
    Card bg Inner Color 
--------------------------------*/

.bg-card-color-1 .inner {
  background: #fde29275 !important;
}

.bg-card-color-2 .inner {
  background: #ffdbe175 !important;
}

.bg-card-color-3 .inner {
  background: #ffc5fa75 !important;
}

.bg-card-color-4 .inner {
  background: #c8ffe975 !important;
}

.bg-card-color-5 .inner {
  background: #e4a7f675 !important;
}

/*-----------------------------
    Rbt Background Image
--------------------------------*/

.bg_image_fixed {
  background-attachment: fixed !important;
}

.bg--fixed {
  background-attachment: fixed !important;
}

.bg--sticky {
  position: sticky !important;
  top: 3.75rem;
}

/* .bg_image--1 {
  background-image: url(../images/bg/app_bg.jpg);
}

.bg_image--2 {
  background-image: url(../images/bg/bg-image-2.jpg);
}

.bg_image--3 {
  background-image: url(../images/bg/bg-image-3.jpg);
}

.bg_image--4 {
  background-image: url(../images/bg/bg-image-4.jpg);
}

.bg_image--5 {
  background-image: url(../images/bg/bg-image-5.jpg);
}

.bg_image--6 {
  background-image: url(../images/bg/bg-image-6.jpg);
}

.bg_image--7 {
  background-image: url(../images/bg/bg-image-7.jpg);
}

.bg_image--8 {
  background-image: url(../images/bg/bg-image-8.jpg);
}

.bg_image--9 {
  background-image: url(../images/bg/bg-image-9.jpg);
}

.bg_image--10 {
  background-image: url(../images/bg/bg-image-10.jpg);
}

.bg_image--11 {
  background-image: url(../images/bg/bg-image-11.jpg);
}

.bg_image--12 {
  background-image: url(../images/bg/bg-image-12.jpg);
}

.bg_image--13 {
  background-image: url(../images/bg/bg-image-13.jpg);
}

.bg_image--14 {
  background-image: url(../images/bg/bg-image-14.jpg);
}

.bg_image--15 {
  background-image: url(../images/bg/bg-image-15.jpg);
}

.bg_image--16 {
  background-image: url(../images/bg/bg-image-16.jpg);
}

.bg_image--17 {
  background-image: url(../images/bg/bg-image-17.jpg);
}

.bg_image--18 {
  background-image: url(../images/bg/bg-image-18.jpg);
}

.bg_image--19 {
  background-image: url(../images/bg/bg-image-19.jpg);
}

.bg_image--20 {
  background-image: url(../images/bg/bg-image-20.jpg);
}

.bg_image--21 {
  background-image: url(../images/bg/bg-image-21.jpg);
}

.bg_image--22 {
  background-image: url(../images/bg/bg-image-22.jpg);
}

.bg_image--23 {
  background-image: url(../images/bg/bg-image-23.jpg);
}

.bg_image--24 {
  background-image: url(../images/bg/bg-image-24.jpg);
}

.bg_image--25 {
  background-image: url(../images/bg/bg-image-25.jpg);
}

.bg_image--26 {
  background-image: url(../images/bg/bg-image-26.jpg);
}

.bg_image--27 {
  background-image: url(../images/bg/bg-image-27.jpg);
}

.bg_image--28 {
  background-image: url(../images/bg/bg-image-28.jpg);
}

.bg_image--29 {
  background-image: url(../images/bg/bg-image-29.jpg);
}

.bg_image--30 {
  background-image: url(../images/bg/bg-image-30.jpg);
} */

.bg_image--9 {
  background-position: bottom 44% center;
}

.rbt-alert-success.alert-success {
  color: var(--color-body);
  background-color: var(--color-grey);
  border: 0 none;
  border-top: 5px solid var(--color-success);
  padding: 10px 0;
  text-align: center;
}

.rbt-alert-success.alert-success a {
  color: var(--color-heading);
}

.rbt-avatars {
  min-width: 70px;
  max-width: 70px;
}

.rbt-avatars.size-lg {
  min-width: 120px;
  max-width: 120px;
  width: 120px;
  height: 120px;
}

.rbt-avatars.size-sm {
  min-width: 52px;
  max-width: 52px;
}

.rbt-avatars img {
  border-radius: 100%;
  width: 100%;
  background: var(--color-white);
  padding: 4px;
  border: 2px solid var(--primary-opacity);
  object-fit: cover;
}

.rbt-edit-photo-inner {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.rbt-edit-photo-inner .rbt-edit-photo {
  background: var(--color-gray-light);
  border-radius: 100%;
  color: var(--color-primary);
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0 none;
  transition: 0.3s;
}

.rbt-edit-photo-inner .rbt-edit-photo:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg_image--19 {
    background-position: center right 25%;
  }
}

@media only screen and (max-width: 767px) {
  .bg_image--19 {
    background-position: center right 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg_image--22 {
    background-position: center right 33%;
  }
}

@media only screen and (max-width: 767px) {
  .bg_image--22 {
    background-position: center right 33%;
  }
}

/* ========= Forms Styles ========= */

/* button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
} */

input,
select,
textarea {
  width: 100%;
  transition: 0.3s;
}

/* input {
    height: 48px;
    padding: 10px 14px;
    border-radius: 5px;
    border: 2px solid #ededed;
} */

/* select {
    transition: 0.3s;
    height: 60px;
    line-height: 28px;
    padding: 10px 20px;
    outline: none;
    color: var(--color-body);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding-right: 30px;
    background: #ffffff url(../images/icons/arrow.png) no-repeat center right 20px;
} */

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  font-size: 16px;
  font-weight: 400;
  /* height: 48px; */
  line-height: 28px;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  color: var(--color-body);
  box-shadow: var(--shadow-10);
  /* -- Placeholder -- */
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
  color: var(--body-color);
  /* Firefox */
  opacity: 1;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--body-color);
  opacity: 1;
}

input[type="text"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--body-color);
  opacity: 1;
}

input[type="text"].p-holder__active,
.input-active input[type="text"],
input[type="text"].input-active,
input[type="password"].p-holder__active,
.input-active input[type="password"],
input[type="password"].input-active,
input[type="email"].p-holder__active,
.input-active input[type="email"],
input[type="email"].input-active,
input[type="number"].p-holder__active,
.input-active input[type="number"],
input[type="number"].input-active,
input[type="tel"].p-holder__active,
.input-active input[type="tel"],
input[type="tel"].input-active,
textarea.p-holder__active,
textarea.input-active {
  border-color: var(--color-primary);
  /* -- Placeholder -- */
}

input[type="text"].p-holder__active::placeholder,
.input-active input[type="text"]::placeholder,
input[type="text"].input-active::placeholder,
input[type="password"].p-holder__active::placeholder,
.input-active input[type="password"]::placeholder,
input[type="password"].input-active::placeholder,
input[type="email"].p-holder__active::placeholder,
.input-active input[type="email"]::placeholder,
input[type="email"].input-active::placeholder,
input[type="number"].p-holder__active::placeholder,
.input-active input[type="number"]::placeholder,
input[type="number"].input-active::placeholder,
input[type="tel"].p-holder__active::placeholder,
.input-active input[type="tel"]::placeholder,
input[type="tel"].input-active::placeholder,
textarea.p-holder__active::placeholder,
textarea.input-active::placeholder {
  color: var(--color-primary);
  /* Firefox */
  opacity: 1;
}

input[type="text"].p-holder__active:-ms-input-placeholder,
.input-active input[type="text"]:-ms-input-placeholder,
input[type="text"].input-active:-ms-input-placeholder,
input[type="password"].p-holder__active:-ms-input-placeholder,
.input-active input[type="password"]:-ms-input-placeholder,
input[type="password"].input-active:-ms-input-placeholder,
input[type="email"].p-holder__active:-ms-input-placeholder,
.input-active input[type="email"]:-ms-input-placeholder,
input[type="email"].input-active:-ms-input-placeholder,
input[type="number"].p-holder__active:-ms-input-placeholder,
.input-active input[type="number"]:-ms-input-placeholder,
input[type="number"].input-active:-ms-input-placeholder,
input[type="tel"].p-holder__active:-ms-input-placeholder,
.input-active input[type="tel"]:-ms-input-placeholder,
input[type="tel"].input-active:-ms-input-placeholder,
textarea.p-holder__active:-ms-input-placeholder,
textarea.input-active:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-primary);
}

input[type="text"].p-holder__active::-ms-input-placeholder,
.input-active input[type="text"]::-ms-input-placeholder,
input[type="text"].input-active::-ms-input-placeholder,
input[type="password"].p-holder__active::-ms-input-placeholder,
.input-active input[type="password"]::-ms-input-placeholder,
input[type="password"].input-active::-ms-input-placeholder,
input[type="email"].p-holder__active::-ms-input-placeholder,
.input-active input[type="email"]::-ms-input-placeholder,
input[type="email"].input-active::-ms-input-placeholder,
input[type="number"].p-holder__active::-ms-input-placeholder,
.input-active input[type="number"]::-ms-input-placeholder,
input[type="number"].input-active::-ms-input-placeholder,
input[type="tel"].p-holder__active::-ms-input-placeholder,
.input-active input[type="tel"]::-ms-input-placeholder,
input[type="tel"].input-active::-ms-input-placeholder,
textarea.p-holder__active::-ms-input-placeholder,
textarea.input-active::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-primary);
}

input[type="text"].p-holder__error,
.input-error input[type="text"],
input[type="text"].input-error,
input[type="password"].p-holder__error,
.input-error input[type="password"],
input[type="password"].input-error,
input[type="email"].p-holder__error,
.input-error input[type="email"],
input[type="email"].input-error,
input[type="number"].p-holder__error,
.input-error input[type="number"],
input[type="number"].input-error,
input[type="tel"].p-holder__error,
.input-error input[type="tel"],
input[type="tel"].input-error,
textarea.p-holder__error,
textarea.input-error {
  border-color: #f4282d;
  /* -- Placeholder -- */
}

input[type="text"].p-holder__error::placeholder,
.input-error input[type="text"]::placeholder,
input[type="text"].input-error::placeholder,
input[type="password"].p-holder__error::placeholder,
.input-error input[type="password"]::placeholder,
input[type="password"].input-error::placeholder,
input[type="email"].p-holder__error::placeholder,
.input-error input[type="email"]::placeholder,
input[type="email"].input-error::placeholder,
input[type="number"].p-holder__error::placeholder,
.input-error input[type="number"]::placeholder,
input[type="number"].input-error::placeholder,
input[type="tel"].p-holder__error::placeholder,
.input-error input[type="tel"]::placeholder,
input[type="tel"].input-error::placeholder,
textarea.p-holder__error::placeholder,
textarea.input-error::placeholder {
  color: #f4282d;
  /* Firefox */
  opacity: 1;
}

input[type="text"].p-holder__error:-ms-input-placeholder,
.input-error input[type="text"]:-ms-input-placeholder,
input[type="text"].input-error:-ms-input-placeholder,
input[type="password"].p-holder__error:-ms-input-placeholder,
.input-error input[type="password"]:-ms-input-placeholder,
input[type="password"].input-error:-ms-input-placeholder,
input[type="email"].p-holder__error:-ms-input-placeholder,
.input-error input[type="email"]:-ms-input-placeholder,
input[type="email"].input-error:-ms-input-placeholder,
input[type="number"].p-holder__error:-ms-input-placeholder,
.input-error input[type="number"]:-ms-input-placeholder,
input[type="number"].input-error:-ms-input-placeholder,
input[type="tel"].p-holder__error:-ms-input-placeholder,
.input-error input[type="tel"]:-ms-input-placeholder,
input[type="tel"].input-error:-ms-input-placeholder,
textarea.p-holder__error:-ms-input-placeholder,
textarea.input-error:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f4282d;
}

input[type="text"].p-holder__error::-ms-input-placeholder,
.input-error input[type="text"]::-ms-input-placeholder,
input[type="text"].input-error::-ms-input-placeholder,
input[type="password"].p-holder__error::-ms-input-placeholder,
.input-error input[type="password"]::-ms-input-placeholder,
input[type="password"].input-error::-ms-input-placeholder,
input[type="email"].p-holder__error::-ms-input-placeholder,
.input-error input[type="email"]::-ms-input-placeholder,
input[type="email"].input-error::-ms-input-placeholder,
input[type="number"].p-holder__error::-ms-input-placeholder,
.input-error input[type="number"]::-ms-input-placeholder,
input[type="number"].input-error::-ms-input-placeholder,
input[type="tel"].p-holder__error::-ms-input-placeholder,
.input-error input[type="tel"]::-ms-input-placeholder,
input[type="tel"].input-error::-ms-input-placeholder,
textarea.p-holder__error::-ms-input-placeholder,
textarea.input-error::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f4282d;
}

input[type="text"].p-holder__error:focus,
.input-error input[type="text"]:focus,
input[type="text"].input-error:focus,
input[type="password"].p-holder__error:focus,
.input-error input[type="password"]:focus,
input[type="password"].input-error:focus,
input[type="email"].p-holder__error:focus,
.input-error input[type="email"]:focus,
input[type="email"].input-error:focus,
input[type="number"].p-holder__error:focus,
.input-error input[type="number"]:focus,
input[type="number"].input-error:focus,
input[type="tel"].p-holder__error:focus,
.input-error input[type="tel"]:focus,
input[type="tel"].input-error:focus,
textarea.p-holder__error:focus,
textarea.input-error:focus {
  border-color: #f4282d;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border-color: var(--color-primary);
}

input[type="checkbox"]~label,
input[type="radio"]~label {
  position: relative;
  font-size: 15px;
  line-height: 25px;
  color: var(--body-color);
  font-weight: 400;
  padding-left: 26px;
  cursor: pointer;
  margin-bottom: 0;
}

input[type="checkbox"]~label::before,
input[type="radio"]~label::before {
  content: " ";
  position: absolute;
  top: 3px;
  left: -3px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: var(--border-width) solid #ccc;
  border-radius: 2px;
  transition: all 0.3s;
}

input[type="checkbox"]~label::after,
input[type="radio"]~label::after {
  content: " ";
  position: absolute;
  top: 9px;
  left: 2px;
  width: 10px;
  height: 5px;
  background-color: transparent;
  border-bottom: var(--border-width) solid #fff;
  border-left: var(--border-width) solid #fff;
  border-radius: 2px;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.3s;
}

input[type="checkbox"]:checked~label::before,
input[type="radio"]:checked~label::before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

input[type="checkbox"]:checked~label::after,
input[type="radio"]:checked~label::after {
  opacity: 1;
}

input[type="radio"]~label::before {
  border-radius: 50%;
}

input[type="radio"]~label::after {
  width: 8px;
  height: 8px;
  left: 3px;
  background: #fff;
  border-radius: 50%;
}

.form-group {
  margin-bottom: 20px;
  display: inline-block;
}

.form-group input {
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  height: 50px;
  font-size: 16px;
  padding: 0px;
  width: 100%;
}

.form-group input:focus {
  border-color: var(--color-primary);
}

.form-group textarea {
  height: auto;
  min-height: 193px;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  resize: none;
  padding: 15px;
  font-size: 16px;
  width: 100%;
}

.form-group textarea:focus {
  border-color: var(--color-primary);
}

textarea {
  padding: 10px 15px;
  height: auto;
}

input[type="submit"] {
  width: auto;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 15px 20px;
  border-radius: 6px;
  display: block;
  font-weight: 500;
  transition: 0.3s;
  border: var(--border-width) solid var(--color-primary);
  background: var(--color-primary);
  color: #ffffff;
  height: 60px;
  margin-top: 30px;
}

input[type="submit"]:hover {
  color: #ffffff;
  -webkit-box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
  box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.contact-form--1 form input::-webkit-input-placeholder,
.contact-form--1 form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-body) !important;
}

.contact-form--1 form input::-moz-placeholder,
.contact-form--1 form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-body) !important;
}

.contact-form--1 form input:-ms-input-placeholder,
.contact-form--1 form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-body) !important;
}

.contact-form--1 form input:-moz-placeholder,
.contact-form--1 form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-body) !important;
}

.form-check {
  padding-left: 0;
}

.rbt-checkbox-wrapper {
  position: relative;
}

input[disabled] {
  display: none;
}

/* -------------------------------- 

Primary style

-------------------------------- */

.cd-title {
  position: relative;
  text-align: center;
}

.cd-intro {
  width: 90%;
  max-width: 768px;
  text-align: center;
}

.cd-intro {
  margin: 4em auto;
}

@media only screen and (min-width: 768px) {
  .cd-intro {
    margin: 5em auto;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-intro {
    margin: 6em auto;
  }
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* -------------------------------- 

  xrotate-1 

  -------------------------------- */

.cd-headline.rotate-1 .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px;
}

.cd-headline.rotate-1 b {
  opacity: 0;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.cd-headline.rotate-1 b.is-visible {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-animation: cd-rotate-1-in 1.2s;
  -moz-animation: cd-rotate-1-in 1.2s;
  animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-animation: cd-rotate-1-out 1.2s;
  -moz-animation: cd-rotate-1-out 1.2s;
  animation: cd-rotate-1-out 1.2s;
}

@-webkit-keyframes cd-rotate-1-in {
  0% {
    -webkit-transform: rotateX(180deg);
    opacity: 0;
  }

  35% {
    -webkit-transform: rotateX(120deg);
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(360deg);
    opacity: 1;
  }
}

@-moz-keyframes cd-rotate-1-in {
  0% {
    -moz-transform: rotateX(180deg);
    opacity: 0;
  }

  35% {
    -moz-transform: rotateX(120deg);
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    -moz-transform: rotateX(360deg);
    opacity: 1;
  }
}

@keyframes cd-rotate-1-in {
  0% {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }

  35% {
    -webkit-transform: rotateX(120deg);
    -moz-transform: rotateX(120deg);
    -ms-transform: rotateX(120deg);
    -o-transform: rotateX(120deg);
    transform: rotateX(120deg);
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    -ms-transform: rotateX(360deg);
    -o-transform: rotateX(360deg);
    transform: rotateX(360deg);
    opacity: 1;
  }
}

@-webkit-keyframes cd-rotate-1-out {
  0% {
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }

  35% {
    -webkit-transform: rotateX(-40deg);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(180deg);
    opacity: 0;
  }
}

@-moz-keyframes cd-rotate-1-out {
  0% {
    -moz-transform: rotateX(0deg);
    opacity: 1;
  }

  35% {
    -moz-transform: rotateX(-40deg);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    -moz-transform: rotateX(180deg);
    opacity: 0;
  }
}

@keyframes cd-rotate-1-out {
  0% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }

  35% {
    -webkit-transform: rotateX(-40deg);
    -moz-transform: rotateX(-40deg);
    -ms-transform: rotateX(-40deg);
    -o-transform: rotateX(-40deg);
    transform: rotateX(-40deg);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }
}

/* -------------------------------- 

  xtype 

  -------------------------------- */

.cd-headline.type .cd-words-wrapper {
  vertical-align: top;
  overflow: hidden;
}

.cd-headline.type .cd-words-wrapper::after {
  /* vertical bar */
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 90%;
  width: 1px;
  background-color: #aebcb9;
}

.cd-headline.type .cd-words-wrapper.waiting::after {
  -webkit-animation: cd-pulse 1s infinite;
  -moz-animation: cd-pulse 1s infinite;
  animation: cd-pulse 1s infinite;
}

.cd-headline.type .cd-words-wrapper.selected {
  background-color: #aebcb9;
}

.cd-headline.type .cd-words-wrapper.selected::after {
  visibility: hidden;
}

.cd-headline.type .cd-words-wrapper.selected b {
  color: #0d0d0d;
}

.cd-headline.type b {
  visibility: hidden;
}

.cd-headline.type b.is-visible {
  visibility: visible;
}

.cd-headline.type i {
  position: absolute;
  visibility: hidden;
}

.cd-headline.type i.in {
  position: relative;
  visibility: visible;
}

@-webkit-keyframes cd-pulse {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}

@-moz-keyframes cd-pulse {
  0% {
    -moz-transform: translateY(-50%) scale(1);
    opacity: 1;
  }

  40% {
    -moz-transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }

  100% {
    -moz-transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}

@keyframes cd-pulse {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-50%) scale(0.9);
    -moz-transform: translateY(-50%) scale(0.9);
    -ms-transform: translateY(-50%) scale(0.9);
    -o-transform: translateY(-50%) scale(0.9);
    transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    -o-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}

/* -------------------------------- 

  xrotate-2 

  -------------------------------- */

.cd-headline.rotate-2 .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px;
}

.cd-headline.rotate-2 i,
.cd-headline.rotate-2 em {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-headline.rotate-2 b {
  opacity: 0;
}

.cd-headline.rotate-2 i {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-20px) rotateX(90deg);
  -moz-transform: translateZ(-20px) rotateX(90deg);
  -ms-transform: translateZ(-20px) rotateX(90deg);
  -o-transform: translateZ(-20px) rotateX(90deg);
  transform: translateZ(-20px) rotateX(90deg);
  opacity: 0;
}

.is-visible .cd-headline.rotate-2 i {
  opacity: 1;
}

.cd-headline.rotate-2 i.in {
  -webkit-animation: cd-rotate-2-in 0.4s forwards;
  -moz-animation: cd-rotate-2-in 0.4s forwards;
  animation: cd-rotate-2-in 0.4s forwards;
}

.cd-headline.rotate-2 i.out {
  -webkit-animation: cd-rotate-2-out 0.4s forwards;
  -moz-animation: cd-rotate-2-out 0.4s forwards;
  animation: cd-rotate-2-out 0.4s forwards;
}

.cd-headline.rotate-2 em {
  -webkit-transform: translateZ(20px);
  -moz-transform: translateZ(20px);
  -ms-transform: translateZ(20px);
  -o-transform: translateZ(20px);
  transform: translateZ(20px);
}

.no-csstransitions .cd-headline.rotate-2 i {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 0;
}

.no-csstransitions .cd-headline.rotate-2 i em {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.no-csstransitions .cd-headline.rotate-2 .is-visible i {
  opacity: 1;
}

@-webkit-keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(-10deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0deg);
  }
}

@-moz-keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -moz-transform: translateZ(-20px) rotateX(90deg);
  }

  60% {
    opacity: 1;
    -moz-transform: translateZ(-20px) rotateX(-10deg);
  }

  100% {
    opacity: 1;
    -moz-transform: translateZ(-20px) rotateX(0deg);
  }
}

@keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
    -moz-transform: translateZ(-20px) rotateX(90deg);
    -ms-transform: translateZ(-20px) rotateX(90deg);
    -o-transform: translateZ(-20px) rotateX(90deg);
    transform: translateZ(-20px) rotateX(90deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(-10deg);
    -moz-transform: translateZ(-20px) rotateX(-10deg);
    -ms-transform: translateZ(-20px) rotateX(-10deg);
    -o-transform: translateZ(-20px) rotateX(-10deg);
    transform: translateZ(-20px) rotateX(-10deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0deg);
    -moz-transform: translateZ(-20px) rotateX(0deg);
    -ms-transform: translateZ(-20px) rotateX(0deg);
    -o-transform: translateZ(-20px) rotateX(0deg);
    transform: translateZ(-20px) rotateX(0deg);
  }
}

@-webkit-keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-100deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-90deg);
  }
}

@-moz-keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -moz-transform: translateZ(-20px) rotateX(0);
  }

  60% {
    opacity: 0;
    -moz-transform: translateZ(-20px) rotateX(-100deg);
  }

  100% {
    opacity: 0;
    -moz-transform: translateZ(-20px) rotateX(-90deg);
  }
}

@keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0);
    -moz-transform: translateZ(-20px) rotateX(0);
    -ms-transform: translateZ(-20px) rotateX(0);
    -o-transform: translateZ(-20px) rotateX(0);
    transform: translateZ(-20px) rotateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-100deg);
    -moz-transform: translateZ(-20px) rotateX(-100deg);
    -ms-transform: translateZ(-20px) rotateX(-100deg);
    -o-transform: translateZ(-20px) rotateX(-100deg);
    transform: translateZ(-20px) rotateX(-100deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-90deg);
    -moz-transform: translateZ(-20px) rotateX(-90deg);
    -ms-transform: translateZ(-20px) rotateX(-90deg);
    -o-transform: translateZ(-20px) rotateX(-90deg);
    transform: translateZ(-20px) rotateX(-90deg);
  }
}

/* -------------------------------- 

  xloading-bar 

  -------------------------------- */

.cd-headline.loading-bar span {
  display: inline-block;
  padding: 0.2em 0;
}

.cd-headline.loading-bar .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
  /* loading bar */
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background: var(--color-primary);
  z-index: 2;
  -webkit-transition: width 0.3s -0.1s;
  -moz-transition: width 0.3s -0.1s;
  transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
  width: 100%;
  -webkit-transition: width 3s;
  -moz-transition: width 3s;
  transition: width 3s;
}

.cd-headline.loading-bar b {
  top: 0.2em;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.cd-headline.loading-bar b.is-visible {
  opacity: 1;
  top: 0;
}

/* -------------------------------- 

  xslide 

  -------------------------------- */

.cd-headline.slide span {
  display: inline-block;
}

.cd-headline.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.slide b {
  opacity: 0;
  top: 0.2em;
}

.cd-headline.slide b.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  -moz-animation: slide-in 0.6s;
  animation: slide-in 0.6s;
}

.cd-headline.slide b.is-hidden {
  -webkit-animation: slide-out 0.6s;
  -moz-animation: slide-out 0.6s;
  animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}

@-moz-keyframes slide-out {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  60% {
    opacity: 0;
    -moz-transform: translateY(120%);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -moz-transform: translateY(120%);
    -ms-transform: translateY(120%);
    -o-transform: translateY(120%);
    transform: translateY(120%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}

/* -------------------------------- 

  xclip 

  -------------------------------- */

.cd-headline.clip span {
  display: inline-block;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
  /* line */
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #aebcb9;
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline.clip b.is-visible {
  opacity: 1;
}

/* -------------------------------- 

  xzoom 

  -------------------------------- */

.cd-headline.zoom .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px;
}

.cd-headline.zoom b {
  opacity: 0;
}

.cd-headline.zoom b.is-visible {
  opacity: 1;
  -webkit-animation: zoom-in 0.8s;
  -moz-animation: zoom-in 0.8s;
  animation: zoom-in 0.8s;
}

.cd-headline.zoom b.is-hidden {
  -webkit-animation: zoom-out 0.8s;
  -moz-animation: zoom-out 0.8s;
  animation: zoom-out 0.8s;
}

@-webkit-keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
  }
}

@-moz-keyframes zoom-in {
  0% {
    opacity: 0;
    -moz-transform: translateZ(100px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateZ(0);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(100px);
    -moz-transform: translateZ(100px);
    -ms-transform: translateZ(100px);
    -o-transform: translateZ(100px);
    transform: translateZ(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes zoom-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateZ(-100px);
  }
}

@-moz-keyframes zoom-out {
  0% {
    opacity: 1;
    -moz-transform: translateZ(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateZ(-100px);
  }
}

@keyframes zoom-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateZ(-100px);
    -moz-transform: translateZ(-100px);
    -ms-transform: translateZ(-100px);
    -o-transform: translateZ(-100px);
    transform: translateZ(-100px);
  }
}

/* -------------------------------- 

  xrotate-3 

  -------------------------------- */

.cd-headline.rotate-3 .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px;
}

.cd-headline.rotate-3 b {
  opacity: 0;
}

.cd-headline.rotate-3 i {
  display: inline-block;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.is-visible .cd-headline.rotate-3 i {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.cd-headline.rotate-3 i.in {
  -webkit-animation: cd-rotate-3-in 0.6s forwards;
  -moz-animation: cd-rotate-3-in 0.6s forwards;
  animation: cd-rotate-3-in 0.6s forwards;
}

.cd-headline.rotate-3 i.out {
  -webkit-animation: cd-rotate-3-out 0.6s forwards;
  -moz-animation: cd-rotate-3-out 0.6s forwards;
  animation: cd-rotate-3-out 0.6s forwards;
}

.no-csstransitions .cd-headline.rotate-3 i {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 0;
}

.no-csstransitions .cd-headline.rotate-3 .is-visible i {
  opacity: 1;
}

@-webkit-keyframes cd-rotate-3-in {
  0% {
    -webkit-transform: rotateY(180deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
  }
}

@-moz-keyframes cd-rotate-3-in {
  0% {
    -moz-transform: rotateY(180deg);
  }

  100% {
    -moz-transform: rotateY(0deg);
  }
}

@keyframes cd-rotate-3-in {
  0% {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes cd-rotate-3-out {
  0% {
    -webkit-transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(-180deg);
  }
}

@-moz-keyframes cd-rotate-3-out {
  0% {
    -moz-transform: rotateY(0);
  }

  100% {
    -moz-transform: rotateY(-180deg);
  }
}

@keyframes cd-rotate-3-out {
  0% {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

/* -------------------------------- 

  xscale 

  -------------------------------- */

.cd-headline.scale b {
  opacity: 0;
}

.cd-headline.scale i {
  display: inline-block;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.is-visible .cd-headline.scale i {
  opacity: 1;
}

.cd-headline.scale i.in {
  -webkit-animation: scale-up 0.6s forwards;
  -moz-animation: scale-up 0.6s forwards;
  animation: scale-up 0.6s forwards;
}

.cd-headline.scale i.out {
  -webkit-animation: scale-down 0.6s forwards;
  -moz-animation: scale-down 0.6s forwards;
  animation: scale-down 0.6s forwards;
}

.no-csstransitions .cd-headline.scale i {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}

.no-csstransitions .cd-headline.scale .is-visible i {
  opacity: 1;
}

@-webkit-keyframes scale-up {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@-moz-keyframes scale-up {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  60% {
    -moz-transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-up {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes scale-down {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
}

@-moz-keyframes scale-down {
  0% {
    -moz-transform: scale(1);
    opacity: 1;
  }

  60% {
    -moz-transform: scale(0);
    opacity: 0;
  }
}

@keyframes scale-down {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

/* -------------------------------- 

  xpush 

  -------------------------------- */

.cd-headline.push b {
  opacity: 0;
}

.cd-headline.push b.is-visible {
  opacity: 1;
  -webkit-animation: push-in 0.6s;
  -moz-animation: push-in 0.6s;
  animation: push-in 0.6s;
}

.cd-headline.push b.is-hidden {
  -webkit-animation: push-out 0.6s;
  -moz-animation: push-out 0.6s;
  animation: push-out 0.6s;
}

@-webkit-keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes push-in {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(10%);
    -moz-transform: translateX(10%);
    -ms-transform: translateX(10%);
    -o-transform: translateX(10%);
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
}

@-moz-keyframes push-out {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  60% {
    opacity: 0;
    -moz-transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
    -moz-transform: translateX(110%);
    -ms-transform: translateX(110%);
    -o-transform: translateX(110%);
    transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/**************************************
	Header Styles
***************************************/

/*---------------------------
    Header Common Styles  
----------------------------*/

.logo a {
  display: inline-block;
}

.logo a img {
  max-height: 50px;
  object-fit: cover;
}

.rbt-header-top {
  position: relative;
  z-index: 9999;
}

.rbt-header-middle {
  position: relative;
  z-index: 999;
}

/*-----------------------------
    Header Space Between  
------------------------------*/

.header-space-betwween {
  padding-left: 150px;
  padding-right: 150px;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .header-space-betwween {
    /* padding-left: 50px; */
    /* padding-right: 50px; */
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .header-space-betwween {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-space-betwween {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-space-betwween {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header-space-betwween {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .header-space-betwween {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/*----------------------------
    Header Top Information 
-----------------------------*/

.rbt-information-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  list-style: none;
  margin: -2px -7px;
  flex-wrap: wrap;
}

.rbt-information-list li {
  margin: 2px 7px;
  color: var(--color-body);
}

.rbt-information-list li a {
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-body);
  display: flex;
  align-items: center;
  transition: 0.4s;
  font-weight: 400;
}

.rbt-information-list li a i {
  margin-right: 10px;
  font-size: 14px;
  margin-top: 2px;
}

.rbt-information-list li a span {
  margin-left: 4px;
}

.rbt-information-list li a:hover {
  color: var(--color-primary);
}

/*--------------------------
    Dropdown Menu  
----------------------------*/

ul.rbt-dropdown-menu li {
  margin: 0;
  list-style: none;
}

ul.rbt-dropdown-menu li a {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  color: var(--color-body);
}

ul.rbt-dropdown-menu li a .left-image {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

ul.rbt-dropdown-menu li a span {
  display: inline-block;
}

ul.rbt-dropdown-menu li a .right-icon {
  display: inline-block;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  ul.rbt-dropdown-menu li a .right-icon {
    font-size: 12px;
  }
}

ul.rbt-dropdown-menu li.has-child-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

ul.rbt-dropdown-menu li .sub-menu {
  border-top: 1px solid #e6e3f14f;
  background: #fff;
  box-shadow: var(--shadow-1);
  min-width: 170px;
  padding: 12px 0;
  margin: 0;
  list-style-type: none;
  z-index: 10;
  text-align: start;
  border-radius: var(--radius);
  right: 0;
}

ul.rbt-dropdown-menu li .sub-menu.hover-reverse {
  left: auto;
  right: 0;
}

ul.rbt-dropdown-menu li .sub-menu li a {
  margin: 2px 10px;
  padding: 4px 12px;
  background: var(--color-white);
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
}

ul.rbt-dropdown-menu li .sub-menu li a:hover {
  color: var(--color-primary);
  background: var(--black-opacity);
}

ul.rbt-dropdown-menu li:hover>a .right-icon {
  transform: rotate(180deg);
  margin-top: 2px;
}

ul.rbt-dropdown-menu.currency-menu li .sub-menu {
  min-width: 100px;
}

/*--------------------------
    Header Section
----------------------------*/

.rbt-header-sec {
  display: flex;
}

@media only screen and (min-width: 1200px) {
  .rbt-header-sec {
    height: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header-sec {
    height: 100%;
  }
}

.rbt-header-sec .rbt-header-sec-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.rbt-header-sec .rbt-header-sec-col .rbt-header-content {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header-sec .rbt-header-sec-col .rbt-header-content {
    margin: 0 -5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header-sec .rbt-header-sec-col .rbt-header-content {
    margin: 0 -5px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-sec .rbt-header-sec-col .rbt-header-content {
    margin: 0 -5px;
  }
}

.rbt-header-sec .rbt-header-sec-col .rbt-header-content>div {
  padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header-sec .rbt-header-sec-col .rbt-header-content>div {
    padding: 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header-sec .rbt-header-sec-col .rbt-header-content>div {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-sec .rbt-header-sec-col .rbt-header-content>div {
    padding: 0 5px;
  }
}

.rbt-header-sec .rbt-header-left {
  text-align: left;
}

.rbt-header-sec .rbt-header-left .rbt-header-content {
  justify-content: flex-start;
}

.rbt-header-sec .rbt-header-right {
  text-align: right;
}

.rbt-header-sec .rbt-header-right .rbt-header-content {
  justify-content: flex-end;
}

.rbt-header-sec .rbt-header-center {
  text-align: center;
}

.rbt-header-sec .rbt-header-center .rbt-header-content {
  justify-content: center;
}

.rbt-header-sec.flex-row-reverse .rbt-header-left {
  text-align: right;
}

.rbt-header-sec.flex-row-reverse .rbt-header-left .rbt-header-content {
  justify-content: flex-end;
}

.rbt-header-sec.flex-row-reverse .rbt-header-right {
  text-align: left;
}

.rbt-header-sec.flex-row-reverse .rbt-header-right .rbt-header-content {
  justify-content: flex-start;
}

.rbt-header-wrapper .rbt-header-content {
  display: flex;
  align-items: center;
  margin: 0 -5px;
}

.rbt-header-wrapper .rbt-header-content>div {
  padding: 0 5px;
}

/*--------------------------
    Header Quick Access
----------------------------*/

.quick-access {
  display: flex;
  align-items: center;
  margin: 0 -3px;
  padding: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quick-access {
    border-right: 0 none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-access {
    border-right: 0 none;
  }
}

@media only screen and (max-width: 767px) {
  .quick-access {
    border-right: 0 none;
  }
}

.quick-access>li {
  margin: 0 0px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .quick-access>li {
    margin: 0 6px;
  }
}

.quick-access>li>a {
  color: var(--color-heading);
  font-size: 16px;
  font-weight: 500;
  transition: 0.4s;
}

.quick-access>li>a i {
  margin-right: 10px;
}

.quick-access>li:hover>a {
  background-color: inherit;
  background-position: 102% 0;
  transition: all 0.4s ease-in-out;
}

.quick-access>li.account-access {
  padding-left: 20px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quick-access>li.account-access {
    padding-left: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-access>li.account-access {
    padding-left: 10px;
    margin-left: 10px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .quick-access>li.account-access {
    padding-left: 10px;
    margin-left: 10px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .quick-access>li.account-access {
    padding-left: 0;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .quick-access>li.account-access::after {
    display: none;
  }
}

.quick-access>li.access-icon a {
  position: relative;
  z-index: 1;
}

.quick-access>li.access-icon a i {
  margin-right: 0;
}

/*--------------------------
    Search With Category
----------------------------*/

.rbt-search-with-category select {
  height: 100%;
  border: 0 none;
  width: 170px;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  padding: 3px 20px;
}

.rbt-search-with-category .search-by-category {
  height: 100%;
  position: relative;
  flex: 1 1 0px;
}

.rbt-search-with-category .search-by-category::after {
  background: var(--color-border);
  position: absolute;
  content: "";
  height: 25px;
  right: 0;
  top: 50%;
  width: 1px;
  transform: translateY(-50%);
}

.rbt-search-with-category .search-field {
  width: 80%;
}

.rbt-search-field,
.rbt-search-with-category {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-border);
  height: 45px;
  border-radius: 500px;
}

.rbt-search-field .rbt-modern-select .bootstrap-select button.btn-light,
.rbt-search-with-category .rbt-modern-select .bootstrap-select button.btn-light {
  border: 0 none;
  box-shadow: none;
  height: inherit;
  padding: 10px 20px;
  border-radius: inherit;
  line-height: 23px;
  padding-right: 40px;
  background-color: transparent;
}

.rbt-search-field .search-field .serach-btn::after,
.rbt-search-with-category .search-field .serach-btn::after {
  display: none;
}

.search-field input {
  height: auto;
  box-shadow: none;
  padding: 0 20px;
  padding-right: 65px;
  line-height: 47px;
}

.search-field.filed-solid input {
  background: var(--color-gray-light);
}

.search-field {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.search-field input {
  border: 0 none;
  box-shadow: none;
}

.search-field .serach-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  border-radius: 0;
}

.search-field .serach-btn::after {
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .rbt-search-with-category .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 139px;
  }

  .rbt-search-with-category .search-field input {
    padding-right: 34px;
    font-size: 13px;
    padding-left: 12px;
  }

  .rbt-search-with-category .rbt-modern-select .bootstrap-select button.btn-light {
    font-size: 13px;
    padding-right: 25px;
  }

  .rbt-search-with-category .filter-select select {
    min-width: 139px;
  }
}

/*--------------------------
    Secondary Menu
----------------------------*/

.rbt-secondary-menu {
  display: flex;
  margin: 0 -15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-secondary-menu {
    margin: 0 -5px;
  }
}

.rbt-secondary-menu li {
  padding: 0 15px;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-secondary-menu li {
    padding: 0 5px;
  }
}

.rbt-secondary-menu li a {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-secondary-menu li a {
    font-size: 13px;
  }
}

.rbt-secondary-menu li a::before {
  content: "\e930";
  font-family: "feather" !important;
  font-style: normal;
  font-weight: 400;
  margin-right: 2px;
  position: relative;
  top: 1px;
}

.rbt-secondary-menu li a:hover {
  color: var(--color-primary);
}

/*--------------------------
    Category Button
----------------------------*/

.rbt-category-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-heading);
  font-size: 16px;
  font-weight: 500;
  background: var(--black-opacity);
  padding: 10px 20px;
  border-radius: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-category-btn {
    background: transparent;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-category-btn {
    background: transparent;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-category-btn {
    background: transparent;
    padding: 0;
  }
}

.rbt-category-btn .icon {
  display: inline-block;
}

.rbt-category-btn .category-text {
  display: inline-block;
  padding-left: 6px;
  font-size: 16px;
  line-height: 1.2;
}

.rbt-category-menu-wrapper {
  position: relative;
}

.rbt-category-menu-wrapper .category-dropdown-menu {
  min-width: 240px;
  height: auto;
  z-index: 90;
  text-align: left;
  padding: 0;
  border-radius: 0 0 10px 10px;
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e6e3f14f;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item {
  display: flex;
  padding: 20px;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav {
  min-width: 230px;
  width: auto;
  border-right: 1px solid #e6e3f14f;
  padding-right: 20px;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-list-wrapper {
  margin: 0;
  list-style: none;
  padding: 0;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-list-wrapper li {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-list-wrapper li a {
  font-weight: 500;
  padding: 10px 20px;
  font-size: 14px;
  display: block;
  color: var(--color-body);
  margin: 0;
  border-radius: 3px;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-list-wrapper li:hover a,
.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-list-wrapper li.active a {
  color: var(--color-primary);
  background: var(--black-opacity);
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-content {
  padding-left: 20px;
  height: 100%;
  width: auto;
  min-width: 320px;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-content .vartical-nav-content-menu li a {
  padding: 5px 0px;
}

.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-content .vartical-nav-content-menu li:hover a,
.rbt-category-menu-wrapper .category-dropdown-menu .category-menu-item .rbt-vertical-nav-content .vartical-nav-content-menu li.active a {
  background: transparent;
}

.rbt-vertical-inner {
  display: none;
}

/*-------------------------
    Admin User Settings
--------------------------*/

.rbt-admin-profile {
  display: flex;
  align-items: center;
}

.rbt-admin-profile .admin-thumbnail {
  margin-right: 12px;
}

.rbt-admin-profile .admin-thumbnail img {
  width: 52px;
  min-width: 52px;
  height: 52px;
  border-radius: 100%;
  border: 2px solid var(--primary-opacity);
  box-shadow: var(--shadow-1);
  padding: 3px;
}

.rbt-admin-profile .admin-info .name {
  color: var(--color-heading);
  font-weight: 500;
  display: block;
  line-height: 1;
  margin-bottom: 2px;
  font-size: var(--font-size-b3);
}

.rbt-user-menu-list-wrapper .user-list-wrapper {
  margin: 0 -12px;
}

.rbt-user-menu-list-wrapper .user-list-wrapper li {
  margin: 0;
}

.rbt-user-menu-list-wrapper .user-list-wrapper li a {
  display: flex;
  padding: 12px 12px;
  align-items: center;
  border-radius: 3px;
  color: var(--color-body);
}

.rbt-user-menu-list-wrapper .user-list-wrapper li a img,
.rbt-user-menu-list-wrapper .user-list-wrapper li a i {
  max-height: 17px;
  margin-right: 7px;
}

.rbt-user-menu-list-wrapper .user-list-wrapper li a i {
  font-size: 17px;
}

.rbt-user-menu-list-wrapper .user-list-wrapper li a span {
  font-size: 14px;
}

.rbt-user-menu-list-wrapper .user-list-wrapper li:hover a {
  color: var(--color-primary);
  background: var(--black-opacity);
}

.rbt-user-wrapper {
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 575px) {
  .rbt-user-wrapper {
    position: static;
  }
}

.rbt-user-wrapper .rbt-user-menu-list-wrapper {
  background: var(--color-white);
  min-width: 270px;
  border-radius: 0 0 10px 10px;
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  border-top: 1px solid #e6e3f14f;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-user-wrapper .rbt-user-menu-list-wrapper {
    left: auto;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-user-wrapper .rbt-user-menu-list-wrapper {
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-user-wrapper .rbt-user-menu-list-wrapper {
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-user-wrapper .rbt-user-menu-list-wrapper {
    left: 20px;
    right: 20px;
    width: auto;
  }
}

.rbt-user-wrapper .rbt-user-menu-list-wrapper .inner {
  padding: 20px;
}

.rbt-user-wrapper .rbt-user-menu-list-wrapper .inner hr {
  margin-left: -20px;
  margin-right: -20px;
}

.rbt-user-wrapper .rbt-user-menu-list-wrapper .inner .rbt-admin-profile {
  padding-bottom: 20px;
}

.rbt-user-wrapper.right-align-dropdown .rbt-user-menu-list-wrapper {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 575px) {
  .rbt-user-wrapper.right-align-dropdown .rbt-user-menu-list-wrapper {
    right: 20px;
    left: 20px;
    width: auto;
  }
}

/*-------------------------
    Mini Cart
--------------------------*/

.rbt-mini-cart {
  position: relative;
}

.rbt-mini-cart .rbt-cart-count {
  position: absolute;
  top: 4px;
  z-index: 2;
  min-width: 16px;
  height: 16px;
  background: var(--color-primary);
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  color: #fff;
  padding: 0 4px 0 4px;
  line-height: 16px;
  left: 73%;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-mini-cart .rbt-cart-count {
    top: 0px;
    left: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-mini-cart .rbt-cart-count {
    left: auto;
    font-weight: 500;
    right: 1px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-mini-cart .rbt-cart-count {
    top: -7px;
    right: -4px;
    left: auto;
  }
}

/*-------------------------
    Header Animation  
--------------------------*/

@keyframes headerSticky {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes stickySlideDown {
  0% {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

/*----------------------------
    Header Top Bar  
----------------------------*/

.rbt-header-top-2 {
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-white);
}

.rbt-header-top-2 p {
  margin-bottom: 0;
  font-size: 14px;
}

.rbt-header-top-2 p a {
  transition: 0.3s;
}

.rbt-header-top-2 p a i {
  position: relative;
  top: 2px;
}

.rbt-header-top-2 .address-content {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}

.rbt-header-top-2 .address-content p {
  margin-bottom: 0;
  padding: 0 15px;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .rbt-header-top-2 .address-content p {
    padding: 0 8px;
  }
}

.rbt-header-top-2 .address-content p+p::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background: var(--color-border);
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
  opacity: 0.5;
}

.rbt-header-top-2 .address-content p a {
  transition: 0.3s;
}

.rbt-header-top-2 .address-content p a:hover {
  color: var(--color-primary);
}

.rbt-header-top-2 .address-content p i {
  margin-right: 8px;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header-top-2 .fancy-menu-text {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-top-2 .fancy-menu-text {
    text-align: center;
  }
}

.rbt-header-top-2 .fancy-menu-address {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header-top-2 .fancy-menu-address {
    justify-content: center;
    margin-top: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-top-2 .fancy-menu-address {
    justify-content: center;
    margin-top: 6px;
  }
}

.rbt-header-top-2 .fancu-menu-end {
  justify-content: flex-end;
  text-align: end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header-top-2.header-top-2 .header-right {
    justify-content: end;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-top-2.header-top-2 .header-right {
    justify-content: center;
  }
}

@media only screen and (max-width: 479px) {
  .rbt-header-top-2.header-top-2 .header-right {
    display: block;
  }
}

.rbt-header-top-2.header-top-2 .header-right .social-icon-wrapper {
  margin-left: 10px;
  padding-left: 10px;
  flex-basis: auto;
}

@media only screen and (max-width: 479px) {
  .rbt-header-top-2.header-top-2 .header-right .social-icon-wrapper {
    margin-top: 10px;
  }
}

.rbt-header-top-2.header-top-2 .header-right .social-icon-wrapper .social-default.icon-naked {
  margin: -5px;
}

.rbt-header-top-2 .social-default.icon-naked li {
  margin: 5px;
}

.rbt-header-top-2 .social-default.icon-naked li a {
  color: var(--color-body);
  font-size: 15px;
  line-height: 14px;
  display: block;
}

.rbt-header-top-2 .social-icon-wrapper {
  position: relative;
  margin-left: 10px;
  padding-left: 15px;
}

@media only screen and (max-width: 575px) {
  .rbt-header-top-2 .social-icon-wrapper {
    margin-left: 0;
    padding-left: 0;
    flex-basis: 100%;
  }
}

.rbt-header-top-2 .social-icon-wrapper::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background: var(--color-border);
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
  opacity: 0.5;
}

@media only screen and (max-width: 575px) {
  .rbt-header-top-2 .social-icon-wrapper::before {
    display: none;
  }
}

.rbt-header-top-2.header-top-transparent {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  background-color: transparent;
}

.rbt-header-top-2.color-white p {
  color: var(--color-white);
  opacity: 0.7;
}

.rbt-header-top-2.color-white a {
  color: var(--color-white);
}

.rbt-header-top-2.color-white .social-default li {
  opacity: 0.7;
}

.rbt-header-top-2.color-white .social-default li a {
  color: var(--color-white);
}

/*------------------------------------------
    Header With TopBar Transparent  
----------------------------------------------*/

.rbt-transparent-header .rbt-header-top {
  border-color: #525260;
}

/*--------------------------
    Header Top Styles  
-----------------------------*/

.rbt-header-campaign {
  padding: 8px 0;
}

.rbt-header-campaign.bg-image1 {
  /* background-image: url(../images/bg/top-banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.rbt-header-campaign .icon-close.position-right {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .rbt-header-campaign .icon-close.position-right {
    right: 10px;
    top: 9px;
    transform: none;
  }
}

.rbt-header-campaign.deactive {
  display: none;
}

.rbt-header-top-news {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  z-index: 9;
  transition: 0.4s;
}

.rbt-header-top-news .inner {
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .rbt-header-top-news .inner {
    text-align: start;
    justify-content: space-between !important;
    padding-right: 25px;
  }
}

.rbt-header-top-news .inner .content .news-text {
  display: inline-block;
  margin-left: 7px;
  color: var(--color-body);
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-header-top-news .inner .content .news-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-header-top-news .inner .content .news-text {
    margin-left: 0;
    margin-top: 10px;
  }
}

.rbt-header-top-news .inner .content .news-text img {
  width: 22px;
  margin-right: 5px;
}

.rbt-header-top-news .inner .right-button {
  margin-left: 30px;
  margin-top: -4px;
}

@media only screen and (max-width: 767px) {
  .rbt-header-top-news .inner .right-button {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-campaign-1.rbt-header-top-news .inner {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header-campaign-1.rbt-header-top-news .inner .right-button {
    margin-left: 0;
    margin-top: 6px;
  }
}

/*---------------------------
    Header Mid Style  
----------------------------*/

.rbt-header-middle {
  padding-top: 15px;
  padding-bottom: 15px;
}

.rbt-header-middle .rbt-header-sec .rbt-header-sec-col {
  flex-basis: inherit;
  flex-grow: inherit;
  max-width: inherit;
}

.rbt-header-middle .rbt-header-sec .rbt-header-sec-col.rbt-header-right,
.rbt-header-middle .rbt-header-sec .rbt-header-sec-col.rbt-header-left {
  flex: 1 1 0px;
}

.rbt-header-middle .rbt-header-sec .rbt-header-sec-col.rbt-header-center {
  text-align: center;
  width: 50%;
  flex-shrink: 0;
}

.rbt-header-middle .rbt-header-sec .rbt-header-sec-col.rbt-header-center .rbt-header-content .header-info {
  width: 100%;
}

.rbt-header-mid-1 .quick-access>li.account-access {
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 0;
  position: relative;
}

.rbt-header-mid-1 .quick-access>li.account-access::after {
  left: -10px;
}

/*---------------------
    Social Share  
-----------------------*/

.social-share-transparent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: -10px;
}

@media only screen and (max-width: 575px) {
  .social-share-transparent {
    margin: -5px;
  }
}

.social-share-transparent li {
  margin: 0;
  position: relative;
}

.social-share-transparent li a {
  font-size: 14px;
  color: var(--color-body);
  display: inline-block;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  text-align: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  z-index: 1;
}

.social-share-transparent li a::before {
  background: var(--color-gray-light);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(0.8);
  border-radius: 100%;
  z-index: -1;
}

.social-share-transparent li a i {
  margin-right: 0px;
}

.social-share-transparent li a:hover {
  color: var(--color-primary);
}

.social-share-transparent li a:hover::before {
  opacity: 1;
  transform: scale(1);
}

/*----------------------------
    Navigation Position  
------------------------------*/

.mainbar-row {
  display: flex;
  justify-content: space-between;
}

.mainbar-row .rbt-main-navigation {
  flex: 1;
  display: flex;
  margin: 0 30px;
  justify-content: center;
}

.mainbar-row.rbt-navigation-end .rbt-main-navigation {
  justify-content: end;
}

.mainbar-row.rbt-navigation-start .rbt-main-navigation {
  justify-content: start;
}

/*-----------------------
    Header Style  
---------------------------*/

.rbt-header {
  position: relative;
  z-index: 99;
}

.rbt-header .logo a img {
  max-height: 50px;
  object-fit: cover;
}

@media only screen and (max-width: 575px) {
  .rbt-header .logo a {
    height: auto;
    line-height: initial;
  }
}

.rbt-header .rbt-header-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 20px 34px #0000000d;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header .rbt-header-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header .rbt-header-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header .rbt-header-wrapper {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.rbt-header .rbt-header-wrapper.header-transparent {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  background: transparent;
  backdrop-filter: inherit;
  box-shadow: none;
}

.rbt-header .rbt-header-wrapper.rbt-sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 100%;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  z-index: 99;
  box-shadow: var(--shadow-1);
}

.rbt-header .rbt-header-wrapper.color-white-variation .mainmenu-nav .mainmenu>li>a {
  color: var(--color-white);
}

.rbt-header .rbt-header-wrapper.color-white-variation .quick-access li a {
  color: var(--color-white);
}

.rbt-header .rbt-header-wrapper.color-white-variation .quick-access li.account-access::after {
  opacity: 0.5;
}

.rbt-header .rbt-header-wrapper.color-white-variation.rbt-sticky {
  background-color: #000 !important;
  box-shadow: var(--shadow-5);
}

.rbt-header .rbt-header-wrapper.color-white-variation .hamberger .hamberger-button {
  color: var(--color-white);
}

.rbt-header .rbt-header-wrapper.bg-color-darker {
  background-color: var(--color-darker);
}

.rbt-header .rbt-header-wrapper.bg-color-darker .mainmenu-nav .mainmenu>li>a {
  color: var(--color-white);
}

.rbt-header .rbt-header-wrapper.height-50 .mainmenu-nav .mainmenu>li>a {
  height: 50px;
  line-height: 50px;
}

.rbt-header .rbt-header-wrapper .header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .rbt-header .rbt-header-wrapper .header-right {
    justify-content: flex-end;
    flex-basis: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header .rbt-header-wrapper .header-left {
    flex-basis: 40%;
  }
}

.rbt-header .rbt-header-wrapper .container-fluid,
.rbt-header .rbt-header-wrapper .container {
  position: relative;
}

.rbt-header.rbt-header-8 {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  z-index: 99;
}

.rbt-header.rbt-header-8 .rbt-header-wrapper {
  box-shadow: none;
  background: transparent;
}

.rbt-header.rbt-header-8 .mainbar-row {
  background-color: var(--color-white);
  padding: 0 25px;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header.rbt-header-8 .mainbar-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header.rbt-header-8 .mainbar-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header.rbt-header-8 .mainbar-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.rbt-header.rbt-transparent-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
}

.rbt-header.rbt-transparent-header .rbt-header-top:not(.bg-not-transparent) {
  background: transparent !important;
}

.rbt-header.rbt-transparent-header .rbt-header-wrapper:not(.bg-not-transparent) {
  background: transparent !important;
  box-shadow: none;
}

.rbt-header.rbt-transparent-header .rbt-header-wrapper.rbt-sticky {
  background-color: var(--color-white) !important;
  box-shadow: var(--shadow-1);
}

.rbt-header.rbt-transparent-header .rbt-header-wrapper.color-white-variation.rbt-sticky {
  background-color: #000 !important;
  box-shadow: var(--shadow-5);
  border: 0 none;
}

.rbt-header.rbt-transparent-header .rbt-header-middle {
  background: transparent !important;
}

.rbt-header.rbt-header-8 .rbt-header-wrapper.rbt-sticky {
  background-color: transparent !important;
  box-shadow: none;
  padding-top: 10px;
}

.rbt-header .rbt-header-wrapper.header-transparent .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper {
  border-radius: 10px;
}

.rbt-header .rbt-header-wrapper.header-transparent.rbt-sticky .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper {
  border-radius: 0 0 10px 10px;
}

.side-nav-opened .rbt-header.rbt-transparent-header .rbt-header-wrapper:not(.bg-not-transparent) {
  background: #fff !important;
}

.rbt-header .rbt-header-wrapper.header-transparent .mainmenu-nav .mainmenu li.has-dropdown .submenu {
  border-radius: 10px;
}

.rbt-header .rbt-header-wrapper.header-transparent.rbt-sticky .mainmenu-nav .mainmenu li.has-dropdown .submenu {
  border-radius: 0 0 10px 10px;
}

/*--------------------------
    Navigation Styles 
----------------------------*/

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

.rbt-header .mainmenu-nav .mainmenu {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.rbt-header .mainmenu-nav .mainmenu>li>a {
  color: var(--color-heading);
  font-size: 15px;
  font-weight: 500;
  padding: 0 17px;
  display: flex;
  align-items: center;
  height: 80px;
  line-height: 80px;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header .mainmenu-nav .mainmenu>li>a {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header .mainmenu-nav .mainmenu>li>a {
    padding: 0 12px;
  }
}

.rbt-header .mainmenu-nav .mainmenu>li>a i {
  transition: 0.3s;
}

.rbt-header .mainmenu-nav .mainmenu>li>a.active {
  color: var(--color-primary);
}

/* .rbt-header .mainmenu-nav .mainmenu>li:hover>a,
.rbt-header .mainmenu-nav .mainmenu>li.active>a {
    color: var(--color-primary) !important;
} */

.rbt-header .mainmenu-nav .mainmenu>li:hover>a i,
.rbt-header .mainmenu-nav .mainmenu>li.active>a i {
  transform: rotate(180deg);
  margin-top: 2px;
}

.rbt-header .mainmenu-nav .mainmenu li {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}

.rbt-header .mainmenu-nav .mainmenu li:first-child a {
  padding-left: 0;
}

.rbt-header .mainmenu-nav .mainmenu li:last-child a {
  padding-right: 0;
}

.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu {
  min-width: 280px;
  height: auto;
  z-index: 90;
  text-align: left;
  padding: 12px 0;
  border-radius: 0 0 10px 10px;
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e6e3f14f;
  left: 0;
}

.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu li {
  position: relative;
}

.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu li a {
  font-weight: 500;
  padding: 10px 20px;
  font-size: 14px;
  display: block;
  color: var(--color-body);
  margin: 0 10px;
  border-radius: 3px;
}

.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu li a:hover {
  color: #c945ec !important;
  background: var(--black-opacity);
}

.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu li a.active {
  color: var(--color-primary) !important;
  background: var(--black-opacity);
}

.rbt-header .mainmenu-nav .mainmenu li.has-dropdown .submenu li ul.submenu {
  left: 100%;
  top: 0;
  text-align: left;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu {
  position: relative;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu {
  transition: 0.3s;
  width: 100%;
  padding: 0 7px;
  border-radius: 0 0 10px 10px;
  right: 0;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu.grid-item-2 {
  width: 600px;
  margin: 0 auto;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu.grid-item-3 {
  width: 900px;
  margin: 0 auto;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu.grid-item-4 {
  width: 100%;
  margin: 0 auto;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper {
  border-radius: 0 0 10px 10px;
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  padding: 25px 25px;
  overflow: hidden;
  border-top: 1px solid #e6e3f14f;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .mega-menu-item {
  padding: 0;
  margin: 0;
  height: 100%;
  margin: 0 -10px;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .mega-menu-item li {
  margin: 0 -10px;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .mega-menu-item li a {
  font-weight: 500;
  padding: 10px 14px;
  font-size: 15px;
  display: block;
  color: var(--color-body);
  margin: 0 10px;
  border-radius: 4px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .mega-menu-item li a .rbt-badge-card {
  margin-left: 10px;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .mega-menu-item li a:hover,
.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .mega-menu-item li a.active {
  color: var(--color-primary) !important;
  background: var(--black-opacity);
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .rbt-ads-wrapper img {
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .btn-wrapper {
  margin: -25px;
  margin-top: 0px;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper .btn-wrapper a {
  font-size: 16px;
}

.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu.menu-skin-dark .wrapper {
  background: var(--color-bodyest);
  box-shadow: none;
  padding: 40px;
}

.rbt-header .mainmenu-nav .mainmenu>li .submenu>li:hover>.submenu {
  opacity: 1;
  visibility: visible;
  border-radius: 10px;
}

.rbt-header .mainmenu-nav.onepagenav .mainmenu li a {
  padding: 0;
  margin: 0 17px;
  position: relative;
}

.rbt-header .mainmenu-nav.onepagenav .mainmenu li a::after {
  position: absolute;
  content: "";
  left: 0;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  bottom: 0;
  opacity: 0;
  transition: 0.4s;
}

.rbt-header .mainmenu-nav.onepagenav .mainmenu li:first-child {
  margin-left: 0;
}

.rbt-header .mainmenu-nav.onepagenav .mainmenu li:last-child {
  margin-right: 0;
}

.rbt-header .mainmenu-nav.onepagenav .mainmenu li.current a {
  color: var(--color-primary);
}

.rbt-header .mainmenu-nav.onepagenav .mainmenu li.current a::after {
  width: 100%;
  opacity: 1;
}

.rbt-header .rbt-category-menu-wrapper {
  height: 80px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-header .rbt-category-menu-wrapper {
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-header .rbt-category-menu-wrapper {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-header .rbt-category-menu-wrapper {
    height: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-header.rbt-header-3 .mainmenu-nav .mainmenu>li>a {
    padding: 0 20px;
  }
}

.rbt-badge-card {
  background-color: transparent;
  background: transparent;
  padding: 1px 10px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 2;
  letter-spacing: 0.5px;
}

.rbt-badge-card::before {
  background: linear-gradient(90deg,
      var(--color-primary) 15%,
      var(--color-secondary) 45%,
      var(--color-secondary) 75%,
      var(--color-primary) 100%) 95%/200% 100%;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  border-radius: 100px;
  opacity: 0.5;
  background-size: 400% 100%;
}

/*----------------------------
    Mega Menu Options  
------------------------------*/

.mega-top-banner {
  /* background-image: url(../images/course/category-10.png),
    radial-gradient(100% 100% at 100% 0%, #484c7a 0%, #23263b 100%); */
  background-size: contain;
  background-position: right 30%;
  margin: -25px;
  width: auto;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 25px;
  position: relative;
  margin-bottom: 30px;
  background-size: cover;
}

.mega-top-banner .content {
  width: 56%;
}

.mega-top-banner .content .title {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}

.mega-top-banner .content .description {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  color: #fff;
}

.nav-quick-access {
  display: flex;
  flex-wrap: wrap;
  background: #f5f5fa;
  margin: -25px;
  margin-top: 20px;
  padding: 16px 25px;
  justify-content: space-between;
}

.nav-quick-access li {
  flex-basis: 50%;
  padding: 5px;
}

.nav-quick-access li a {
  font-size: 15px;
  display: flex;
  align-items: center;
}

.nav-quick-access li a i {
  padding-right: 7px;
  color: #9698c3;
  font-size: 17px;
}

.nav-category-item {
  position: relative;
  z-index: 1;
  padding: 25px 20px;
}

.nav-category-item .image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.nav-category-item a {
  display: flex;
  align-items: center;
}

.nav-category-item a span {
  font-size: 14px;
  font-weight: 600;
}

.nav-category-item a i {
  font-size: 16px;
  color: var(--color-body);
  padding-left: 4px;
  transition: 0.4s;
}

.nav-category-item:hover a i {
  padding-left: 10px;
}

.rbt-drop-menu-hidden .submenu,
.rbt-drop-menu-hidden .rbt-megamenu {
  display: none !important;
}

.rbt-drop-menu-hidden .rbt-header {
  z-index: 2;
}

.rbt-drop-menu-hidden .rbt-header.rbt-transparent-header {
  position: relative !important;
}

/*-----------------------
    Single Demo  
------------------------*/

@media only screen and (max-width: 1199px) {
  .single-dropdown-menu-presentation .single-mega-item {
    margin-top: 0 !important;
  }
}

.home-plesentation-wrapper {
  margin-top: -30px;
  max-height: inherit;
  scrollbar-width: none;
  margin-bottom: -20px;
  padding-bottom: 20px;
}

.home-plesentation-wrapper::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .home-plesentation-wrapper {
    margin-bottom: 12px;
    margin-top: 0;
    max-height: inherit;
    min-height: inherit;
    overflow: inherit;
  }
}

@media only screen and (max-width: 1199px) {
  .home-plesentation-wrapper .single-mega-item+.single-mega-item .demo-single {
    border-top: 1px solid var(--color-border);
  }
}

.demo-single {
  text-align: center;
  border-radius: var(--radius-10);
  margin-top: 30px;
  position: relative;
  transition: 0.4s;
}

@media only screen and (max-width: 1199px) {
  .demo-single {
    box-shadow: none;
    margin-top: 0;
    border-radius: 0;
  }
}

.demo-single .inner {
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .demo-single .inner {
    text-align: left;
  }
}

.demo-single .inner .thumbnail {
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .demo-single .inner .thumbnail {
    display: none;
  }
}

.demo-single .inner .thumbnail a {
  display: block;
  overflow: hidden;
}

.demo-single .inner .thumbnail a img {
  width: 100%;
  transition: all 0.3s ease;
  border-radius: var(--radius-10);
}

.demo-single .inner .content {
  padding: 10px;
  padding-bottom: 0;
  position: absolute;
  bottom: -30px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  opacity: 0;
  transition: 0.4s;
}

@media only screen and (max-width: 1199px) {
  .demo-single .inner .content {
    padding: 0;
    opacity: 1;
    position: static;
    text-align: left;
  }
}

.demo-single .inner .content .title {
  margin-bottom: 0;
  line-height: inherit;
  font-size: 14px;
  color: var(--color-white) !important;
  display: block;
}

@media only screen and (max-width: 1199px) {
  .demo-single .inner .content .title {
    font-size: 15px;
    color: var(--color-heading) !important;
  }
}

.demo-single .inner .content .title a {
  color: inherit;
}

.demo-single .inner .content .title i {
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 1199px) {
  .demo-single .inner .content .title i {
    display: none;
  }
}

.demo-single:hover {
  transform: translateY(-20px);
}

.demo-single:hover .inner .content {
  opacity: 1;
  bottom: -30px;
}

@media only screen and (max-width: 1199px) {
  .demo-single:hover {
    transform: none;
  }
}

.coming-soon .demo-single .inner .thumbnail a img,
.coming-soon .demo-single .inner .content .title a {
  cursor: default;
}

/*----------------------
    Load More Btn  
-----------------------*/

.rbt-megamenu .load-demo-btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: none;
}

/*----------------------
    Extra Css  
------------------------*/

.rbt-header .rbt-border-bottom-light .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu .wrapper {
  border-top: 0 none;
}

.dashboard-mainmenu li a.active span,
.dashboard-mainmenu li a.active i {
  color: var(--color-primary);
}

/*--------------------
    Mega Menu  
-----------------------*/

/*------------------------------
    Popup Menu Styles  
--------------------------------*/

.popup-mobile-menu {
  z-index: 9999;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

@media only screen and (min-width: 1200px) {
  .popup-mobile-menu {
    display: none;
  }
}

.popup-mobile-menu .inner-wrapper {
  width: 400px;
  z-index: 999;
  position: absolute;
  background-color: var(--color-white);
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  left: -150px;
  transition: all 0.5s ease-out;
  padding: 60px 50px;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .popup-mobile-menu .inner-wrapper {
    padding: 30px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .popup-mobile-menu .inner-wrapper {
    width: 300px;
    padding: 30px 20px;
  }
}

.popup-mobile-menu .inner-wrapper .inner-top {
  border-bottom: 1px solid var(--primary-opacity);
  padding-bottom: 25px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .popup-mobile-menu .inner-wrapper .inner-top {
    padding-bottom: 15px;
    margin-bottom: 0px;
  }
}

.popup-mobile-menu .inner-wrapper .inner-top .content {
  display: flex;
  justify-content: space-between;
}

.popup-mobile-menu .inner-wrapper .inner-top .logo a img {
  max-height: 48px;
}

.popup-mobile-menu .inner-wrapper .inner-top .close-button {
  background: var(--color-white);
  border: 0 none;
  color: var(--color-heading);
  width: 40px;
  height: 40px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.popup-mobile-menu .inner-wrapper .content {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .popup-mobile-menu .inner-wrapper .content {
    display: block;
  }
}

.popup-mobile-menu .inner-wrapper .description {
  padding-right: 18%;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .popup-mobile-menu .inner-wrapper .description {
    padding-right: 0;
  }
}

.popup-mobile-menu.active {
  visibility: visible;
  opacity: 1;
}

.popup-mobile-menu.active .inner-wrapper {
  opacity: 1;
  left: 0;
  overflow-y: auto;
}

.popup-mobile-menu .mainmenu {
  list-style: none;
  /* padding: 0; */
  margin: 0;
  padding: 15px 0px;
}

.popup-mobile-menu .mainmenu li {
  margin: 0;
}

.popup-mobile-menu .mainmenu li a {
  padding: 12px 0;
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.popup-mobile-menu .mainmenu li a.active {
  color: var(--color-primary) !important;
}

.popup-mobile-menu .mainmenu li a i {
  display: none;
}

.popup-mobile-menu .mainmenu li+li {
  border-top: 1px solid var(--color-border);
}

.popup-mobile-menu .mainmenu .has-dropdown .submenu {
  padding: 0;
  max-width: 100%;
  list-style: none;
  /* padding-left: 14px; */
  display: none;
}

.popup-mobile-menu .mainmenu .has-dropdown .submenu li a {
  font-size: 15px;
}

.popup-mobile-menu .mainmenu .has-dropdown .submenu li a.active {
  color: var(--color-primary) !important;
}

.popup-mobile-menu .mainmenu .has-menu-child-item>a,
.popup-mobile-menu .mainmenu .has-dropdown>a {
  position: relative;
}

.popup-mobile-menu .mainmenu .has-menu-child-item>a::after,
.popup-mobile-menu .mainmenu .has-dropdown>a::after {
  position: absolute;
  content: "\e9b1";
  font-family: "feather" !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}

.popup-mobile-menu .mainmenu .has-menu-child-item>a.open::after,
.popup-mobile-menu .mainmenu .has-dropdown>a.open::after {
  content: "\e996";
}

.popup-mobile-menu .mainmenu .rbt-megamenu {
  padding: 0;
  max-width: 100%;
  list-style: none;
  padding-left: 14px;
  display: none;
}

.popup-mobile-menu .mainmenu .mega-menu-item {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 0;
}

.popup-mobile-menu .mainmenu .mega-menu-item li a {
  font-size: 15px;
}

.popup-mobile-menu .mainmenu .mega-menu-item li:last-child {
  border-bottom: 1px solid var(--color-border);
}

.popup-mobile-menu .mainmenu .single-mega-item:last-child .mega-menu-item li:last-child {
  border-bottom-color: transparent;
}

.popup-mobile-menu .mainmenu .single-mega-item+.single-mega-item {
  margin-top: 15px;
}

.popup-mobile-menu .mainmenu .single-mega-item .rbt-short-title {
  margin-top: 8px;
}

.popup-mobile-menu .mega-top-banner {
  margin: 0;
  margin-top: 10px;
  background-image: inherit;
  background-color: var(--color-dark);
  border-radius: 4px;
  margin-bottom: 9px;
}

.popup-mobile-menu .mega-top-banner .content {
  width: 100%;
  display: block;
}

.popup-mobile-menu .nav-quick-access {
  display: block;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -20px;
  padding: 6px 17px;
  overflow: hidden;
}

.popup-mobile-menu .nav-quick-access li a {
  display: flex;
  font-size: 14px;
}

.popup-mobile-menu .nav-quick-access li a i {
  display: flex;
}

.popup-mobile-menu .rbt-ads-wrapper {
  margin-bottom: 20px;
  margin-top: -16px;
  margin-left: -15px;
}

@media only screen and (max-width: 1199px) {
  .popup-mobile-menu .rbt-ads-wrapper {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .popup-mobile-menu .rbt-ads-wrapper img {
    border-radius: 10px;
  }
}

.popup-mobile-menu .btn-wrapper {
  margin-left: -14px;
  margin-bottom: 20px;
  margin-top: -14px;
}

.popup-mobile-menu .rbt-btn.btn-xl {
  padding: 0 15px !important;
  font-size: 14px !important;
  height: 50px;
  line-height: 52px;
  border-radius: 4px !important;
}

.popup-mobile-menu .rbt-btn i {
  display: block !important;
}

.popup-mobile-menu .mega-category-item {
  margin-top: -15px;
  margin-bottom: 15px;
}

.popup-mobile-menu .mega-category-item .nav-category-item {
  padding: 8px 14px;
}

/* @media only screen and (max-width: 767px) {
  .hamberger {
    margin-left: 10px;
  }
} */

.hamberger .hamberger-button {
  background: transparent;
  border: 0 none;
  color: var(--color-body);
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0;
}

/*--------------------------------
    Offcanvas Menu  
--------------------------------*/

.rbt-offcanvas-trigger .offcanvas-trigger {
  display: block;
  color: var(--color-heading);
  position: relative;
  cursor: pointer;
  font-size: 0.25em;
}

.rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars {
  display: inline-block;
  width: 26px;
  margin: 0;
  vertical-align: middle;
  height: 20px;
}

.rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars span {
  display: inline-block;
  height: 2px;
  margin-left: 0;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  background-color: #000;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  background-color: transparent;
  transition: transform 0.3s, opacity 0.3s, width 0.3s,
    background-color 0.3s 0.3s, -webkit-transform 0.3s;
}

@media only screen and (max-width: 767px) {
  .rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars span {
    margin-bottom: 4px;
  }
}

.rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars span::before,
.rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars span::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #090909;
}

.rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars span::before {
  transform: translate(-100%, 0);
  transition: all 0.325s cubic-bezier(0.38, 0.98, 0.4, 1);
}

.rbt-offcanvas-trigger .offcanvas-trigger .offcanvas-bars span::after {
  transition: all 0.325s cubic-bezier(0.38, 0.98, 0.4, 1);
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span::before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span::after {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span:first-child::before {
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span:nth-child(2)::before {
  -webkit-transition-delay: 0.24s;
  transition-delay: 0.24s;
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span:nth-child(2)::after {
  -webkit-transition-delay: 0.06s;
  transition-delay: 0.06s;
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span:last-child::before {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.rbt-offcanvas-trigger .offcanvas-trigger:hover .offcanvas-bars span:last-child::after {
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
}

.rbt-offcanvas-trigger.md-size {
  margin-top: 2px;
}

.rbt-offcanvas-trigger.md-size .offcanvas-trigger .offcanvas-bars {
  width: 22px;
  height: 20px;
}

.rbt-offcanvas-trigger.md-size .offcanvas-trigger .offcanvas-bars span {
  margin-bottom: 2px;
}

/*-------------------------------
    Offcanvas Menu Sidebar  
--------------------------------*/

.side-menu,
.rbt-cart-side-menu {
  width: 30%;
  position: fixed;
  right: 0;
  top: 0;
  background: var(--color-white);
  z-index: 9999;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  overflow: hidden;
  overscroll-behavior: contain;
  transition: transform 0.85s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.6s step-end;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

  .side-menu,
  .rbt-cart-side-menu {
    width: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .side-menu,
  .rbt-cart-side-menu {
    width: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .side-menu,
  .rbt-cart-side-menu {
    width: 550px;
  }
}

@media only screen and (max-width: 767px) {

  .side-menu,
  .rbt-cart-side-menu {
    width: 450px;
  }
}

@media only screen and (max-width: 575px) {

  .side-menu,
  .rbt-cart-side-menu {
    width: 310px;
  }
}

.side-menu .inner-wrapper,
.rbt-cart-side-menu .inner-wrapper {
  padding: 60px 50px;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {

  .side-menu .inner-wrapper,
  .rbt-cart-side-menu .inner-wrapper {
    padding: 30px 30px;
  }
}

.side-menu .inner-wrapper .inner-top,
.rbt-cart-side-menu .inner-wrapper .inner-top {
  border-bottom: 1px solid var(--primary-opacity);
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.side-menu .inner-wrapper .content,
.rbt-cart-side-menu .inner-wrapper .content {
  display: flex;
  justify-content: space-between;
}

.side-menu .inner-wrapper .description,
.rbt-cart-side-menu .inner-wrapper .description {
  padding-right: 18%;
  margin-top: 20px;
}

.side-menu .side-nav,
.rbt-cart-side-menu .side-nav {
  margin-bottom: 30px;
  display: block;
}

.side-menu .side-nav .navbar-nav,
.rbt-cart-side-menu .side-nav .navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.side-menu .side-nav .navbar-nav li,
.rbt-cart-side-menu .side-nav .navbar-nav li {
  display: block;
  margin: 10px 0;
  padding: 0 !important;
  opacity: 0;
  transition: all 0.8s ease 500ms;
  transform: translateY(30px);
}

.side-menu .side-nav .navbar-nav li a,
.rbt-cart-side-menu .side-nav .navbar-nav li a {
  color: var(--color-heading);
  font-size: var(--font-size-b1);
  font-weight: 500;
}

.side-menu .side-nav .navbar-nav li:first-child,
.rbt-cart-side-menu .side-nav .navbar-nav li:first-child {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.side-menu .side-nav .navbar-nav li:nth-child(2),
.rbt-cart-side-menu .side-nav .navbar-nav li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.side-menu .side-nav .navbar-nav li:nth-child(3),
.rbt-cart-side-menu .side-nav .navbar-nav li:nth-child(3) {
  transition-delay: 0.3s;
}

.side-menu .side-nav .navbar-nav li:nth-child(4),
.rbt-cart-side-menu .side-nav .navbar-nav li:nth-child(4) {
  transition-delay: 0.4s;
}

.side-menu .side-nav .navbar-nav li:nth-child(5),
.rbt-cart-side-menu .side-nav .navbar-nav li:nth-child(5) {
  transition-delay: 0.5s;
}

.side-menu.start-from-left,
.rbt-cart-side-menu.start-from-left {
  left: 0;
  right: auto;
  transform: translate3d(-100%, 0, 0);
}

.side-menu.start-from-left.side-menu-active,
.rbt-cart-side-menu.start-from-left.side-menu-active {
  transform: translate3d(0, 0, 0);
}

.side-menu.side-menu-active,
.rbt-cart-side-menu.side-menu-active {
  transform: translate3d(0, 0, 0);
  transition: transform 0.85s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.6s step-start;
}

.side-menu.side-menu-active .side-nav .navbar-nav li,
.rbt-cart-side-menu.side-menu-active .side-nav .navbar-nav li {
  transform: translateY(0);
  opacity: 1;
}

.close_side_menu {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1091;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s, visibility 0.3s;
}

.offcanvas-menu-active .close_side_menu,
.cart-sidenav-menu-active .close_side_menu {
  opacity: 1;
  visibility: visible;
}

/*---------------------------------
    Rainbow Offcanvas Side Menu  
-----------------------------------*/

.rbt-offcanvas-side-menu {
  z-index: 9999;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.rbt-offcanvas-side-menu .inner-wrapper {
  width: 450px;
  z-index: 999;
  position: absolute;
  background-color: var(--color-white);
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  left: -150px;
  transition: all 0.5s ease-out;
  padding: 60px 50px;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

@media only screen and (max-width: 575px) {
  .rbt-offcanvas-side-menu .inner-wrapper {
    width: 310px;
    padding: 40px 30px;
  }
}

.rbt-offcanvas-side-menu .inner-wrapper .inner-top {
  border-bottom: 1px solid var(--primary-opacity);
  padding-bottom: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .rbt-offcanvas-side-menu .inner-wrapper .inner-top {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.rbt-offcanvas-side-menu.active-offcanvas {
  visibility: visible;
  opacity: 1;
}

.rbt-offcanvas-side-menu.active-offcanvas .inner-wrapper {
  opacity: 1;
  left: 0;
  overflow-y: auto;
}

@media only screen and (max-width: 1199px) {
  .rbt-vertical-nav-list-wrapper .vartical-nav-content-menu-wrapper {
    display: none;
  }
}

/*---------------------------------
    cateory Side Menu
-----------------------------------*/

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper {
  margin: 0;
  padding: 0;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vartical-nav-content-menu-wrapper {
  padding-left: 15px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vartical-nav-content-menu-wrapper .vartical-nav-content-menu+.vartical-nav-content-menu {
  margin-top: 20px;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vartical-nav-content-menu-wrapper a {
  font-size: 16px;
  font-weight: 500;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vertical-nav-item {
  margin: 0;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vertical-nav-item>a {
  padding: 8px 0;
  display: block;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vertical-nav-item>a::after {
  position: absolute;
  content: "\e9b1";
  font-family: "feather" !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vertical-nav-item>a.active::after {
  content: "\e996";
}

.rbt-category-sidemenu .rbt-vertical-nav-list-wrapper .vertical-nav-item+.vertical-nav-item {
  border-top: 1px solid var(--color-border);
}

/*---------------------------------------
    One Page Navigation Inner Section 
------------------------------------------*/

.rbt-inner-onepage-navigation {
  border-radius: 500px;
  background: var(--color-white);
  overflow: hidden;
  box-shadow: var(--shadow-1);
  padding: 7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-inner-onepage-navigation {
    padding: 0;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-inner-onepage-navigation {
    padding: 0;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-inner-onepage-navigation {
    padding: 0;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-inner-onepage-navigation {
    position: static;
  }
}

.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu {
  display: flex;
  padding: 0;
  justify-content: space-between;
  margin: -3px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-inner-onepage-navigation .mainmenu-nav .mainmenu {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-inner-onepage-navigation .mainmenu-nav .mainmenu {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-inner-onepage-navigation .mainmenu-nav .mainmenu {
    padding: 17px;
  }
}

.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  margin: 3px;
  flex-grow: 1;
  text-align: center;
}

.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li a {
  margin: 0;
  position: relative;
  display: block;
  color: var(--color-heading);
  z-index: 2;
  padding: 10px 25px;
  border-radius: 500px;
}

.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li a::after {
  position: absolute;
  content: "";
  left: 0;
  background: var(--black-opacity);
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 1;
  transition: 0.4s;
  z-index: -1;
  border-radius: 500px;
}

.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li.current a,
.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li:hover a {
  color: var(--color-white);
}

.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li.current a::after,
.rbt-inner-onepage-navigation .mainmenu-nav .mainmenu li:hover a::after {
  width: 100%;
  opacity: 1;
  background: var(--color-primary);
}

/**************************************
	Elements Styles
***************************************/

/*===================
Button Area 
====================*/

.rbt-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -10px;
}

.rbt-button-group a,
.rbt-button-group button {
  margin: 10px;
}

.rbt-btn {
  padding: 0 26px;
  background: var(--color-primary);
  height: 60px;
  line-height: 60px;
  color: var(--color-white);
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-in-out;
  border-radius: 6px;
  border: 0 none;
  outline: none;
}

.rbt-btn.btn-secondary {
  background: var(--color-secondary);
}

.rbt-btn.btn-secondary:hover {
  background: var(--color-primary);
}

.rbt-btn.btn-coral {
  background: var(--color-coral);
}

.rbt-btn.btn-coral:hover {
  background: var(--color-primary);
}

.rbt-btn.btn-violet {
  background: var(--color-violet);
}

.rbt-btn.btn-violet:hover {
  background: var(--color-primary);
}

.rbt-btn.btn-pink {
  background: var(--color-pink);
}

.rbt-btn.btn-pink:hover {
  background: var(--color-primary);
}

.rbt-btn.btn-white {
  box-shadow: var(--shadow-1);
  background: var(--color-white);
  color: var(--color-heading);
}

.rbt-btn.btn-white:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.rbt-btn.btn-border {
  background: transparent;
  border: 2px solid var(--color-border);
  color: var(--color-heading);
  line-height: 57px;
}

.rbt-btn.btn-border:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.rbt-btn.btn-border.color-white-off {
  border-color: var(--color-border-2);
  color: var(--color-white-off);
}

.rbt-btn.btn-border.color-white-off:hover {
  border-color: var(--color-primary);
  color: var(--color-white);
}

.rbt-btn.btn-gradient {
  color: var(--color-white);
  background-size: 300% 100%;
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
}

.rbt-btn.btn-gradient.btn-gradient-2 {
  background-image: linear-gradient(to right,
      var(--color-pink),
      var(--color-violet),
      var(--color-violet),
      var(--color-pink));
}

.rbt-btn.btn-gradient.btn-gradient-3 {
  background-image: linear-gradient(to right,
      var(--color-secondary),
      var(--color-primary),
      var(--color-primary),
      var(--color-secondary));
}

.rbt-btn.btn-gradient:hover {
  background-color: inherit;
  background-position: 102% 0;
  transition: all 0.4s ease-in-out;
}

.rbt-btn.rbt-marquee-btn {
  overflow: hidden;
}

.rbt-btn.rbt-marquee-btn span {
  display: inline-flex;
  position: relative;
  z-index: 3;
  line-height: 1.2;
}

.rbt-btn.rbt-marquee-btn span::after {
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 100%;
  transform: translate(100%, -50%);
}

.rbt-btn.rbt-marquee-btn.marquee-text-y span::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 100%);
}

.rbt-btn.rbt-marquee-btn.marquee-auto span {
  animation: btnTxtMarqueeX 2s linear infinite;
}

.rbt-btn.rbt-switch-btn {
  overflow: hidden;
}

.rbt-btn.rbt-switch-btn span {
  display: inline-flex;
  position: relative;
  z-index: 3;
  transition: opacity 0.65s, transform 0.85s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}

.rbt-btn.rbt-switch-btn span::after {
  content: attr(data-text);
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  opacity: 0;
  transition: inherit;
  left: 100%;
  transform: translate(50%, -50%);
}

.rbt-btn.rbt-switch-btn.rbt-switch-y span::after {
  left: 50%;
  transform: translate(-50%, 100%);
}

.rbt-btn.icon-hover .btn-text {
  transition-timing-function: cubic-bezier(0, 0.71, 0.4, 1);
  display: inline-block;
  transition: transform 0.5s;
  transform: translateX(7px);
}

.rbt-btn.icon-hover .btn-icon {
  margin-inline-start: -7px;
  display: inline-block;
}

.rbt-btn.icon-hover .btn-icon i {
  transition-timing-function: cubic-bezier(0, 0.71, 0.4, 1);
  display: inline-block;
  opacity: 0;
  transform: translateX(-7px);
  transition: opacity 0.25s, transform 0.5s;
  padding-left: 0;
}

.rbt-btn.hover-icon-reverse .icon-reverse-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbt-btn.hover-icon-reverse .btn-text {
  display: inline-block;
  transition: transform 0.6s 0.125s cubic-bezier(0.1, 0.75, 0.25, 1);
  margin-inline-start: -23px;
}

.rbt-btn.hover-icon-reverse .btn-icon {
  display: inline-block;
  transition: opacity 0.4s 0.25s, transform 0.6s 0.25s;
  transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
}

.rbt-btn.hover-icon-reverse .btn-icon+.btn-icon {
  margin-inline-end: 0;
  margin-inline-start: 8px;
  display: inline-block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  opacity: 0;
  transform: translateX(-10px);
  transition-delay: 0s;
  order: -2;
}

.rbt-btn.hover-icon-reverse .btn-icon+.btn-icon i {
  padding-left: 0;
  padding-right: 6px;
}

.rbt-btn.rounded-player {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  padding: 0;
  line-height: 100px;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.rounded-player {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.rbt-btn.rounded-player span {
  display: inline-block;
}

.rbt-btn.rounded-player span i {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}

.rbt-btn.rounded-player.border {
  background-image: inherit;
  border-color: var(--color-white) !important;
  border-width: 2px !important;
}

.rbt-btn.rounded-player.border:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary) !important;
}

.rbt-btn.rounded-player.sm-size {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.rbt-btn.rounded-player:focus {
  outline: none;
  color: var(--color-white);
}

.rbt-btn.rounded-player:hover {
  transform: scale(1.1);
  background: var(--color-primary);
}

.rbt-btn.rounded-player-2 {
  border: 0;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  border-radius: 100%;
  background: var(--color-white);
  margin: 0 auto;
  display: inline-block;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.rounded-player-2 {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-btn.rounded-player-2 {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.rbt-btn.rounded-player-2 .play-icon {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rbt-btn.rounded-player-2 .play-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-6px, -9px);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 15px solid var(--color-primary);
}

.rbt-btn.rounded-player-2.btn-large {
  width: 60px;
  height: 60px;
  margin-left: 0;
  margin-top: 0;
}

.rbt-btn.rounded-player-2.sm-size {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.rounded-player-2.sm-size {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.rbt-btn.rounded-player-2.btn-theme-color {
  background: var(--color-primary);
}

.rbt-btn.rounded-player-2.btn-theme-color::after {
  border-color: var(--color-primary);
}

.rbt-btn.rounded-player-2.btn-theme-color::before {
  border-color: var(--color-primary);
}

.rbt-btn.rounded-player-2.btn-theme-color .play-icon::before {
  border-left: 15px solid var(--color-white);
}

.rbt-btn.rounded-player-2.btn-theme-color:hover {
  color: inherit;
  background: var(--color-primary);
}

.rbt-btn.rounded-player-2:hover {
  color: inherit;
  background: var(--color-white);
}

.rbt-btn.with-animation::after,
.rbt-btn.with-animation::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  border: 1.5px solid var(--color-white);
}

.rbt-btn.with-animation::before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.rbt-btn.with-animation::after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.rbt-btn:hover {
  background-color: var(--color-secondary);
  color: var(--color-white) !important;
  transform: translate3d(0, -2px, 0);
  box-shadow: var(--shadow-7);
}

.rbt-btn:hover.icon-hover .btn-text {
  transform: translateX(-7px);
}

.rbt-btn:hover.icon-hover .btn-icon i {
  opacity: 1;
  transform: translateX(7px);
}

.rbt-btn:hover.hover-icon-reverse .btn-text {
  transition-delay: 0.1s;
  transform: translateX(23px);
}

.rbt-btn:hover.hover-icon-reverse .btn-icon {
  opacity: 0;
  transition-delay: 0s;
  transform: translateX(10px);
}

.rbt-btn:hover.hover-icon-reverse .btn-icon+.btn-icon {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0.225s;
}

.rbt-btn:hover.rbt-switch-btn span {
  transform: translateX(-200%);
}

.rbt-btn:hover.rbt-switch-btn span::after {
  opacity: 1;
  transform: translate(100%, -50%);
}

.rbt-btn:hover.rbt-switch-btn.rbt-switch-y span {
  transform: translateY(-200%);
}

.rbt-btn:hover.rbt-switch-btn.rbt-switch-y span::after {
  transform: translate(-50%, 150%);
}

.rbt-btn:hover.rbt-marquee-btn span {
  animation: btnTxtMarqueeX 2s linear infinite;
}

.rbt-btn:hover.rbt-marquee-btn.marquee-text-y span {
  animation: btnTxtMarqueeY 0.5s linear infinite;
}

.rbt-btn.btn-xs {
  padding: 0 14px;
  font-size: var(--font-size-b3);
  height: 30px;
  line-height: 29px;
  font-size: 14px;
}

.rbt-btn.btn-sm {
  padding: 0 22px;
  font-size: var(--font-size-b3);
  height: 45px;
  line-height: 43px;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.btn-sm {
    padding: 0 10px;
  }
}

.rbt-btn.btn-md {
  padding: 0 25px;
  font-size: var(--font-size-b3);
  height: 50px;
  line-height: 48px;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.btn-md {
    padding: 0 15px;
  }
}

.rbt-btn.btn-lg {
  height: 70px;
  line-height: 70px;
  padding: 0 35px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.btn-lg {
    padding: 0 17px;
  }
}

.rbt-btn.btn-xl {
  padding: 0 45px;
  font-size: 20px;
  height: 75px;
  line-height: 75px;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.btn-xl {
    padding: 0 20px;
    font-size: 16px;
    height: 55px;
    line-height: 55px;
  }
}

.rbt-btn.btn-xxl {
  padding: 0 60px;
  font-size: 22px;
  height: 100px;
  line-height: 100px;
}

@media only screen and (max-width: 767px) {
  .rbt-btn.btn-xxl {
    padding: 0 20px;
    font-size: 18px;
    height: 70px;
    line-height: 70px;
  }
}

.rbt-btn i {
  padding-left: 6px;
  display: inline-block;
  top: 2px;
  position: relative;
  font-size: 17px;
}

.rbt-btn i.image-icon {
  top: inherit;
  transition: 0.4s;
}

.rbt-btn.btn-border-gradient {
  background: linear-gradient(90deg, #cfa2e8, #637fea);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 10;
  color: var(--color-heading);
  box-shadow: 0 10px 13px 0 rgba(209, 211, 237, 0.39);
  border-radius: 5;
}

.rbt-btn.btn-border-gradient::before {
  content: "";
  z-index: -1;
  top: 2px;
  left: 2px;
  position: absolute;
  background: #fff;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 5px;
}

.rbt-btn.btn-border-gradient.radius-round {
  border-radius: 500px;
}

.rbt-btn.btn-border-gradient.radius-round::before {
  border-radius: 500px;
}

.rbt-btn.hover-transform-none:hover {
  transform: none;
}

/*-------------------------------
    Button Bg Color Variation 
--------------------------------*/

a.bg-primary-opacity:hover,
button.bg-primary-opacity:hover {
  background: var(--color-primary) !important;
  color: var(--color-white) !important;
}

a.bg-secondary-opacity:hover,
button.bg-secondary-opacity:hover {
  background: var(--color-secondary) !important;
  color: var(--color-white) !important;
}

a.bg-coral-opacity:hover,
button.bg-coral-opacity:hover {
  background: var(--color-coral) !important;
  color: var(--color-white) !important;
}

a.bg-violet-opacity:hover,
button.bg-violet-opacity:hover {
  background: var(--color-violet) !important;
  color: var(--color-white) !important;
}

a.bg-pink-opacity:hover,
button.bg-pink-opacity:hover {
  background: var(--color-pink) !important;
  color: var(--color-white) !important;
}

.bg-color-primary .rbt-btn:hover {
  background: var(--color-white);
  color: var(--color-primary);
}

@keyframes btnTxtMarqueeX {
  100% {
    transform: translateX(-200%);
  }
}

@keyframes btnTxtMarqueeY {
  100% {
    transform: translateY(-200%);
  }
}

a.btn-underline-gradient,
button.btn-underline-gradient {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
  background: linear-gradient(to right,
      var(--color-primary) 0%,
      var(--color-secondary) 100%);
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}

a.btn-underline-gradient::before,
a.btn-underline-gradient::after,
button.btn-underline-gradient::before,
button.btn-underline-gradient::after {
  background: linear-gradient(to right,
      var(--color-primary) 0%,
      var(--color-secondary) 100%);
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

a.btn-underline-gradient::after,
button.btn-underline-gradient::after {
  background: linear-gradient(to right,
      var(--color-heading) 0%,
      var(--color-heading) 100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scaleX(0);
  transform-origin: right center;
}

a.btn-underline-gradient:hover::after,
button.btn-underline-gradient:hover::after {
  transform-origin: left center;
  transform: scaleX(1);
}

/*-----------------------
    Modern Button  
------------------------*/

a.rbt-moderbt-btn {
  position: relative;
  height: 60px;
  line-height: 56px;
  transition: all 0.2s ease;
  padding: 0 26px;
  display: inline-block;
  z-index: 1;
  color: var(--color-heading);
}

@media only screen and (max-width: 767px) {
  a.rbt-moderbt-btn {
    padding: 0 10px;
  }
}

a.rbt-moderbt-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: var(--color-primary);
  opacity: 0.5;
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
  z-index: -1;
}

a.rbt-moderbt-btn i {
  position: relative;
  top: 2px;
  margin-left: 10px;
  transform: translateX(-5px);
  transition: all 0.3s ease;
  display: inline-block;
}

a.rbt-moderbt-btn:hover {
  color: var(--color-white);
}

a.rbt-moderbt-btn:hover i {
  transform: translateX(0);
}

a.rbt-moderbt-btn:hover::before {
  width: 100%;
  background: var(--color-primary);
  opacity: 1;
}

/*----------------------------------
    Education Transparent Button 
------------------------------------*/

a.rbt-btn-link,
button.rbt-btn-link,
span.rbt-btn-link {
  color: var(--color-heading);
  position: relative;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

a.rbt-btn-link i,
button.rbt-btn-link i,
span.rbt-btn-link i {
  position: relative;
  top: 1px;
  display: inline-block;
  padding-left: 4px;
}

a.rbt-btn-link::after,
button.rbt-btn-link::after,
span.rbt-btn-link::after {
  position: absolute;
  content: "";
  left: auto;
  bottom: 0;
  background: currentColor;
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}

a.rbt-btn-link:hover,
button.rbt-btn-link:hover,
span.rbt-btn-link:hover {
  color: var(--color-primary) !important;
}

a.rbt-btn-link:hover::after,
button.rbt-btn-link:hover::after,
span.rbt-btn-link:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

a.rbt-btn-link.left-icon i,
button.rbt-btn-link.left-icon i,
span.rbt-btn-link.left-icon i {
  padding-left: 0;
  padding-right: 4px;
}

a.rbt-btn-link.color-white:hover,
button.rbt-btn-link.color-white:hover,
span.rbt-btn-link.color-white:hover {
  color: var(--color-white) !important;
}

a.transparent-button,
button.transparent-button,
span.transparent-button {
  color: var(--color-heading);
  position: relative;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 500;
}

a.transparent-button i,
button.transparent-button i,
span.transparent-button i {
  display: inline-block;
  padding-left: 4px;
  transition: 0.4s;
}

a.transparent-button:hover i,
button.transparent-button:hover i,
span.transparent-button:hover i {
  padding-left: 10px;
}

/*-----------------------
 Video Player Button 
-------------------------*/

.video-popup-wrapper {
  position: relative;
}

.video-popup-wrapper .position-to-top {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.video-popup-wrapper .rbt-btn.rounded-player:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }

  75% {
    -webkit-transform: scale(1.9);
    transform: scale(1.9);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.3);
    transform: scale(2.3);
    opacity: 0;
  }
}

@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }

  100% {
    opacity: 0;
  }
}

@keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }

  100% {
    opacity: 0;
  }
}

/*--------------------------
    Section Title
---------------------------*/

.rbt-new-badge {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.rbt-new-badge.rbt-new-badge-one {
  color: var(--color-heading);
  font-size: 16px;
  font-weight: var(--f-medium);
  line-height: 1;
  padding: 19px 24px 21px 20px;
  box-shadow: 0 15px 40px #d2d3e2a1;
  background: #fff;
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .rbt-new-badge.rbt-new-badge-one {
    font-size: 14px;
    padding: 18px 10px 18px 10px;
  }
}

.rbt-new-badge.rbt-new-badge-one:before {
  position: absolute;
  top: 50%;
  right: -19px;
  left: -19px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 45px;
  width: -moz-calc(100% + 38px);
  width: calc(100% + 38px);
  background: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 29px #d1d3ed63;
  box-shadow: 0 0 29px #d1d3ed63;
  z-index: -1;
  content: "";
  -webkit-clip-path: polygon(100% 0, 97% 50%, 100% 100%, 0 100%, 3% 50%, 0 0);
  clip-path: polygon(100% 0, 97% 50%, 100% 100%, 0 100%, 3% 50%, 0 0);
}

.section-title .subtitle {
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: var(--color-heading);
  margin-bottom: 15px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 100px;
  text-transform: uppercase;
  display: inline-block;
  background: var(--color-primary);
  color: var(--color-primary);
}

.section-title .subtitle-2 {
  margin-bottom: 20px;
  display: inline-block;
}

.section-title .title {
  font-weight: var(--f-semi-bold);
}

.section-title.text-center .description {
  padding: 0 23%;
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title.text-center .description {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.text-center .description {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.text-center .description {
    padding: 0;
  }
}

.section-title.text-center .description.has-medium-font-size {
  padding: 0 23%;
  letter-spacing: -0.5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title.text-center .description.has-medium-font-size {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.text-center .description.has-medium-font-size {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.text-center .description.has-medium-font-size {
    padding: 0;
  }
}

.section-title.text-xl-start .description {
  padding: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title.text-xl-start .description {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.text-xl-start .description {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.text-xl-start .description {
    padding: 0;
  }
}

.select-label,
.rbt-label-style {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.5;
  margin-bottom: 6px;
}

.rbt-title-style-2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  background-color: #e4e9fd;
  padding: 12px 16px;
  border-radius: 5px;
}

.rbt-title-style-3 {
  margin-bottom: 24px;
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--color-border-2);
}

.rbt-short-title {
  font-size: 12px;
  padding-bottom: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.5;
  margin-bottom: 6px;
  border-bottom: 1px solid var(--color-border);
}

/*====================
    Breadcrump Area 
======================*/

.rbt-breadcrumb-default .title {
  color: var(--color-heading);
  margin-bottom: 5px;
  font-size: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-breadcrumb-default .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-breadcrumb-default .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-breadcrumb-default .title {
    font-size: 28px;
  }
}

/*-----------------------------
    Breadcrumd Page List  
-------------------------------*/

.page-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.page-list li {
  display: inline-block;
  font-size: 14px;
  color: var(--color-body);
  margin: 0;
}

.page-list li i {
  display: inline-block;
  position: relative;
  top: 2px;
}

.page-list li.active {
  color: var(--color-body);
  opacity: 0.6;
}

.page-list li a {
  color: var(--color-body);
  transition: 0.5s;
}

.page-list li a:hover {
  color: var(--color-primary);
}

.page-list li .icon-right i {
  color: var(--color-body);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*----------------------------
    Breadcrumb Style Two  
------------------------------*/

.breadcrumb-style-max-width {
  max-width: 1085px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-style-max-width {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-style-max-width {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-style-max-width {
    max-width: 540px;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb-style-max-width {
    max-width: 420px;
  }
}

@media only screen and (max-width: 479px) {
  .breadcrumb-style-max-width {
    max-width: inherit;
  }
}

.rbt-article-content-wrapper {
  padding: 50px 100px;
  background: #fff;
  box-shadow: var(--shadow-2);
  padding-top: 0;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-article-content-wrapper {
    padding: 50px 30px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-article-content-wrapper {
    padding: 50px 30px;
    padding-top: 0;
  }
}

.rbt-overlay-page-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  box-shadow: var(--shadow-2);
}

.rbt-overlay-page-wrapper .breadcrumb-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 44px 140px 50px;
  background: #fff;
  border-radius: 4px 4px 0 0;
  margin: 120px auto 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-overlay-page-wrapper .breadcrumb-image-container {
    padding: 44px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-overlay-page-wrapper .breadcrumb-image-container {
    padding: 44px 30px 50px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 479px) {
  .rbt-overlay-page-wrapper .breadcrumb-image-container {
    margin: 0px auto 0;
  }
}

.rbt-overlay-page-wrapper .breadcrumb-image-container .breadcrumb-image-wrapper {
  display: block;
  position: absolute;
  top: -243px;
  bottom: -250px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  height: -moz-calc(100% + 243px);
  height: calc(100% + 243px);
  width: 100vw;
  min-width: 1024px;
  z-index: -4;
}

.rbt-overlay-page-wrapper .breadcrumb-image-container .breadcrumb-image-wrapper img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.rbt-overlay-page-wrapper .breadcrumb-content-top .title {
  font-size: 42px;
}

@media only screen and (max-width: 767px) {
  .rbt-overlay-page-wrapper .breadcrumb-content-top .title {
    font-size: 32px;
  }
}

/*----------------------------
    Breadcrumb Style Main  
------------------------------*/

.rbt-page-banner-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  padding: 60px 0px 235px;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .rbt-page-banner-wrapper {
    padding: 40px 0px 180px;
  }
}

.rbt-page-banner-wrapper .rbt-banner-image {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -4;
}

.rbt-page-banner-wrapper .rbt-banner-image img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.rbt-page-banner-wrapper .rbt-banner-content-top .title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .rbt-page-banner-wrapper .rbt-banner-content-top .title-wrapper {
    display: block;
  }
}

.rbt-page-banner-wrapper .rbt-banner-content-top .title-wrapper .rbt-badge-2 {
  margin-left: 20px;
}

@media only screen and (max-width: 575px) {
  .rbt-page-banner-wrapper .rbt-banner-content-top .title-wrapper .rbt-badge-2 {
    margin-left: 0;
    margin-top: 20px;
  }
}

.rbt-page-banner-wrapper .rbt-banner-content-top .title {
  font-size: 42px;
}

@media only screen and (max-width: 767px) {
  .rbt-page-banner-wrapper .rbt-banner-content-top .title {
    font-size: 32px;
  }
}

.rbt-page-banner-wrapper .rbt-banner-content-top .description {
  color: var(--color-heading);
}

.rbt-section-overlayping-top {
  margin: -175px auto 0;
  position: relative;
  z-index: 2;
}

/*-----------------------------
    Breadcrumb Style Three  
------------------------------*/

.rbt-breadcrumb-default.rbt-breadcrumb-style-3 {
  position: relative;
  min-height: 490px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 80px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-breadcrumb-default.rbt-breadcrumb-style-3 {
    height: auto;
    padding: 120px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-breadcrumb-default.rbt-breadcrumb-style-3 {
    height: auto;
    padding: 60px 0;
  }
}

.rbt-breadcrumb-default.rbt-breadcrumb-style-3 .breadcrumb-inner>img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.rbt-breadcrumb-default.rbt-breadcrumb-style-3 .content {
  position: relative;
  z-index: 2;
  padding-right: 10%;
}

.rbt-breadcrumb-style-3 .title {
  margin-bottom: 20px;
}

.rbt-breadcrumb-style-3 .description {
  color: var(--color-heading);
}

@media only screen and (max-width: 767px) {
  .rbt-breadcrumb-style-3 .description {
    margin-bottom: 20px;
  }
}

.rbt-breadcrumb-style-3 .page-list {
  margin-bottom: 20px;
}

.rbt-breadcrumb-style-3 .rating {
  font-size: 14px;
  font-weight: 500;
}

.rbt-breadcrumb-style-3 .total-student {
  font-size: 14px;
  font-weight: 500;
}

.rbt-breadcrumb-style-3 .total-student span {
  color: var(--color-heading);
}

.rbt-breadcrumb-style-3 .rbt-meta li {
  color: var(--color-heading);
}

.rbt-breadcrumb-style-3 .rbt-course-details-feature {
  margin: -5px;
}

.rbt-breadcrumb-style-3 .rbt-course-details-feature .feature-sin {
  margin: 5px;
}

/*----------------------------- 
    Edu Course Style 
-------------------------------*/

.rbt-card {
  overflow: hidden;
  box-shadow: var(--shadow-1);
  padding: 30px;
  border-radius: var(--radius);
  background: var(--color-white);
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card {
    padding: 15px;
  }
}

.rbt-card .rbt-card-img {
  position: relative;
}

.rbt-card .rbt-card-img a {
  display: block;
}

.rbt-card .rbt-card-img a img {
  width: 100%;
  transition: 0.5s;
  object-fit: cover;
  border-radius: var(--radius);
}

.rbt-card .rbt-card-body {
  padding-top: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card .rbt-card-body {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card .rbt-card-body {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card .rbt-card-body {
    padding-top: 20px;
  }
}

.rbt-card .rbt-card-body .rbt-meta {
  margin-bottom: 14px;
}

@media only screen and (max-width: 767px) {
  .rbt-card .rbt-card-body .rbt-meta {
    margin-bottom: 8px;
  }
}

.rbt-card .rbt-card-body .rbt-category {
  margin-bottom: 15px;
}

.rbt-card .rbt-card-body .lesson-number {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card .rbt-card-body .lesson-number {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card .rbt-card-body .lesson-number {
    margin-bottom: 8px;
  }
}

.rbt-card .rbt-card-body .rbt-card-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.rbt-card .rbt-card-body .rbt-card-title a {
  color: var(--color-heading);
}

.rbt-card .rbt-card-body .rbt-card-title a:hover {
  color: var(--color-primary);
}

@media only screen and (max-width: 575px) {
  .rbt-card .rbt-card-body .rbt-card-title {
    font-size: 18px;
  }
}

.rbt-card .rbt-card-body .rbt-card-text {
  color: var(--color-body);
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: var(--font-size-b3);
  border-bottom: 1px solid #d8d8d8;
}

@media only screen and (max-width: 1199px) {
  .rbt-card .rbt-card-body .rbt-card-text {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card .rbt-card-body .rbt-card-text {
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1199px) {
  .rbt-card .rbt-card-body .rbt-author-meta {
    margin-bottom: 10px !important;
  }
}

.rbt-card .rbt-card-body .rbt-review {
  margin-bottom: 12px;
}

.rbt-card .rbt-card-body .rbt-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.rbt-card .rbt-card-body .rbt-card-bottom .rbt-btn-link {
  font-size: 14px;
}

.rbt-card .rbt-card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-top: -10px;
}

@media only screen and (max-width: 767px) {
  .rbt-card .rbt-card-top {
    margin-bottom: 10px;
    margin-top: 0;
  }
}

.rbt-card .rbt-card-top .rbt-review {
  margin-bottom: 0;
}

.rbt-card.variation-02 {
  position: relative;
  padding: 0;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
}

.rbt-card.variation-02 .rbt-card-body {
  padding: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.variation-02 .rbt-card-body {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.variation-02 .rbt-card-body {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.variation-02 .rbt-card-body {
    padding: 20px;
  }
}

.rbt-card.variation-02 .rbt-card-img a img {
  max-height: 350px;
  border-radius: 6px 6px 0 0;
}

.rbt-card.height-auto .rbt-card-img a img {
  max-height: inherit !important;
}

.rbt-card.card-minimal {
  box-shadow: var(--shadow-9);
}

.rbt-card.card-minimal .rbt-card-body {
  padding: 50px 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.card-minimal .rbt-card-body {
    padding: 40px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-minimal .rbt-card-body {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-minimal .rbt-card-body {
    padding: 20px;
  }
}

.rbt-card.variation-03 {
  height: 100%;
}

.rbt-card.variation-03 .rbt-card-img .thumbnail-link {
  position: relative;
  display: block;
}

.rbt-card.variation-03 .rbt-card-img .thumbnail-link::before {
  position: absolute;
  content: "";
  background: rgba(111, 120, 148, 0.54);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: var(--transition-2);
  opacity: 0;
  border-radius: var(--radius);
}

.rbt-card.variation-03 .rbt-card-img .thumbnail-link .rbt-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 50px;
  transition: 0.4s;
  opacity: 0;
  width: max-content;
}

.rbt-card.variation-03 .rbt-card-body {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .rbt-card.variation-03 .rbt-card-body {
    padding-top: 15px;
  }
}

.rbt-card.variation-03 .rbt-card-body .rbt-card-title {
  flex-basis: 80%;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-title {
    flex-basis: 90%;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-title {
    flex-basis: 90%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-title {
    flex-basis: 90%;
    font-size: 20px;
  }
}

.rbt-card.variation-03 .rbt-card-body .rbt-card-bottom {
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-bottom {
    flex-basis: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-bottom {
    flex-basis: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.variation-03 .rbt-card-body .rbt-card-bottom {
    flex-basis: 10%;
  }
}

.rbt-card.variation-03 .card-information {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.rbt-card.variation-03 .card-information img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-border);
  object-fit: cover;
}

.rbt-card.variation-03 .card-information .card-count {
  padding-left: 10px;
}

.rbt-card.variation-03.program-image-large .card-information img {
  width: 40px;
  height: 40px;
}

.rbt-card.variation-03:hover .rbt-card-img .thumbnail-link::before {
  opacity: 1;
}

.rbt-card.variation-03:hover .rbt-card-img .thumbnail-link .rbt-btn {
  margin-top: 0;
  opacity: 1;
}

.rbt-card.height-330 .rbt-card-img a img {
  max-height: 330px;
}

.rbt-card.card-list {
  display: flex;
  max-height: 150px;
  border-radius: 2px;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list {
    display: block;
    max-height: inherit;
    align-items: center;
    height: auto;
    border-radius: var(--radius);
  }
}

.rbt-card.card-list .rbt-card-img {
  height: 100%;
}

.rbt-card.card-list .rbt-card-img a {
  height: 100%;
}

.rbt-card.card-list .rbt-card-img a img {
  border-radius: 2px 0 0 2px;
  max-height: initial;
  max-width: 290px;
  min-width: 290px;
  object-fit: cover;
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.card-list .rbt-card-img a img {
    max-width: 200px;
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list .rbt-card-img a img {
    max-height: initial;
    max-width: inherit;
    min-width: inherit;
    width: 100%;
    object-fit: cover;
    border-radius: var(--radius) var(--radius) 0 0;
  }
}

.rbt-card.card-list .rbt-card-body {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 575px) {
  .rbt-card.card-list .rbt-card-body {
    padding: 20px;
  }
}

.rbt-card.card-list-2 {
  display: flex;
  border-radius: var(--radius);
  align-items: center;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2 {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2 {
    display: block;
  }
}

.rbt-card.card-list-2 .rbt-card-img {
  flex-basis: 40%;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2 .rbt-card-img {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2 .rbt-card-img {
    height: auto;
  }
}

.rbt-card.card-list-2 .rbt-card-img a {
  display: block;
  height: 100%;
  width: 100%;
}

.rbt-card.card-list-2 .rbt-card-img a img {
  border-radius: var(--radius);
  max-height: 100%;
  max-width: 100%;
}

.rbt-card.card-list-2 .rbt-card-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 60%;
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.card-list-2 .rbt-card-body {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2 .rbt-card-body {
    padding-left: 0;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2 .rbt-card-body {
    padding-left: 0;
    padding-top: 30px;
  }
}

.rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
  font-size: 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.card-list-2.elegant-course {
    display: block;
  }
}

.rbt-card.card-list-2.elegant-course .rbt-card-img {
  flex-basis: 55%;
  display: block;
}

.rbt-card.card-list-2.elegant-course .rbt-card-img a {
  display: block;
  height: 100%;
}

.rbt-card.card-list-2.elegant-course .rbt-card-img a img {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  border-radius: 6px 0 0 6px;
}

.rbt-card.card-list-2.elegant-course .rbt-card-body {
  flex-basis: 45%;
  position: relative;
}

.rbt-card.card-list-2.event-list-card .rbt-card-img {
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-img {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-img {
    height: auto;
  }
}

.rbt-card.card-list-2.event-list-card .rbt-card-img a {
  height: 100%;
}

.rbt-card.card-list-2.event-list-card .rbt-card-img a img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-img a img {
    max-width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-img a img {
    max-width: 100%;
    height: auto;
  }
}

.rbt-card.card-list-2.event-list-card .rbt-card-body {
  padding-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-body {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-body {
    padding-left: 0;
    padding-top: 20px;
  }
}

.rbt-card.card-list-2.event-list-card .rbt-card-body .rbt-card-title {
  font-size: 22px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .rbt-card.card-list-2.event-list-card .rbt-card-body .rbt-card-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.rbt-card.elegant-course {
  padding: 0;
  border-radius: 10px;
  align-items: inherit;
}

.rbt-card.elegant-course .rbt-meta {
  margin: -3px;
  margin-bottom: -6px;
}

.rbt-card.elegant-course .rbt-meta li {
  margin: 3px;
  margin-bottom: 6px;
}

.rbt-card.elegant-course .rbt-meta-badge {
  margin: -3px;
  margin-bottom: -6px;
}

.rbt-card.elegant-course .rbt-meta-badge li {
  margin: 3px;
  margin-bottom: 6px;
}

.rbt-card.elegant-course .rbt-meta-badge li .rbt-badge {
  transition: 0.3s;
}

.rbt-card.elegant-course .rbt-meta-badge li .rbt-badge:hover {
  background: var(--primary-opacity);
  color: var(--color-primary);
}

.rbt-card.elegant-course .rbt-card-img a img {
  border-radius: var(--radius) var(--radius) 0 0;
}

.rbt-card.elegant-course .rbt-card-body {
  padding: 30px;
}

.rbt-card.elegant-course .rbt-card-body .rbt-card-bottom .rbt-btn-link {
  margin-left: 20px;
}

.rbt-card.elegant-course.card-list-2 .rbt-card-body {
  padding: 45px 30px 35px;
}

@media only screen and (max-width: 767px) {
  .rbt-card.elegant-course.card-list-2 .rbt-card-body {
    padding: 45px 20px 35px;
  }
}

.rbt-card.event-grid-card .rbt-meta {
  margin: -3px;
  margin-bottom: 10px;
}

.rbt-card.event-grid-card .rbt-meta li {
  margin: 3px;
}

.rbt-card.event-grid-card .rbt-card-body {
  padding-top: 15px;
}

.rbt-card.event-grid-card .rbt-card-body .rbt-card-title {
  margin-bottom: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-card.event-grid-card .rbt-card-body .rbt-card-title {
    font-size: 24px;
  }
}

.rbt-card.event-grid-card .rbt-badge span {
  font-size: 12px;
  color: var(--color-body);
  display: block;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.rbt-meta {
  margin: -7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-meta {
    margin: -3px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-meta {
    margin: -3px;
  }
}

.rbt-meta li {
  font-size: 14px;
  line-height: 15px;
  color: var(--color-body);
  display: inline-block;
  margin: 7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-meta li {
    margin: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-meta li {
    margin: 3px;
    font-size: var(--font-size-b4);
  }
}

.rbt-meta li i {
  margin-right: 5px;
}

.rbt-meta.rbt-meta-white li {
  color: var(--color-white);
}

.rbt-category {
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap;
}

.rbt-category a {
  display: inline-block;
  padding: 0 20px;
  height: 34px;
  line-height: 33px;
  border: 1px solid var(--color-border);
  border-radius: 500px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-body);
  transition: 0.4s;
  margin: 0 5px;
  min-width: 56px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-category a {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-category a {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-category a {
    padding: 0 10px;
  }
}

.rbt-category a:hover {
  background-color: var(--color-grey);
}

.lesson-number {
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}

.lesson-number .lesson-time {
  font-weight: 400;
}

.rbt-review {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.rbt-review .rating {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.rbt-review .rating i {
  font-size: 13px;
  color: #ff9747;
  transition: 0.3s;
}

.rbt-review .rating-count {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.rating i {
  font-size: 13px;
  color: #ff9747;
  transition: 0.3s;
}

.rating i.off {
  font-weight: 400;
}

.rbt-price {
  display: flex;
  align-items: center;
}

.rbt-price .current-price {
  font-size: 24px;
  font-weight: 700;
}

.rbt-price .off-price {
  font-size: 20px;
  font-weight: 500;
  text-decoration: line-through;
  opacity: 0.4;
  margin-left: 7px;
}

.rbt-price.large-size .current-price {
  font-size: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-price.large-size .current-price {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-price.large-size .current-price {
    font-size: 28px;
  }
}

.rbt-price.large-size .off-price {
  font-size: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-price.large-size .off-price {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-price.large-size .off-price {
    font-size: 16px;
  }
}

/*----------------------------
    Service Card Styles  
------------------------------*/

.rbt-service.rbt-service-1 {
  border-radius: 6px;
}

.rbt-service.rbt-service-1 .inner {
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-1 .inner {
    padding: 22px 18px;
  }
}

.rbt-service.rbt-service-1 .inner .icon {
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-1 .inner .icon {
    margin-bottom: 15px;
  }
}

.rbt-service.rbt-service-1 .inner .icon img {
  max-width: 55px;
}

.rbt-service.rbt-service-1 .inner .content .title {
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-1 .inner .content .title {
    margin-bottom: 9px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-1 .inner .content p {
    margin-bottom: 12px;
  }
}

.rbt-service.rbt-service-1 .rbt-flipbox-back.inner p {
  color: var(--color-white);
}

.rbt-service.rbt-service-2 {
  padding: 30px;
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: var(--radius);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-service.rbt-service-2 {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-2 {
    padding: 30px 20px;
  }
}

.rbt-service.rbt-service-2 .inner {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-service.rbt-service-2 .inner {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-2 .inner {
    flex-direction: column-reverse;
  }
}

.rbt-service.rbt-service-2 .inner .thumbnail {
  flex-basis: 50%;
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-2 .inner .thumbnail {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
}

.rbt-service.rbt-service-2 .inner .thumbnail img {
  width: 100%;
}

.rbt-service.rbt-service-2 .inner .content {
  flex-basis: 50%;
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-2 .inner .content {
    flex-basis: 100%;
  }
}

.rbt-service.rbt-service-2 .inner .content .title {
  margin-bottom: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-service.rbt-service-2 .inner .content .title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-service.rbt-service-2 .inner .content .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-service.rbt-service-2 .inner .content .title {
    font-size: 22px;
  }
}

.rbt-service.rbt-service-2 .inner .content p {
  font-size: 16px;
  margin-bottom: 12px;
}

.rbt-service.rbt-service-2.bg-gray-ligt {
  background: var(--color-gray-light);
  box-shadow: none;
}

.rbt-service.rbt-service-2.bg-no-shadow {
  background: var(--color-white);
  box-shadow: none;
}

.rbt-service.rbt-service-2.variation-2 .inner .content {
  flex-basis: 65%;
}

.rbt-service.rbt-service-2.variation-2 .inner .content .title {
  margin-bottom: 8px;
}

.rbt-service.rbt-service-2.variation-2 .inner .thumbnail {
  flex-basis: 35%;
}

.rbt-flipbox {
  position: relative;
  perspective: 1200px;
  overflow: hidden;
  border-radius: 6px;
  height: 100%;
}

.rbt-flipbox .rbt-flipbox-wrap {
  position: relative;
  transition-property: box-shadow, transform;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  height: 100%;
}

.rbt-flipbox .rbt-flipbox-wrap .rbt-flipbox-face {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  backface-visibility: hidden;
  transition: 0.6s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
}

.rbt-flipbox .rbt-flipbox-wrap .rbt-flipbox-front .content>a {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-flipbox .rbt-flipbox-wrap .rbt-flipbox-front .content>a {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-flipbox .rbt-flipbox-wrap .rbt-flipbox-front .content>a {
    margin-top: 10px;
  }
}

.rbt-flipbox .rbt-flipbox-wrap .rbt-flipbox-back {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg) translateZ(1px);
  background: linear-gradient(1turn, #e9dcfa, #d05aff);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  text-align: center;
  border-radius: 6px;
  text-align: left;
}

.rbt-flipbox:hover .rbt-flipbox-wrap {
  transform: rotateY(180deg);
  transform-origin: 50% 50% !important;
}

.rbt-flipbox:hover .rbt-flipbox-wrap .rbt-flipbox-face {
  backface-visibility: inherit;
  backface-visibility: inherit;
}

.rbt-flipbox.variation-2 .front-thumb,
.rbt-flipbox.variation-2 .back-thumb {
  width: 100%;
}

.rbt-flipbox.variation-2 .front-thumb img,
.rbt-flipbox.variation-2 .back-thumb img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  max-height: 150px;
  margin-bottom: 25px;
}

/*-------------------------------
    Service Style Five  
---------------------------------*/

.service-card-5 .inner {
  border-radius: 5px;
  padding: 20px;
  transition: 0.4s;
  display: flex;
  align-items: center;
  transition: 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s 0.1s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  z-index: 1;
  box-shadow: var(--shadow-9);
}

.service-card-5 .inner::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease;
  background: var(--color-white);
  border-radius: 5px;
}

.service-card-5 .inner .icon {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  transition: 0.4s;
  justify-content: center;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-card-5 .inner .icon {
    margin-right: 15px;
  }
}

.service-card-5 .inner .content .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0;
  transition: 0.4s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-card-5 .inner .content .title {
    font-size: 15px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-card-5 .inner .content .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .service-card-5 .inner .content .title {
    font-size: 16px;
    text-align: center;
  }
}

.service-card-5 .inner .content .description {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  transition: 0.4s;
}

.service-card-5:hover .inner::before {
  background: var(--color-white);
  transform: scale(1.1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.1s 0s cubic-bezier(0.23, 1, 0.32, 1);
}

.service-card-5.variation-2 .inner {
  box-shadow: none;
  flex-direction: column;
  padding: 0;
}

.service-card-5.variation-2 .inner::before {
  display: none;
}

.service-card-5.variation-2 .inner .icon {
  margin-right: 0;
  margin-bottom: 15px;
  background: var(--pink-opacity);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}

.service-card-5.variation-2 .inner .icon a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  padding: 4px;
}

.service-card-5.variation-2 .inner .icon a::before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(90deg, #cfa2e8, #637fea);
  width: 100%;
  height: 100%;
}

.service-card-5.variation-2 .inner .icon img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 100%;
}

.service-card-5.variation-2 .inner:hover .icon img {
  transform: scale(1.1);
}

/*-------------------------------
    Service Style Five  
---------------------------------*/

.service-card-6 {
  height: 100%;
}

.service-card-6 .inner {
  border-radius: 5px;
  padding: 45px 35px;
  position: relative;
  z-index: 1;
  border: 1px solid var(--color-border);
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .service-card-6 .inner {
    padding: 30px 20px;
  }
}

.service-card-6 .inner .icon {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}

.service-card-6 .inner .icon img {
  max-height: 60px;
}

.service-card-6 .inner .icon .opacity_image {
  position: absolute;
  transform: translateX(-50%) scale(0.8);
  opacity: 0.8;
  z-index: -1;
  -webkit-filter: blur(15px);
  filter: blur(15px);
  left: 50%;
  top: 30%;
}

.service-card-6 .inner .content {
  padding-top: 10px;
}

.service-card-6 .inner .content .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
  transition: 0.4s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-card-6 .inner .content .title {
    font-size: 17px;
    line-height: 28px;
  }
}

.service-card-6 .inner .content .description {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.service-card-6 .inner .number-text {
  font-size: 100px;
  font-weight: 800;
  opacity: 0.05;
  display: inline-block;
  line-height: 70px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: -1;
}

.service-card-6:hover .inner::before {
  background: var(--color-gray-light);
  transform: scale(1.1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.1s 0s cubic-bezier(0.23, 1, 0.32, 1);
}

.service-card-6.bg-color .inner {
  border-color: transparent;
}

/*-----------------------
    Swiper Slider  
------------------------*/

.slick-initialized .slick-slide {
  margin-bottom: -10px;
}

.swiper-cards .swiper-slide {
  overflow: visible;
}

.swiper-3d .swiper-slide-shadow {
  background: transparent;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
  position: absolute;
}

.rbt-dot-bottom-center {
  padding-bottom: 60px;
}

.rbt-dot-bottom-center .rbt-swiper-pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
}

.rbt-dot-bottom-center .rbt-swiper-pagination.swiper-pagination-fraction {
  color: var(--heading-opacity);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 3px;
}

.rbt-swiper-pagination .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  opacity: 0.5;
  background: transparent;
  box-shadow: inset 0 0 0 5px var(--color-body);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin: 0 8px !important;
}

@media only screen and (max-width: 767px) {
  .rbt-swiper-pagination .swiper-pagination-bullet {
    margin: 0 16px !important;
  }
}

.rbt-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--color-primary);
  transform: scale(2);
  opacity: 1;
}

.bg-gradient-3 .rbt-swiper-pagination .swiper-pagination-bullet {
  box-shadow: inset 0 0 0 5px var(--color-white);
}

.bg-gradient-3 .rbt-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  box-shadow: inset 0 0 0 1px var(--color-white);
}

/*---------------------------
    Slick Arrow Styles  
-----------------------------*/

.rbt-arrow-between {
  position: relative;
}

.rbt-arrow-between .rbt-swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  color: var(--color-body);
  background-color: var(--color-white);
  z-index: 3;
}

.rbt-arrow-between .rbt-swiper-arrow .custom-overfolow {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.rbt-arrow-between .rbt-swiper-arrow i {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  margin-top: -9px;
  margin-left: -9px;
  z-index: 1;
}

.rbt-arrow-between .rbt-swiper-arrow i.rbt-icon {
  transition: transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -webkit-transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.rbt-arrow-between .rbt-swiper-arrow i.rbt-icon-top {
  position: absolute;
  transition: transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -webkit-transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateX(300%);
}

.rbt-arrow-between .rbt-swiper-arrow::after {
  transition: transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -webkit-transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  z-index: -1;
}

.rbt-arrow-between .rbt-swiper-arrow:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.rbt-arrow-between .rbt-swiper-arrow:hover::after {
  transform: scale(1.2);
}

.rbt-arrow-between .rbt-swiper-arrow:hover i.rbt-icon {
  transform: translateX(-300%);
}

.rbt-arrow-between .rbt-swiper-arrow:hover i.rbt-icon-top {
  transform: translateX(0);
}

.rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-left {
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-left {
    left: 20px;
  }
}

.rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-right {
  left: auto;
  right: 30px;
}

.rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-right i.rbt-icon-top {
  transform: translateX(-300%);
}

.rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-right:hover i.rbt-icon {
  transform: translateX(300%);
}

.rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-right:hover i.rbt-icon-top {
  transform: translateX(0);
}

@media only screen and (max-width: 767px) {
  .rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-right {
    left: auto;
    right: 20px;
  }
}

.rbt-arrow-between .rbt-swiper-arrow.swiper-button-disabled {
  cursor: not-allowed;
  opacity: 0;
}

.rbt-arrow-between.rbt-dot-bottom-center .rbt-swiper-arrow {
  margin-top: -29px;
}

.rbt-arrow-between.icon-bg-gray .rbt-swiper-arrow {
  background: var(--color-gray-lighter);
}

.rbt-arrow-between.icon-bg-gray .rbt-swiper-arrow::after {
  background: var(--color-gray-lighter);
}

.rbt-arrow-between.icon-bg-primary .rbt-swiper-arrow {
  background: var(--color-primary);
  color: var(--color-white);
}

.rbt-arrow-between.icon-bg-primary .rbt-swiper-arrow::after {
  background: var(--color-primary);
}

.rbt-arrow-between.md-size .rbt-swiper-arrow {
  width: 40px;
  height: 40px;
}

.rbt-arrow-between.arrow-right-bottom .rbt-swiper-arrow {
  position: absolute;
  top: auto;
  transform: none;
  bottom: 0;
  right: 0;
  opacity: 1;
}

.rbt-arrow-between:hover .rbt-swiper-arrow {
  opacity: 1;
}

.rbt-arrow-between:hover .rbt-swiper-arrow.swiper-button-disabled {
  opacity: 0.5 !important;
}

.parent-swiper {
  margin: -30px;
}

.parent-swiper .parent-swiper-inner {
  padding: 30px;
}

.parent-swiper .swiper-slide .rbt-card {
  box-shadow: none;
}

.parent-swiper .swiper-slide .rbt-card:hover {
  box-shadow: var(--shadow-1);
  transform: scale(1);
}

.parent-swiper .rbt-arrow-between .rbt-swiper-arrow {
  left: 60px;
}

.parent-swiper .rbt-arrow-between .rbt-swiper-arrow.rbt-arrow-right {
  left: auto;
  right: 60px;
}

.swiper .rbt-cat-box-1.variation-2 .inner {
  box-shadow: var(--shadow-light);
}

.swiper .rbt-cat-box-1 .inner {
  box-shadow: var(--shadow-light);
}

@media only screen and (max-width: 767px) {
  .banner-swiper-active.rbt-dot-bottom-center .rbt-swiper-pagination {
    bottom: 23px;
  }
}

/*------------------------
    Feature Styles  
-------------------------*/

.rbt-feature {
  display: flex;
  transition: var(--transition-3);
}

.rbt-feature .icon {
  width: 50px;
  min-width: 50px;
  height: 50px;
  background: var(--color-secondary);
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.rbt-feature .icon i {
  color: var(--color-white);
  font-size: 24px;
}

.rbt-feature .icon.bg-primary-opacity i {
  color: var(--color-primary);
}

.rbt-feature .icon.bg-pink-opacity i {
  color: var(--color-pink);
}

.rbt-feature .icon.bg-coral-opacity i {
  color: var(--color-coral);
}

.rbt-feature .icon.bg-secondary-opacity i {
  color: var(--color-secondary);
}

.rbt-feature .feature-content .feature-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0;
}

.rbt-feature .feature-content .feature-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 5px;
}

.rbt-feature+.rbt-feature {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .rbt-feature+.rbt-feature {
    margin-top: 20px;
  }
}

.rbt-feature.feature-style-2 {
  padding: 20px;
}

.rbt-feature.feature-style-2:hover {
  box-shadow: var(--shadow-5);
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  .rbt-feature.feature-style-2:hover {
    transform: scale(1);
  }
}

.rbt-feature.feature-style-2+.feature-style-2 {
  margin-top: 10px;
}

.rbt-round-icon {
  width: 50px;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .rbt-round-icon {
    margin: auto;
  }

  .counter-text {
    text-align: center;
    margin-top: 10px;
  }
}

.rbt-round-icon i {
  color: var(--color-white);
  font-size: 24px;
}

.rbt-round-icon.bg-primary-opacity i {
  color: var(--color-primary);
}

.rbt-round-icon.bg-pink-opacity i {
  color: var(--color-pink);
}

.rbt-round-icon.bg-coral-opacity i {
  color: var(--color-coral);
}

.rbt-round-icon.bg-secondary-opacity i {
  color: var(--color-secondary);
}

.rbt-round-icon.bg-violet-opacity i {
  color: var(--color-violet);
}

.rbt-round-icon.bg-warning-opacity i {
  color: var(--color-warning);
}

.rbt-single-course-features .subtitle {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-single-course-features .subtitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-single-course-features .subtitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-single-course-features .subtitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/*-----------------------
    About Styles  
------------------------*/

.about-style-1 {
  position: relative;
}

.about-style-1 .thumbnail-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper {
    display: block;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail.image-1 {
  left: -100px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-1 {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-1 {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-1 {
    left: 0;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail.image-1 img {
  max-height: 490px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-1 img {
    width: 100%;
    transform: none !important;
    margin-bottom: 20px;
    max-height: inherit;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail.image-2 {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-2 {
    top: -33px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-2 {
    display: none;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail.image-2 img {
  max-height: 250px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-2 img {
    width: 100%;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail.image-3 {
  position: relative;
  left: 150px;
  top: -179px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-3 {
    left: 88px;
    top: -193px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-3 {
    left: 0;
    top: 0;
    width: 100%;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail.image-3 img {
  max-height: 530px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .about-style-1 .thumbnail-wrapper .thumbnail.image-3 img {
    transform: none !important;
  }
}

.about-style-1 .thumbnail-wrapper .thumbnail img {
  border-radius: 6px;
}

.about-style-2 {
  position: relative;
  z-index: 2;
}

.about-style-2 .about-wrapper {
  margin-top: -150px;
  background: var(--color-white);
  border-radius: 10px;
  padding: 15px;
  box-shadow: var(--shadow-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-style-2 .about-wrapper {
    margin-top: 80px;
    padding: 15px 15px 30px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style-2 .about-wrapper {
    margin-top: 80px;
    padding: 15px 15px 30px 15px;
  }
}

.about-style-2 .about-wrapper .thumbnail img {
  border-radius: 10px;
  width: 100%;
}

.about-style-2 .about-wrapper .content .inner {
  padding: 0 40px;
}

@media only screen and (max-width: 767px) {
  .about-style-2 .about-wrapper .content .inner {
    padding: 0;
  }
}

.about-style-2 .about-wrapper .content .inner .title {
  font-size: 32px;
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .about-style-2 .about-wrapper .content .inner .title {
    font-size: 26px;
  }
}

.about-style-2 .about-wrapper .content .inner p {
  margin-bottom: 20px;
  width: 80%;
}

.contact-address {
  padding: 0;
  margin: 0;
  list-style: none;
}

.contact-address li {
  font-size: 16px;
}

.contact-address li i {
  color: var(--color-primary);
  margin-right: 10px;
}

/*-----------------------------
    Call To Action Styles  
------------------------------*/

.rbt-callto-action.callto-action-default {
  position: relative;
  padding: 40px;
  height: 100%;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-callto-action.callto-action-default {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-callto-action.callto-action-default {
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-callto-action-area.mt_dec--half {
    margin-bottom: 0;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-callto-action-area.mt_dec--half {
    margin-bottom: 0;
    padding-bottom: 80px;
  }
}

/*--------------------------------
    Call To Action Style  
--------------------------------*/

.rbt-cta-default.style-2 .content-wrapper {
  background-color: var(--color-white);
  padding: 70px 50px;
  box-shadow: var(--shadow-1);
  border-radius: 6px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cta-default.style-2 .content-wrapper {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-2 .content-wrapper {
    padding: 40px 30px;
  }
}

.rbt-cta-default.style-2 .content .title {
  margin-bottom: 20px;
}

.rbt-cta-default.style-2 .content .subtitle {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 0;
}

.rbt-cta-default.style-2 .shape-text-image img {
  position: absolute;
  width: 100%;
  height: auto;
  top: -220%;
  right: -73%;
}

.rbt-cta-default.style-3 .content-wrapper {
  background-color: var(--color-primary);
  padding: 70px 50px;
  border-radius: var(--radius);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cta-default.style-3 .content-wrapper {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-3 .content-wrapper {
    padding: 40px 30px;
  }
}

.rbt-cta-default.style-3 .content .title {
  margin-bottom: 0;
  color: var(--color-white);
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-3 .content .title {
    font-size: 22px;
    line-height: 1.5;
  }
}

.rbt-cta-default.style-4 {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-cta-default.style-4 {
    position: relative;
    padding: 30px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cta-default.style-4 {
    position: relative;
    padding: 30px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-4 {
    position: relative;
    padding: 30px 10px;
    margin-top: 0 !important;
  }
}

.rbt-cta-default.style-4 .content .title {
  margin-bottom: 0;
  color: var(--color-heading);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-cta-default.style-4 .content .title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-4 .content .title {
    font-size: 24px;
  }
}

.rbt-cta-default.style-4 .content-wrapper {
  padding-top: 20px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cta-default.style-4 .content-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-4 .content-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.rbt-cta-default.style-4 .content-wrapper .inner {
  padding-left: 45px;
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-4 .content-wrapper .inner {
    padding-left: 0;
  }
}

.rbt-cta-default.style-4 .content-wrapper .thumbnail {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 21%;
}

@media only screen and (max-width: 767px) {
  .rbt-cta-default.style-4 .content-wrapper .thumbnail {
    display: none;
  }
}

.rbt-cta-default.style-4.variation-2 .content-wrapper .thumbnail {
  width: 25%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-cta-default.style-4.variation-2 .content-wrapper .thumbnail {
    width: 27%;
  }
}

.rbt-cta-default.style-4.variation-2 .content-wrapper .inner {
  padding-top: 15px;
  padding-bottom: 15px;
}

.rbt-cta-5 .title {
  font-size: 60px;
  padding-right: 26%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-cta-5 .title {
    font-size: 45px;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cta-5 .title {
    font-size: 40px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cta-5 .title {
    font-size: 34px;
    padding-right: 0;
  }
}

.rbt-call-to-action-5 {
  position: relative;
}

.rbt-call-to-action-5 .shape-images {
  position: absolute;
  right: 10%;
  top: 0;
  max-width: 36%;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-call-to-action-5 .shape-images {
    right: 5%;
    max-width: 48%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-call-to-action-5 .shape-images {
    right: 4%;
    max-width: 53%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-call-to-action-5 .shape-images {
    right: 4%;
    max-width: 53%;
  }
}

/*-------------------------
    Accordion Area  
--------------------------*/

.rbt-accordion-style .card {
  background: var(--color-primary-lighter);
  border: 0;
  border-radius: var(--radius) !important;
}

.rbt-accordion-style .card .card-header {
  background-color: transparent;
  border-bottom: 0 none;
  box-shadow: none;
  padding: 20px 30px;
  margin-bottom: 0;
  padding-right: 38px;
}

@media only screen and (max-width: 767px) {
  .rbt-accordion-style .card .card-header {
    padding-left: 20px;
  }
}

.rbt-accordion-style .card .card-header button {
  font-size: 16px;
  line-height: 20px;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  width: 100%;
  text-align: left;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .rbt-accordion-style .card .card-header button {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-accordion-style .card .card-header button .rbt-badge-5 {
    margin-left: 0 !important;
    margin-top: 7px;
  }
}

.rbt-accordion-style .card .card-header button[aria-expanded="true"] {
  color: var(--color-primary);
}

.rbt-accordion-style .card .card-header button[aria-expanded="true"]::before {
  color: var(--color-primary);
}

.rbt-accordion-style .card .card-header button::before {
  position: absolute;
  content: "\e996";
  font-family: "feather" !important;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}

.rbt-accordion-style .card .card-header button.collapsed {
  color: var(--color-heading);
}

.rbt-accordion-style .card .card-header button.collapsed::before {
  content: "\e9b1";
}

.rbt-accordion-style .card .card-body {
  border-top: 1px solid var(--white-opacity);
  padding: 20px 30px 25px;
}

@media only screen and (max-width: 767px) {
  .rbt-accordion-style .card .card-body {
    padding: 20px 20px;
  }
}

.rbt-accordion-style .card+.card {
  margin-top: 10px;
}

.rbt-accordion-style.rbt-accordion-01 .card {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
}

.rbt-accordion-style.rbt-accordion-01 .card .card-body {
  background: var(--gradient-6);
}

.rbt-accordion-style.rbt-accordion-02 .card {
  background: transparent;
  border-radius: 0 !important;
}

.rbt-accordion-style.rbt-accordion-02 .card .card-header {
  border-bottom: 1px solid var(--color-border);
  padding: 0px 20px 20px 0;
}

.rbt-accordion-style.rbt-accordion-02 .card .card-header button::before {
  right: -20px;
}

.rbt-accordion-style.rbt-accordion-02 .card .card-header .accordion-button::after {
  display: none;
}

.rbt-accordion-style.rbt-accordion-02 .card .card-body {
  padding: 20px 20px 20px 0;
  border-top: 0;
  background: transparent;
}

.rbt-accordion-style.rbt-accordion-02.right-no-padding .card .card-body {
  padding-right: 0;
}

.rbt-accordion-style.rbt-accordion-03 .card {
  background: transparent;
  border-radius: 0 !important;
}

.rbt-accordion-style.rbt-accordion-03 .card .card-header {
  border: 1px solid var(--color-border);
  padding: 15px 20px;
  border-radius: var(--radius) !important;
}

.rbt-accordion-style.rbt-accordion-03 .card .card-body {
  padding: 15px 20px 10px 0;
  border: 0;
  background: transparent;
}

.rbt-accordion-style.rbt-accordion-04 .card {
  border: 2px solid var(--color-border);
  background: transparent;
  border-radius: var(--radius) !important;
}

.rbt-accordion-style.rbt-accordion-04 .card .card-header {
  padding: 15px 20px;
  padding-right: 38px;
  border-radius: var(--radius) !important;
}

.rbt-accordion-style.rbt-accordion-04 .card .card-body {
  border-top: 2px solid var(--color-border);
  background: transparent;
}

.rbt-accordion-style.rbt-accordion-05 .card {
  border: 0 none;
  background: transparent;
  border-radius: var(--radius) !important;
}

.rbt-accordion-style.rbt-accordion-05 .card .card-header {
  padding: 0px;
  border-radius: none !important;
}

.rbt-accordion-style.rbt-accordion-05 .card .card-header button {
  padding-left: 32px;
}

.rbt-accordion-style.rbt-accordion-05 .card .card-header button::before {
  right: auto;
  left: 0;
}

.rbt-accordion-style.rbt-accordion-05 .card .card-body {
  border-top: 0 none;
  padding: 0px;
  margin-top: 12px;
  background: transparent;
}

.rbt-accordion-style.rbt-accordion-06 .card .card-body {
  background: transparent;
  border-top: 1px solid var(--color-border);
}

.accordion-button::after {
  visibility: hidden;
}

/*--------------------------
    Rbt Card Table  
--------------------------*/

.rbt-table {
  border: 0 none;
}

.rbt-table tr th,
.rbt-table tr td {
  padding: 10px 20px;
  border: 0 none;
}

.rbt-table thead tr th,
.rbt-table thead tr td {
  background: var(--primary-opacity);
}

.rbt-table thead tr th,
.rbt-table thead tr td,
.rbt-table tfoot tr th,
.rbt-table tfoot tr td {
  color: var(--color-heading);
  font-weight: 600;
  font-size: 16px;
}

.rbt-table tbody tr th,
.rbt-table tbody tr td {
  font-weight: 400;
  color: var(--color-body);
  font-size: 16px;
  vertical-align: middle;
}

.rbt-table tfoot tr th,
.rbt-table tfoot tr td {
  background: var(--color-grey);
}

.rbt-table tbody tr:nth-child(even) th,
.rbt-table tbody tr:nth-child(even) td {
  background: var(--color-grey);
}

/*-----------------------------
    Accordion Color White  
-------------------------------*/

.rbt-accordion-color-white .rbt-accordion-style .card .card-header button[aria-expanded="true"] {
  color: var(--color-white);
}

.rbt-accordion-color-white .rbt-accordion-style.rbt-accordion-01 .card .card-body {
  color: var(--color-white-off);
}

.rbt-accordion-color-white .rbt-accordion-style.rbt-accordion-01 .card {
  background: var(--primary-opacity);
  box-shadow: none;
}

.rbt-accordion-color-white .rbt-accordion-style .card .card-header button[aria-expanded="true"]::before {
  color: var(--color-border-2);
}

.rbt-accordion-color-white .rbt-accordion-style .card .card-header button.collapsed {
  color: var(--color-white-off);
}

.rbt-accordion-color-white .rbt-accordion-style .card .card-header button[aria-expanded="true"]::before,
.rbt-accordion-color-white .rbt-accordion-style .card .card-header button::before {
  background: linear-gradient(90deg,
      var(--color-border-2),
      var(--color-border-2));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
  white-space: nowrap;
}

.rbt-accordion-color-white .rbt-accordion-style .card .card-body {
  border-top: none;
}

.rbt-accordion-color-white .rbt-accordion-style .card .card-body,
.rbt-accordion-color-white .rbt-accordion-style .card .card-body a {
  color: var(--color-white-off);
}

.rbt-accordion-color-white .rbt-accordion-style.rbt-accordion-02 .card .card-header {
  border-bottom: 2px solid var(--color-border-2);
}

/*----------------------
    Edu Image Gallery  
------------------------*/

.rbt-image-gallery-1 {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-image-gallery-1 {
    padding-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-image-gallery-1 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-image-gallery-1 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-image-gallery-1 {
    padding-right: 0;
    margin-bottom: 0 !important;
  }
}

.rbt-image-gallery-1 .image-1 {
  max-height: 600px;
}

@media only screen and (max-width: 575px) {
  .rbt-image-gallery-1 .image-1 {
    width: 100%;
    transform: none !important;
  }
}

.rbt-image-gallery-1 .image-2 {
  max-height: 500px;
  position: absolute;
  right: 10%;
  top: 30%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-image-gallery-1 .image-2 {
    right: 0;
    top: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-image-gallery-1 .image-2 {
    top: 16%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-image-gallery-1 .image-2 {
    top: 16%;
    right: -1%;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-image-gallery-1 .image-2 {
    display: none;
  }
}

.rbt-image-gallery-1.text-end .image-2 {
  left: 10%;
  right: auto;
  top: 30%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-image-gallery-1.text-end .image-2 {
    top: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-image-gallery-1.text-end .image-2 {
    top: 16%;
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-image-gallery-1.text-end .image-2 {
    top: 16%;
    left: 22%;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-image-gallery-1.text-end .image-2 {
    display: none;
  }
}

/*------------------------
    Counter Up Styles  
-------------------------*/

.rbt-counterup {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: 5px;
  text-align: left;
  padding: 30px 30px 50px;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-counterup {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-counterup {
    padding: 30px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-counterup {
    padding: 30px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-counterup {
    padding: 25px 15px;
  }
}

.rbt-counterup .inner .icon,
.rbt-counterup .inner .rbt-round-icon {
  margin: 0 auto;
  margin-bottom: 20px;
}

.rbt-counterup .inner .icon img,
.rbt-counterup .inner .rbt-round-icon img {
  max-height: 100px;
}

.rbt-counterup .inner .rbt-round-icon {
  margin-bottom: 20px;
  position: relative;
  background: var(--color-white);
  width: 100px;
  margin-right: auto;
  margin-left: auto;
  height: 100px;
  line-height: 100px;
  padding: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  box-shadow: var(--shadow-10);
}

.rbt-counterup .inner .content .counter {
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  padding: 0 24px;
  margin-left: -20px;
  margin-bottom: 9px;
}

@media only screen and (max-width: 767px) {
  .rbt-counterup .inner .content .counter {
    font-size: 40px;
    line-height: 1;
  }
}

.rbt-counterup .inner .content .counter::after {
  position: absolute;
  content: "+";
  right: 0;
  font-size: 42px;
}

.rbt-counterup .inner .content .counter.witdollar-icon::after {
  content: "$";
}

.rbt-counterup .inner .content .counter.without-icon {
  margin-left: 0;
}

.rbt-counterup .inner .content .counter.without-icon::after {
  display: none;
}

.rbt-counterup .inner .content .subtitle {
  display: block;
}

.rbt-counterup.style-2 {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.rbt-counterup.style-2 .inner .content .counter {
  font-weight: 700;
  font-size: 55px;
  line-height: 55px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .rbt-counterup.style-2 .inner .content .counter {
    font-size: 36px;
  }
}

.rbt-counterup.style-2 .inner .content .subtitle {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
}

.rbt-counterup.style-2.text-color-white .inner .content .title {
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 10px;
}

.rbt-counterup.style-2.text-color-white .inner .content .subtitle {
  opacity: 0.9;
  text-transform: none;
}

.rbt-counterup .top-circle-shape {
  position: absolute;
  width: 243px;
  top: -47%;
  height: 243px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: linear-gradient(90deg, #cfa2e8, #637fea);
  opacity: 0.09;
  border-radius: 100%;
  box-sizing: border-box;
}

.rbt-counterup .top-circle-shape::before {
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 100%;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  left: 20px;
  top: 20px;
}

.rbt-counterup .top-circle-shape.border-color-secondary::after {
  border-color: var(--color-secondary);
}

.rbt-counterup .top-circle-shape.border-color-pink::after {
  border-color: var(--color-pink);
}

.rbt-counterup .top-circle-shape.border-color-coral::after {
  border-color: var(--color-coral);
}

.rbt-counterup.border-bottom-gradient {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.rbt-counterup.border-bottom-gradient::before {
  background: linear-gradient(90deg, #cfa2e8, #637fea);
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
}

.rbt-counterup.rbt-hover-active {
  background: transparent;
  box-shadow: none;
  transition: var(--transition);
}

.rbt-counterup.rbt-hover-active.active {
  background: var(--color-white);
  box-shadow: var(--shadow-8);
}

.rbt-counterup.style-3 {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.rbt-counterup.style-3 .inner .content .counter {
  font-weight: 900;
  font-size: 75px;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: -0.5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-counterup.style-3 .inner .content .counter {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-counterup.style-3 .inner .content .counter {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-counterup.style-3 .inner .content .counter {
    font-size: 40px;
  }
}

.rbt-counterup.style-3 .inner .content .subtitle {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 17px;
  margin-top: 6px;
}

.rbt-counterup.text-color-white .inner .content .title {
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 10px;
}

.rbt-counterup.text-color-white .inner .content .subtitle {
  opacity: 0.9;
  text-transform: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-counterup.variation-01 .inner .content .counter {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-counterup.variation-01 .inner .content .counter {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-counterup.variation-01 .inner .content .counter {
    font-size: 35px;
  }
}

.single-counter {
  position: relative;
}

.single-counter+.single-counter::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: var(--color-white);
  opacity: 0.2;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-counter+.single-counter::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-counter+.single-counter::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .single-counter+.single-counter::before {
    display: none;
  }
}

/*-----------------------
    Black Overlay  
------------------------*/

.bg-black-overlay .rbt-counterup .inner .content .counter {
  color: var(--color-white);
}

.bg-black-overlay .rbt-counterup .inner .content .counter::after {
  color: var(--color-white);
}

.bg-black-overlay .rbt-counterup .inner .content .subtitle {
  color: var(--color-white);
}

.hanger-line {
  position: relative;
  padding-top: 30px;
}

.hanger-line::before {
  content: "";
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
  background-image: radial-gradient(ellipse at center,
      var(--color-primary) 0,
      rgba(255, 255, 255, 0) 70%);
}

.hanger-line>.col-lg-3::before {
  content: "";
  width: 3px;
  height: 32px;
  background-image: linear-gradient(var(--color-primary),
      rgba(255, 255, 255, 0));
  left: 50%;
  bottom: 100%;
  position: absolute;
  transform: translateX(-50%);
}

.hanger-line>.col-lg-3 {
  position: relative;
}

.hanger-line>.col-lg-3::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 4px solid var(--color-primary);
  border-radius: 100%;
  left: 50%;
  bottom: calc(100% + 23px);
  transform: translateX(-50%);
  background: #f9f9ff;
}

.hanger-line>.col-lg-3.mt_md--30::before {
  height: 62px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hanger-line>.col-lg-3.mt_md--30::before {
    height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .hanger-line>.col-lg-3.mt_md--30::before {
    height: 32px;
  }
}

.hanger-line>.col-lg-3.mt_md--30::after {
  bottom: calc(100% + 53px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hanger-line>.col-lg-3.mt_md--30::after {
    bottom: calc(100% + 23px);
  }
}

@media only screen and (max-width: 767px) {
  .hanger-line>.col-lg-3.mt_md--30::after {
    bottom: calc(100% + 23px);
  }
}

/*--------------------------
    Pricing Table  
----------------------------*/

.pricing-billing-duration {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .pricing-billing-duration {
    text-align: left;
  }
}

.pricing-billing-duration ul {
  background: var(--color-white);
  border-radius: 500px;
  padding: 16px 20px;
  display: inline-block;
  box-shadow: var(--shadow-1);
}

@media only screen and (max-width: 767px) {
  .pricing-billing-duration ul {
    padding: 6px 6px;
  }
}

.pricing-billing-duration ul .nav-item {
  float: left;
  margin: 0;
}

.pricing-billing-duration ul .nav-item .nav-link {
  height: 60px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-heading);
  border: 0 none;
  background: transparent;
  padding: 0 35px;
  border-radius: 500px;
  transition: var(--transition);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-billing-duration ul .nav-item .nav-link {
    font-size: 18px;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-billing-duration ul .nav-item .nav-link {
    font-size: 17px;
    padding: 0 15px;
    letter-spacing: -0.5px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-billing-duration ul .nav-item .nav-link {
    font-size: 17px;
    padding: 0 15px;
    letter-spacing: -0.5px;
    height: 50px;
  }
}

.pricing-billing-duration ul .nav-item .nav-link.active {
  background-size: 300% 100%;
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
  color: var(--color-white);
}

.pricing-table {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: 5px;
  padding: 60px 64px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-table {
    padding: 60px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-table {
    padding: 40px 20px;
  }
}

.pricing-table .pricing-header {
  margin-bottom: 30px;
  text-align: center;
}

.pricing-table .pricing-header .price-wrap .monthly-pricing {
  display: none;
}

.pricing-table .pricing-header .price-wrap .amount {
  font-weight: 500;
  font-size: 50px;
  line-height: 1.2;
  color: var(--color-primary);
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .pricing-table .pricing-header .price-wrap .amount {
    font-size: 36px;
  }
}

.pricing-table .pricing-header .price-wrap .duration {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  margin-left: -4px;
}

.pricing-table .pricing-header .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.pricing-table .pricing-body {
  margin-bottom: 30px;
}

.pricing-table .pricing-body .list-item li {
  display: flex;
  align-items: center;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .pricing-table .pricing-body .list-item li {
    font-size: 16px;
  }
}

.pricing-table .pricing-body .list-item li i {
  color: var(--color-success);
  margin-right: 10px;
}

.pricing-table .pricing-body .list-item li+li {
  margin-top: 16px;
}

@media only screen and (max-width: 767px) {
  .pricing-table .pricing-body .list-item li+li {
    margin-top: 10px;
  }
}

.pricing-table .pricing-body .list-item li.off {
  opacity: 0.5;
}

.pricing-table .pricing-body .list-item li.off i {
  color: var(--color-danger);
}

.pricing-table.style-2 .pricing-body {
  margin-bottom: 0;
  margin-top: 30px;
}

.pricing-table.style-3 {
  padding: 60px 0px;
  text-align: center;
  background: transparent;
  box-shadow: none;
}

@media only screen and (max-width: 767px) {
  .pricing-table.style-3 {
    padding: 30px 0px;
  }
}

.pricing-table.style-3 .icon-image img {
  max-height: 100px;
  object-fit: cover;
}

.pricing-table.style-3 .pricing-body,
.pricing-table.style-3 .pricing-btn,
.pricing-table.style-3 .pricing-header {
  padding: 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .pricing-table.style-3 .pricing-body,
  .pricing-table.style-3 .pricing-btn,
  .pricing-table.style-3 .pricing-header {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .pricing-table.style-3 .pricing-body,
  .pricing-table.style-3 .pricing-btn,
  .pricing-table.style-3 .pricing-header {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {

  .pricing-table.style-3 .pricing-body,
  .pricing-table.style-3 .pricing-btn,
  .pricing-table.style-3 .pricing-header {
    padding: 0 20px;
  }
}

.pricing-table.style-3 .pricing-header {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
  border-top: 1px solid var(--color-border);
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-table.style-3 .pricing-header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-table.style-3 .pricing-header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    margin-top: 20px;
  }
}

.pricing-table.style-3 .pricing-body .list-item li {
  justify-content: center;
}

.pricing-table.style-3 .pricing-body .list-item li.off {
  text-decoration: line-through;
}

.pricing-table.style-3.active {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
}

.pricing-badge {
  background: var(--color-secondary);
  padding: 0 17px;
  position: absolute;
  right: 0;
  top: 15px;
  height: 30px;
  line-height: 30px;
  padding-left: 25px;
}

.pricing-badge span {
  font-weight: 700;
  font-size: 14px;
  color: var(--color-white);
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.pricing-badge::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 23px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  right: 87%;
  top: 50%;
  content: "";
  transform: translateY(-50%);
}

/*----------------------------
    Advance Pricing Table  
------------------------------*/

.advance-pricing {
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--shadow-1);
}

.advance-pricing .pricing-left {
  height: 100%;
  background: var(--color-lighter);
  padding: 60px 50px;
  border-radius: 10px 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .advance-pricing .pricing-left {
    padding: 30px 20px;
  }
}

.advance-pricing .pricing-left .main-title {
  margin-bottom: 10px;
}

.advance-pricing .pricing-left .price-wrapper .price-amount {
  font-size: 60px;
  text-align: center;
  display: block;
  color: var(--color-heading);
  font-weight: 700;
  line-height: 1;
}

.advance-pricing .pricing-left .price-wrapper .price-amount sup {
  font-size: 16px;
  top: -34px;
}

.advance-pricing .pricing-left .rbt-btn {
  width: 100%;
  margin: 10px 0;
}

.advance-pricing .pricing-left .rating {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.advance-pricing .pricing-left .rating a {
  font-weight: 700;
  color: #f1b304;
}

.advance-pricing .pricing-left .rating a svg {
  fill: #f1b304;
}

.advance-pricing .pricing-left .subtitle {
  display: block;
  color: var(--color-body);
  text-align: center;
}

.advance-pricing .pricing-right {
  padding: 60px 30px;
}

@media only screen and (max-width: 767px) {
  .advance-pricing .pricing-right {
    padding: 60px 20px;
    padding-bottom: 40px;
  }
}

.advance-pricing .pricing-right .plan-offer-list li {
  font-size: 16px;
}

.advance-pricing .pricing-right .plan-offer-list li i {
  width: 22px;
  height: 22px;
  background: var(--color-success);
  margin: 0 auto;
  line-height: 22px;
  text-align: center;
  border-radius: 100%;
  color: var(--color-white);
  font-size: 14px;
  margin-right: 10px;
  display: inline-block;
}

.advance-pricing .pricing-right .plan-offer-list li.off {
  opacity: 0.5;
}

.advance-pricing .pricing-right .plan-offer-list li.off i {
  background: var(--color-danger);
}

.advance-pricing .pricing-right .price-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.advance-pricing .pricing-right .price-title li {
  font-size: 16px;
  margin: 8px 0;
}

/*------------------------
    Edu Badge Styles  
--------------------------*/

.rbt-badge-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -10px;
}

.rbt-badge-group div,
.rbt-badge-group span {
  margin: 10px;
}

.rbt-default-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: var(--color-white);
  box-shadow: var(--shadow-2);
  border-radius: 500px;
  padding: 0px 20px;
  font-size: 12px;
  font-weight: 500;
  z-index: 1;
  letter-spacing: 0.5px;
  transition: 0.4s;
  height: 35px;
  line-height: 34px;
}

.rbt-default-badge:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.rbt-badge {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 22px;
  background: var(--color-gray-light);
  font-size: 14px;
  letter-spacing: 0.3px;
  border-radius: 6px;
  color: var(--color-body);
}

.rbt-badge.variation-02 {
  padding: 0 16px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.rbt-badge.variation-03 {
  padding: 0 12px;
}

.rbt-badge-2 {
  display: flex;
  align-items: center;
  background: rgba(226, 213, 252, 0.8);
  box-shadow: 0 12px 22px 0 rgba(214, 191, 242, 0.28);
  padding: 0 20px;
  border: 1px solid #fff;
  border-radius: 500px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  justify-content: center;
  color: var(--color-heading);
  width: max-content;
}

.rbt-badge-2 img {
  max-height: 30px;
  border-radius: 100%;
}

.rbt-badge-2 .image {
  margin: 0;
  margin-right: 10px;
}

.rbt-badge-2 strong {
  margin-left: 5px;
  display: inline-block;
  color: var(--color-primary);
}

.rbt-badge-2.badge-full-height {
  height: 100%;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}

.rbt-badge-2.badge-full-height img {
  max-height: 50px;
}

.rbt-badge-2.badge-full-height .image {
  margin-right: 0;
}

.rbt-badge-3 {
  position: absolute;
  bottom: 23px;
  right: 20px;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: transparent !important;
}

.rbt-badge-3 span {
  font-size: var(--font-size-b4);
  line-height: 1.2;
  color: #ffffff;
  display: block;
  font-weight: var(--f-bold);
  /* font-family: var(--font-secondary); */
}

.rbt-badge-3::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  /* background: url(../images/icons/offer-badge-bg-color.svg); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  z-index: -1;
  right: 0;
  bottom: 0;
}

.rbt-badge-3::after {
  transform: rotate(90deg);
}

.rbt-badge-4 {
  display: inline-block;
  height: auto;
  line-height: initial;
  padding: 6px 10px;
  background: var(--white-opacity);
  font-size: 14px;
  letter-spacing: 0.3px;
  border-radius: 6px;
  color: var(--color-heading);
  position: relative;
}

.rbt-badge-4::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-opacity);
  transition: 0.4s;
  opacity: 0;
  transform: scale(0.9);
  border-radius: 6px;
}

.rbt-badge-4:hover {
  color: var(--color-primary);
}

.rbt-badge-4:hover::after {
  opacity: 1;
  transform: scale(1);
}

.rbt-badge-5 {
  background: var(--color-gray-light);
  padding: 5px 7px;
  font-size: 12px;
  display: flex;
  height: 22px;
  align-items: center;
  border-radius: var(--radius-small);
  justify-content: center;
  width: max-content;
}

.rbt-badge-6 {
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: var(--color-heading);
  margin-bottom: 15px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 100px;
  text-transform: uppercase;
  display: inline-block;
  background: var(--color-primary);
  color: var(--color-primary);
}

.team-style-default,
.rbt-default-card {
  margin-top: 75px;
  position: relative;
}

.team-style-default .inner,
.rbt-default-card .inner {
  background: var(--color-white);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
}

.team-style-default .inner .thumbnail,
.rbt-default-card .inner .thumbnail {
  max-width: 150px;
  max-height: 150px;
  border-radius: 100%;
  border: 6px solid var(--color-border);
  top: -75px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 150px;
  min-height: 150px;
  overflow: hidden;
}

.team-style-default .inner .thumbnail img,
.rbt-default-card .inner .thumbnail img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.team-style-default .inner .content,
.rbt-default-card .inner .content {
  text-align: center;
  padding: 20px;
  padding-top: 85px;
  padding-bottom: 35px;
}

.team-style-default .inner .content .title,
.rbt-default-card .inner .content .title {
  font-size: 34px;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .team-style-default .inner .content .title,
  .rbt-default-card .inner .content .title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .team-style-default .inner .content .title,
  .rbt-default-card .inner .content .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {

  .team-style-default .inner .content .title,
  .rbt-default-card .inner .content .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {

  .team-style-default .inner .content .title,
  .rbt-default-card .inner .content .title {
    font-size: 22px;
  }
}

.team-style-default .inner .content .subtitle,
.rbt-default-card .inner .content .subtitle {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 15px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .team-style-default .inner .content .subtitle,
  .rbt-default-card .inner .content .subtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {

  .team-style-default .inner .content .subtitle,
  .rbt-default-card .inner .content .subtitle {
    font-size: 16px;
  }
}

.team-style-default .inner .content .team-form,
.rbt-default-card .inner .content .team-form {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  letter-spacing: 0.6px;
  font-style: italic;
}

.team-style-default .inner .content .team-form img,
.rbt-default-card .inner .content .team-form img {
  padding-right: 10px;
  max-height: 18px;
}

.team-style-default .inner .content .description,
.rbt-default-card .inner .content .description {
  margin-bottom: 0;
  padding: 0 5%;
}

.team-style-default .inner .social-icon li a,
.rbt-default-card .inner .social-icon li a {
  color: var(--color-body);
}

.team-style-default.border-gradient .thumbnail,
.rbt-default-card.border-gradient .thumbnail {
  background: linear-gradient(95deg,
      var(--color-primary) 15%,
      var(--color-tertiary) 45%,
      var(--color-pink) 75%,
      var(--color-secondary) 100%) 98%/200% 100%;
  padding: 5px;
  border: 0 none;
}

.team-style-default.style-two,
.rbt-default-card.style-two {
  margin-top: 30px;
}

.team-style-default.style-two .inner,
.rbt-default-card.style-two .inner {
  padding-top: 35px;
}

.team-style-default.style-two .inner .thumbnail,
.rbt-default-card.style-two .inner .thumbnail {
  top: 0;
  position: static;
  left: inherit;
  transform: none;
  min-width: 150px;
  min-height: 150px;
  margin: 0 auto;
}

.team-style-default.style-two .inner .content,
.rbt-default-card.style-two .inner .content {
  padding-top: 10px;
}

.team-style-default.style-three,
.rbt-default-card.style-three {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
}

.team-style-default.style-three .inner,
.rbt-default-card.style-three .inner {
  padding: 30px;
}

@media only screen and (max-width: 767px) {

  .team-style-default.style-three .inner,
  .rbt-default-card.style-three .inner {
    padding: 15px;
  }
}

.team-style-default.style-three .inner .thumbnail,
.rbt-default-card.style-three .inner .thumbnail {
  top: 0;
  position: static;
  left: inherit;
  transform: none;
  min-width: 100%;
  min-height: inherit;
  max-height: inherit;
  margin: 0 auto;
  border-radius: 6px;
  border: 0 none;
}

.team-style-default.style-three .inner .thumbnail img,
.rbt-default-card.style-three .inner .thumbnail img {
  border-radius: 6px;
}

.team-style-default.style-three .inner .content,
.rbt-default-card.style-three .inner .content {
  padding: 20px 0 0 0;
}

.team-style-default.style-three .inner .content .subtitle,
.rbt-default-card.style-three .inner .content .subtitle {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 10px;
  display: inline-block;
  color: var(--color-body);
  font-weight: 400;
}

.team-style-default.small-layout .inner,
.rbt-default-card.small-layout .inner {
  padding: 20px;
}

.team-style-default.small-layout .inner .content .title,
.rbt-default-card.small-layout .inner .content .title {
  font-size: 20px;
  margin-bottom: 2px;
}

.team-style-default.small-layout .inner .content .subtitle,
.rbt-default-card.small-layout .inner .content .subtitle {
  font-size: 16px;
  margin-bottom: 6px;
}

.team {
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}

.team::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(to bottom,
      rgba(15, 15, 15, 0),
      rgba(15, 15, 15, 0.75) 100%);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
}

.team::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      var(--color-primary) 100%);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  opacity: 0;
}

.team .thumbnail {
  display: block;
  position: relative;
  z-index: 1;
}

.team .thumbnail img {
  width: 100%;
}

.team .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 40px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team .content {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .team .content {
    padding: 20px;
  }
}

.team .content .title {
  color: #ffffff;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.25s;
  transform: translateY(10px);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team .content .title {
    font-size: 22px;
  }
}

.team .content .title a {
  color: #ffffff;
}

.team .content .designation {
  color: #ffffff;
  font-size: 14px;
  line-height: 34px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transform: translateY(10px);
}

.team ul.social-icon {
  position: absolute;
  top: 25px;
  left: 35px;
  padding: 0;
  z-index: 2;
  list-style: none;
  display: flex;
  margin: 0 -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team ul.social-icon {
    left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .team ul.social-icon {
    left: 20px;
  }
}

.team ul.social-icon li {
  margin: 0 10px;
  transform: translateY(8px) scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
}

.team ul.social-icon li a {
  color: #ffffff;
  font-size: 15px;
  transition: 0.3s;
}

.team ul.social-icon li a svg {
  transition: 0.3s;
  fill: #ffffff;
  height: 18px;
  width: 18px;
}

.team ul.social-icon li a:hover svg {
  transform: scale(1.3);
}

.team.team-style--bottom ul.social-icon {
  top: auto;
  left: 40px;
  bottom: 117px;
}

@media only screen and (max-width: 767px) {
  .team.team-style--bottom ul.social-icon {
    bottom: 97px;
    left: 20px;
  }
}

.team.team-style--bottom .content h4.title {
  margin-bottom: 6px;
}

.team.team-style--bottom.variation-2::after,
.team.team-style--bottom.variation-2::before {
  display: none;
}

.team.team-style--bottom.variation-2 .thumbnail a {
  display: block;
  position: relative;
}

.team.team-style--bottom.variation-2 .thumbnail a::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(to bottom,
      rgba(15, 15, 15, 0),
      rgba(15, 15, 15, 0.75) 100%);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
}

.team.team-style--bottom.variation-2 .thumbnail a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.85) 0%,
      rgba(0, 0, 0, 0) 100%);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  opacity: 0;
}

.team.team-style--bottom.variation-2 .content {
  display: flex;
  align-items: center;
}

.team.team-style--bottom.variation-2 .content .inner {
  width: 80%;
}

.team.team-style--bottom.variation-2 .content .icon-right {
  opacity: 0;
  width: 20%;
  text-align: right;
  transition: opacity 0.45s, transform 0.45s;
  transform: translateX(-20px);
  visibility: hidden;
}

.team.team-style--bottom.variation-2 .content .icon-right i {
  color: var(--color-white);
  font-size: 20px;
}

.team.team-style--bottom.variation-2:hover .thumbnail a::before {
  opacity: 0;
}

.team.team-style--bottom.variation-2:hover .thumbnail a::after {
  opacity: 1;
}

.team:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 55px rgba(253, 71, 102, 0.0022);
}

.team:hover::before {
  opacity: 0;
}

.team:hover::after {
  opacity: 1;
}

.team:hover .content .title {
  transition-delay: 0.25s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.team:hover .content .designation {
  transition-delay: 0.33s;
  opacity: 0.7;
  visibility: visible;
  transform: translateY(0);
}

.team:hover ul.social-icon li {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.team:hover ul.social-icon li:nth-child(1) {
  -webkit-transition-delay: 0.07692s;
  transition-delay: 0.07692s;
}

.team:hover ul.social-icon li:nth-child(2) {
  -webkit-transition-delay: 0.15385s;
  transition-delay: 0.15385s;
}

.team:hover ul.social-icon li:nth-child(3) {
  -webkit-transition-delay: 0.23077s;
  transition-delay: 0.23077s;
}

.team:hover ul.social-icon li:nth-child(4) {
  -webkit-transition-delay: 0.33077s;
  transition-delay: 0.23077s;
}

.team:hover ul.social-icon li:nth-child(5) {
  -webkit-transition-delay: 0.43077s;
  transition-delay: 0.23077s;
}

.team:hover .content {
  opacity: 1;
  visibility: visible;
}

.team:hover.team-style--bottom.variation-2 .content .icon-right {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

.swiper-slide .team-style-default.style-three {
  margin: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .swiper-slide .team-style-default.style-three {
    margin: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper-slide .team-style-default.style-three {
    margin: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .swiper-slide .team-style-default.style-three {
    margin: 15px;
  }
}

.team-slide-activation-2 .swiper-wrapper {
  margin: -30px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-slide-activation-2 .swiper-wrapper {
    margin: -15px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-slide-activation-2 .swiper-wrapper {
    margin: -15px 0;
  }
}

@media only screen and (max-width: 767px) {
  .team-slide-activation-2 .swiper-wrapper {
    margin: -15px 0;
  }
}

/*---------------------
    Team Tab   
--------------------*/

.rbt-team-tab-thumb {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px auto;
  margin-bottom: -30px;
  outline: none;
  border: 0 none;
}

@media only screen and (max-width: 575px) {
  .rbt-team-tab-thumb {
    margin-bottom: -10px;
  }
}

.rbt-team-tab-thumb li {
  padding-left: 5px;
  padding-right: 5px;
  flex-basis: 33.33%;
  margin-bottom: 10px;
  outline: none;
  cursor: pointer;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .rbt-team-tab-thumb li {
    flex-basis: 33.33%;
    margin-bottom: 14px;
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-team-tab-thumb li {
    flex-basis: 50%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}

.rbt-team-tab-thumb li a {
  display: block;
}

.rbt-team-tab-thumb li .rbt-team-thumbnail {
  padding: 10px;
  background: var(--color-white);
  box-shadow: var(--shadow-2);
  border-radius: 6px;
}

.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb {
  position: relative;
  display: inline-block;
  width: 100%;
}

.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb img {
  border-radius: 6px;
  width: auto;
  object-fit: cover;
  height: 206px;
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-team-tab-thumb li .rbt-team-thumbnail .thumb img {
    width: 100%;
    height: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-team-tab-thumb li .rbt-team-thumbnail .thumb img {
    width: 100%;
    height: auto;
  }
}

.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: var(--gradient-7);
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
  opacity: 0;
}

.rbt-team-tab-thumb li .rbt-team-thumbnail .thumb::before {
  content: "\e94b";
  position: absolute;
  font-family: "feather" !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  font-size: 18px;
  z-index: 2;
  font-size: 28px;
  opacity: 0;
  margin-left: 15px;
}

.rbt-team-tab-thumb li a.active .thumb::after {
  background: var(--gradient-7);
  opacity: 0.5;
}

.rbt-team-tab-thumb li a.active .thumb::before {
  opacity: 1;
  margin: 0;
}

.rbt-team-tab-thumb li a.active .thumb img {
  border-radius: 6px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
}

.top-circle-shape {
  position: absolute;
  width: 240px;
  top: -96px;
  height: 240px;
  right: -96px;
  margin: 0 auto;
  background-image: linear-gradient(90deg, #cfa2e8, #637fea);
  opacity: 0.09;
  border-radius: 100%;
  box-sizing: border-box;
}

.top-circle-shape::before {
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 100%;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  left: 30px;
  top: 30px;
}

.top-circle-shape.position-bottom-right {
  bottom: -96px;
  right: -96px;
  top: auto;
}

.top-circle-shape-top .top-circle-shape {
  top: 0;
}

.top-circle-shape-top.top-circle-shape-top-left .top-circle-shape {
  top: 0;
  right: auto;
  left: -96px;
}

.rbt-team-tab-content {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  padding: 26px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.rbt-team-tab-content .inner {
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .rbt-team-tab-content .inner {
    display: block;
  }
}

.rbt-team-tab-content .author-info {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-team-tab-content .author-info {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-team-tab-content .author-info {
    margin-bottom: 10px;
  }
}

.rbt-team-tab-content .author-info .title {
  margin-bottom: 7px;
}

.rbt-team-tab-content .author-info .designation {
  display: block;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 12px;
}

.rbt-team-tab-content .rbt-team-thumbnail {
  flex-basis: 44%;
}

.rbt-team-tab-content .rbt-team-thumbnail .thumb img {
  border-radius: 6px;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.rbt-team-tab-content .rbt-team-details {
  flex-basis: 56%;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .rbt-team-tab-content .rbt-team-details {
    padding-left: 0;
    margin-top: 20px;
  }
}

.rbt-team-tab-content .rbt-team-details p {
  line-height: 28px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .rbt-team-tab-content .rbt-team-details p {
    margin-bottom: 10px;
  }
}

.rbt-team-tab-content .rbt-team-details .team-form {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  letter-spacing: 0.6px;
  font-style: italic;
}

.rbt-team-tab-content .rbt-team-details .team-form img {
  padding-right: 10px;
  max-height: 18px;
}

.rbt-team-tab-content .rbt-team-details .rbt-information-list li a {
  font-size: 16px;
}

/*---------------------------
    Team Modal Style  
-----------------------------*/

.rbt-team-modal-thumb {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px auto;
  margin-bottom: 0;
  outline: none;
  border: 0 none;
}

@media only screen and (max-width: 575px) {
  .rbt-team-modal-thumb {
    margin-bottom: -10px;
  }
}

.rbt-team-modal-thumb .rbt-team-thumbnail {
  padding: 10px;
  background: var(--color-white);
  box-shadow: var(--shadow-2);
  border-radius: 6px;
  display: block;
}

.rbt-team-modal-thumb .rbt-team-thumbnail .thumb {
  position: relative;
  display: inline-block;
  width: 100%;
}

.rbt-team-modal-thumb .rbt-team-thumbnail .thumb img {
  border-radius: 6px;
  width: auto;
  object-fit: cover;
  max-width: 100%;
}

.rbt-team-modal-thumb .rbt-team-thumbnail .thumb::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: var(--gradient-7);
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
  opacity: 0;
}

.rbt-team-modal-thumb .rbt-team-thumbnail .thumb::before {
  content: "\e94b";
  position: absolute;
  font-family: "feather" !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  font-size: 18px;
  z-index: 2;
  font-size: 28px;
  opacity: 0;
  margin-left: 15px;
}

.rbt-team-modal-thumb .rbt-team-thumbnail:hover .thumb::after {
  background: var(--gradient-7);
  opacity: 0.5;
}

.rbt-team-modal-thumb .rbt-team-thumbnail:hover .thumb::before {
  opacity: 1;
  margin-left: 0;
}

/*-----------------------
    Social Icons 
-------------------------*/

.social-default {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  margin: -5px;
}

.social-default li {
  margin: 5px;
}

.social-default li a {
  width: 40px;
  background-color: var(--color-gray-light);
  height: 40px;
  line-height: 37px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  transition: 0.3s;
  color: var(--color-body);
  z-index: 2;
  position: relative;
}

.social-default li a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--color-primary);
  transform: scale(0.5);
  opacity: 0;
  transition: 0.3s;
  border-radius: 100%;
}

.social-default li a:hover {
  color: var(--color-white);
}

.social-default li a:hover::after {
  transform: scale(1);
  opacity: 1;
}

.social-default li a i {
  vertical-align: middle;
}

.social-default.transparent-with-border li a {
  line-height: 41px;
  background: transparent;
  border: 2px solid var(--color-border);
}

.social-default.transparent-with-border li a:hover {
  border-color: transparent !important;
}

.social-default.transparent-with-border li a::after {
  border: 1px solid var(--color-primary);
}

.social-default.with-gradient li a::after {
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
  background-size: 300% 100%;
}

.social-default.with-bg-primary li a {
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  transition: 0.5s;
  line-height: 40px;
  color: var(--color-white);
}

.social-default.with-bg-primary li a::after {
  display: none;
}

.social-default.with-bg-primary li a:hover {
  background-color: transparent;
  color: var(--color-primary);
}

.social-default.icon-naked li {
  margin: 5px;
}

.social-default.icon-naked li a {
  background: transparent !important;
  width: auto;
  height: auto;
  line-height: initial;
}

.social-default.icon-naked li a::after {
  display: none;
}

.social-default.icon-naked li a:hover {
  color: var(--color-primary) !important;
}

.social-default.icon-large li a {
  font-size: 36px;
  opacity: 0.7;
}

.social-default.color-white li a {
  color: var(--color-white);
}

/*----------------------------
    Edu Pagination Style  
------------------------------*/

.rbt-pagination {
  margin: -8px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .rbt-pagination {
    margin: -4px;
  }
}

.rbt-pagination li {
  margin: 8px;
}

@media only screen and (max-width: 767px) {
  .rbt-pagination li {
    margin: 4px;
  }
}

.rbt-pagination li a {
  width: 40px;
  height: 40px;
  background: var(--color-white);
  border-radius: 6px;
  text-align: center;
  color: var(--color-body);
  transition: 0.4s;
  font-weight: 500;
  box-shadow: var(--shadow-1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .rbt-pagination li a {
    width: 45px;
    height: 45px;
  }
}

.rbt-pagination li a i {
  font-size: 22px;
  font-weight: 500;
}

.rbt-pagination li.active a,
.rbt-pagination li:hover a {
  background: var(--color-primary);
  color: var(--color-white);
}

/*-------------------------
    Back To Top  
---------------------------*/

@-webkit-keyframes border-transform {

  0%,
  100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }

  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }

  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }

  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }

  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }

  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}

.paginacontainer {
  height: 3000px;
}

.rbt-progress-parent {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--black-opacity);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.rbt-progress-parent.rbt-backto-top-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.rbt-progress-parent::after {
  position: absolute;
  font-family: "feather" !important;
  content: "\e914";
  text-align: center;
  line-height: 46px;
  font-size: 20px;
  color: var(--color-primary);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.rbt-progress-parent:hover::after {
  color: var(--color-primary);
}

.rbt-progress-parent::before {
  position: absolute;
  font-family: "feather" !important;
  content: "\e914";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background: #0d0d12;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  color: var(--color-primary);
}

.rbt-progress-parent svg path {
  fill: none;
}

.rbt-progress-parent svg.rbt-back-circle path {
  stroke: var(--color-primary);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 0.6;
}

/*----------------------
    Newsletter Styles  
------------------------*/

.newsletter-form input {
  flex-grow: 1;
  width: auto;
  margin-right: 7px;
  height: 45px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 500px;
}

.newsletter-form-1 {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  z-index: 1;
}

.newsletter-form-1.radius-round input {
  border-radius: 500px;
}

.newsletter-form-1 input {
  background: var(--color-white);
  border: 0 none;
  height: 70px;
  line-height: 70px;
  padding: 0 20px;
  padding-right: 172px;
}

@media only screen and (max-width: 767px) {
  .newsletter-form-1 input {
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .newsletter-form-1 input {
    padding-right: 20px;
    font-size: 14px;
  }
}

.newsletter-form-1 .rbt-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .newsletter-form-1 .rbt-btn {
    right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .newsletter-form-1 .rbt-btn {
    position: static;
    transform: none;
    margin-top: 20px;
  }
}

/*-------------------------------
    Newsletter Styles One 
----------------------------------*/

.newsletter-style-1 {
  padding: 100px 50px;
  border-radius: var(--radius);
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .newsletter-style-1 {
    padding: 60px 20px;
  }
}

.newsletter-style-1::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  /* background: url(../images/shape/dots.svg) repeat center/auto; */
  top: 0;
  z-index: 0;
}

.newsletter-style-2 {
  position: relative;
  z-index: 1;
}

.newsletter-style-2::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  /* background: url(../images/shape/dots.png) repeat center/auto; */
  top: 0;
  z-index: -1;
}

.newsletter-style-2 .note-text {
  font-size: 14px;
  display: block;
  opacity: 0.9;
}

/*---------------------
    Brand Styles 
----------------------*/

.brand-style-1 {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid var(--color-border-2);
  border-right: 1px solid var(--color-border-2);
}

.brand-style-1 li {
  flex-basis: 25%;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  margin: 0px 0px -1px 0px;
  border-color: var(--color-border-2);
  padding: 70px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .brand-style-1 li {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .brand-style-1 li {
    flex-basis: 33.33%;
  }
}

@media only screen and (max-width: 479px) {
  .brand-style-1 li {
    flex-basis: 50%;
  }
}

.brand-style-1 li a {
  display: block;
}

.brand-style-1 li a img {
  max-height: 32px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-1 li a img {
    max-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-style-1 li a img {
    max-height: 25px;
  }
}

.brand-style-2 {
  padding: 0;
  margin: -10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-2 {
    justify-content: flex-start !important;
  }
}

@media only screen and (max-width: 767px) {
  .brand-style-2 {
    justify-content: flex-start !important;
  }
}

.brand-style-2 li {
  flex-basis: 16.666%;
  text-align: center;
  margin: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-2 li {
    flex-basis: 25%;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .brand-style-2 li {
    flex-basis: 25%;
    text-align: left;
  }
}

@media only screen and (max-width: 575px) {
  .brand-style-2 li {
    flex-basis: 33.33%;
  }
}

@media only screen and (max-width: 479px) {
  .brand-style-2 li {
    flex-basis: 50%;
  }
}

.brand-style-2 li a {
  display: block;
}

.brand-style-2 li a img {
  max-width: 160px;
  max-height: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-2 li a img {
    max-width: inherit;
    max-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-style-2 li a img {
    max-width: inherit;
    max-height: 20px;
  }
}

.brand-style-2.variation-2 li {
  flex-basis: 25%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .brand-style-2.variation-2 li {
    flex-basis: 33.33%;
  }
}

@media only screen and (max-width: 479px) {
  .brand-style-2.variation-2 li {
    flex-basis: 50%;
  }
}

.brand-style-3 {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px;
}

.brand-style-3 li {
  flex-basis: 14.28%;
  text-align: center;
  padding: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-3 li {
    flex-basis: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .brand-style-3 li {
    flex-basis: 25%;
  }
}

@media only screen and (max-width: 575px) {
  .brand-style-3 li {
    flex-basis: 33.33%;
  }
}

@media only screen and (max-width: 479px) {
  .brand-style-3 li {
    flex-basis: 50%;
  }
}

.brand-style-3 li a {
  display: block;
}

.brand-style-3 li a img {
  max-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-3 li a img {
    max-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-style-3 li a img {
    max-height: 25px;
  }
}

.brand-style-3.variation-2 li {
  flex-basis: 25%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .brand-style-3.variation-2 li {
    flex-basis: 33.33%;
  }
}

@media only screen and (max-width: 479px) {
  .brand-style-3.variation-2 li {
    flex-basis: 50%;
  }
}

.filter-button-default,
.filter-tab-button {
  display: flex;
  justify-content: center;
  margin: -10px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {

  .filter-button-default,
  .filter-tab-button {
    margin: -5px;
  }
}

.filter-button-default button,
.filter-tab-button button {
  border: 0 none;
  padding: 0px 35px;
  text-transform: capitalize;
  background: var(--color-white);
  color: var(--color-body);
  margin: 10px;
  box-shadow: var(--shadow-1);
  height: 65px;
  line-height: 65px;
  border-radius: 500px;
  font-size: 18px;
  letter-spacing: -0.6px;
  transition: 0.4s;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .filter-button-default button,
  .filter-tab-button button {
    padding: 0px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .filter-button-default button,
  .filter-tab-button button {
    padding: 0px 24px;
  }
}

@media only screen and (max-width: 767px) {

  .filter-button-default button,
  .filter-tab-button button {
    padding: 0 20px;
    height: 48px;
    line-height: 48px;
    margin: 5px;
    font-size: 16px;
  }
}

.filter-button-default button .course-number,
.filter-tab-button button .course-number {
  position: absolute;
  display: inline-block;
  right: 20px;
  font-size: 14px;
  top: 8px;
  line-height: initial;
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {

  .filter-button-default button .course-number,
  .filter-tab-button button .course-number {
    right: 10px;
    top: 4px;
  }
}

.filter-button-default button.is-checked,
.filter-button-default button.active,
.filter-button-default button:hover,
.filter-tab-button button.is-checked,
.filter-tab-button button.active,
.filter-tab-button button:hover {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
}

.filter-tab-button li {
  margin: 0;
}

/*----------------------------------
    Portfolio Grid Layout  
----------------------------------*/

.grid-metro2 {
  margin: 0 -15px;
}

.grid-metro2 .maso-item {
  width: 50%;
  padding: 0 15px;
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .grid-metro2 .maso-item {
    width: 100%;
  }
}

.grid-metro2 .resizer {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .grid-metro2 .resizer {
    width: 100%;
  }
}

.grid-metro3 {
  margin: 0 -15px;
}

.grid-metro3 .maso-item {
  width: 33.33%;
  padding: 0 15px;
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-metro3 .maso-item {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .grid-metro3 .maso-item {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .grid-metro3 .maso-item {
    width: 100%;
  }
}

.grid-metro3 .resizer {
  width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-metro3 .resizer {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .grid-metro3 .resizer {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .grid-metro3 .resizer {
    width: 100%;
  }
}

.demo-presentation-mesonry.grid-metro3 {
  margin: 0 -60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .demo-presentation-mesonry.grid-metro3 {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .demo-presentation-mesonry.grid-metro3 {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .demo-presentation-mesonry.grid-metro3 {
    margin: 0 -15px;
  }
}

.demo-presentation-mesonry.grid-metro3 .maso-item {
  padding: 0 60px;
  margin-top: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .demo-presentation-mesonry.grid-metro3 .maso-item {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .demo-presentation-mesonry.grid-metro3 .maso-item {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .demo-presentation-mesonry.grid-metro3 .maso-item {
    padding: 0 15px;
    margin-top: 30px;
  }
}

/*---------------------
    Extra Css  
-----------------------*/

.bg-gradient-8 .filter-button-default button,
.bg-gradient-8 .filter-tab-button button {
  box-shadow: var(--shadow-5);
}

.bg-gradient-8 .rbt-team-modal-thumb .rbt-team-thumbnail {
  box-shadow: var(--shadow-2);
  background: var(--white-opacity);
}

.rbt-contact-form.contact-form-style-1 {
  padding: 40px;
  position: relative;
  z-index: 1;
  padding-left: 40px;
  z-index: 2;
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: var(--radius);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-contact-form.contact-form-style-1 {
    padding: 30px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-contact-form.contact-form-style-1 {
    max-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-contact-form.contact-form-style-1 {
    padding: 30px 20px;
    padding-left: 20px;
    max-width: inherit;
  }
}

.rbt-contact-form.contact-form-style-1 .callto-action-wrapper span.text {
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  padding-right: 10px;
}

.rbt-contact-form.contact-form-style-1 .callto-action-wrapper span i {
  font-size: 22px;
  font-weight: 400;
  color: var(--color-heading);
  line-height: 26px;
  padding-right: 4px;
}

.rbt-contact-form.contact-form-style-1 .callto-action-wrapper span a {
  font-size: 22px;
  font-weight: 700;
  color: var(--color-heading);
  line-height: 26px;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .rbt-contact-form.contact-form-style-1 .callto-action-wrapper span a {
    font-size: 14px;
  }
}

.rbt-contact-form.contact-form-style-1 .callto-action-wrapper span a:hover {
  color: var(--color-primary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-contact-form.contact-form-style-1 .shape-group {
    display: none;
  }
}

.rbt-contact-form.contact-form-style-1 .shape-group .shape {
  position: absolute;
  right: -193px;
  top: 50%;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-contact-form.contact-form-style-1 .shape-group .shape {
    right: -151px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-contact-form.contact-form-style-1 .shape-group .shape {
    display: none;
  }
}

.rbt-contact-form.contact-form-style-1 .shape-group .shape.shape-01 {
  right: -64px;
  top: 26%;
}

/* Form Group  */

.form-group {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  display: block;
}

.form-group label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9px;
  color: var(--color-body);
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
  font-size: 18px;
  line-height: 28px;
}

.form-group input,
.form-group textarea {
  border: 0;
  border-bottom: 2px solid var(--color-border);
  background-color: transparent;
  padding-bottom: 8px;
  padding-top: 20px;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.form-group input[type="submit"] {
  border: 2px solid var(--color-heading);
  background: #ffffff;
  color: var(--color-heading);
  font-size: 16px;
  font-weight: 700;
  height: 46px;
  line-height: 44px;
  padding: 0;
  text-transform: capitalize;
}

.form-group input[type="submit"]:hover {
  background: var(--color-heading);
  color: #ffffff;
}

.form-group textarea {
  border: 0;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 2px solid var(--color-border);
  background-color: transparent;
  resize: none;
  min-height: 133px;
}

.form-group span.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--color-primary);
  transition: 0.4s;
}

.form-group.focused label {
  top: -10px;
  font-size: 12px;
  color: var(--color-primary);
  transition: 0.3s;
}

.form-group.focused span.focus-border {
  width: 100%;
  transition: 0.4s;
}

/* ---------------------------
Testimonial Styles 
------------------------------*/

.rbt-testimonial-content .inner {
  padding-bottom: 20px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-content .inner {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-content .inner {
    text-align: center;
  }
}

.rbt-testimonial-content .inner::before {
  position: absolute;
  width: 177px;
  height: 100%;
  left: -64px;
  top: -57px;
  /* background-image: url(../images/testimonial/image-1.png); */
  content: "";
  opacity: 1;
  background-repeat: no-repeat;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-testimonial-content .inner::before {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-content .inner::before {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-content .inner::before {
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.rbt-testimonial-content .inner p {
  font-size: 30px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-testimonial-content .inner p {
    font-size: 24px;
    line-height: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-content .inner p {
    font-size: 28px;
    line-height: 1.5;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-content .inner p {
    font-size: 18px;
    line-height: 26px;
    padding: 0;
    font-weight: 400;
  }
}

.rbt-testimonial-content .inner .rating i {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-content .author-info {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-content .author-info {
    text-align: center;
  }
}

.rbt-testimonial-content .author-info h6 {
  font-weight: 300;
  margin-bottom: 0;
  letter-spacing: -0.5px;
  margin-top: 12px;
}

.rbt-testimonial-content .author-info h6 span {
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: 500;
}

.bg-theme-gradient-odd:nth-child(odd) .rbt-testimonial-box .inner {
  background: var(--gradient-7);
}

.bg-theme-gradient-odd:nth-child(odd) .rbt-testimonial-box .description p {
  color: var(--color-white);
}

.bg-theme-gradient-odd:nth-child(odd) .rbt-testimonial-box .clint-info-wrapper .client-info .title {
  color: var(--color-white-off);
}

.bg-theme-gradient-even:nth-child(even) .rbt-testimonial-box .inner {
  background: var(--gradient-7);
}

.bg-theme-gradient-even:nth-child(even) .rbt-testimonial-box .description p {
  color: var(--color-white);
}

.bg-theme-gradient-even:nth-child(even) .rbt-testimonial-box .clint-info-wrapper .client-info .title {
  color: var(--color-white-off);
}

/* ------------------------------
Testimonial Thumbnail 
-------------------------------- */

ul.testimonial-thumb-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 510px;
  margin: 0px auto;
  margin-bottom: -30px;
  outline: none;
  border: 0 none;
}

@media only screen and (max-width: 575px) {
  ul.testimonial-thumb-wrapper {
    margin-bottom: -10px;
  }
}

ul.testimonial-thumb-wrapper li {
  padding-left: 15px;
  padding-right: 15px;
  flex-basis: 33.33%;
  margin-bottom: 30px;
  outline: none;
  cursor: pointer;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  ul.testimonial-thumb-wrapper li {
    flex-basis: 33.33%;
    margin-bottom: 14px;
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media only screen and (max-width: 575px) {
  ul.testimonial-thumb-wrapper li {
    flex-basis: 50%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}

ul.testimonial-thumb-wrapper li a {
  display: block;
}

ul.testimonial-thumb-wrapper li .thumb {
  position: relative;
  display: inline-block;
  width: 100%;
}

ul.testimonial-thumb-wrapper li .thumb img {
  border-radius: 6px;
  width: 100%;
  max-height: 140px;
  min-height: 140px;
  object-fit: cover;
  height: 140px;
  padding: 10px;
  background: var(--color-white);
  box-shadow: var(--shadow-2);
}

ul.testimonial-thumb-wrapper li .thumb img:hover {
  transform: scale(1.1);
}

ul.testimonial-thumb-wrapper li .thumb::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
}

ul.testimonial-thumb-wrapper li a.active .thumb::after {
  background-color: rgba(255, 255, 255, 0);
}

ul.testimonial-thumb-wrapper li a.active .thumb img {
  transform: scale(1.1);
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  ul.testimonial-thumb-wrapper li a.active .thumb img {
    transform: none;
    object-fit: cover;
  }
}

/* ------------------------------
    Testimonial Style Two 
-------------------------------- */

.scroll-animation-wrapper {
  position: relative;
  z-index: 1;
}

.scroll-animation-wrapper::before,
.scroll-animation-wrapper::after {
  position: absolute;
  height: 100%;
  width: 100px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#fff),
      to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, #fff, rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0));
  z-index: 2;
  content: "";
}

@media only screen and (max-width: 767px) {

  .scroll-animation-wrapper::before,
  .scroll-animation-wrapper::after {
    width: 50px;
  }
}

.scroll-animation-wrapper::before {
  top: 0;
  left: 0;
}

.scroll-animation-wrapper::after {
  top: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.scroll-animation-wrapper.no-overlay::before,
.scroll-animation-wrapper.no-overlay::after {
  display: none;
}

.scroll-animation {
  display: flex;
  width: -webkit-calc((420px * 16) + (30px * 16));
  width: -moz-calc((420px * 16) + (30px * 16));
  width: 7200px;
  position: relative;
}

.scroll-animation.scroll-right-left {
  -webkit-animation: scroll 80s linear infinite;
  -moz-animation: scroll 80s linear infinite;
  animation: scroll 80s linear infinite;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.scroll-animation.scroll-left-right {
  -webkit-animation: scrollLeft 80s linear infinite;
  -moz-animation: scrollLeft 80s linear infinite;
  animation: scrollLeft 80s linear infinite;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.scroll-animation .single-column-20 {
  width: 500px;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .scroll-animation .single-column-20 {
    width: 310px;
    padding: 0 10px;
  }
}

.rbt-testimonial-box {
  position: relative;
  z-index: 2;
  border-radius: var(--radius);
}

.rbt-testimonial-box .inner {
  position: relative;
  padding: 50px 40px;
  z-index: 2;
  background: var(--color-white);
  border-radius: var(--radius);
  box-shadow: 2px 2px 10px #33333312;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-testimonial-box .inner {
    padding: 40px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-testimonial-box .inner {
    padding: 40px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-box .inner {
    padding: 30px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .inner {
    padding: 22px 19px;
  }
}

.rbt-testimonial-box .inner::before {
  position: absolute;
  content: "";
  right: 40px;
  top: 40px;
  z-index: -1;
  /* background-image: url(../images/shape/quote.svg); */
  background-repeat: no-repeat, repeat;
  width: 52px;
  height: 44px;
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .inner::before {
    right: 20px;
    top: 20px;
  }
}

.rbt-testimonial-box .inner.bg-color-primary-opacity::before {
  filter: grayscale(1);
}

.rbt-testimonial-box.no-box-shadow .inner {
  box-shadow: none;
}

.rbt-testimonial-box.bg-gray-light .inner {
  background: var(--color-gray-light);
}

.rbt-testimonial-box.bg-gradient-7 .inner {
  background: transparent;
  border-radius: var(--radius);
}

/* .rbt-testimonial-box.bg-gradient-7 .inner::before {
  background-image: url(../images/icons/quote.svg);
} */

.rbt-testimonial-box.no-padding .inner {
  padding: 0;
}

.rbt-testimonial-box .clint-info-wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .clint-info-wrapper {
    display: block;
  }
}

.rbt-testimonial-box .clint-info-wrapper .thumb {
  position: relative;
  z-index: 1;
  display: inline-block;
  height: 70px;
  padding: 3px;
  background: var(--color-white);
  border-radius: 100%;
  overflow: hidden;
  min-width: 70px;
  border: 2px solid var(--primary-opacity);
}

.rbt-testimonial-box .clint-info-wrapper .thumb img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 100%;
}

.rbt-testimonial-box .clint-info-wrapper .client-info {
  padding-left: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info {
    padding-left: 0;
    margin-top: 15px;
  }
}

.rbt-testimonial-box .clint-info-wrapper .client-info .title {
  margin-bottom: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info .title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .clint-info-wrapper .client-info .title {
    font-size: 20px;
  }
}

.rbt-testimonial-box .clint-info-wrapper .client-info span {
  line-height: 28px;
  display: inline-block;
  font-size: 16px;
}

.rbt-testimonial-box .description {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .description {
    margin-top: 8px;
  }
}

.rbt-testimonial-box .description p {
  margin-bottom: 0;
}

.rbt-testimonial-box .description .rbt-btn-link {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box .description .rbt-btn-link {
    margin-top: 10px;
  }
}

.rbt-testimonial-box.style-2 .inner {
  text-align: center;
}

/* .rbt-testimonial-box.style-2 .inner::before {
  background-image: url(../images/icons/quote.svg);
} */

.rbt-testimonial-box.style-2 .icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbt-testimonial-box.style-2 .icons img {
  max-height: 50px;
}

.rbt-testimonial-box.style-2 .clint-info-wrapper {
  display: flex;
  align-items: center;
  margin-top: 22px;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box.style-2 .clint-info-wrapper {
    display: block;
  }
}

.rbt-testimonial-box.style-2 .clint-info-wrapper .thumb {
  height: 40px;
  padding: 2px;
  min-width: 40px;
}

.rbt-testimonial-box.style-2 .clint-info-wrapper .thumb img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 100%;
}

.rbt-testimonial-box.style-2 .clint-info-wrapper .client-info {
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-testimonial-box.style-2 .clint-info-wrapper .client-info {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-testimonial-box.style-2 .clint-info-wrapper .client-info {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-testimonial-box.style-2 .clint-info-wrapper .client-info {
    padding-left: 0;
    margin-top: 15px;
  }
}

.rbt-testimonial-box.style-2 .clint-info-wrapper .client-info .title {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.rbt-testimonial-box.style-2 .clint-info-wrapper .client-info .title span {
  line-height: inherit;
  display: inline-block;
  font-size: inherit;
}

.rbt-testimonial-box.text-white .title {
  color: var(--color-white);
}

.rbt-testimonial-box.text-white .designation {
  color: var(--color-white-off);
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-webkit-calc(-250px * 7));
    transform: translateX(-1750px);
  }
}

@-moz-keyframes scroll {
  0% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -moz-transform: translateX(-moz-calc(-250px * 7));
    transform: translateX(-1750px);
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-webkit-calc(-250px * 7));
    -moz-transform: translateX(-moz-calc(-250px * 7));
    transform: translateX(-1750px);
  }
}

@-webkit-keyframes scrollLeft {
  0% {
    -webkit-transform: translateX(-webkit-calc(-250px * 7));
    transform: translateX(-1750px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes scrollLeft {
  0% {
    -moz-transform: translateX(-moz-calc(-250px * 7));
    transform: translateX(-1750px);
  }

  to {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes scrollLeft {
  0% {
    -webkit-transform: translateX(-webkit-calc(-250px * 7));
    -moz-transform: translateX(-moz-calc(-250px * 7));
    transform: translateX(-1750px);
  }

  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}

.advance-tab-button-1 .tab-button-list {
  list-style: none;
  padding: 0;
  margin: -10px 0;
}

.advance-tab-button-1 .tab-button-list li {
  margin: 10px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advance-tab-button-1 .tab-button-list li {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .advance-tab-button-1 .tab-button-list li {
    width: 100%;
  }
}

.advance-tab-button-1 .tab-button-list .tab-button {
  cursor: pointer;
  padding: 47px 27px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0 none;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .advance-tab-button-1 .tab-button-list .tab-button {
    padding: 18px 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advance-tab-button-1 .tab-button-list .tab-button {
    padding: 25px 24px;
  }
}

@media only screen and (max-width: 767px) {
  .advance-tab-button-1 .tab-button-list .tab-button {
    padding: 25px 24px;
  }
}

.advance-tab-button-1 .tab-button-list .tab-button::before {
  position: absolute;
  content: "";
  background: var(--color-white);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
  transform: scale(0.9);
  z-index: -1;
  border-radius: 7px;
  box-shadow: var(--shadow-1);
}

.advance-tab-button-1 .tab-button-list .tab-button:hover::before {
  opacity: 1;
  transform: scale(1);
}

.advance-tab-button-1 .tab-button-list .tab-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  border-left: 20px solid var(--color-white);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  margin-top: -20px;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.9);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advance-tab-button-1 .tab-button-list .tab-button::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .advance-tab-button-1 .tab-button-list .tab-button::after {
    display: none;
  }
}

.advance-tab-button-1 .tab-button-list .tab-button .title {
  color: var(--color-heading);
  font-size: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.advance-tab-button-1 .tab-button-list .tab-button .description {
  margin-bottom: 0;
  transition: 0.3s;
  color: var(--color-body);
  letter-spacing: -0.5px;
}

.advance-tab-button-1 .tab-button-list .tab-button.active .title {
  letter-spacing: 0;
}

.advance-tab-button-1 .tab-button-list .tab-button.active::before {
  opacity: 1;
  transform: scale(1);
}

.advance-tab-button-1 .tab-button-list .tab-button.active::after {
  opacity: 1;
  transform: scale(1);
}

.nav-tabs {
  border-bottom: 0;
}

.advance-tab-content-1 .thumbnail img {
  border-radius: 10px;
}

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(0.9) rotate(-180deg);
  left: auto;
  right: 100%;
}

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(1) rotate(-180deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  left: 50%;
  transform: scale(0.9) translateX(-50%) rotate(-92deg);
  bottom: 94%;
  top: auto;
}

.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  opacity: 0;
  transform: scale(1) translateX(-50%) rotate(-92deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button.active::after {
  opacity: 1;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
  color: var(--color-body);
  background: transparent;
  border: 0 none;
  box-shadow: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.advance-tab-button-1 .tab-button-list .tab-button:focus {
  outline: none;
  border: 0 none;
  box-shadow: none;
}

/*-------------------------
    Advance Tab Style Two  
--------------------------*/

.tab-button-style-2 {
  border-bottom: 2px solid var(--color-border);
  justify-content: center;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 767px) {
  .tab-button-style-2 {
    margin-bottom: 20px;
  }
}

.tab-button-style-2 li {
  margin-top: 0;
  margin-bottom: 0;
}

.tab-button-style-2 li a {
  position: relative;
  display: block;
  outline: none;
  padding: 16px 28px;
  border: 0 none;
  box-shadow: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-button-style-2 li a {
    padding: 16px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-button-style-2 li a {
    padding: 14px 10px;
  }
}

.tab-button-style-2 li a::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: var(--color-primary);
  transform: scaleX(0);
  transition: transform 0.3s;
}

.tab-button-style-2 li a.active {
  border: 0 none;
  outline: none;
  color: var(--color-primary);
}

.tab-button-style-2 li a.active::after {
  transform: scaleX(1);
}

.tab-button-style-2 li a .title {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}

.advance-tab-content-style-2 {
  text-align: center;
  padding: 0 15%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .advance-tab-content-style-2 {
    padding: 0 6%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advance-tab-content-style-2 {
    padding: 0 7%;
  }
}

@media only screen and (max-width: 767px) {
  .advance-tab-content-style-2 {
    padding: 0;
  }
}

.advance-tab-content-style-2.text-start {
  padding: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .advance-tab-content-style-2.text-start {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advance-tab-content-style-2.text-start {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .advance-tab-content-style-2.text-start {
    padding: 0;
  }
}

/*---------------------
    Tab Styles  
-----------------------*/

.rbt-default-tab {
  position: relative;
}

.rbt-default-tab .tab-button {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  border-bottom: none;
}

.rbt-default-tab .tab-button .tabs__tab {
  margin: 0;
}

.rbt-default-tab .tab-button .tabs__tab .nav-link {
  color: var(--color-heading);
  padding: 24px 44px;
  margin-bottom: 0px;
  outline: none;
  border: 0 none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-default-tab .tab-button .tabs__tab .nav-link {
    padding: 24px 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-default-tab .tab-button .tabs__tab .nav-link {
    padding: 24px 24px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-default-tab .tab-button .tabs__tab .nav-link {
    padding: 24px 20px;
  }
}

.rbt-default-tab .tab-button .tabs__tab .nav-link.active {
  background: var(--color-extra2);
  border-color: transparent;
}

.rbt-default-tab .tab-button .rainbow-tab-button button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0 none;
  color: var(--color-heading);
}

.rbt-default-tab .rainbow-tab-content {
  background: var(--color-extra2);
  padding: 40px;
}

@media only screen and (max-width: 767px) {
  .rbt-default-tab .rainbow-tab-content {
    padding: 30px;
  }
}

.rbt-default-tab .rainbow-tab-content p:last-child {
  margin-bottom: 0;
}

.rbt-default-tab.style-two .tab-button {
  margin-bottom: 30px;
}

.rbt-default-tab.style-two .tab-button .tabs__tab button {
  border-radius: var(--radius);
}

.rbt-default-tab.style-two .rainbow-tab-content {
  border-radius: var(--radius);
}

/*----------------------
    Category Styles  
-------------------------*/

.rbt-category-gallery {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.rbt-category-gallery img {
  border-radius: 6px;
  width: 100%;
}

.rbt-category-gallery .rbt-bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.45s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  visibility: hidden;
  border-radius: 6px;
}

.rbt-category-gallery .hover-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.rbt-category-gallery .hover-content .title {
  margin: 0;
  color: inherit;
  opacity: 0;
  transform: translateY(10px);
  transition-property: transform, opacity;
  transition-duration: 0.45s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  margin-bottom: 0;
  color: var(--color-white);
}

.rbt-category-gallery:hover .rbt-bg-overlay {
  opacity: 1;
  visibility: visible;
}

.rbt-category-gallery:hover .hover-content .title {
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateY(0);
}

/*-------------------
  Instagram Styles
---------------------*/

.instagram-grid {
  text-align: center;
  overflow: hidden;
}

.instagram-grid a {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  display: block;
}

.instagram-grid a img {
  border-radius: 5px;
  transition: var(--transition);
  overflow: hidden;
}

.instagram-grid a:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--color-heading);
  border-radius: 5px;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}

.instagram-grid a .user-info {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) scale(1.5);
  z-index: 1;
  color: var(--color-white);
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}

.instagram-grid a .user-info .icon {
  display: block;
  font-size: 26px;
}

.instagram-grid a .user-info .user-name {
  font-size: 18px;
  font-weight: 500;
}

.instagram-grid:hover a img {
  transform: scale(1.1);
}

.instagram-grid:hover a:after {
  visibility: visible;
  opacity: 0.5;
}

.instagram-grid:hover a .user-info {
  transform: translateY(-50%) scale(1);
  visibility: visible;
  opacity: 1;
}

/*--------------------------
    split Area Styles  
----------------------------*/

.rbt-splite-style .shape img {
  max-height: 96px;
  margin-bottom: 20px;
}

.rbt-splite-style .thumbnail img {
  border-radius: 0;
  width: 100%;
}

.rbt-splite-style .thumbnail.image-left-content img {
  border-radius: 0 10px 10px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splite-style .thumbnail.image-left-content img {
    border-radius: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splite-style .thumbnail.image-left-content img {
    border-radius: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splite-style .thumbnail.image-left-content img {
    border-radius: 0;
  }
}

.rbt-splite-style .split-inner {
  padding: 30px 115px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splite-style .split-inner {
    padding: 30px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splite-style .split-inner {
    padding: 40px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splite-style .split-inner {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splite-style .split-inner {
    padding: 30px 10px;
  }
}

.rbt-splite-style .split-inner .title {
  font-size: 36px;
  line-height: 50px;
  color: var(--color-heading);
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .rbt-splite-style .split-inner .title {
    font-size: 24px;
    line-height: 30px;
  }
}

.rbt-splite-style .split-inner .description {
  margin-bottom: 30px;
}

.rbt-splite-style .split-inner .split-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.rbt-splite-style .split-inner .split-list li {
  color: var(--color-body);
}

@media only screen and (max-width: 767px) {
  .rbt-splite-style .split-inner .split-list li {
    font-size: 16px;
  }
}

/*------------------------
    Countdown Styles  
-------------------------*/

.countdown-style-1 .countdown {
  display: flex;
  margin: 0 -20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -10px;
}

.countdown-style-1 .countdown .countdown-container {
  margin: 10px;
  position: relative;
  transition: 0.4s;
  text-align: center;
  padding: 15px 20px;
  background: var(--white-opacity);
  border-radius: 10px;
  min-width: 120px;
}

.countdown-style-1 .countdown .countdown-container .countdown-heading {
  display: block;
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  display: block;
  font-weight: 300;
  font-size: 18px;
  line-height: 36px;
  transition: 0.4s;
  opacity: 0.7;
}

.countdown-style-1 .countdown .countdown-container .countdown-value {
  display: block;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.24;
  color: var(--color-white);
  text-align: center;
  position: relative;
  transition: 0.4s;
}

@media only screen and (max-width: 767px) {
  .countdown-style-1 .countdown .countdown-container .countdown-value {
    font-size: 24px;
  }
}

/*-----------------------
    Progress Bar 
-----------------------*/

.single-progress+.single-progress {
  margin-top: 40px;
}

.single-progress {
  position: relative;
  overflow: hidden;
}

.single-progress .title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: #acacac;
  color: var(--color-body);
  letter-spacing: 0.5px;
  margin-bottom: 18px;
}

.progress {
  height: 6px;
  overflow: visible;
  background-color: var(--color-gray-light);
  border-radius: 10px;
}

.progress .progress-bar {
  border-radius: 10px;
  width: 0;
  height: 100%;
  background-color: var(--color-primary);
  transition: width 0.5s ease;
  overflow: visible;
}

.progress .progress-bar.bar-color-2 {
  background: #fb5343;
}

.progress .progress-bar.bar-color-3 {
  background: #e33fa1;
}

.progress .progress-bar.bar-color-4 {
  background: #6549d5;
}

.progress .progress-bar.bar-color-success {
  background: #3eb75e;
}

.progress .progress-bar.bar-color-danger {
  background: #ff0003;
}

.progress .progress-bar.bar-color-warning {
  background: #ff8f3c;
}

.progress .progress-bar.bar-gradiand {
  background: radial-gradient(circle farthest-corner at 0% 0%,
      var(--color-pink) 25%,
      var(--color-secondary) 45%,
      var(--color-primary) 65%,
      var(--color-light-green) 85%);
}

.progress .progress-number {
  position: absolute;
  font-size: 14px;
  top: 0;
  line-height: 1.25;
  right: 0;
  z-index: 1;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.progress-info .title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--color-body);
  letter-spacing: 0.5px;
}

.progress-info span.progress-number {
  font-size: 14px;
  color: var(--color-body);
}

.no-radius .progress {
  border-radius: 0;
}

.no-radius .progress .progress-bar {
  border-radius: 0;
}

.large-size .progress {
  height: 20px;
}

/*-----------------------------------
    Radial Progress 
-----------------------------------*/

.radial-progress-single {
  text-align: center;
  padding: 0 20px;
}

.radial-progress-single .circle-info {
  padding-top: 20px;
  text-align: center;
}

.radial-progress-single .circle-info .title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: -0.5px;
}

.radial-progress-single .circle-info .subtitle {
  font-style: italic;
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--color-body);
}

.radial-progress {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .radial-progress {
    display: inline-block;
  }
}

.radial-progress .circle-text {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.radial-progress .circle-text span.count {
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  color: var(--color-body);
  position: relative;
  margin-left: -14px;
}

.radial-progress .circle-text span.count::after {
  position: absolute;
  content: "%";
  left: 100%;
  color: var(--color-body);
}

/*--------------------------
    Category Box Layout  
----------------------------*/

.rbt-cat-box-1 {
  display: block;
}

.rbt-cat-box-1 .inner {
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: var(--radius-10);
  padding: 30px 40px;
}

@media only screen and (max-width: 1199px) {
  .rbt-cat-box-1 .inner {
    padding: 20px 20px;
  }
}

.rbt-cat-box-1 .inner .icons {
  margin-bottom: 20px;
}

.rbt-cat-box-1 .inner .icons img {
  height: 50px;
}

.rbt-cat-box-1 .inner .icons img {
  max-height: 80px;
}

.rbt-cat-box-1 .inner .icons i {
  font-size: 34px;
}

.rbt-cat-box-1 .inner .content .title {
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-cat-box-1 .inner .content .title {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cat-box-1 .inner .content .title {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cat-box-1 .inner .content .title {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

.rbt-cat-box-1.variation-2 .inner {
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  border-radius: var(--radius-10);
  padding: 20px 15px 25px 15px;
}

.rbt-cat-box-1.variation-2 .inner .thumbnail a img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.rbt-cat-box-1.variation-2 .inner .icons {
  margin-bottom: 10px;
  position: relative;
  margin-top: -25px;
  background: #fff;
  width: 70px;
  margin-right: auto;
  margin-left: auto;
  height: 70px;
  line-height: 70px;
  padding: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  box-shadow: var(--shadow-10);
}

.rbt-cat-box-1.variation-2 .inner .icons img {
  max-height: 50px;
}

.rbt-cat-box-1.variation-2 .inner .content .title {
  margin-bottom: 5px;
}

.rbt-cat-box-1.variation-3 .inner {
  background: transparent;
  box-shadow: none;
  padding: 0;
  position: relative;
  z-index: 1;
}

.rbt-cat-box-1.variation-3 .inner .thumbnail img {
  border-radius: var(--radius-10);
}

.rbt-cat-box-1.variation-3 .inner .thumbnail a {
  display: block;
  position: relative;
  z-index: 1;
}

.rbt-cat-box-1.variation-3 .inner .thumbnail a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 300ms;
  border-radius: var(--radius-10);
}

.rbt-cat-box-1.variation-3 .inner .thumbnail a .read-more-btn {
  position: absolute;
  transition: 400ms;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  margin-top: 15px;
  visibility: hidden;
  z-index: 1;
}

.rbt-cat-box-1.variation-3 .inner .content {
  padding-top: 15px;
}

.rbt-cat-box-1.variation-3 .inner .content .title {
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-cat-box-1.variation-3 .inner .content .title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-cat-box-1.variation-3 .inner .content .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-cat-box-1.variation-3 .inner .content .title {
    font-size: 16px;
  }
}

.rbt-cat-box-1.variation-3 .inner .content .description {
  font-size: var(--font-size-b3);
}

.rbt-cat-box-1.variation-3:hover .inner .thumbnail a::after {
  opacity: 1;
}

.rbt-cat-box-1.variation-3:hover .inner .thumbnail a .read-more-btn {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}

.rbt-cat-box-1.variation-4 .inner {
  position: relative;
  z-index: 1;
  border-radius: 0;
  padding: 30px;
}

.rbt-cat-box-1.variation-4 .inner .icons {
  margin-bottom: 5px;
}

.rbt-cat-box-1.variation-4 .inner .icons i {
  font-size: 60px;
}

.rbt-cat-box-1.variation-4 .inner .content {
  padding-top: 5px;
}

.rbt-cat-box-1.variation-4 .inner .content .title {
  margin-bottom: 0;
  font-size: 19px;
  line-height: 1.4;
}

.rbt-cat-box-1.variation-4 .inner .content .description {
  font-size: var(--font-size-b3);
}

.rbt-cat-box-1.variation-5 .inner {
  position: relative;
  z-index: 1;
  border-radius: 0;
  padding: 0;
}

.rbt-cat-box-1.variation-5 .inner .thumbnail a {
  display: block;
}

.rbt-cat-box-1.variation-5 .inner .thumbnail a img {
  border-radius: 6px;
}

.rbt-cat-box-1.variation-5 .inner .content {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  position: absolute;
  z-index: 2;
  background: var(--color-white);
  left: 10px;
  bottom: 10px;
  right: 10px;
  border-radius: 6px;
}

.rbt-cat-box-1.variation-5 .inner .content .title {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.4;
  flex-basis: 90%;
}

.rbt-cat-box-1.variation-5 .inner .content .read-more-btn {
  flex-basis: 10%;
}

.rbt-cat-box-1.list-style .inner {
  display: flex;
  align-items: center;
  padding: 0;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-7);
  background: var(--color-white);
}

.rbt-cat-box-1.list-style .inner .thumbnail {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 6px 0 0 6px;
  overflow: hidden;
}

.rbt-cat-box-1.list-style .inner .thumbnail img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.rbt-cat-box-1.list-style .inner .content {
  flex-grow: 1;
  text-align: left;
  padding-left: 20px;
}

.rbt-cat-box-1.list-style .inner .content .title {
  margin-bottom: 1px;
  font-size: 16px;
}

.rbt-cat-box-1.image-overlaping-content {
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 2;
}

.rbt-cat-box-1.image-overlaping-content::after {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 6px;
}

.rbt-cat-box-1.image-overlaping-content .inner {
  padding: 0;
  box-shadow: none;
  position: relative;
  height: 80px;
}

.rbt-cat-box-1.image-overlaping-content .inner .thumbnail {
  height: 100%;
  width: 100%;
}

.rbt-cat-box-1.image-overlaping-content .inner .thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.rbt-cat-box-1.image-overlaping-content .inner .content {
  position: absolute;
  top: 50%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  transform: translateY(-50%);
  z-index: 1;
}

.rbt-cat-box-1.image-overlaping-content .inner .content .title {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.rbt-cat-box-1.image-overlaping-content .inner .content .rbt-btn-link {
  color: var(--color-white-off);
}

.rbt-cat-box-1.image-overlaping-content .inner .content .rbt-btn-link::after {
  background: currentColor;
}

.rbt-cat-box-1.image-overlaping-content .inner .content .rbt-btn-link:hover {
  color: var(--color-white) !important;
}

.rbt-cat-box-1.image-overlaping-content:hover::after {
  background: rgba(0, 0, 0, 0.7);
}

.rbt-cat-box-1.image-overlaping-content.active::after {
  background: var(--color-primary);
  opacity: 0.7;
}

.rbt-cat-box-1.image-overlaping-content.on-hover-content-visible .inner .content .title {
  margin-top: 27px;
  margin-bottom: 0;
  transition: 0.4s;
}

.rbt-cat-box-1.image-overlaping-content.on-hover-content-visible .inner .content .rbt-btn-link {
  opacity: 0;
  transition: 0.4s;
}

.rbt-cat-box-1.image-overlaping-content.on-hover-content-visible:hover {
  transform: none;
}

.rbt-cat-box-1.image-overlaping-content.on-hover-content-visible:hover .inner .content .title {
  margin-top: 0;
}

.rbt-cat-box-1.image-overlaping-content.on-hover-content-visible:hover .inner .content .rbt-btn-link {
  opacity: 1;
}

.rbt-cat-box-1.image-overlaping-content.with-image-content .inner .content {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
}

.rbt-cat-box-1.image-overlaping-content.with-image-content .inner .content .icons {
  margin-bottom: 0;
  margin-right: 10px;
}

.rbt-cat-box-1.image-overlaping-content.with-image-content .inner .content .icons img {
  max-height: 30px;
}

.rbt-cat-box-1.image-overlaping-content.with-image-content:hover {
  transform: none;
}

.rbt-cat-box-1:hover {
  transform: translate3d(0, -10px, 0);
}

/*---------------------------
    Filter Exanded  
-----------------------------*/

.default-exp-wrapper {
  border-top: 1px solid var(--color-border);
}

.default-exp-wrapper .filter-inner {
  display: flex;
  padding: 40px 0;
  justify-content: flex-end;
  margin: -10px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .default-exp-wrapper .filter-inner {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-exp-wrapper .filter-inner {
    justify-content: start;
  }
}

@media only screen and (max-width: 767px) {
  .default-exp-wrapper .filter-inner {
    justify-content: start;
  }
}

.default-exp-wrapper .filter-inner .filter-select-option {
  flex-basis: 20%;
  padding: 10px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .default-exp-wrapper .filter-inner .filter-select-option {
    flex-basis: 33.33%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-exp-wrapper .filter-inner .filter-select-option {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .default-exp-wrapper .filter-inner .filter-select-option {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .default-exp-wrapper .filter-inner .filter-select-option {
    flex-basis: 100%;
  }
}

.default-exp-wrapper .filter-inner .filter-select-option .filter-leble {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.default-exp-wrapper .filter-inner .filter-select-option .nice-select {
  width: 100%;
  padding-left: 20px;
  padding-right: 40px;
}

.default-exp-wrapper .filter-inner .filter-select-option .nice-select::after {
  right: 22px;
  height: 8px;
  width: 8px;
  opacity: 0.5;
}

.default-exp-wrapper .filter-inner .filter-select-option .nice-select .list {
  min-width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.default-exp-wrapper .filter-select select {
  width: 100%;
  min-width: 100%;
}

.default-exp-expand {
  display: none;
}

/*-------------------------
    Price Filter  
--------------------------*/

.price--output input {
  border: 0 none;
}

.price--output span {
  color: var(--color-body);
  font-size: 14px;
}

.price--filter {
  display: inline-block;
}

.price--output {
  display: inline-block;
}

.ui-widget-content {
  background: var(--color-gray-lighter) none repeat scroll 0 0;
  border: 0 none;
  color: var(--color-primary);
}

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: var(--color-primary) none repeat scroll 0 0;
}

.ui-slider-horizontal {
  height: 4px;
}

.ui-slider .ui-slider-handle {
  border-radius: 100%;
  cursor: default;
  height: 10px;
  position: absolute;
  touch-action: none;
  width: 10px;
  z-index: 2;
  outline: none;
  cursor: pointer;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
  margin-left: -3px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: var(--color-primary) none repeat scroll 0 0;
  border: 0 none;
  color: var(--color-primary);
}

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: var(--color-primary) none repeat scroll 0 0;
}

.ui-slider-horizontal {
  margin-bottom: 8px;
  margin-top: 15px;
}

.price__output--wrap {
  display: flex;
  align-items: center;
}

.price__output--wrap .price--output {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.price__output--wrap .price--output span {
  color: var(--color-body);
  font-size: 12px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-weight: 600;
  opacity: 0.5;
  text-transform: uppercase;
}

.price__output--wrap .price--output input {
  border: 0 none;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 400;
  width: 100px;
  letter-spacing: 0.5px;
  box-shadow: none;
  font-weight: 600;
  opacity: 0.5;
}

.price__output--wrap .price--filter {
  display: inline-block;
  margin-top: -2px;
}

.price__output--wrap .price--filter a.rbt-btn {
  padding: 6px 10px;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 6px;
  line-height: 16px;
  height: auto;
}

/*-------------------
    Bootstrap Select  
----------------------*/

.rbt-modern-select .bootstrap-select button.btn-light {
  border: 0 none;
  box-shadow: var(--shadow-1);
  height: 50px;
  padding: 10px 20px;
  outline: none;
  color: var(--color-body);
  border-radius: var(--radius);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 30px;
  background-color: var(--color-white);
  outline: none;
  border: 2px solid #ededed;
}

.rbt-modern-select.bg-transparent .bootstrap-select button.btn-light {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid var(--color-border);
}

.rbt-modern-select.height-45 .bootstrap-select button.btn-light {
  height: 45px;
}

.rbt-modern-select .bootstrap-select button.actions-btn {
  padding: 7px 6px;
  font-size: 13px;
  box-shadow: none;
  background: #f8f9fa;
  height: 38px;
  line-height: 24px;
  transition: 0.4s;
}

.rbt-modern-select .bootstrap-select button.actions-btn:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.rbt-modern-select .bootstrap-select .dropdown-menu.inner {
  display: block;
  padding: 10px;
  margin: 0;
}

.rbt-modern-select .bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 11px;
}

.rbt-modern-select .dropdown-menu {
  padding: 0;
  box-shadow: var(--shadow-1);
  border: 0 none;
  border-radius: 6px !important;
  background-color: var(--color-white);
  min-width: 100%;
  max-width: 100%;
}

.rbt-modern-select .dropdown-item {
  padding: 5px 10px;
  color: var(--color-body);
  border-radius: 4px;
}

.rbt-modern-select .bootstrap-select .dropdown-menu li {
  margin: 0;
  padding: 0;
}

.rbt-modern-select .bootstrap-select .dropdown-menu li.no-results {
  padding: 4px 8px;
  background: transparent;
  white-space: nowrap;
  font-size: 16px;
  color: var(--color-danger);
  padding-bottom: 10px;
}

.rbt-modern-select .dropdown-item.active,
.rbt-modern-select .dropdown-item:active {
  background-color: var(--black-opacity);
  color: var(--color-primary);
}

.rbt-modern-select .bootstrap-select .dropdown-menu li a span.text {
  font-size: 16px;
}

.rbt-modern-select .bootstrap-select .dropdown-toggle .filter-option {
  display: flex;
  width: 100%;
  position: relative;
  flex: inherit;
  min-width: 100%;
  align-items: center;
}

.rbt-modern-select .filter-option-inner {
  display: block;
  width: 100%;
}

.rbt-modern-select .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
  display: block;
}

.rbt-modern-select .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.rbt-modern-select .dropdown-toggle::after {
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  opacity: 0.5;
}

.rbt-modern-select .btn-check:active+.btn-light:focus,
.rbt-modern-select .btn-check:checked+.btn-light:focus,
.rbt-modern-select .btn-light.active:focus,
.rbt-modern-select .btn-light:active:focus,
.rbt-modern-select .show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.rbt-modern-select .bs-searchbox .form-control {
  outline: none;
  box-shadow: none;
  border: 2px solid var(--color-border);
  border-radius: 6px;
  margin-bottom: 2px;
  font-size: 16px;
}

.rbt-modern-select .dropdown-item:focus,
.rbt-modern-select .dropdown-item:hover {
  color: var(--color-primary);
  background-color: var(--black-opacity);
}

.rbt-modern-select .btn-group>.btn-group:not(:last-child)>.btn,
.rbt-modern-select .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.rbt-modern-select .bs-actionsbox,
.rbt-modern-select .bs-donebutton,
.rbt-modern-select .bs-searchbox {
  padding: 10px;
  padding-bottom: 5px;
}

.rbt-modern-select .bs-searchbox+.bs-actionsbox {
  padding: 0 10px 4px;
}

.rbt-modern-select .bs-actionsbox .btn-group button {
  width: calc(50% - 10px);
  margin: 5px;
  margin-left: 5px !important;
}

.rbt-modern-select .bs-actionsbox .btn-group {
  display: block;
  margin: -5px;
}

.rbt-modern-select .bootstrap-select>select {
  left: 0;
}

/*-----------------------
    Team Styles  
------------------------*/

.modal-body {
  padding: 25px;
}

.modal-content {
  border-radius: 10px;
}

/*----------------------
    Rbt List Style  
------------------------*/

.rbt-list-style-1 li {
  display: flex;
  font-size: 16px;
  margin: 7px 0;
  line-height: 1.45;
}

@media only screen and (max-width: 767px) {
  .rbt-list-style-1 li {
    font-size: 16px;
  }
}

.rbt-list-style-1 li i {
  padding-right: 10px;
  margin-top: 5px;
}

.rbt-list-style-1 li:first-child {
  margin-top: 0;
}

.rbt-list-style-1 li:last-child {
  margin-bottom: 0;
}

.rbt-list-style-1.color-white li {
  color: var(--color-white);
}

.rbt-list-style-2 {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: -10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .rbt-list-style-2 {
    display: block;
    text-align: left;
  }
}

.rbt-list-style-2 li {
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;
}

.rbt-list-style-2 li.off {
  opacity: 0.4;
}

@media only screen and (max-width: 575px) {
  .rbt-list-style-2 li {
    text-align: center;
    width: 13%;
    display: inline-block;
    font-size: 12px;
  }

  .rbt-list-style-2 li i {
    margin-right: 0 !important;
  }
}

.rbt-list-style-2 li i {
  width: 30px;
  height: 30px;
  background: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  color: var(--color-primary);
  display: inline-block;
  line-height: 29px;
  margin-right: 10px;
  font-weight: 700;
  text-align: center;
}

.rbt-list-style-3 li {
  display: flex;
  font-size: 16px;
  margin: 7px 0;
  line-height: 1.45;
  color: var(--color-body);
}

.rbt-list-style-3 li i {
  padding-right: 10px;
  margin-top: 4px;
}

.rbt-list-style-3 li:first-child {
  margin-top: 0;
}

.rbt-list-style-3 li:last-child {
  margin-bottom: 0;
}

.rbt-list-style-3.color-white li {
  color: var(--color-white);
}

.plan-offer-list {
  margin: -10px;
}

.plan-offer-list li {
  font-size: 16px;
  margin: 10px;
}

.plan-offer-list li i {
  margin: 0 auto;
  line-height: 22px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.plan-offer-list li.off {
  opacity: 0.5;
}

.plan-offer-list li.off i {
  background: var(--color-danger);
}

.plan-offer-list.rbt-list-primary-opacity li i {
  background: var(--primary-opacity) !important;
  color: var(--color-primary) !important;
}

.plan-offer-list.rbt-list-white-opacity li i {
  background: var(--white-opacity) !important;
  color: var(--color-white) !important;
}

/*-------------------------------
 Modern Course Features  
-------------------------------*/

.modern-course-features-box {
  background: var(--primary-opacity);
  border-radius: 20px;
  padding: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-course-features-box {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-course-features-box {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .modern-course-features-box {
    padding: 20px;
  }
}

.modern-course-features-box .inner {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-course-features-box .inner {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .modern-course-features-box .inner {
    display: block;
  }
}

.modern-course-features-box .inner .thumbnail {
  flex-basis: 50%;
}

.modern-course-features-box .inner .thumbnail img {
  border-radius: 10px;
  width: 100%;
}

.modern-course-features-box .inner .content {
  flex-basis: 50%;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-course-features-box .inner .content {
    padding-left: 0;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-course-features-box .inner .content {
    padding-left: 0;
    padding-top: 30px;
  }
}

.modern-course-features-box .inner .content .title {
  color: var(--color-white);
  font-size: 36px;
  padding-right: 14%;
  margin-bottom: 20px;
  margin-top: -11px;
}

@media only screen and (max-width: 767px) {
  .modern-course-features-box .inner .content .title {
    font-size: 26px;
    padding-right: 0;
    margin-top: 0;
  }
}

.modern-course-features-box .inner .content p {
  color: var(--color-white-off);
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-course-features-box .inner .content p {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-course-features-box .inner .content p {
    margin-bottom: 20px;
  }
}

.modern-course-features-box .inner ul li {
  color: var(--color-white-off);
}

.modern-course-features-box .inner ul li i {
  background: var(--primary-opacity);
  color: var(--color-primary);
}

.modern-course-features-box.one-colume-grid .inner {
  display: block;
}

.modern-course-features-box.one-colume-grid .inner .content {
  padding-left: 0;
  padding-top: 34px;
}

.modern-course-features-box.grid-content-reverse .inner {
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .modern-course-features-box.grid-content-reverse .inner {
    flex-direction: column;
  }
}

.modern-course-features-box.grid-content-reverse .inner .thumbnail {
  flex-basis: 100%;
  width: 100%;
  margin-top: 30px;
}

.modern-course-features-box.grid-content-reverse .inner .content {
  flex-basis: 100%;
  padding-left: 0;
}

.modern-course-features-box.grid-content-reverse .inner .rbt-image-gallery-wrapper {
  width: 100%;
  display: block;
}

.modern-course-features-box.grid-content-no-flex .inner {
  display: block;
}

.bg-color-white .modern-course-features-box .inner .content .title,
.bg-color-white .course-feature-list li .feature-content .featute-title {
  color: var(--color-heading);
}

.bg-color-white .modern-course-features-box .inner .content p,
.bg-color-white .course-feature-list li .feature-content .featute-title span {
  color: var(--color-body);
}

.bg-color-white .modern-course-features-box {
  background: var(--secondary-opacity);
}

.bg-color-white .modern-course-features-box .inner ul li {
  color: var(--color-body);
}

/*---------------------------
    Course Feature List  
-----------------------------*/

.course-feature-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -20px;
}

@media only screen and (max-width: 767px) {
  .course-feature-list {
    margin: -10px -10px;
  }
}

.course-feature-list li {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .course-feature-list li {
    padding: 10px 10px;
  }
}

.course-feature-list li .icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 18px;
  margin-right: 12px;
}

.course-feature-list li .icon i {
  color: var(--color-white);
}

.course-feature-list li .feature-content .featute-title {
  color: var(--color-white);
  margin-bottom: 0;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .course-feature-list li .feature-content .featute-title {
    font-size: 16px;
  }
}

.course-feature-list li .feature-content .featute-title span {
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: var(--color-white-off);
}

@media only screen and (max-width: 767px) {
  .course-feature-list li .feature-content .featute-title span {
    font-size: 14px;
  }
}

.plan-offer-list-wrapper {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .plan-offer-list-wrapper {
    display: block;
  }
}

.plan-offer-list-wrapper .plan-offer-list+.plan-offer-list {
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .plan-offer-list-wrapper .plan-offer-list+.plan-offer-list {
    margin-left: -10px;
    margin-top: 30px;
  }
}

.search-trigger-active.open i.feather-search::before {
  content: "\ea02";
}

.rbt-search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #f1f0f3;
  box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
  z-index: 104;
  visibility: hidden;
  clip: rect(0px, 200vw, 0, 0px);
  opacity: 0;
  transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
  transform: translateZ(0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-search-dropdown {
    max-height: 500px;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-search-dropdown {
    max-height: 500px;
    overflow-y: scroll;
  }
}

.rbt-search-dropdown .wrapper {
  max-width: 900px;
  margin: 16px auto;
  padding: 0 16px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-search-dropdown .wrapper {
    margin: 15px 0;
    display: block;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-search-dropdown .wrapper {
    margin: 15px 0;
    display: block;
    margin-top: 0;
  }
}

.rbt-search-dropdown .wrapper form {
  margin: 20px 0px 0px 0px;
  display: flex;
}

@media only screen and (max-width: 575px) {
  .rbt-search-dropdown .wrapper form {
    display: block;
  }
}

.rbt-search-dropdown .wrapper form input {
  height: 50px;
  line-height: 48px;
  margin: 0 12px 0 0;
  padding: 0 16px;
  border: 2px solid var(--color-border);
  transition: 0.3s;
}

@media only screen and (max-width: 575px) {
  .rbt-search-dropdown .wrapper form input {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.rbt-search-dropdown .wrapper form input:focus {
  border-color: var(--color-primary);
}

.rbt-search-dropdown.active {
  visibility: visible;
  opacity: 1;
  clip: rect(0px, 200vw, 200vh, 0px);
  transition: clip 0.6s linear, opacity 0.2s linear;
}

.rbt-search-dropdown .rbt-card {
  padding: 10px;
  box-shadow: var(--shadow-1);
}

.rbt-search-dropdown .rbt-card .rbt-card-body {
  padding-top: 18px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-card-title {
  margin-bottom: 7px;
  font-size: 16px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-review {
  margin-bottom: 8px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-review .rating {
  margin-right: 5px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-review .rating i {
  font-size: 9px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-review .rating-count {
  font-size: 11px;
  margin-top: 1px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-price .current-price {
  font-size: 14px;
}

.rbt-search-dropdown .rbt-card .rbt-card-body .rbt-price .off-price {
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-search-dropdown .rbt-search-with-category {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-search-dropdown .rbt-search-with-category {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-search-dropdown.search-with-category-popup {
    overflow-y: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-search-dropdown.search-with-category-popup {
    overflow-y: inherit;
  }
}

.side-nav-opened body::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99;
  transition: all 0.7s;
}

/*-------------------------
    Search Style Default  
--------------------------*/

.rbt-search-style {
  position: relative;
}

.rbt-search-style input {
  background: transparent;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  padding-right: 60px;
  border-radius: 500px;
  min-width: 300px;
  border: 2px solid var(--color-white);
  color: var(--color-white);
}

.rbt-search-style input:focus {
  border-color: var(--color-white);
}

.rbt-search-style .rbt-search-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.rbt-search-style .rbt-search-btn i {
  color: var(--color-white);
  transition: 0.3s;
}

.rbt-search-style .rbt-search-btn:hover i {
  color: var(--color-primary);
}

/*-------------------------
    Search Style One  
--------------------------*/

.rbt-search-style-1 {
  position: relative;
}

.rbt-search-style-1 input {
  height: 50px;
  line-height: 50px;
  padding-right: 50px;
  width: 300px;
}

.rbt-search-style-1 .search-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background: transparent;
  padding: 0;
  border: 0 none;
  display: block;
  width: 50px;
  text-align: center;
  transition: 0.4s;
}

.rbt-search-style-1 .search-btn:hover {
  color: var(--color-primary);
}

/*----------------------
    RBT Video Styles  
-----------------------*/

.mfp-bg {
  z-index: 9999;
}

.mfp-wrap {
  z-index: 9999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

html.picture {
  margin: 0 !important;
}

img.mfp-img {
  padding-bottom: 0;
}

.mfp-bg {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  backdrop-filter: blur(15px);
}

.mfp-iframe-scaler iframe {
  border-radius: 10px;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 1;
}

.mfp-iframe-holder .mfp-close,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  top: -60px;
  right: -50px;
  font-size: 30px;
  font-weight: 400;
  transition: 0.4s;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: var(--heading-opacity);
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: var(--color-white);
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  top: -10px;
  right: -50px;
}

.side-nav-opened body::before,
.close_side_menu,
.popup-mobile-menu,
.rbt-offcanvas-side-menu {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);
}

/*----------------------
    Error Styles  
------------------------*/

.rbt-error-area .title {
  font-size: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-error-area .title {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-error-area .title {
    font-size: 50px;
  }
}

.rbt-error-area .sub-title {
  font-size: 50px;
}

@media only screen and (max-width: 767px) {
  .rbt-error-area .sub-title {
    font-size: 40px;
  }
}

/**************************************
	Blog Styles
***************************************/

/*-----------------------
    Blog Style  
-------------------------*/

.rbt-blog-grid.rbt-card .rbt-card-body .rbt-card-title {
  margin-bottom: 13px;
}

.rbt-blog-grid.rbt-card .rbt-card-body .blog-meta {
  margin-bottom: 10px;
}

/* Common Style  */

.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.alignwide {
  margin-left: -100px;
  margin-right: -100px;
  max-width: 1400px;
  width: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}

figcaption {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.wp-block-separator.is-style-dots {
  background: 0 0;
  border: none;
  text-align: center;
  max-width: none;
  line-height: 1;
  height: auto;
}

.wp-block-separator.is-style-dots::before {
  content: "···";
  padding-left: 21px;
  color: var(--color-dark-alt);
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 21px;
  /* font-family: georgia, serif; */
}

.blocks-gallery-grid {
  display: flex;
  padding: 0;
  margin: 0 -10px;
  list-style: none;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.blocks-gallery-grid li.blocks-gallery-item {
  padding: 0 10px;
  flex-basis: 33.33%;
}

.blocks-gallery-grid li.blocks-gallery-item img {
  border-radius: var(--radius);
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .blocks-gallery-grid li.blocks-gallery-item {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .blocks-gallery-grid li.blocks-gallery-item {
    flex-basis: 100%;
  }
}

.columns-2 .blocks-gallery-grid li.blocks-gallery-item {
  flex-basis: 50%;
}

@media only screen and (max-width: 767px) {
  .columns-2 .blocks-gallery-grid li.blocks-gallery-item {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .columns-2 .blocks-gallery-grid li.blocks-gallery-item {
    flex-basis: 100%;
  }
}

/*-----------------------
    Blog Meta  
-----------------------*/

.blog-meta {
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  .blog-meta {
    margin: 0 -7px;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-meta {
    padding: 0;
  }
}

.blog-meta li {
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-body);
  margin: 0 12px;
  margin-bottom: 5px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .blog-meta li {
    font-size: 14px;
    margin: 0 7px;
    margin-bottom: 10px;
  }
}

.blog-meta li a {
  color: var(--color-body);
  display: inline-block;
  line-height: 22px;
}

.blog-meta li a:hover {
  color: var(--color-primary);
}

.blog-meta li svg {
  margin-right: 13px;
  font-size: 22px;
}

/*---------------------------
    Blog Sidebar Styles  
----------------------------*/

.rbt-categori-leftbar {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-categori-leftbar {
    gap: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-categori-leftbar {
    gap: 10px;
    flex-wrap: nowrap;
  }
}

.rbt-categori-list {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-categori-list {
    gap: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-categori-list {
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.rbt-categori-list a {
  border: 0 none;
  padding: 0px 35px;
  text-transform: capitalize;
  background: var(--color-white);
  color: var(--color-body);
  box-shadow: var(--shadow-1);
  height: 65px;
  line-height: 65px;
  border-radius: 500px;
  font-size: 18px;
  letter-spacing: -0.6px;
  transition: 0.4s;
  display: inline-block;
  min-width: 100px;
  transition: 0.4s;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-categori-list a {
    padding: 0px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-categori-list a {
    padding: 0px 10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-categori-list a {
    padding: 0px 20px;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    min-width: max-content;
  }
}

.rbt-categori-list a.blank {
  box-shadow: 19px 20px 34px 0 rgba(164, 160, 196, 0.19);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .rbt-categori-list a.blank {
    display: none;
  }
}

.rbt-categori-list a i {
  margin-right: 6px;
}

.rbt-categori-list a:hover {
  background: var(--color-primary);
  color: var(--color-white);
  transform: scale(1.1);
}

.rbt-categori-list.medium-size a {
  padding: 0px 25px;
  font-size: 16px;
  height: 50px;
  line-height: 49px;
}

.meta-list {
  display: flex;
  align-items: center;
  margin: -10px;
  padding: 0;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meta-list {
    margin: -5px;
  }
}

@media only screen and (max-width: 767px) {
  .meta-list {
    margin: -5px;
  }
}

.meta-list li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 18px;
  padding: 10px;
  font-size: var(--font-size-b3);
}

.meta-list li a:hover {
  color: var(--color-primary) !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meta-list li {
    padding: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .meta-list li {
    padding: 5px;
  }
}

.meta-list li i {
  display: inline-block;
  margin-right: 6px;
  font-size: 16px;
}

.meta-list li .author-thumbnail {
  max-width: 45px;
  border-radius: 100%;
  height: 45px;
  margin-right: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meta-list li .author-thumbnail {
    margin-right: 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .meta-list li .author-thumbnail {
    margin-right: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .meta-list li .author-thumbnail {
    margin-right: 6px;
  }
}

.meta-list li .author-thumbnail img {
  border-radius: 100%;
  width: 100%;
  object-fit: contain;
}

.meta-list li .author-info a {
  display: inline-block;
  transition: 0.3s;
}

.meta-list li span {
  display: inline-block;
}

/*----------------------------
    Blog Details Styles  
----------------------------*/

.blog-content-wrapper audio {
  display: block;
  width: 100%;
}

.blog-content-wrapper .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.blog-content-wrapper iframe {
  border: 0;
  width: 100%;
  border-radius: 10px;
}

.blog-content-wrapper iframe.square {
  border-radius: 0;
}

.blog-content-wrapper .post-thumbnail img {
  width: 100%;
}

.blog-content-wrapper p {
  line-height: 1.67;
}

.blog-content-wrapper p a {
  color: var(--color-primary);
}

.blog-content-wrapper .tagcloud {
  margin-bottom: 30px;
}

.blog-content-wrapper .social-share-block {
  border-bottom: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blog-content-wrapper .social-share-block .post-like {
  padding-bottom: 20px;
}

.blog-content-wrapper .social-share-block .post-like a {
  display: flex;
  align-items: center;
}

.blog-content-wrapper .social-share-block .post-like a i {
  width: 40px;
  height: 40px;
  line-height: 34px;
  border: 2px solid var(--color-border);
  color: var(--color-body);
  border-radius: 100%;
  text-align: center;
  margin-right: 20px;
}

.blog-content-wrapper .social-share-block .post-like a span {
  font-size: 16px;
  line-height: 1.5;
}

.blog-content-wrapper .social-share-block .post-like a:hover i {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.blog-content-wrapper .social-share-block .post-like a:hover span {
  color: var(--color-primary);
}

.blog-content-wrapper .social-share-block .social-icon {
  padding-bottom: 20px;
}

.blog-content-wrapper iframe,
.blog-content-wrapper .rbt-blockquote {
  border-radius: var(--radius) !important;
}

.blog-content-wrapper iframe.square,
.blog-content-wrapper .rbt-blockquote.square {
  border-radius: 0 !important;
}

.tagcloud {
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
}

.tagcloud a {
  border: 1px solid var(--color-border);
  font-size: 14px;
  color: var(--color-body);
  height: 30px;
  padding: 0 10px;
  margin: 5px;
  display: inline-block;
  line-height: 27px;
  border-radius: 4px;
}

.tagcloud a:hover {
  background-color: var(--color-primary);
  color: #ffffff;
  border-color: var(--color-primary);
}

.about-author {
  padding: 40px 0;
  border-bottom: 1px solid var(--color-border);
}

.about-author .media {
  display: flex;
}

@media only screen and (max-width: 479px) {
  .about-author .media {
    display: block;
  }
}

.about-author .thumbnail a img {
  border-radius: 6px;
  margin-right: 30px;
  margin-bottom: 20px;
  min-width: 105px;
  max-height: 105px;
}

@media only screen and (max-width: 767px) {
  .about-author .thumbnail a img {
    margin-right: 20px;
  }
}

.about-author .media-body .author-info .title {
  margin-bottom: 0;
}

.about-author .media-body .author-info .title a .hover-flip-item span::before {
  color: var(--color-heading);
}

.about-author .media-body .author-info .title a .hover-flip-item span::after {
  color: var(--color-primary);
}

.about-author .media-body .author-info .subtitle {
  color: var(--color-body);
  display: block;
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .about-author .media-body .author-info .subtitle {
    margin-top: 8px;
  }
}

.about-author .media-body .content .description {
  margin-top: 6px;
  margin-bottom: 0;
}

.about-author .media-body .content .social-icon {
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .rbt-instructor .about-author .media {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-instructor .about-author .thumbnail {
    margin-bottom: 30px;
  }
}

/*------------------------
    Comment Post Total  
---------------------------*/

.rbt-total-comment-post {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid var(--color-border);
}

@media only screen and (max-width: 575px) {
  .rbt-total-comment-post {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-total-comment-post .add-comment-button {
    margin-top: 20px;
  }
}

/*------------------------
    Comment Form  
-------------------------*/

.comment-respond {
  margin: 40px 0;
  position: relative;
}

.comment-respond .comment-form-cookies-consent {
  margin-bottom: 20px;
}

.comment-respond .form-group label {
  font-size: 16px;
}

/* --------------------------
    Comments Styles  
-----------------------------*/

.comment-list ul.children {
  padding-left: 75px;
}

@media only screen and (max-width: 767px) {
  .comment-list ul.children {
    padding-left: 20px;
  }
}

.comment-list .comment {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid var(--color-border);
}

.comment-list .comment:first-child {
  border-top: transparent;
}

.comment-list .comment .children .comment {
  border-top: 1px solid var(--color-border);
}

.comment-list .comment .single-comment {
  padding: 20px 0;
  display: flex;
}

@media only screen and (max-width: 575px) {
  .comment-list .comment .single-comment {
    display: block;
  }
}

.comment-list .comment .single-comment .comment-img {
  margin-bottom: 15px;
  min-width: 70px;
  margin-right: 20px;
  max-width: 70px;
}

.comment-list .comment .single-comment .comment-img img {
  border-radius: 100%;
  width: 100%;
  background: var(--color-white);
  padding: 4px;
  border: 2px solid var(--primary-opacity);
}

.comment-list .comment .commenter {
  margin-bottom: 15px;
  line-height: 18px;
}

.comment-list .comment .commenter a .hover-flip-item span::before {
  color: var(--color-heading);
}

.comment-list .comment .commenter a .hover-flip-item span::after {
  color: var(--color-primary);
}

.comment-list .comment .comment-meta {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.comment-list .comment .time-spent {
  color: var(--color-heading);
  font-size: 12px;
  line-height: 18px;
}

.comment-list .comment .reply-edit a.comment-reply-link {
  font-size: 14px;
  line-height: 18px;
  display: flex;
  color: var(--color-primary);
  margin-left: 8px;
  padding-left: 8px;
  position: relative;
  font-weight: 500;
  overflow: visible;
}

.comment-list .comment .reply-edit a.comment-reply-link .hover-flip-item span::before {
  color: var(--color-heading);
}

.comment-list .comment .reply-edit a.comment-reply-link .hover-flip-item span::after {
  color: var(--color-primary);
}

.comment-list .comment .reply-edit a.comment-reply-link:hover {
  color: var(--color-primary);
}

.comment-list .comment .reply-edit a.comment-reply-link::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: -2px;
  width: 4px;
  height: 4px;
  background: var(--color-heading);
  border-radius: 100%;
}

.comment-list .comment .comment-text p {
  color: var(--color-tertiary);
}

/*---------------------------
    Unit Test Blog Details  
-----------------------------*/

.wp-block-quote,
blockquote,
.wp-block-coblocks-click-to-tweet,
.wp-block-pullquote blockquote,
.wp-block-quote.is-style-large,
.wp-block-quote.is-large,
blockquote.has-text-align-right,
.wp-block-quote.has-text-align-right {
  padding: 40px 40px 40px 100px;
  position: relative;
  font-size: 24px;
  line-height: 1.4;
  background: transparent;
  border-radius: 5px;
  margin: 30px 0;
  font-weight: 500;
  color: var(--color-heading);
  z-index: 1;
  border: 1px solid var(--color-border);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .wp-block-quote,
  blockquote,
  .wp-block-coblocks-click-to-tweet,
  .wp-block-pullquote blockquote,
  .wp-block-quote.is-style-large,
  .wp-block-quote.is-large,
  blockquote.has-text-align-right,
  .wp-block-quote.has-text-align-right {
    padding: 20px;
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {

  .wp-block-quote,
  blockquote,
  .wp-block-coblocks-click-to-tweet,
  .wp-block-pullquote blockquote,
  .wp-block-quote.is-style-large,
  .wp-block-quote.is-large,
  blockquote.has-text-align-right,
  .wp-block-quote.has-text-align-right {
    padding: 20px;
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {

  .wp-block-quote,
  blockquote,
  .wp-block-coblocks-click-to-tweet,
  .wp-block-pullquote blockquote,
  .wp-block-quote.is-style-large,
  .wp-block-quote.is-large,
  blockquote.has-text-align-right,
  .wp-block-quote.has-text-align-right {
    margin: 20px 0;
  }
}

.wp-block-quote::before,
blockquote::before,
.wp-block-coblocks-click-to-tweet::before,
.wp-block-pullquote blockquote::before,
.wp-block-quote.is-style-large::before,
.wp-block-quote.is-large::before,
blockquote.has-text-align-right::before,
.wp-block-quote.has-text-align-right::before {
  position: absolute;
  content: "";
  top: 40px;
  left: 30px;
  z-index: -1;
  /* background-image: url(../images/shape/quote.svg); */
  background-repeat: no-repeat, repeat;
  width: 52px;
  height: 44px;
  transform: rotate(180deg);
  filter: grayscale(1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .wp-block-quote::before,
  blockquote::before,
  .wp-block-coblocks-click-to-tweet::before,
  .wp-block-pullquote blockquote::before,
  .wp-block-quote.is-style-large::before,
  .wp-block-quote.is-large::before,
  blockquote.has-text-align-right::before,
  .wp-block-quote.has-text-align-right::before {
    top: 7px;
    left: 0;
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {

  .wp-block-quote::before,
  blockquote::before,
  .wp-block-coblocks-click-to-tweet::before,
  .wp-block-pullquote blockquote::before,
  .wp-block-quote.is-style-large::before,
  .wp-block-quote.is-large::before,
  blockquote.has-text-align-right::before,
  .wp-block-quote.has-text-align-right::before {
    top: 7px;
    left: 0;
    font-size: 48px;
  }
}

.wp-block-quote p,
blockquote p,
.wp-block-coblocks-click-to-tweet p,
.wp-block-pullquote blockquote p,
.wp-block-quote.is-style-large p,
.wp-block-quote.is-large p,
blockquote.has-text-align-right p,
.wp-block-quote.has-text-align-right p {
  margin-bottom: 0;
  font-style: normal;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
  color: var(--color-heading);
}

.wp-block-quote cite,
blockquote cite,
.wp-block-coblocks-click-to-tweet cite,
.wp-block-pullquote blockquote cite,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-large cite,
blockquote.has-text-align-right cite,
.wp-block-quote.has-text-align-right cite {
  margin-top: 10px;
}

/**************************************
	Shop Styles
***************************************/

/*------------------------
    Shop Styles  
--------------------------*/

.filter-select select {
  border: 0 none;
  box-shadow: var(--shadow-1);
  width: auto;
  min-width: 250px;
}

/*---------------------------
    Product Details 
----------------------------*/

.pro-qty {
  max-width: 120px;
  height: 50px;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 0;
  display: flex;
  margin: auto;
  min-width: 50px;
  align-items: center;
}

.pro-qty .qtybtn {
  width: 45px;
  display: block;
  float: left;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-body);
  height: 50px;
}

.pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 33px;
  line-height: 33px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}

@media only screen and (max-width: 575px) {
  .pro-qty {
    margin: 0;
  }
}

.rbt-single-product .product-action {
  display: inline-flex;
}

@media only screen and (max-width: 575px) {
  .rbt-single-product .product-action {
    display: block;
  }
}

.rbt-single-product .pro-qty {
  margin-right: 20px;
  height: 60px;
}

@media only screen and (max-width: 575px) {
  .rbt-single-product .pro-qty {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.rbt-single-product .product-feature {
  margin-top: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-single-product .product-feature {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .rbt-search-style-1 input {
    width: 200px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-single-product .product-feature {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-single-product .product-feature {
    margin-top: 10px;
  }
}

.rbt-single-product .product-feature li {
  font-weight: 400;
  margin: 5px 0;
}

.rbt-single-product .product-feature li span {
  font-weight: 700;
  color: var(--color-heading);
}

.rbt-single-product .product-feature li a {
  position: relative;
  display: inline-block;
  padding: 3px;
  color: var(--color-body);
}

.rbt-single-product .product-feature li a::after {
  position: absolute;
  content: ",";
  right: -3px;
}

.rbt-single-product .product-feature li a:hover {
  color: var(--color-primary);
}

.rbt-single-product .product-feature li a:last-child:after {
  display: none;
}

.product-description-nav {
  border-bottom: 1px solid #eeeeee;
  justify-content: center;
  margin-bottom: 35px;
}

.product-description-nav .nav-item {
  margin: 0 25px;
}

.product-description-nav .nav-item button.nav-link {
  background: transparent;
  border: 0 none;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  padding: 0;
  padding-bottom: 15px;
  color: var(--color-heading);
  position: relative;
  transition: 0.4s;
}

.product-description-nav .nav-item button.nav-link::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  background: var(--color-primary);
  transition: 0.4s;
  width: 0;
}

.product-description-nav .nav-item button.nav-link:hover,
.product-description-nav .nav-item button.nav-link.active {
  color: var(--color-primary);
}

.product-description-nav .nav-item button.nav-link:hover::after,
.product-description-nav .nav-item button.nav-link.active::after {
  width: 100%;
}

.product-description-content p:last-child {
  margin-bottom: 0;
}

.product-description-content .comment-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.product-description-content .notification-text .title {
  font-size: 16px;
  line-height: 26px;
  margin-right: 10px;
}

.product-description-content .comment-list .comment .commenter {
  margin-bottom: 10px;
}

.product-description-content .comment-list .comment .comment-meta {
  margin-bottom: 16px;
}

/*----------------------
    My Account  
------------------------*/

.rbt-my-account-tab-button {
  flex-direction: column;
  background-color: #fff;
  box-shadow: var(--shadow-1);
  border: 0 none;
  border-radius: 6px;
  overflow: hidden;
}

.rbt-my-account-tab-button a {
  border: 1px solid var(--color-border);
  border-bottom: none;
  color: var(--color-body);
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding: 20px 25px;
  border-right-color: transparent;
  border-left-color: transparent;
}

.rbt-my-account-tab-button a:last-child {
  border-bottom: 1px solid transparent;
}

.rbt-my-account-tab-button a:first-child {
  border-top: 1px solid transparent;
}

.rbt-my-account-tab-button a.active,
.rbt-my-account-tab-button a:hover {
  background-color: var(--color-primary);
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
  color: #fff;
  background-size: 300% 100%;
}

.rbt-my-account-tab-button a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

.rbt-my-account-inner {
  background-color: #fff;
  font-size: 14px;
  border: 0 none;
  padding: 35px 30px 40px;
  box-shadow: var(--shadow-1);
  border-radius: 6px;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .rbt-my-account-inner {
    padding: 20px 15px;
  }
}

.rbt-my-account-inner h3 {
  border-bottom: 1px dashed var(--color-border);
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.rbt-my-account-inner .about-address a {
  color: var(--color-body);
}

.rbt-my-account-inner .about-address a:hover {
  color: var(--color-primary);
}

.rbt-my-account-table {
  white-space: nowrap;
  font-size: 15px;
}

.rbt-my-account-table .table th,
.rbt-my-account-table table th {
  padding: 10px;
  font-weight: 600;
}

.rbt-my-account-table .table td,
.rbt-my-account-table table td {
  padding: 20px 10px;
  vertical-align: middle;
}

.rbt-my-account-table .table td a:hover,
.rbt-my-account-table table td a:hover {
  color: #fff;
}

.rbt-saved-message {
  border-top: 2px solid var(--color-primary);
  border-radius: 0;
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
  background: #fff;
  box-shadow: var(--shadow-1);
}

.account-details-form h4 {
  margin: 0;
}

.account-details-form input {
  height: 50px;
}

.table> :not(:first-child) {
  border-top: 0;
}

/*---------------------------------------
    11. Cart
-----------------------------------------*/

.cart-submit-btn-group {
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
}

.cart-submit-btn-group .single-button {
  padding: 10px;
}

.rbt-cart-area .section-title .title {
  border-bottom: 1px dashed var(--color-border);
  padding-bottom: 10px;
}

.cart-table .table {
  border-bottom: 8px solid var(--color-border);
  margin: 0;
}

@media only screen and (max-width: 575px) {
  .cart-table .table {
    border-top: 8px solid transparent;
  }
}

.cart-table .table thead {
  background-color: var(--color-primary);
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
  background-size: 300% 100%;
}

@media only screen and (max-width: 575px) {
  .cart-table .table thead {
    display: none;
  }
}

.cart-table .table thead tr th {
  text-align: center;
  border: none;
  font-size: 18px;
  text-transform: inherit;
  font-weight: 500;
  color: var(--color-white);
  padding: 12px 20px;
}

.cart-table .table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}

.cart-table .table thead tr th:last-child {
  border-radius: 0 6px 6px 0;
}

.cart-table .table tbody tr {
  transition: 0.4s;
}

.cart-table .table tbody tr:hover {
  box-shadow: var(--shadow-1);
}

.cart-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 20px;
  vertical-align: middle;
  border-bottom: 1px solid var(--color-border);
  padding-left: 0;
}

@media only screen and (max-width: 575px) {
  .cart-table .table tbody tr td {
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px;
    text-align: left;
  }
}

.cart-table .table> :not(:first-child) {
  border-top: 0 none;
}

.cart-table th.pro-thumbnail,
.cart-table td.pro-thumbnail {
  max-width: 160px;
  min-width: 120px;
  width: 160px;
}

@media only screen and (max-width: 575px) {

  .cart-table th.pro-thumbnail a,
  .cart-table td.pro-thumbnail a {
    width: 160px;
  }
}

.cart-table th.pro-title,
.cart-table td.pro-title {
  min-width: 200px;
}

.cart-table td.pro-thumbnail a {
  display: block;
}

.cart-table td.pro-thumbnail a img {
  width: 100%;
  background-color: #f6f7f8;
  border-radius: 6px;
}

.cart-table td.pro-title a {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-heading);
  transition: 0.4s;
}

.cart-table td.pro-title a:hover {
  color: var(--color-primary);
}

.cart-table td.pro-price span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: var(--color-body);
}

.cart-table td.pro-quantity .pro-qty {
  max-width: 120px;
  height: 50px;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 0;
  display: flex;
  margin: auto;
  min-width: 50px;
  align-items: center;
}

.cart-table td.pro-quantity .pro-qty .qtybtn {
  width: 45px;
  display: block;
  float: left;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-body);
  height: 50px;
}

.cart-table td.pro-quantity .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 33px;
  line-height: 33px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}

@media only screen and (max-width: 575px) {
  .cart-table td.pro-quantity .pro-qty {
    margin: 0;
  }
}

.cart-table td.pro-subtotal span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: var(--color-primary);
}

.cart-table td.pro-addtocart button {
  width: 140px;
  border-radius: 50px;
  height: 36px;
  line-height: 24px;
  padding: 5px 20px;
  text-transform: capitalize;
}

.cart-table .pro-remove a {
  display: block;
  font-weight: 600;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  line-height: 56px;
  z-index: 1;
}

.cart-table .pro-remove a::after {
  background: var(--color-gray-light);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.4s;
  opacity: 0;
  transform: scale(0.8);
  border-radius: 100%;
  z-index: -1;
}

.cart-table .pro-remove a:hover::after {
  opacity: 1;
  transform: scale(1);
}

.cart-table .pro-remove a i {
  font-size: 24px;
}

@media only screen and (max-width: 575px) {
  .cart-table .pro-remove a {
    width: 60px;
    text-align: center;
  }
}

/*-- Calculate Shipping --*/

.calculate-shipping {
  margin-bottom: 23px;
}

.calculate-shipping form .nice-select {
  width: 100%;
  border-radius: 6px;
  height: 45px;
  border: 1px solid var(--color-border);
  line-height: 24px;
  padding: 5px 20px;
  background-color: transparent;
}

.calculate-shipping form .nice-select::after {
  border-color: var(--color-body);
}

.calculate-shipping form .nice-select .current {
  display: block;
  line-height: 24px;
  font-size: 14px;
  color: var(--color-body);
}

.calculate-shipping form input {
  width: 100%;
  border-radius: 6px;
  height: 45px;
  border: 1px solid var(--color-border);
  line-height: 24px;
  padding: 5px 20px;
  color: var(--color-body);
  background-color: transparent;
}

.calculate-shipping form input[type="submit"] {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  width: 140px;
}

/*-- Discount Coupon --*/

.discount-coupon form input {
  width: 100%;
  border-radius: 6px;
  height: 45px;
  border: 1px solid var(--color-border);
  line-height: 24px;
  padding: 5px 20px;
  color: var(--color-body);
  background-color: transparent;
}

.discount-coupon form input[type="submit"] {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  width: 140px;
}

/*-- Cart Summary --*/

.cart-summary .cart-summary-wrap {
  background-color: var(--color-white);
  padding: 45px 50px;
  margin-bottom: 30px;
  box-shadow: var(--shadow-1);
  border-radius: 6px;
}

@media only screen and (max-width: 479px) {
  .cart-summary .cart-summary-wrap {
    padding: 25px 30px;
  }
}

.cart-summary .cart-summary-wrap p {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: var(--color-body);
  margin: 0;
  padding: 12px 0;
}

.cart-summary .cart-summary-wrap p span {
  float: right;
}

.cart-summary .cart-summary-wrap p+p {
  border-top: 1px dashed var(--color-border);
}

.cart-summary .cart-summary-wrap h2 {
  border-top: 2px solid var(--color-border);
  padding-top: 14px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  color: var(--color-heading);
  margin: 0;
  margin-top: 20px;
}

.cart-summary .cart-summary-wrap h2 span {
  float: right;
}

.cart-summary .cart-summary-button {
  overflow: hidden;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

.cart-summary .cart-summary-button button {
  margin-top: 10px;
  width: 140px;
  border-radius: 50px;
  height: 36px;
  border: 1px solid var(--color-border);
  line-height: 24px;
  padding: 5px 20px;
  color: var(--color-body);
  background-color: transparent;
  margin-left: 20px;
  float: right;
}

.cart-summary .cart-summary-button button:last-child {
  margin-left: 0;
}

.cart-summary .cart-summary-button button.checkout-btn {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button button {
    margin-left: 0;
    margin-right: 20px;
  }

  .cart-summary .cart-summary-button button:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-button button {
    margin-left: 0;
    margin-right: 10px;
  }

  .cart-summary .cart-summary-button button:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .cart-summary .cart-summary-button button {
    width: 130px;
  }
}

/*---------------------------------------
    13. Checkout
-----------------------------------------*/

.checkout-title {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}

.checkout-content-wrapper {
  background: var(--color-white);
  box-shadow: var(--shadow-1);
  padding: 30px;
  border-radius: 6px;
}

/*-- Checkout Form --*/

.checkout-form label,
.rbt-default-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-heading);
}

.checkout-form .nice-select,
.rbt-default-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: var(--color-body);
  margin-bottom: 15px;
}

.checkout-form .nice-select::after,
.rbt-default-form .nice-select::after {
  width: 6px;
  height: 6px;
  border-width: 1px;
  right: 20px;
  border-color: var(--color-body);
}

.checkout-form .nice-select .current,
.rbt-default-form .nice-select .current {
  color: var(--color-body);
  display: block;
  line-height: 23px;
}

.checkout-form .nice-select .list,
.rbt-default-form .nice-select .list {
  width: 100%;
}

.checkout-form input,
.rbt-default-form input {
  width: 100%;
  background-color: transparent;
  border: 2px solid var(--color-border);
  border-radius: 6px;
  line-height: 23px;
  padding: 10px 18px;
  font-size: 14px;
  color: var(--color-body);
  margin-bottom: 15px;
}

.checkout-form input[type="checkbox"],
.rbt-default-form input[type="checkbox"] {
  width: auto;
}

.checkout-form .check-box,
.rbt-default-form .check-box {
  float: left;
  margin-right: 70px;
}

.checkout-form .check-box:last-child,
.rbt-default-form .check-box:last-child {
  margin-right: 0;
}

.custom-file-input {
  height: auto;
  border: 2px dotted #c2c2c2 !important;
}

input[type="file"]::file-selector-button {
  border: none;
  background: #000;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

/*-- Shipping Form --*/

#shipping-form {
  display: none;
}

/*-- Checkout Cart Total --*/

.checkout-cart-total {
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  padding: 30px;
  position: relative;
  border-radius: 6px;
}

@media only screen and (max-width: 479px) {
  .checkout-cart-total {
    padding: 30px 19px;
  }
}

.checkout-cart-total h4 {
  flex-basis: 18px;
  line-height: 23px;
  font-weight: 700;
}

.checkout-cart-total h4:first-child {
  margin-top: 0;
  margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
  margin-top: 15px;
  margin-bottom: 0;
}

.checkout-cart-total h4 span {
  float: right;
  display: block;
}

.checkout-cart-total ul {
  border-bottom: 1px solid var(--color-border);
  padding: 0;
  margin: 0;
}

.checkout-cart-total ul li {
  color: var(--color-body);
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
  display: block;
  margin-bottom: 16px;
}

.checkout-cart-total ul li span {
  color: var(--color-body);
  float: right;
}

.checkout-cart-total p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: var(--color-body);
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.checkout-cart-total p span {
  float: right;
}

/*-- Checkout Payment Method --*/

.checkout-payment-method {
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  padding: 30px;
  position: relative;
  border-radius: 6px;
}

@media only screen and (max-width: 479px) {
  .checkout-payment-method {
    padding: 30px;
  }
}

/*-- Single Payment Method --*/

.single-method {
  margin-bottom: 20px;
}

.single-method:last-child {
  margin-bottom: 0;
}

.single-method p {
  display: none;
  margin-top: 8px;
  font-size: 14px;
  color: var(--color-heading);
  line-height: 23px;
}

/*-- Place Order --*/

.place-order {
  background-color: var(--color-heading);
  border: medium none;
  border-radius: 50px;
  color: #fff;
  float: left;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  line-height: 24px;
  margin-top: 40px;
  padding: 6px 20px;
  text-transform: uppercase;
  width: 158px;
}

/*---------------------
    Minicart Style  
---------------------*/

.rbt-minicart-wrapper {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rbt-minicart-wrapper .minicart-item {
  display: flex;
  position: relative;
  margin: 0;
  margin-bottom: 20px;
}

.rbt-minicart-wrapper .minicart-item .close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 575px) {
  .rbt-minicart-wrapper .minicart-item .close-btn {
    position: static;
  }
}

.rbt-minicart-wrapper .minicart-item .close-btn button {
  font-size: 14px;
  top: 10px;
  position: relative;
}

.rbt-minicart-wrapper .minicart-item:last-child {
  margin-bottom: 0;
}

.rbt-minicart-wrapper .minicart-item+.minicart-item {
  border-top: 1px dashed var(--color-border);
  padding-top: 20px;
}

.rbt-minicart-wrapper .thumbnail {
  flex-shrink: 0;
}

.rbt-minicart-wrapper .thumbnail a {
  display: block;
}

.rbt-minicart-wrapper .thumbnail a img {
  width: 80px;
  height: 80px;
  border-radius: var(--radius);
}

.rbt-minicart-wrapper .product-content {
  flex-grow: 1;
  padding-left: 20px;
}

.rbt-minicart-wrapper .product-content .title {
  font-size: 14px;
  margin-bottom: 10px;
}

.rbt-minicart-wrapper .product-content .quantity {
  font-size: 14px;
  color: var(--color-body);
  display: block;
}

.rbt-cart-side-menu .inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rbt-cart-side-menu .side-nav {
  max-height: 50vh;
  overflow-y: auto;
  flex-grow: 1;
}

.rbt-cart-side-menu .rbt-cart-subttotal {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.rbt-cart-side-menu .rbt-cart-subttotal p {
  margin-bottom: 0;
  color: var(--color-heading);
  font-weight: 700;
  font-size: 18px;
}

/**************************************
	Template Styles
***************************************/

/*------------------------------
 * HiStudy Banner Styles
---------------------------------*/

.active-header-transparent .rbt-banner-area {
  padding-top: 100px;
}

.height-350 {
  height: 350px;
}

.height-245 {
  height: 245px;
}

.height-850 {
  height: 850px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .height-850 {
    height: auto;
  }
}

.height-650 {
  height: 650px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .height-650 {
    height: auto;
  }
}

.height-750 {
  height: 750px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-750 {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .height-750 {
    height: auto;
  }
}

.height-950 {
  height: 950px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-950 {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .height-950 {
    height: auto;
  }
}

.rbt-display-1 {
  font-size: 80px !important;
  line-height: 1.1 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-display-1 {
    font-size: 70px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-display-1 {
    font-size: 60px !important;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-display-1 {
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-display-1 {
    font-size: 28px !important;
  }
}

.rbt-display-2 {
  font-size: 53px !important;
  line-height: 1.3 !important;
}

@media only screen and (max-width: 767px) {
  .rbt-display-2 {
    font-size: 42px;
  }
}

/*-------------------------------------------------------
    Banner Style Home One And Home Five (variation-2)
-------------------------------------------------------------*/

.rbt-banner-1 {
  background-color: var(--color-light);
  padding: 0 150px;
  position: relative;
  overflow: hidden;
  /* background-image: url(../images/bg/main_bg.svg); */
  background-position: top center;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-1 {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1 {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1 {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1 {
    padding: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1::after {
    height: 142px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1::after {
    height: 109px;
  }
}

.rbt-banner-1 .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1 .content {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1 .content {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1 .content {
    display: block;
  }
}

.rbt-banner-1 .content .inner {
  position: relative;
  z-index: 1;
}

.rbt-banner-1 .content .inner .title {
  font-size: 64px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .rbt-banner-1 .content .inner .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-1 .content .inner .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1 .content .inner .title {
    font-size: 54px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1 .content .inner .title {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1 .content .inner .title {
    font-size: 42px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-1 .content .inner .title {
    font-size: 32px;
  }
}

.rbt-banner-1 .content .inner .description {
  color: var(--color-body);
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 400;
  padding-right: 24px;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .rbt-banner-1 .content .inner .description {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-1 .content .inner .description {
    padding-right: 18%;
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1 .content .inner .description {
    padding-right: 26%;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-1 .content .inner .description {
    font-size: 16px;
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.rbt-banner-1 .content .shape-wrapper {
  margin-top: 20px;
  z-index: 2;
  margin-bottom: 6px;
  position: relative;
  left: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1 .content .shape-wrapper {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1 .content .shape-wrapper {
    left: -76px;
    bottom: -14px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1 .content .shape-wrapper {
    display: none;
  }
}

.rbt-banner-1 .content .shape-wrapper img {
  max-width: none;
  height: auto;
  max-width: 534px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1 .content .shape-wrapper img {
    max-width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1 .content .shape-wrapper img {
    max-width: 450px;
  }
}

.rbt-banner-1 .content .shape-wrapper .hero-bg-shape-1 {
  position: absolute;
  top: 112px !important;
  left: 5px !important;
  z-index: -3;
}

.rbt-banner-1 .content .shape-wrapper .hero-bg-shape-1 img {
  max-width: 100%;
  height: auto;
}

.rbt-banner-1 .content .shape-wrapper .hero-bg-shape-2 {
  position: absolute;
  top: 112px !important;
  left: 70px !important;
  z-index: -2;
}

.rbt-banner-1 .content .shape-wrapper .hero-bg-shape-2 img {
  max-width: 100%;
  height: auto;
}

.rbt-banner-1 .content .banner-card {
  position: relative;
  max-width: 390px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1 .content .banner-card {
    position: absolute;
    max-width: 350px;
    bottom: 12%;
    right: 7%;
    z-index: 2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1 .content .banner-card {
    position: absolute;
    max-width: 290px;
    bottom: 12%;
    right: 40px;
    z-index: 2;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1 .content .banner-card {
    position: relative;
    max-width: 320px;
    bottom: 12%;
    right: 40px;
    z-index: 2;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-1 .content .banner-card {
    right: 0;
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1.variation-2 {
    padding: 0 60px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1.variation-2 {
    padding: 0 15px;
    padding-top: 60px;
    padding-bottom: 50px;
  }
}

.rbt-banner-1.variation-2::after {
  display: none;
}

.rbt-banner-1.variation-2 .content {
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1.variation-2 .content {
    padding-right: 10%;
  }
}

.rbt-banner-1.variation-2 .content .banner-card {
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1.variation-2 .content .banner-card {
    position: static;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1.variation-2 .content .banner-card {
    position: static;
    margin-top: 40px;
  }
}

.rbt-banner-1.variation-2 .content .inner .title {
  font-size: 60px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 29px;
  padding-right: 10%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-1.variation-2 .content .inner .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1.variation-2 .content .inner .title {
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1.variation-2 .content .inner .title {
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1.variation-2 .content .inner .title {
    font-size: 30px;
    padding-right: 0;
    margin-bottom: 15px;
  }
}

.rbt-banner-1.variation-2 .content .inner .description {
  padding-right: 17%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-1.variation-2 .content .inner .description {
    padding-right: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-1.variation-2 .content .inner .description {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-1.variation-2 .content .inner .description {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-1.variation-2 .content .inner .description {
    font-size: 18px;
  }
}

.rbt-banner-1.variation-2 .content .inner .rbt-new-badge.rbt-new-badge-one::before {
  box-shadow: none;
}

/*------------------------------
    Banner Style Home Two  
-------------------------------*/

.rbt-banner-2 {
  background-color: var(--color-light);
  position: relative;
  /* background-image: url(../images/bg/services_bg.svg); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.rbt-banner-2 .banner-content .inner {
  position: relative;
  z-index: 1;
}

.rbt-banner-2 .banner-content .inner .title {
  font-size: 90px;
  font-weight: 600;
  letter-spacing: -2.5px;
  max-width: 950px;
  line-height: 1.06;
  margin: 0 auto;
  margin-bottom: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-2 .banner-content .inner .title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-2 .banner-content .inner .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-2 .banner-content .inner .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-2 .banner-content .inner .title {
    font-size: 35px;
    line-height: 1.3;
  }
}

.rbt-banner-2 .service-wrapper {
  position: relative;
}

.rbt-banner-2 .service-wrapper .rbt-service.rbt-service-2 {
  position: relative;
  top: 145px;
}

.rbt-banner-2 .wrapper {
  padding-top: 50px;
  padding-bottom: 100px;
}

/*-----------------------
    Home 03 Banner 
------------------------*/

.rbt-banner-3 {
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.rbt-banner-3 .wrapper {
  padding-top: 90px;
  padding-bottom: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-3 .wrapper {
    padding-top: 50px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .wrapper {
    padding-top: 50px;
    padding-bottom: 80px;
  }
}

.rbt-banner-3 .banner-content .inner .section-title {
  margin-bottom: 10px;
}

.rbt-banner-3 .banner-content .inner .title {
  font-size: 75px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-3 .banner-content .inner .title {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-3 .banner-content .inner .title {
    font-size: 45px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .banner-content .inner .title {
    font-size: 42px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-3 .banner-content .inner .title {
    font-size: 36px;
  }
}

.rbt-banner-3 .banner-content .inner .description {
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 26px;
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .banner-content .inner .description {
    font-size: 18px;
    margin-bottom: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .banner-content .inner .profile-share {
    display: block;
  }
}

.rbt-banner-3 .banner-content .inner .profile-share a img {
  max-width: 60px;
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .banner-content .inner .profile-share .more-author-text {
    margin-left: 0;
    margin-top: 20px;
  }
}

.rbt-banner-3 .top-shape {
  position: absolute;
  z-index: -1;
  top: -5px;
  left: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-3 .top-shape {
    left: -100px;
  }
}

.rbt-banner-3 .left-shape {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-3 .left-shape {
    bottom: -120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-3 .left-shape {
    width: 75%;
  }
}

.rbt-banner-3 .rbt-contact-form.contact-form-style-1 {
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-3 .rbt-contact-form.contact-form-style-1 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .rbt-contact-form.contact-form-style-1 {
    margin-left: 0;
  }
}

.rbt-banner-3 .edumarque {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  /* background-image: url("../images/banner/histudy-text.png"); */
  background-repeat: repeat-x;
  background-size: auto;
  height: 206px;
  width: 52%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-3 .edumarque {
    height: 275px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-3 .edumarque {
    width: 72%;
  }
}

/*-----------------------
    Home 04 Banner  
------------------------*/

.rbt-banner-4 {
  background-color: var(--color-light);
  position: relative;
  /* background-image: url(../images/bg/how-it-work-bg.svg); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

/*--------------------------------
    Banner Home 06 && 12   
--------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-5 {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 {
    padding: 100px 0;
  }
}

.rbt-banner-5 .inner .subtitle {
  font-size: 18px;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 18px;
  color: var(--color-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 .inner .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 .inner .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-5 .inner .subtitle {
    font-size: 14px;
  }
}

.rbt-banner-5 .inner .title {
  margin-bottom: 25px;
  display: inline-block;
  color: var(--color-white);
  font-size: 70px;
  padding: 0 25%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-5 .inner .title {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-5 .inner .title {
    max-width: 100%;
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 .inner .title {
    font-size: 40px;
    padding: 0 17%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 .inner .title {
    font-size: 42px;
    padding: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-5 .inner .title {
    font-size: 32px;
  }
}

.rbt-banner-5 .inner .title span {
  display: inline-block;
}

.rbt-banner-5 .inner .title span label {
  margin-bottom: 0;
}

.rbt-banner-5 .inner .description {
  font-family: var(--font-primary);
  padding: 0 22%;
  font-size: 24px;
  line-height: 1.7;
  margin-bottom: 40px;
  color: var(--color-white);
  opacity: 0.7;
  letter-spacing: -0.5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-5 .inner .description {
    padding: 0 14%;
    font-size: 22px;
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 .inner .description {
    padding: 0 1%;
    font-size: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 .inner .description {
    padding: 0 1%;
    font-size: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-5 .inner .description {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.rbt-banner-5 .inner .list-icon li {
  color: var(--color-body);
}

.rbt-banner-5 .inner .list-icon li .icon {
  background-color: var(--color-blackest) !important;
}

.rbt-banner-5 .inner .header-caption span {
  color: var(--color-white);
  font-size: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-5 .inner .header-caption span {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 .inner .header-caption span {
    font-size: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 .inner .header-caption span {
    font-size: 65px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-5 .inner .header-caption span {
    font-size: 46px;
  }
}

.rbt-banner-5 .inner.text-start .title {
  padding: 0;
  max-width: 36%;
  font-size: 53px;
  line-height: 1.3;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-5 .inner.text-start .title {
    max-width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-5 .inner.text-start .title {
    max-width: 71%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 .inner.text-start .title {
    max-width: 69%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 .inner.text-start .title {
    max-width: 100%;
    font-size: 34px;
  }
}

.rbt-banner-5 .inner.text-start .description {
  padding: 0;
  max-width: 33%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-5 .inner.text-start .description {
    padding: 0;
    max-width: 48%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-5 .inner.text-start .description {
    padding: 0;
    max-width: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-5 .inner.text-start .description {
    max-width: 100%;
    font-size: 18px;
  }
}

/*--------------------------------------
    Banner Home 07 && 09 && 13 
------------------------------------------*/

.rbt-banner-6 .inner .rbt-badge {
  background: rgba(0, 0, 0, 0.45);
  color: var(--color-white);
}

.rbt-banner-6 .inner .subtitle {
  font-size: 18px;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 32px;
  color: var(--color-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-6 .inner .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-6 .inner .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-6 .inner .subtitle {
    font-size: 14px;
  }
}

.rbt-banner-6 .inner .title {
  margin-bottom: 25px;
  display: inline-block;
  color: var(--color-white);
  font-size: 70px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-6 .inner .title {
    font-size: 60px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-6 .inner .title {
    margin-bottom: 0;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-6 .inner .title {
    margin-bottom: 0;
    font-size: 42px;
    word-wrap: break-word;
    width: 100%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-6 .inner .title {
    font-size: 42px;
  }
}

.rbt-banner-6 .inner .title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbt-banner-6 .inner .title span b {
  word-wrap: break-word;
}

.rbt-banner-6.variation-01 {
  height: 950px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .rbt-banner-6.variation-01 {
    height: auto;
  }
}

.rbt-banner-6.variation-01 .wrapper {
  padding-bottom: 150px;
  padding-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-6.variation-01 .wrapper {
    padding-bottom: 250px;
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-6.variation-01 .wrapper {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-6.variation-01 .wrapper {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

.rbt-banner-6.variation-01 .inner .title {
  max-width: 60%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-6.variation-01 .inner .title {
    max-width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-6.variation-01 .inner .title {
    max-width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-6.variation-01 .inner .title {
    max-width: 100%;
    font-size: 32px;
  }
}

.rbt-banner-6.variation-02 {
  height: 950px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .rbt-banner-6.variation-02 {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-6.variation-02 .wrapper {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-6.variation-02 .wrapper {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-6.variation-02 .wrapper {
    padding: 80px 0;
  }
}

.rbt-banner-6.variation-03 {
  height: 750px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .rbt-banner-6.variation-03 {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-6.variation-03 .wrapper {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-6.variation-03 .wrapper {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-6.variation-03 .wrapper {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-6.variation-03 .inner .title {
    font-size: 32px;
  }
}

/*-----------------------
    Banner Home 08
------------------------*/

.rbt-banner-7 {
  height: 850px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .rbt-banner-7 {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-7 .wrapper {
    padding: 100px 0;
    margin-top: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-7 .wrapper {
    padding: 100px 0;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-7 .wrapper {
    padding: 100px 0;
    margin-top: -20px;
  }
}

.rbt-banner-7 .inner .title {
  margin-bottom: 34px;
  font-size: 53px;
  line-height: 1.3;
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-7 .inner .title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-7 .inner .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-7 .inner .title {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-7 .inner .title {
    font-size: 28px;
  }
}

.rbt-banner-7 .thumbnail-wrapper {
  position: relative;
}

.rbt-banner-7 .thumbnail-wrapper .card-info {
  display: inline-block;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .rbt-banner-7 .thumbnail-wrapper .card-info {
    position: static;
    margin-top: 20px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-7 .profile-share {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-7 .profile-share .more-author-text {
    margin-left: 0;
    margin-top: 20px;
  }
}

/*--------------------------------------
    Banner Home 10 && 14 && 15 &&  about 
----------------------------------------*/

.rbt-banner-8 .inner .meta-text {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-8 .inner .meta-text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8 .inner .meta-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8 .inner .meta-text {
    font-size: 15px;
  }
}

.rbt-banner-8 .inner .rbt-badge-2 {
  margin-left: 20px;
}

@media only screen and (max-width: 575px) {
  .rbt-banner-8 .inner .rbt-badge-2 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-8 .inner .rbt-badge-group {
    display: block;
  }
}

.rbt-banner-8 .inner .title {
  margin-bottom: 15px;
  font-size: 82px;
  line-height: 1.3;
  margin-top: 15px;
  font-weight: 900;
  letter-spacing: -0.5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-8 .inner .title {
    font-size: 54px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8 .inner .title {
    font-size: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8 .inner .title {
    font-size: 42px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-8 .inner .title {
    font-size: 38px;
    margin-top: 20px;
  }
}

.rbt-banner-8.variation-01 {
  height: 650px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .rbt-banner-8.variation-01 {
    height: auto;
  }
}

.rbt-banner-8.variation-01 .wrapper {
  padding-bottom: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-banner-8.variation-01 .wrapper {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-8.variation-01 .wrapper {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8.variation-01 .wrapper {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.variation-01 .wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.rbt-banner-8.variation-02 {
  padding: 100px 0;
}

.rbt-banner-8.variation-02 .inner .title {
  font-size: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8.variation-02 .inner .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.variation-02 .inner .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-8.variation-02 .inner .title {
    font-size: 36px;
  }
}

.rbt-banner-8.variation-02 .inner .description {
  padding: 0 26%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-8.variation-02 .inner .description {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8.variation-02 .inner .description {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.variation-02 .inner .description {
    padding: 0;
  }
}

.rbt-banner-8.variation-03 .inner .banner-title {
  font-size: 70px;
  line-height: 1;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8.variation-03 .inner .banner-title {
    font-size: 57px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.variation-03 .inner .banner-title {
    font-size: 48px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-8.variation-03 .inner .banner-title {
    font-size: 36px;
  }
}

.rbt-banner-8.variation-03 .inner .description {
  padding: 0 19%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-8.variation-03 .inner .description {
    padding: 0 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8.variation-03 .inner .description {
    padding: 0 12%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.variation-03 .inner .description {
    padding: 0;
  }
}

.rbt-banner-8.variation-03 .inner .follow-us-text {
  color: var(--color-body);
  margin-top: 40px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}

.rbt-banner-8.variation-03 .team .content .title {
  font-size: var(--h4);
  line-height: 1.25;
}

.rbt-banner-8.variation-03 .rbt-dot-bottom-center {
  padding-top: 10px;
}

.rbt-banner-8.section-bottom-overlay .wrapper {
  padding-top: 100px;
  padding-bottom: 350px;
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.section-bottom-overlay .wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.rbt-banner-8.with-shape {
  padding-bottom: 300px !important;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-8.with-shape {
    padding-bottom: 130px !important;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.with-shape {
    padding-bottom: 130px !important;
  }
}

.rbt-banner-8.with-shape .shape-image {
  position: absolute;
  bottom: 0;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.3;
}

@media only screen and (max-width: 767px) {
  .rbt-banner-8.with-shape .shape-image {
    width: 89%;
  }
}

.banner-overlay-section {
  position: relative;
  margin-top: -278px;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .banner-overlay-section {
    margin-top: 0;
    padding-top: 80px;
  }
}

.rbt-banner-area .social-default.transparent-with-border li a {
  color: var(--color-white);
  border: 2px solid var(--white-opacity);
}

.rbt-banner-area .follow-us-text {
  color: var(--color-white);
  font-size: 14px;
  opacity: 0.7;
  display: block;
  margin-top: 10px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

/* Gif Banner Area  */

.rbt-gif-banner-area {
  position: relative;
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  z-index: 2;
}

.rbt-gif-banner-area .thumbnail img {
  outline: none;
  max-height: 600px;
  object-fit: cover;
}

/*-----------------------
    Banner Style 9  
------------------------*/

.rbt-banner-9 {
  justify-content: center;
}

.rbt-banner-9 .inner .title {
  font-size: 100px;
  line-height: 1.1;
  padding: 0 10%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-9 .inner .title {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-9 .inner .title {
    font-size: 60px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-9 .inner .title {
    font-size: 46px;
    padding: 0;
  }
}

.rbt-banner-9 .inner .description {
  font-size: 22px;
  line-height: 43px;
  padding: 0 24%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-banner-9 .inner .description {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-9 .inner .description {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-9 .inner .description {
    font-size: 20px;
    line-height: 38px;
    padding: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-banner-9 .inner .description {
    font-size: 18px;
    line-height: 30px;
    padding: 0;
  }
}

/*-----------------------
    Banner Style 10 
------------------------*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-banner-10 {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-banner-10 {
    padding: 80px 0;
  }
}

/*----------------------
 tool tip 
------------------------*/

.profile-share {
  display: flex;
  align-items: center;
}

.profile-share a {
  position: relative;
  z-index: 1;
  transition: var(--transition);
}

.profile-share a:nth-child(2) {
  margin-left: -12px;
}

.profile-share a:nth-child(3) {
  margin-left: -12px;
}

.profile-share a img {
  max-width: 60px;
  border-radius: 50%;
  border: 3px solid var(--color-white);
  transition: var(--transition);
  box-shadow: var(--shadow-1);
}

.profile-share a.more-author-text {
  color: var(--color-body);
  display: inline-block;
  padding-left: 7px;
}

.profile-share a.more-author-text:hover {
  transform: none;
  color: var(--color-primary);
}

.profile-share a:hover {
  z-index: 2;
  transform: translateY(-3px) scale(1.1);
}

.profile-share .more-author-text {
  margin-left: 20px;
}

.profile-share .more-author-text .total-join-students {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.profile-share .more-author-text .subtitle {
  font-size: 16px;
  letter-spacing: -0.5px;
}

.avatar {
  position: relative;
}

.avatar::after,
.avatar::before {
  --scale: 0;
  --arrow-size: 8px;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.avatar::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: var(--color-white);
  padding: 5px 8px;
  background: var(--color-primary);
  width: max-content;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  margin: 1px;
}

.avatar::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--color-primary);
}

.avatar:hover::before,
.avatar:hover::after {
  --scale: 1;
}

.rbt-slider-animation .inner>* {
  animation-name: fadeOutUp;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.rbt-slider-animation .swiper-slide-active .inner>* {
  animation-name: fadeInUp;
}

.rbt-slider-animation .swiper-slide-active .inner .section-title {
  animation-delay: 0.5s;
}

.rbt-slider-animation .swiper-slide-active .inner .title {
  animation-delay: 1s;
}

.rbt-slider-animation .swiper-slide-active .inner .button-group {
  animation-delay: 1.2s;
}

.rbt-slider-animation .swiper-slide-active .inner .social-share-wrapper {
  animation-delay: 1.5s;
}

/*------------------------
    Swiper Thumb  
-------------------------*/

.rbt-swiper-thumb {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
}

.rbt-swiper-thumb .swiper-wrapper {
  justify-content: center;
}

.rbt-swiper-thumb .swiper-slide {
  display: inline-block;
  line-height: 100%;
  height: 80px;
  width: 80px !important;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 4px;
  transition: 0.4s;
}

.rbt-swiper-thumb .swiper-slide::before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background: var(--color-white);
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

.rbt-swiper-thumb .swiper-slide::after {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  transition: 0.4s;
}

.rbt-swiper-thumb .swiper-slide.swiper-slide-thumb-active::before {
  background: linear-gradient(90deg,
      var(--color-secondary),
      var(--color-primary));
}

.rbt-swiper-thumb .swiper-slide.swiper-slide-thumb-active::after {
  opacity: 0;
}

.rbt-swiper-thumb .swiper-slide img {
  object-fit: cover;
  height: 100%;
  border-radius: 100%;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/*-------------------------
    Course Details  
--------------------------*/

/*-------------------------
    Single Course Meta  
--------------------------*/

.rbt-single-course-meta .rbt-course-review {
  margin-left: 15px;
}

/*---------------------------
    Video Course Content  
----------------------------*/

.rbt-course-main-content li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 575px) {
  .rbt-course-main-content li a {
    display: block;
  }
}

.rbt-course-main-content li:first-child {
  margin-top: 0;
}

.rbt-course-main-content li:last-child {
  margin-bottom: 0;
}

.rbt-course-main-content .course-content-left {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .rbt-course-main-content .course-content-left {
    margin-bottom: 12px;
  }
}

.rbt-course-main-content .course-content-left i {
  padding-right: 6px;
}

.rbt-course-main-content .course-content-left span {
  font-size: 16px;
}

.rbt-course-main-content .course-content-right {
  margin: 0 -5px;
  margin-bottom: -10px;
}

.rbt-course-main-content .course-content-right span {
  display: inline-block;
  margin: 0 5px;
  margin-bottom: 10px;
}

.rbt-course-main-content .course-content-right .min-lable,
.rbt-course-main-content .course-content-right .course-lock {
  font-size: 16px;
}

.rbt-course-main-content .course-content-right.only-lock {
  margin: 0;
  margin-bottom: -10px;
}

.about-author-list .about-author:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .rbt-instructor .about-author .media {
    display: block;
  }
}

.rbt-instructor .about-author .thumbnail a img {
  margin-bottom: 0;
  min-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 100%;
}

@media only screen and (max-width: 575px) {
  .rbt-instructor .about-author .thumbnail a img {
    margin-bottom: 0;
    min-width: 100%;
    max-height: initial;
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-instructor .about-author .media-body {
    margin-top: 20px;
  }
}

.rbt-instructor .about-author .media-body .rbt-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.rbt-instructor .about-author .media-body .rbt-meta li {
  display: flex;
  align-items: center;
}

.video-course-content li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*----------------------------
    Rbt rating Styles  
------------------------------*/

.rating-box {
  border-radius: 5px;
  background: var(--warning-opacity);
  text-align: center;
  padding: 22px 10px;
  padding-top: 10px;
}

.rating-box .rating-number {
  font-weight: 800;
  font-size: 66px;
  line-height: 80px;
  color: var(--color-heading);
}

.rating-box span {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.rating-box .rating svg {
  color: var(--color-warning);
}

.rating-box .sub-title {
  color: var(--color-warning);
}

.rating-text {
  display: inline-block;
  position: relative;
  top: 14px;
}

@media only screen and (max-width: 767px) {
  .rating-text {
    top: 0;
    margin-bottom: 8px;
  }
}

.rating-text svg {
  color: var(--color-warning);
}

.review-wrapper .single-progress-bar {
  position: relative;
  top: -14px;
}

@media only screen and (max-width: 767px) {
  .review-wrapper .single-progress-bar {
    margin-bottom: 15px;
  }
}

.review-wrapper .progress {
  max-width: 70%;
  margin-left: 115px;
  height: 6px;
  background: #eeeeee;
  border-radius: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .review-wrapper .progress {
    max-width: 61%;
  }
}

@media only screen and (max-width: 767px) {
  .review-wrapper .progress {
    max-width: 83%;
    margin-left: 0;
  }
}

.review-wrapper .progress .progress-bar {
  background-color: var(--color-warning);
  border-radius: 0;
}

.review-wrapper span.value-text {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 500;
  font-size: 16px;
}

/*---------------------------------
    Featured Review List Wrapper  
-----------------------------------*/

.rbt-course-review.about-author {
  padding: 25px 0;
}

.rbt-course-review.about-author:first-child {
  padding-top: 0;
}

.rbt-course-review.about-author .rating {
  margin-top: 5px;
  margin-bottom: 10px;
}

/*---------------------------
    Course Top  
----------------------------*/

.rbt-course-top-wrapper .select-label {
  opacity: 0.8;
  color: var(--color-heading);
}

.rbt-course-top-wrapper .default-exp-wrapper {
  border-top: 1px solid var(--color-border-2);
  margin-top: 30px;
}

.rbt-course-top-wrapper .default-exp-wrapper .filter-inner {
  padding-bottom: 0;
}

.rbt-course-top-wrapper .default-exp-wrapper .filter-inner .bootstrap-select {
  width: 100% !important;
  max-width: 245px;
}

.rbt-course-top-wrapper .default-exp-wrapper.top-border-less {
  border: 0 none;
  margin-top: 0;
}

.rbt-course-top-wrapper .price__output--wrap .price--output input,
.rbt-course-top-wrapper .price__output--wrap .price--output span {
  opacity: 0.8;
  color: var(--color-heading);
}

.rbt-course-top-wrapper .ui-widget-content {
  background: var(--white-opacity) none repeat scroll 0 0;
}

.rbt-sorting-list {
  margin: -10px -10px;
}

.rbt-sorting-list .rbt-short-item {
  margin: 10px 10px;
}

.rbt-sorting-list .course-switch-layout {
  display: flex;
  align-items: center;
  background: var(--white-opacity);
  border-radius: 500px;
  padding: 8px;
  margin: 0;
}

.rbt-sorting-list .course-switch-layout li {
  margin: 0;
}

.rbt-sorting-list .course-switch-layout li button {
  background: transparent;
  display: block;
  padding: 7px 16px;
  border-radius: 500px;
  font-size: 16px;
  transition: 0.4s;
  border: 0 none;
  box-shadow: none;
}

.rbt-sorting-list .course-switch-layout li button i {
  font-size: 14px;
  transition: 0.4s;
}

.rbt-sorting-list .course-switch-layout li button.active {
  background: var(--color-white);
  color: var(--color-primary);
}

.rbt-sorting-list .course-switch-layout li button.active i {
  color: var(--color-primary);
}

.rbt-short-item .course-index {
  color: var(--color-heading);
  font-size: 16px;
}

/*-------------------------
    Course Grid Column  
---------------------------*/

.rbt-course-grid-column {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: -30px;
}

.rbt-course-grid-column .course-grid-3,
.rbt-course-grid-column .course-grid-2,
.rbt-course-grid-column .course-grid-4 {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 30px;
}

.rbt-course-grid-column .course-grid-3 .rbt-card,
.rbt-course-grid-column .course-grid-2 .rbt-card,
.rbt-course-grid-column .course-grid-4 .rbt-card {
  transition: 0.4s;
}

.rbt-course-grid-column .course-grid-4 {
  flex: 0 0 auto;
  width: 25%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-course-grid-column .course-grid-4 {
    width: 33.33%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-course-grid-column .course-grid-4 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-course-grid-column .course-grid-4 {
    width: 100%;
  }
}

.rbt-course-grid-column .course-grid-3 {
  flex: 0 0 auto;
  width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-course-grid-column .course-grid-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-course-grid-column .course-grid-3 {
    width: 100%;
  }
}

.rbt-course-grid-column .course-grid-2 {
  flex: 0 0 auto;
  width: 50%;
}

.rbt-course-grid-column.active-list-view .course-grid-3,
.rbt-course-grid-column.active-list-view .course-grid-2 {
  width: 100%;
}

.rbt-course-grid-column.list-column-half.active-list-view .course-grid-3,
.rbt-course-grid-column.list-column-half.active-list-view .course-grid-2,
.rbt-course-grid-column.list-column-half.active-list-view .course-grid-4 {
  width: 50%;
}

@media only screen and (max-width: 767px) {

  .rbt-course-grid-column.list-column-half.active-list-view .course-grid-3,
  .rbt-course-grid-column.list-column-half.active-list-view .course-grid-2,
  .rbt-course-grid-column.list-column-half.active-list-view .course-grid-4 {
    width: 100%;
  }
}

.rbt-course-grid-column .rbt-card .rbt-card-img {
  transition: none;
}

.rbt-course-grid-column .rbt-card .rbt-card-img a {
  transition: none;
}

.rbt-course-grid-column .rbt-card .rbt-card-img a img {
  transition: none;
}

/*------------------------- 
Course Sidebar 
-------------------------*/

.course-sidebar-top {
  margin-top: -500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .course-sidebar-top {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .course-sidebar-top {
    margin-top: 0;
  }
}

.course-sidebar .subtitle {
  font-size: 14px;
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.course-sidebar .social-share-wrapper {
  background: #f5f5fa;
  margin: 0 -27px -27px;
  padding: 30px;
  border-radius: 0 0 6px 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course-sidebar .social-share-wrapper {
    margin: 0 -10px -10px;
    padding: 20px;
    border-radius: 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .course-sidebar .social-share-wrapper {
    margin: 0 -10px -10px;
    padding: 20px;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .course-sidebar .social-share-wrapper {
    margin: 0 -10px -10px;
    padding: 20px;
    border-radius: 6px;
  }
}

.course-sidebar .social-share-wrapper .rbt-post-share span {
  font-size: 16px;
}

.course-sidebar .contact-with-us p {
  margin-bottom: 0;
  font-size: 14px;
}

/* Course Video Wrapper  */

.video-popup-with-text.video-popup-wrapper {
  position: relative;
  display: block;
  z-index: 2;
}

.video-popup-with-text.video-popup-wrapper .play-view-text {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.video-popup-with-text.video-popup-wrapper .position-to-top {
  z-index: 2;
}

.video-popup-with-text.video-popup-wrapper::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black 100%);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  border-radius: 6px;
}

.rbt-widget-details .rbt-course-details-list-wrapper li,
.rbt-widget-details .rbt-default-sidebar-list li,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li {
  display: flex;
  justify-content: space-between;
}

.rbt-widget-details .rbt-course-details-list-wrapper li i,
.rbt-widget-details .rbt-default-sidebar-list li i,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li i,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li i {
  color: var(--color-body);
  margin-right: 10px;
  font-size: 16px;
}

.rbt-widget-details .rbt-course-details-list-wrapper li span,
.rbt-widget-details .rbt-default-sidebar-list li span,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li span,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li span {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.rbt-widget-details .rbt-course-details-list-wrapper li span.rbt-feature-value,
.rbt-widget-details .rbt-default-sidebar-list li span.rbt-feature-value,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li span.rbt-feature-value,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li span.rbt-feature-value {
  font-size: 12px;
}

.rbt-widget-details .rbt-course-details-list-wrapper li a,
.rbt-widget-details .rbt-default-sidebar-list li a,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li a,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li a {
  color: var(--color-body);
  transition: 0.4s;
}

.rbt-widget-details .rbt-course-details-list-wrapper li a:hover,
.rbt-widget-details .rbt-default-sidebar-list li a:hover,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li a:hover,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li a:hover {
  color: var(--color-primary);
}

.rbt-widget-details .rbt-course-details-list-wrapper li+li,
.rbt-widget-details .rbt-default-sidebar-list li+li,
.rbt-default-sidebar-wrapper .rbt-course-details-list-wrapper li+li,
.rbt-default-sidebar-wrapper .rbt-default-sidebar-list li+li {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid var(--color-border);
}

/*------------------------
    Contact Styles 
--------------------------*/

.rbt-address {
  padding: 40px;
  background: var(--color-white);
  padding-bottom: 50px;
  transition: 0.3s;
  border-radius: var(--radius);
  overflow: hidden;
  box-shadow: var(--shadow-1);
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-address {
    padding: 30px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-address {
    padding: 30px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-address {
    padding: 30px;
    padding-bottom: 40px;
  }
}

.rbt-address .icon {
  color: var(--color-primary);
  border-radius: 100%;
  font-size: 33px;
  display: inline-block;
  margin-bottom: 12px;
}

.rbt-address .icon i {
  font-size: 40px;
}

.rbt-address .inner h4.title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.rbt-address .inner p {
  margin: 0;
}

.rbt-address .inner p a {
  margin: 0;
  color: var(--color-body);
}

/*-----------------------
    Course Sidebar  
------------------------*/

/*-----------------------------
    Rbt Sidebar Common Style 
--------------------------------*/

.rbt-check-group {
  position: relative;
}

.rbt-check-group label {
  display: flex;
  justify-content: space-between;
}

.rbt-tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.rbt-tag-list a {
  border: 0 none;
  padding: 0px 19px;
  text-transform: capitalize;
  background: var(--color-white);
  color: var(--color-body);
  box-shadow: var(--shadow-1);
  height: 36px;
  line-height: 36px;
  border-radius: 500px;
  font-size: 14px;
  display: block;
  transition: 0.4s;
  text-align: center;
  margin: 0 5px;
  margin-bottom: 10px;
}

.rbt-tag-list a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/*---------------------------
    Rbt Show More Button  
------------------------------*/

.rbt-show-more-btn {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-primary);
  display: block;
  margin-top: 5px;
  position: relative;
  padding: 3px 0;
  cursor: pointer;
}

.rbt-show-more-btn::before {
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
  position: absolute;
  content: "";
  left: 0;
  bottom: 100%;
  width: 100%;
  height: 80px;
}

.rbt-show-more-btn::after {
  content: "\e92e";
  position: absolute;
  font-family: "feather";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.rbt-show-more-btn.active::before {
  background: none;
  display: none;
}

.rbt-show-more-btn.active::after {
  content: "\e931";
}

.has-show-more .has-show-more-inner-content {
  max-height: 300px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.has-show-more .rbt-course-details-list-wrapper {
  max-height: 230px;
}

.has-show-more .rbt-featured-review-list-wrapper {
  max-height: 550px;
}

.has-show-more.active .has-show-more-inner-content {
  max-height: inherit !important;
}

.filter-top-2 .has-show-more .has-show-more-inner-content {
  max-height: 120px;
}

/*---------------------------------
    Rbt Sidebar Widget Wrapper
-----------------------------------*/

.rbt-sidebar-widget-wrapper {
  box-shadow: var(--shadow-1);
  padding: 30px;
  border-radius: var(--radius);
  background: var(--color-white);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  color: var(--color-heading);
}

.rbt-sidebar-widget-wrapper.with-card-style {
  box-shadow: none;
  border-radius: inherit;
  background: inherit;
  padding: 0;
}

.rbt-sidebar-widget-wrapper.with-card-style::before {
  display: none;
}

.rbt-sidebar-widget-wrapper.with-card-style .rbt-single-widget {
  padding: 30px;
  height: 100%;
  border-radius: var(--radius);
  background: var(--color-white);
  box-shadow: var(--shadow-1);
}

/*------------------------
    Rbt Single Widget  
-------------------------*/

.rbt-single-widget {
  background: var(--color-white);
  padding-top: 30px;
}

.rbt-single-widget:first-child {
  padding-top: 0;
}

.rbt-single-widget .rbt-widget-title {
  font-size: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--color-border);
  margin-bottom: 15px;
}

.rbt-single-widget .rbt-widget-title-2 {
  font-size: 12px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--color-border);
  margin-bottom: 15px;
  color: var(--color-heading);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

.rbt-single-widget ul.rbt-sidebar-list-wrapper li+li {
  margin-top: 10px;
}

.rbt-single-widget ul.rbt-sidebar-list-wrapper.recent-post-list li+li {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid var(--color-border-2);
}

.rbt-single-widget input[type="checkbox"]~label,
.rbt-single-widget input[type="radio"]~label {
  padding-left: 23px;
}

.rbt-single-widget .rating {
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.rbt-single-widget .rating .off {
  color: var(--color-body);
  opacity: 0.4;
}

.rbt-single-widget .rbt-lable {
  background: var(--color-gray-light);
  padding: 5px 7px;
  font-size: 12px;
  display: flex;
  height: 22px;
  align-items: center;
  border-radius: var(--radius-small);
}

.rbt-single-widget .recent-post-list li {
  display: flex;
  align-items: center;
}

.rbt-single-widget .recent-post-list li .thumbnail {
  margin-right: 12px;
}

.rbt-single-widget .recent-post-list li .thumbnail a {
  display: block;
}

.rbt-single-widget .recent-post-list li .thumbnail a img {
  height: 60px;
  width: 60px;
  border-radius: var(--radius);
  object-fit: cover;
  min-width: 60px;
  min-height: 60px;
}

.rbt-single-widget .recent-post-list li .content .title {
  margin-bottom: 12px;
  font-size: 14px;
}

.rbt-single-widget .recent-post-list li .content .rbt-meta li {
  font-size: 12px;
}

.rbt-single-widget .recent-post-list li .content .rbt-meta li i {
  margin-right: 5px;
}

.rbt-course-action-bottom {
  background-color: var(--color-white);
  box-shadow: var(--shadow-1);
  position: fixed;
  bottom: -75px;
  left: 0;
  right: 0;
  z-index: 99;
  overflow: hidden;
  padding: 10px 0;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .rbt-course-action-bottom {
    bottom: -128px;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-course-action-bottom {
    bottom: -150px;
  }
}

.rbt-course-action-bottom.rbt-course-action-active {
  bottom: 0 !important;
}

@media only screen and (max-width: 767px) {
  .rbt-course-action-bottom.rbt-course-action-active {
    bottom: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-course-action-bottom.rbt-course-action-active {
    bottom: 0 !important;
  }
}

.rbt-course-action-bottom .course-action-bottom-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .rbt-course-action-bottom .course-action-bottom-right {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-course-action-bottom .rbt-price.large-size .current-price {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-course-action-bottom .rbt-price.large-size .current-price {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-course-action-bottom .rbt-price.large-size .off-price {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-course-action-bottom .rbt-price.large-size .off-price {
    font-size: 13px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-course-action-bottom .rbt-price {
    display: none;
  }
}

/*-----------------------------------
    Instructor Dashboard Style  
----------------------------------*/

.tutor-bg-photo {
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.tutor-bg-photo::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  display: block;
  z-index: -1;
  content: "";
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.5) 90%);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  border-radius: 6px;
}

.rbt-dashboard-content-wrapper {
  position: relative;
  margin-bottom: 30px;
}

.rbt-tutor-information {
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: absolute;
  bottom: 30px;
  padding: 0 40px;
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .rbt-tutor-information {
    padding: 0 30px;
    display: block;
  }
}

.rbt-tutor-information .rbt-tutor-information-left {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .rbt-tutor-information .rbt-tutor-information-left {
    display: block;
  }
}

.rbt-tutor-information .rbt-tutor-information-left .thumbnail {
  margin-right: 20px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .rbt-tutor-information .rbt-tutor-information-left .thumbnail {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.rbt-tutor-information .rbt-tutor-information-left .tutor-content .title {
  margin-bottom: 8px;
  color: var(--color-white);
}

.rbt-tutor-information .rbt-tutor-information-left .tutor-content .rbt-review .rating-count {
  color: var(--color-white);
}

@media only screen and (max-width: 767px) {
  .rbt-tutor-information .rbt-tutor-information-right {
    margin-top: 20px;
  }
}

/*----------------------------
    Instructor Dashboard  
------------------------------*/

@media only screen and (max-width: 767px) {
  .rbt-dashboard-table .rbt-table {
    min-width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-table-750 .rbt-table {
    min-width: 750px;
  }
}

/**************************************
	Footer Styles
***************************************/

/*===========================
    Start Footer Area 
=============================*/

/*-----------------------
    Footer Style One  
-------------------------*/

a.facebook i {
  background-color: #3255d6 !important;
  display: block;
  padding: 8px;
  color: #fff;
  border-radius: 50px;
}

a.instagram i {
  background: radial-gradient(circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%);
  display: block;
  padding: 8px;
  color: #fff;
  border-radius: 50px;
}

.footer-style-1 .footer-top {
  padding-top: 60px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-style-1 .footer-top {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.footer-style-1 .ft-title {
  color: var(--color-white);
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-style-1 .ft-title {
    font-size: 18px;
  }
}

.footer-style-1 .description {
  font-size: 16px;
  line-height: 25px;
}

.footer-style-1 .newsletter-form input {
  min-width: 100%;
}

.footer-style-1 .newsletter-form .right-icon {
  display: block;
}

.footer-style-1 .newsletter-form .right-icon input {
  padding-right: 30px;
}

.footer-style-1 .newsletter-form .right-icon::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "feather" !important;
}

.footer-style-1 .newsletter-form .right-icon.icon-email::after {
  content: "\e98a";
}

.footer-widget .ft-link li {
  margin: 0;
  color: var(--color-body);
  font-size: 16px;
  line-height: 25px;
}

.footer-widget .ft-link li span {
  font-weight: 500;
  color: var(--color-footer);
}

.footer-widget .ft-link li a {
  color: var(--color-footer);
  display: inline-block;
  position: relative;
  font-size: var(--font-size-b3);
}

.footer-widget .ft-link li a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s;
}

.footer-widget .ft-link li a:hover {
  color: var(--color-primary);
}

.footer-widget .ft-link li a:hover:after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.footer-widget .ft-link li+li {
  margin-top: 10px;
}

.footer-widget .form-group label {
  font-size: 16px;
  line-height: 31px;
}

/* Footer Style Three  */

.footer-style-2 .logo {
  margin-bottom: 30px;
  position: relative;
}

.footer-style-2 .text p {
  margin-top: 30px;
}

.footer-style-2 .text p a {
  transition: 0.3s;
}

.footer-style-2 .text p a:hover {
  color: var(--color-primary);
}

/*----------------------
    Copyright Styles  
-----------------------*/

.copyright-style-1 p {
  color: var(--color-white);
  font-size: 16px;
  line-height: 25px;
}

.copyright-style-1 .copyright-link {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.copyright-style-1 .copyright-link li {
  font-size: 16px;
  line-height: 25px;
  padding: 0 10px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}

.copyright-style-1 .copyright-link li a {
  color: var(--color-body);
  transition: 0.4s;
}

.copyright-style-1 .copyright-link li a:hover {
  color: var(--color-primary);
}

.copyright-style-1 .copyright-link li+li::after {
  position: absolute;
  content: "";
  height: 14px;
  width: 1px;
  background: var(--color-border);
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
}

/*-------------------------
    Back To Top  
---------------------------*/

/*----------------------------
    BG Color Darker  
------------------------------*/

.bg-color-darker {
  --dark-border: #525260;
}

.bg-color-darker input[type="text"],
.bg-color-darker input[type="password"],
.bg-color-darker input[type="email"],
.bg-color-darker input[type="number"],
.bg-color-darker input[type="tel"],
.bg-color-darker textarea,
.bg-color-darker select {
  color: var(--color-white-off);
}

.bg-color-darker .rbt-information-list li a {
  color: var(--color-white-off);
}

.bg-color-darker .rbt-information-list li a:hover {
  color: var(--color-white);
}

.bg-color-darker .social-share-transparent li a {
  color: var(--color-white-off);
}

.bg-color-darker .social-share-transparent li a:hover {
  color: var(--color-white);
}

.bg-color-darker .quick-access>li>a {
  color: var(--color-white-off);
}

.bg-color-darker .rbt-header-top-news .inner .content .news-text,
.bg-color-darker ul.rbt-dropdown-menu>li>a,
.bg-color-darker .rbt-search-with-category .search-field .serach-btn,
.bg-color-darker .rbt-secondary-menu li a {
  color: var(--color-white-off);
}

.bg-color-darker .rbt-btn.btn-border-gradient {
  box-shadow: none;
}

.bg-color-darker .rbt-search-with-category {
  border-color: var(--dark-border);
}

.bg-color-darker .rbt-search-with-category .search-by-category::after,
.bg-color-darker .rbt-separator::after {
  background: var(--dark-border);
}

.bg-color-darker .rbt-round-btn::after,
.bg-color-darker .social-share-transparent li a::before {
  background: var(--white-opacity);
}

/*----------------------------
    Slider Default Style 
------------------------------*/

.slider-fixed--height {
  width: 100%;
  padding-top: 110px;
  padding-bottom: 75px;
}

.rbt-splash-slider {
  background-color: var(--color-light);
  padding: 100px 150px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
  /* background-image: url(../images/splash/bg/bg-2.png); */
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splash-slider {
    padding: 100px 50px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-slider {
    padding: 100px 50px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-slider {
    padding: 100px 50px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-slider {
    padding: 60px 15px;
    padding-bottom: 60px;
  }
}

.rbt-splash-slider::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  /* background: url(../images/bg/banner-bg-shape-1.svg); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 145px;
  z-index: 9;
}

.rbt-splash-slider .banner-top {
  display: flex;
  margin: 0 -40px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splash-slider .banner-top {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-splash-slider .banner-top {
    display: block;
    margin-top: -20px;
  }
}

.rbt-splash-slider .banner-top .banner-badge-top {
  margin: 0 40px;
}

@media only screen and (max-width: 575px) {
  .rbt-splash-slider .banner-top .banner-badge-top {
    margin-top: 20px;
  }
}

.rbt-splash-slider .banner-top .banner-badge-top .icon {
  margin-bottom: 8px;
}

.rbt-splash-slider .banner-top .banner-badge-top .icon img,
.rbt-splash-slider .banner-top .banner-badge-top .icon svg {
  max-height: 24px;
}

.rbt-splash-slider .banner-top .banner-badge-top .rating i {
  font-size: 16px;
}

.rbt-splash-slider .banner-top .banner-badge-top .subtitle {
  font-size: 16px;
  color: var(--color-heading);
  /* font-family: var(--font-primary); */
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 767px) {
  .rbt-splash-slider .banner-top .banner-badge-top .subtitle {
    font-size: 14px;
  }
}

.rbt-splash-slider .inner {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .rbt-splash-slider .inner {
    margin-top: 40px;
  }
}

.rbt-splash-slider .inner .title {
  font-size: 72px;
  line-height: 1.2;
  margin-bottom: 20px;
  padding-right: 10%;
  color: var(--color-heading);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splash-slider .inner .title {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-slider .inner .title {
    font-size: 60px;
    padding-right: 0;
  }

  .rbt-splash-slider .inner .title br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-slider .inner .title {
    font-size: 40px;
    padding-right: 0;
  }

  .rbt-splash-slider .inner .title br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-slider .inner .title {
    font-size: 40px;
    padding-right: 0;
  }

  .rbt-splash-slider .inner .title br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-splash-slider .inner .title {
    font-size: 34px;
  }
}

.rbt-splash-slider .inner .description {
  color: var(--color-body);
  font-size: 18px;
  font-weight: 400;
  /* font-family: var(--font-primary); */
}

.rbt-splash-slider .inner .description strong {
  position: relative;
}

.rbt-splash-slider .inner .description strong::after {
  position: absolute;
  background: var(--color-body);
  width: 100%;
  height: 2px;
  content: "";
  bottom: -5px;
  left: 0;
  opacity: 0.3;
}

.rbt-splash-slider .splash-service-main {
  margin-top: 80px;
  border-radius: 10px;
}

.rbt-splash-slider .splash-service-main .bg-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.rbt-splash-slider .splash-service-main .bg-shape img {
  width: 100%;
}

.rbt-splash-slider .service-wrapper {
  position: relative;
  z-index: 10;
  background: rgba(39, 0, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(15px);
  background-image: linear-gradient(218.15deg, #b966e763 0%, #2f57ef4a 100%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-slider .service-wrapper.service-white {
    margin-top: 64px;
  }
}

.rbt-splash-slider .shape-image {
  position: absolute;
}

.rbt-splash-slider .shape-image.shape-1 {
  bottom: 0;
  left: -250px;
}

.rbt-splash-slider .shape-image.shape-2 {
  bottom: 300px;
  right: 0;
}

.rbt-splash-slider .shape-image.shape-3 {
  top: 0;
  left: 120px;
}

.rbt-splash-slider .banner-group-shape .shape-image {
  position: absolute;
}

.rbt-splash-slider .banner-group-shape .shape-image.shape-4 {
  top: 0;
  left: 100px;
}

.rbt-splash-slider .banner-group-shape .shape-image.shape-5 {
  top: 0;
  right: 0;
}

.rbt-splash-slider .banner-group-shape .shape-image.shape-6 {
  bottom: 100px;
  left: -100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-slider .banner-group-shape .shape-image.shape-6 {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-slider .banner-group-shape .shape-image.shape-6 {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-slider .banner-group-shape .shape-image.shape-6 {
    left: 0;
  }
}

.rbt-splash-slider .banner-group-shape .shape-image.shape-7 {
  right: 20px;
  bottom: -130px;
}

@media only screen and (max-width: 767px) {
  .rbt-splash-slider .banner-group-shape .shape-image img {
    transform: scale(0.8);
  }
}

.rbt-shape-bg-area {
  position: relative;
  z-index: 1;
}

.rbt-shape-bg-area .rbt-shape-bg {
  position: absolute;
  top: 50px;
  z-index: -1;
  opacity: 0.5;
}

/*-----------------------
    Service Area  
------------------------*/

@media only screen and (max-width: 767px) {
  .splash-service-main .service-wrapper {
    padding: 30px 0 0;
  }
}

.service__style--1 {
  z-index: 2;
  text-align: left;
  padding: 50px 50px;
  position: relative;
  border-right: 1px solid #ffffff1a;
  height: 100%;
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service__style--1 {
    padding: 40px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__style--1 {
    padding: 30px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__style--1 {
    padding: 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__style--1 {
    padding: 15px;
  }
}

.service__style--1::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--color-darker);
  z-index: -1;
  position: absolute;
  border-radius: 10px;
  opacity: 0;
  transition: 0.3s;
}

.service__style--1 .icon {
  margin-bottom: 22px;
}

.service__style--1 .icon svg {
  font-size: 60px;
  stroke-width: 1;
  color: var(--color-primary);
  width: 60px;
  height: 60px;
}

.service__style--1 .icon img {
  max-height: 60px;
}

.service__style--1 .content .title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .service__style--1 .content .title {
    margin-bottom: 10px;
  }
}

.service__style--1 .content p {
  color: #ffffffb3;
  font-size: 16px;
  font-weight: 400;
}

.service__style--1:hover {
  transform: translateY(-20px) scale(1.03);
  border-color: transparent;
}

.service__style--1:hover::before {
  opacity: 1;
  transform: scale(1);
}

.service.service__style--1:hover::after {
  opacity: 1;
}

.service.service__style--1::after {
  content: "";
  width: 80%;
  height: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: var(--color-darker);
  bottom: 0;
  border-radius: 0;
  filter: blur(10px);
  z-index: -5;
  opacity: 0;
  transition: 0.3s;
}

.service__style--column:last-child .service__style--1 {
  border-right: none;
}

/*-------------------------------
    Modern Course Features  
-------------------------------*/

.top-features-box {
  background: linear-gradient(208.29deg,
      #f5d9d5 0%,
      #f5eab4 42.92%,
      #99d9f5 100%);
  border-radius: 10px;
  padding: 40px;
  position: relative;
  z-index: 1;
  box-shadow: var(--shadow-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .top-features-box {
    padding: 40px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top-features-box {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .top-features-box {
    padding: 30px;
  }
}

.top-features-box .inner .content .pre-title {
  font-size: 14px;
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 12px;
}

.top-features-box .inner .content .title {
  color: var(--color-white);
}

.top-features-box .inner .content p {
  color: var(--color-white);
  margin-bottom: 35px;
}

.top-features-box .inner .thumbnail {
  margin-bottom: 30px;
  margin-top: 30px;
}

.top-features-box .inner .thumbnail img {
  width: auto;
}

.top-features-box .inner .rbt-badge-group {
  margin: -5px;
}

.top-features-box .inner .rbt-badge {
  padding: 0 12px;
  background: rgba(255, 255, 255, 0.05);
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-white);
  margin: 5px;
}

.top-features-box.grid-content-reverse .inner {
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .top-features-box.grid-content-reverse .inner {
    flex-direction: column;
  }
}

.top-features-box.grid-content-reverse .inner .thumbnail {
  flex-basis: 100%;
  width: 100%;
  margin-top: 30px;
}

.top-features-box.grid-content-reverse .inner .content {
  flex-basis: 100%;
  padding-left: 0;
}

.top-features-box.grid-content-reverse .inner .rbt-image-gallery-wrapper {
  width: 100%;
  display: block;
}

.top-features-box.grid-content-no-flex .inner {
  display: block;
}

.top-features-box .shape-image {
  position: absolute;
  right: 0;
  top: 23px;
  z-index: -1;
}

/*-------------------------------
    Layout Presentation
-------------------------------*/

.splash-layout-presentation .advance-tab-button-1 .tab-button-list {
  flex-direction: column;
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button {
  padding: 23px 27px;
  background: var(--black-opacity);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button {
    padding: 20px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button {
    padding: 20px 15px;
  }
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button::before {
  background: var(--primary-opacity) !important;
  box-shadow: none;
  width: 0;
  transition: width 0s ease-in-out;
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button::after {
  border-left: 12px solid var(--color-grey);
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  margin-top: -12px;
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button .title {
  font-size: 18px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button .title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button .title {
    font-size: 16px;
  }
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button .title i {
  top: 2px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  font-size: 20px;
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button .title img {
  min-height: auto;
  min-width: auto;
  width: auto;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  object-fit: cover;
}

.splash-layout-presentation .advance-tab-button-1 .tab-button-list .tab-button.active::before {
  opacity: 1;
  width: 100%;
  transition: width 4.5s ease-in-out;
}

/*--------------------------
    Single Demo Area  
----------------------------*/

.has-section-before-title {
  position: relative;
  z-index: 1;
}

.has-section-before-title .section-before-title {
  position: absolute;
  color: var(--color-grey2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  font-size: 650px;
  font-weight: 700;
  line-height: 0.8em;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .has-section-before-title .section-before-title {
    font-size: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .has-section-before-title .section-before-title {
    font-size: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .has-section-before-title .section-before-title {
    font-size: 300px;
  }
}

@media only screen and (max-width: 575px) {
  .has-section-before-title .section-before-title {
    font-size: 150px;
  }
}

.indicator-icon {
  max-width: 60px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .indicator-icon {
    bottom: -70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .indicator-icon {
    bottom: -70px;
  }
}

@media only screen and (max-width: 767px) {
  .indicator-icon {
    bottom: -70px;
  }
}

.single-demo {
  position: relative;
  border-radius: 15px;
  transition: 0.7s cubic-bezier(0.26, 0.76, 0.3, 0.75);
}

.single-demo::before {
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(120deg,
      rgba(0, 128, 231, 0.5803921569) 20.69%,
      rgba(71, 25, 197, 0.5411764706) 50.19%,
      rgba(253, 71, 102, 0.5490196078) 90%);
  top: -20px;
  border-radius: 100%;
  filter: blur(50px);
  z-index: -1;
  display: none;
}

.single-demo .single-demo-link {
  position: relative;
  z-index: 2;
  display: block;
  transition: 0.7s cubic-bezier(0.26, 0.76, 0.3, 0.75);
  box-shadow: var(--shadow-1);
  border-radius: 15px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff;
}

.single-demo .single-demo-link::after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: 13px;
  left: 30px;
  z-index: -1;
  color: rgba(0, 0, 0, 0.07);
  background-color: #f15252;
  box-shadow: 10px 0 0 #facb34, 20px 0 0 #a7df4b;
}

.single-demo .thumbnail {
  position: relative;
}

.single-demo .thumbnail>img {
  width: 100% !important;
  max-height: 446px;
  object-fit: cover;
  object-position: top;
}

@media only screen and (max-width: 1199px) {
  .single-demo .thumbnail>img {
    min-height: auto;
  }
}

.single-demo .content {
  padding: 20px 20px;
  display: block;
  text-align: center;
}

.single-demo .content .title {
  color: var(--color-heading);
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  letter-spacing: 0.2px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-demo .content .title {
    font-size: 16px;
  }
}

.single-demo .content .label-new {
  background: var(--color-primary);
  background: linear-gradient(145deg,
      var(--color-primary) 0%,
      var(--color-secondary) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="var(--color-primary)", endColorstr="var(--color-secondary)", GradientType=1);
  padding: 5px 11px;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  margin-left: 10px;
}

.single-demo .mobile-view {
  position: absolute;
  right: -70px;
  bottom: -20px;
  z-index: 2;
  padding: 7px;
  padding-top: 16px;
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  transition: opacity 0.6s ease 0.12s, transform 0.4s ease;
  width: 152px;
  height: auto;
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid #ffffff;
  backdrop-filter: blur(7.5px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-demo .mobile-view {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-demo .mobile-view {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .single-demo .mobile-view {
    display: none;
  }
}

.single-demo .mobile-view::before {
  position: absolute;
  content: "";
  background: #ffffff;
  border-radius: 5px;
  top: 7px;
  left: 50%;
  height: 2px;
  width: 28px;
  transform: translateX(-50%);
}

.single-demo .mobile-view img {
  border-radius: 2px;
}

.single-demo:hover {
  transform: translateY(-6px);
}

.single-demo:hover .mobile-view {
  transform: translateY(-5%);
}

.single-demo.coming-soon {
  pointer-events: none;
}

/*--------------------------
    Inner Page Layout Area  
------------------------------*/

.rbt-inner-page-layout-area .rbt-splite-style .split-inner {
  padding-left: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-inner-page-layout-area .rbt-splite-style .split-inner {
    padding-left: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-inner-page-layout-area .rbt-splite-style .split-inner {
    padding-left: 50px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-inner-page-layout-area .rbt-splite-style .split-inner {
    padding-left: 50px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-inner-page-layout-area .rbt-splite-style .split-inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.rbt-inner-page-layout-area .plan-offer-list-wrapper {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .rbt-inner-page-layout-area .plan-offer-list-wrapper {
    display: block;
  }
}

.rbt-inner-page-layout-area .plan-offer-list-wrapper .plan-offer-list+.plan-offer-list {
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .rbt-inner-page-layout-area .plan-offer-list-wrapper .plan-offer-list+.plan-offer-list {
    margin-left: -10px;
    margin-top: 30px;
  }
}

.rbt-inner-page-layout-area .layout-format-image {
  margin-left: -15px;
}

.rbt-splash-inner-layout-inner {
  background: var(--color-white);
  border-radius: 6px;
  overflow: hidden;
  margin-left: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-inner-layout-inner {
    border-radius: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-inner-layout-inner {
    border-radius: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-inner-layout-inner {
    border-radius: 0;
    margin-left: 0;
  }
}

.rbt-splash-inner-layout-inner .thumbnail.image-left-content img {
  border-radius: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-inner-layout-inner .thumbnail.image-left-content img {
    border-radius: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-inner-layout-inner .thumbnail.image-left-content img {
    border-radius: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-inner-layout-inner .thumbnail.image-left-content img {
    border-radius: 0;
  }
}

.rbt-splash-inner-layout-inner .content {
  padding: 30px 20px;
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
  background-size: 300% 100%;
}

@media only screen and (max-width: 767px) {
  .rbt-splash-inner-layout-inner .content {
    padding: 15px 20px;
  }
}

.rbt-splash-inner-layout-inner .content .title {
  margin-bottom: 0;
  font-size: 20px;
  color: var(--color-white);
}

@media only screen and (max-width: 767px) {
  .rbt-splash-inner-layout-inner .content .title {
    font-size: 16px;
  }
}

.splash-right-align-image .rbt-splash-inner-layout-inner {
  margin-left: 0;
  margin-right: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .splash-right-align-image .rbt-splash-inner-layout-inner {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .splash-right-align-image .rbt-splash-inner-layout-inner {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .splash-right-align-image .rbt-splash-inner-layout-inner {
    margin-right: 0;
  }
}

.rbt-splash-inner-layout-inner .thumbnail.image-left-content img,
.rbt-splash-inner-layout-inner .content {
  filter: grayscale(1);
}

.swiper-slide-active .rbt-splash-inner-layout-inner .thumbnail.image-left-content img,
.swiper-slide-active .rbt-splash-inner-layout-inner .content {
  filter: grayscale(0);
}

.swiper-slide-active .rbt-splash-inner-layout-inner {
  box-shadow: var(--shadow-7);
}

/*--------------------------
    Testimonial Styles  
----------------------------*/

.rbt-splash-testimonial-area .rbt-testimonial-box {
  height: 100%;
}

.rbt-splash-testimonial-area .rbt-testimonial-box.style-2 .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rbt-splash-testimonial-area .rbt-testimonial-box.style-2 .inner::before {
  display: none;
}

.rbt-splash-testimonial-area .line-shape {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .rbt-splash-testimonial-area .line-shape {
    bottom: 30px;
  }
}

/*------------------------
    Preview Service  
--------------------------*/

.rbt-splash-service {
  text-align: left;
  padding: 35px 50px 35px 50px;
  transition: 0.5s all ease-in-out;
  position: relative;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  border: 2px dashed var(--color-border);
  box-shadow: var(--shadow-9);
  background: var(--color-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-service {
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-service {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-service {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 479px) {
  .rbt-splash-service {
    padding: 20px;
  }
}

.rbt-splash-service a.over-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.rbt-splash-service .inner {
  width: 100%;
}

.rbt-splash-service .inner .icon {
  margin-bottom: 29px;
}

.rbt-splash-service .inner .icon svg,
.rbt-splash-service .inner .icon img {
  width: 50px;
  height: auto;
  color: var(--color-primary);
  stroke-width: 1 !important;
}

.rbt-splash-service .inner .content .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  color: var(--color-heading);
}

.rbt-splash-service .inner .content .title a {
  color: var(--color-heading);
  transition: var(--transition);
  text-decoration: none;
}

.rbt-splash-service .inner .content .description,
.rbt-splash-service .inner .content p {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-body);
  margin-bottom: 22px;
  transition: var(--transition);
}

.rbt-splash-service .inner .content .read-more-button {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}

.rbt-splash-service .inner .content .read-more-button::after {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: auto;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

.rbt-splash-service .inner .content .read-more-button i {
  color: var(--color-primary);
  font-size: 32px;
}

.rbt-splash-service.no-translate {
  padding: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-service.no-translate {
    padding: 35px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-service.no-translate {
    padding: 35px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-service.no-translate {
    padding: 35px 20px;
  }
}

.rbt-splash-service.no-translate .inner>* {
  transform: none;
}

.rbt-splash-service .liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px;
  font-size: 14px;
  color: var(--color-heading);
}

.rbt-splash-service .liststyle li {
  font-size: 14px;
  color: var(--color-heading);
}

.rbt-splash-service .liststyle span {
  margin-right: 5px;
  color: var(--color-body);
}

.rbt-splash-service.support p {
  margin-bottom: 20px;
}

.rbt-splash-service.bg-transparent {
  background: transparent;
  box-shadow: none;
}

.rbt-splash-service.rbt-splash-service-white .liststyle span {
  color: var(--color-white);
}

.rbt-splash-service.rbt-splash-service-white .plan-offer-list li {
  color: var(--color-white);
}

.rbt-splash-service.rbt-splash-service-white .inner .icon svg {
  color: var(--color-white);
}

.rbt-splash-service:hover {
  transform: translate3d(0, -10px, 0);
}

.rbt-splash-service .inner>* {
  transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
  transform: translateY(20px);
}

.rbt-splash-service:hover .inner>* {
  transform: translateY(0px);
}

/*------------------------ 
    Call To Action Footer  
--------------------------*/

.overlpping-call-to-action {
  padding-bottom: 340px;
}

.rbt-splash-callto-action {
  position: relative;
  top: -220px;
  margin-bottom: -220px;
  position: relative;
}

.rbt-splash-callto-action .inner {
  background-color: var(--color-bodyest);
  /* background-image: url(../images/splash/cta-01.png); */
  background-position: center right;
  background-repeat: no-repeat;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 100px 100px 100px;
  border-radius: 16px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splash-callto-action .inner {
    background-position: center right -300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-callto-action .inner {
    background-position: center right -400px;
    padding: 100px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-callto-action .inner {
    background-position: center right -550px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-callto-action .inner {
    padding: 30px;
    background-image: none;
  }
}

.rbt-splash-callto-action .inner .line-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: -1;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .rbt-splash-callto-action .inner .line-shape {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splash-callto-action .inner .line-shape {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-callto-action .inner .line-shape {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-callto-action .inner .line-shape {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-callto-action .inner .rbt-badge-2 {
    display: none;
  }
}

.rbt-splash-callto-action .title {
  font-size: 48px;
  display: block;
  margin-bottom: 20px;
  color: var(--color-white);
  width: 37%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rbt-splash-callto-action .title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-callto-action .title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-callto-action .title {
    font-size: 30px;
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-callto-action .title {
    font-size: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-splash-callto-action .title {
    font-size: 26px;
  }
}

.rbt-splash-callto-action .subtitle {
  color: var(--color-white-off);
  font-size: 14px;
  display: block;
  margin-top: 20px;
}

/*---------------------------
    Plugin Presentation 
----------------------------*/

.rbt-feature-column:nth-child(odd) {
  padding-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-feature-column:nth-child(odd) {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-feature-column:nth-child(odd) {
    padding-top: 0;
  }
}

.rbt-feature-column:nth-child(even) .inner .thumbnail img {
  width: 111px;
  height: 111px;
}

@media only screen and (max-width: 767px) {
  .rbt-feature-column:nth-child(even) .inner .thumbnail img {
    width: 60px;
    height: 60px;
  }
}

.rbt-feature-plugin .inner {
  text-align: center;
}

.rbt-feature-plugin .inner .thumbnail {
  border-radius: 100%;
  box-shadow: var(--shadow-1);
  display: inline-block;
}

.rbt-feature-plugin .inner .thumbnail img {
  border-radius: 100%;
  width: 145px;
  height: 145px;
}

@media only screen and (max-width: 767px) {
  .rbt-feature-plugin .inner .thumbnail img {
    width: 60px;
    height: 60px;
  }
}

.rbt-feature-plugin .inner .content {
  padding-top: 22px;
}

.rbt-feature-plugin .inner .content .title {
  margin-bottom: 14px;
}

.rbt-feature-plugin .inner .content .rbt-badge-6 {
  text-transform: none;
}

@media only screen and (max-width: 767px) {
  .rbt-feature-plugin .inner .content .rbt-badge {
    padding: 0 10px;
    width: max-content;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-feature-plugin .inner .content .rbt-badge {
    font-size: 10px;
  }
}

/*-----------------------------
    All Feature Presentation 
-------------------------------*/

.rbt-splash-feature-box {
  background-color: var(--color-white);
  box-shadow: var(--shadow-9);
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}

.rbt-splash-feature-box .inner .content {
  padding: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-feature-box .inner .content {
    padding: 40px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-feature-box .inner .content {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-feature-box .inner .content {
    padding: 40px 20px;
    padding-bottom: 20px;
  }
}

.rbt-splash-feature-box .inner .content .description {
  font-size: 18px;
  color: var(--color-body);
}

@media only screen and (max-width: 767px) {
  .rbt-splash-feature-box .inner .content .description {
    font-size: 16px;
    line-height: 26px;
  }
}

.rbt-splash-feature-box .inner .content .description strong {
  color: var(--color-heading);
}

.rbt-splash-feature-box.space-between-align .inner {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .rbt-splash-feature-box.space-between-align .inner {
    display: block;
  }
}

.rbt-splash-feature-box.space-between-align .inner .content {
  flex-basis: 45%;
  max-width: 45%;
  padding: 20px 20px 20px 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-splash-feature-box.space-between-align .inner .content {
    padding: 20px 20px 20px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-splash-feature-box.space-between-align .inner .content {
    padding: 20px 20px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-splash-feature-box.space-between-align .inner .content {
    padding: 20px 20px 20px 20px;
    max-width: 100%;
  }
}

.rbt-splash-feature-box.space-between-align .inner .content .theme-gradient {
  white-space: pre-wrap;
}

.rbt-splash-feature-box.space-between-align .inner .thumbnail {
  flex-basis: 55%;
  max-width: 55%;
}

.rbt-splash-feature-box.color-white .inner .content .description {
  color: #ffffffd5;
}

.rbt-splash-feature-box.color-white .inner .content .description strong {
  color: var(--color-white);
}

.splash-layout-presentation .wrapper .splash-layout-presentation-box {
  position: relative;
  z-index: 1;
  padding: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .splash-layout-presentation .wrapper .splash-layout-presentation-box {
    padding: 50px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .splash-layout-presentation .wrapper .splash-layout-presentation-box {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .splash-layout-presentation .wrapper .splash-layout-presentation-box {
    padding: 40px 15px;
  }
}

.splash-layout-presentation .wrapper .splash-layout-presentation-box::after {
  position: absolute;
  background: var(--gradient-2) !important;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
}

.scroll-animation-all-wrapper {
  position: relative;
  padding: 30px 0;
}

.scroll-animation-all-wrapper::before,
.scroll-animation-all-wrapper::after {
  position: absolute;
  height: 100%;
  width: 300px;
  background: linear-gradient(90deg, #fff 100px, rgba(255, 255, 255, 0));
  z-index: 2;
  content: "";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .scroll-animation-all-wrapper::before,
  .scroll-animation-all-wrapper::after {
    width: 100px;
    background: linear-gradient(90deg, #fff 10px, rgba(255, 255, 255, 0));
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .scroll-animation-all-wrapper::before,
  .scroll-animation-all-wrapper::after {
    width: 100px;
    background: linear-gradient(90deg, #fff 10px, rgba(255, 255, 255, 0));
  }
}

@media only screen and (max-width: 767px) {

  .scroll-animation-all-wrapper::before,
  .scroll-animation-all-wrapper::after {
    width: 100px;
    background: linear-gradient(90deg, #fff 10px, rgba(255, 255, 255, 0));
  }
}

.scroll-animation-all-wrapper::before {
  top: 0;
  left: 0;
}

.scroll-animation-all-wrapper::after {
  top: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.scroll-animation-all-wrapper .scroll-animation-wrapper::before,
.scroll-animation-all-wrapper .scroll-animation-wrapper::after {
  display: none;
}

.home-demo-area .section-title .description.has-medium-font-size {
  padding: 0 15%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .home-demo-area .section-title .description.has-medium-font-size {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-demo-area .section-title .description.has-medium-font-size {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-demo-area .section-title .description.has-medium-font-size {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .home-demo-area .section-title .description.has-medium-font-size {
    padding: 0;
  }
}

.splash-testimonial-all-wrapper {
  position: relative;
  z-index: 2;
}

.splash-testimonial-all-wrapper::before {
  position: absolute;
  height: 70%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0) 10%, #192335 80%);
  z-index: 2;
  content: "";
  left: 0;
  bottom: -5px;
}

.splash-testimonial-all-wrapper .read-more-review {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

@media only screen and (max-width: 767px) {
  .splash-testimonial-all-wrapper .read-more-review {
    width: max-content;
  }
}

.rbt-buy-now-area {
  position: relative;
  z-index: 1;
}

.rbt-buy-now-area .map-image {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  width: 70%;
}

.rbt-buy-now-content .title {
  font-size: 60px;
  padding: 0 12%;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rbt-buy-now-content .title {
    font-size: 40px;
    padding: 0 8%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-buy-now-content .title {
    font-size: 50px;
    padding: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-buy-now-content .title {
    font-size: 30px;
    padding: 0;
    margin-bottom: 20px;
  }
}

.rbt-buy-now-content .subtitle {
  font-size: 24px;
  margin-bottom: 50px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-buy-now-content .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-buy-now-content .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

.rbt-buy-now-content .label-text {
  font-size: 14px;
  margin-top: 60px;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

.rbt-bebefit-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 0 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rbt-bebefit-list {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rbt-bebefit-list {
    padding: 0;
  }
}

.rbt-bebefit-list .single-bebefit {
  flex-basis: 20%;
  text-align: center;
  padding: 0 10px;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .rbt-bebefit-list .single-bebefit {
    flex-basis: 33.33%;
  }
}

@media only screen and (max-width: 575px) {
  .rbt-bebefit-list .single-bebefit {
    flex-basis: 50%;
  }
}

.rbt-bebefit-list .single-bebefit .icon img {
  opacity: 0.75;
  max-height: 45px;
}

.rbt-bebefit-list .single-bebefit .title {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
  opacity: 0.75;
  color: var(--color-white);
  margin-top: 15px;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .rbt-bebefit-list .single-bebefit .title {
    font-size: 14px;
  }
}

/* new add css--------------------------- */

.home-offerbg {
  padding: 20px;
  color: rgb(255, 255, 255);
  background-size: 170% 100%;
  background-image: linear-gradient(to right,
      var(--color-primary),
      #a64ed6,
      #9c38d3,
      var(--color-primary));
  outline: 1px solid #ffffff6e;
  outline-offset: -7px;
}

.home-offerbg .offer-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .home-offerbg .offer-text {
    display: block !important;
  }
}

.home-offerbg .offer-big {
  font-size: 28px;
  margin-right: 10px;
  font-weight: 600;
}

.home-offerbg .signin-btn {
  background-color: #fff;
  padding: 8px 25px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  display: inline-block;
  color: var(--color-primary);
}

.future-box-bg {
  background-color: #f1f2f8;
  padding: 20px;
}

.future-box-bg .count-text {
  font-weight: 600;
  font-size: 28px;
}

.rbt-icon img {
  height: 55px;
}

footer {
  background-color: #222744;
}

.bonus-offer-inner {
  padding: 20px;
  position: relative;
}

.bonus-tag {
  display: inline-block;
  padding: 5px 17px;
  font-size: 15px;
  border-radius: 20px;
  line-height: 16px;
  position: absolute;
  top: -12px;
  left: 20px;
}

.bonus-rs {
  font-size: 42px;
  font-weight: 600;
  line-height: 43px;
}

.right-arrow {
  position: absolute;
  right: -20px;
  height: 50px;
  width: 50px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 100%;
  top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-screen {
  position: absolute;
  top: -100px;
}

.banner-content {
  padding-right: 80px;
}

@media only screen and (max-width: 991px) {
  .border-signup {
    border: none !important;
  }

  .banner-content {
    padding-right: 0px;
  }

  .app-screen {
    position: relative;
    top: 0px;
  }

  .app-w {
    padding: 60px 0px !important;
  }
}

.app-w {
  padding: 120px 0px;
}

.student-price {
  padding: 7px;
  color: #fff;
  font-weight: 500;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.home_Benefits img {
  width: 68px;
}

.user-list-wrapper span.material-symbols-outlined {
  font-size: 18px !important;
  margin-right: 10px;
}

.login-register-bg {
  background-color: var(--color-light);
  /* background-image: url(../images/bg/signup-bg.svg); */
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  background-attachment: fixed;
}

.border-signup {
  border-left: 1px solid #e0e0e0;
}

.blue-title {
  background-color: var(--color-primary);
}

.rbt-title-style-2 img {
  width: 26px;
  margin-right: 10px;
}

.quote {
  /* background-image: url(../images/event/quote.png); */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 60px 30px;
  border-radius: 10px;
  text-align: center;
  color: var(--color-black);
  font-size: 16px;
  font-weight: 600;
}

.dashboard_bg {
  background-color: var(--primary-opacity);
}

.hide.option-block {
  display: none;
}

.rbt-form-check {
  display: inline-block;
}

.payent {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px;
}

.payent label {
  font-size: 17px !important;
}

.counter-border {
  border-left: 4px solid #e541ed;
  padding: 5px 20px;
}

.how-work {
  background-color: var(--color-primary);
  color: var(--color-white);
  display: flex;
  border-radius: 10px;
  width: 45px;
  text-align: center;
  height: 45px;
  justify-content: center;
  align-items: center;
}

#time-countdown {
  border: 1px solid #9d9af4;
  display: inline-block;
  padding: 7px;
  border-radius: 4px;
  background-color: #e4e3f8;
}

.add-money {
  border: 1px solid var(--color-gray);
  font-weight: 600;
}

.add-money:hover {
  color: var(--color-secondary);
}

.add-money.active {
  background-color: var(--color-secondary);
  color: var(--color-white);
  border: 1px solid var(--color-secondary);
}

/* .highlight {
    background-color: var(--secondary-opacity);
    border: 1px solid var(--color-secondary);
    color: var(--color-secondary);
} */

.btn-border01 {
  border: 1px solid #ccc;
  background-color: #fff;
}

.btn-border01:hover {
  border: 1px solid #ccc;
  background-color: #fff;
}

.color-white.d-flex.align-items-center.justify-content-center.nav-new {
  font-size: 14px;
}



@media only screen and (max-width: 767px) {
  #whatsAppChatOpener {
    right: 25px !important;
    bottom: 160px !important;
  }
}

@media only screen and (min-width: 768px) {
  #whatsAppChatOpener {
    right: 89px !important;
    height: 60px !important;
    width: 60px !important;
  }
}

.num-hover:hover{
  color: #c945ec !important;
}
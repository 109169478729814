@import "../Styles/variables";

.assignmentList-container {
    width: 100%;
    text-align: start;
    padding: 5rem 3rem;
    background-color: white;
    margin: 0 auto;
    background-image: url("../Assets/images/New/assignmentlist.jpg");
    background-size: cover;
    background-position: center;


    .assignmentList-steps-container {
        padding: 2rem;
        border-radius: 1rem;
        margin: auto;
        max-width: 800px;

        .assignmentList-title {
            color: #2f2f2f;
            font-size: 2.8rem;
            font-weight: 600;
            line-height: 4rem;
        }

        .assignmentList-description {
            font-size: 1.4rem;
            margin-top: 1rem;
            color: #6d737a;
        }
    }

    .timeline-item {
        border: 1px solid #B0AEFF;
        padding: 6px;
        width: 50%;
    }

    .view-question-btn {
        border: none;
        padding: 5px;
        border-radius: 0.2rem;
        color: var(--color-white);
        background-size: 300% 100%;
        background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-secondary), var(--color-primary));
    }

    .timeline-title {
        font-weight: 700;
        font-size: 13px;
    }

    .row.timeline {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .timeline-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        padding: 10px;
    }

    .timeline-card {
        background-color: white;
        border: 1px solid #B0AEFF;
        border-radius: 1px;
        padding: 15px;
        margin: 10px 0;
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-between;
    }

    .timeline-card-title {
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 15px;
        align-self: center;
    }

    .timeline-button {
        background-color: #ed4db5;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.2rem;
    }

    .timeline-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0.2;
        padding-top: 0px;
    }

    .timeline-circle {
        background-color: #ff6bcb;
        color: white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 26px 0;
    }

    .more {
        margin-top: 20px;
        text-align: center;
    }

    .cta-button {
        background-color: #4a90e2;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
    }

    .timeline-more {
        font-size: 15px;
        padding-bottom: 17px;
        cursor: pointer;
    }

    /* Responsive Design */
    @media (max-width: 768px) {
        .row.timeline {
            flex-direction: column;
            align-items: center;
        }

        .timeline-middle {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            display: none;
        }

        .timeline-circle {
            margin: 5px;
        }

        .timeline-card {
            max-width: 300px;
        }

        .assignmentList-table-container {
            width: 100%;
        }

        .assignment-questiontitle {
            font-size: 1rem;
        }

        .timeline-button {
            float: left;
            margin-top: 12px !important;
        }

        .table-text {
            display: block !important;
        }

        .mobile-text {
            display: none !important;
        }
    }
}


.assignmentList-table-container {
    width: 50%;
    margin: 0 auto;
    overflow-x: auto;
}

.assignmentList-table {
    width: 100%;
    border-collapse: collapse;
}

.assignmentList-table th,
.assignmentList-table td {
    border: 1px solid #ddd;
    padding: 11px 11px;
    text-align: left;
    vertical-align: middle;
}

.assignmentList-table th {
    background-color: #f4f4f4;
}

.assignmentList-button {
    margin-left: 10px;
}

.assignment-questiontitle {
    font-size: 1.2rem;
    font-weight: 600;
}

.timeline-button {
    float: inline-end;
}

.table-text {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
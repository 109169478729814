@import "../Styles/variables";

.notification-popup {
    background-color: #5956E9;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.pop-text {
    color: #fff;
}

.custom-select {
    font-size: 1.2rem;
    height: 40px;
}

.footer {
    background-color: #fff;
    padding: 10px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.feature {
    display: flex;
}

.feature p {
    padding: 11px 10px;
    font-size: 1.2rem;
}

.pop-file {
    padding: 12px !important;
    font-size: 1rem !important;
}

.notification-pop .modal-content {
    height: auto;
}

.pop-image-check {
    width: 40px;
    height: 40px;
}

.pop-input {
    margin-bottom: 20px !important;
}

.pop-mobile {
    position: relative !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    background-color: #f5f5f5 !important;
    padding: 19px;
}

.pop-file {
    cursor: pointer !important;
    text-align: center !important;
    padding: 20px !important;
    border-radius: 0.25rem !important;
    border: 1px dashed #ced4da !important;
    transition: background-color 0.3s ease !important;
}

.pop-filer:hover {
    background-color: #e0e0e0 !important;
}

.textarea-box {
    width: 100%;
    height: 150px;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    overflow: auto;
}

.file-input {
    display: none;
}

.pop-file {
    display: block;
}

.pop-btn {
    padding: 8px 20px;
    font-size: 1.2rem;
    background: #F748FF;
    color: #fff;
    border: 1px solid #F748FF;
    border-radius: 6px;
}

.close-btn-pop {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000;
    outline: none;
    background: #fff;
    width: 25px;
    border-radius: 15px;
    height: 25px;
    font-size: 19px !important;

}

.close-btn-pop:hover {
    color: #F748FF;
}

@media (max-width: 768px) {
    .pop-mobile {
        height: 8.5rem !important;
    }

    .feature p {
        padding: 10px 10px !important;
    }

    .pop-image-check {
        width: 35px;
        height: 35px;
        margin-bottom: 1rem;
    }

    .pop-input {
        margin-bottom: 10px !important;
    }
}
@import "../Styles/variables";

.benefits-section-wrapper {
    background-color: white;
    padding-bottom: 5rem;
    padding-top: 4rem;

    .benefits-section-container {
        .benefits-section-title {
            font-size: 2rem;
            font-weight: 600;
        }

        .benefits-section-description {
            font-size: 1.5rem;
            padding: 1rem 0;
            color: $color-gray-500;
        }
    }

    .benefits-card-wrapper {
        margin: 0 auto;
        padding-bottom: 5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .benefits-card-container {
            padding: 4rem 2rem;
            min-width: 33rem;
            flex: 1 1 0;
            border-radius: 2rem;
            transition: border .2s;
            border: 1px solid white;

            .benefits-card-title {
                padding: 2rem 0;
                font-size: 1.8rem;
                font-weight: 600;
            }

            .benefits-card-description {
                color: #808080;
                font-size: 1.5rem;
                padding: 0rem 2rem;
            }

            &:hover {
                border: 1px solid #CDC3C3;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .benefits-card-container {
        padding: 2rem 2rem !important;
    }

    .benefits-card-wrapper {
        padding-bottom: 0rem !important;
    }

    .benefits-section-wrapper {
        padding-bottom: 1rem;
    }

}


.step {
  color: white;
  width: 30px;
  height: 30px;
  font-size: 18px;
  background: linear-gradient(180deg, #E541ED 0%, #5956E9 100%),linear-gradient(0deg, #E5E5FE, #E5E5FE);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step.completed {
  background-color: indigo;
}

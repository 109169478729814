@import "../../Styles/variables";

.checkOutWrapper {
  min-height: 100vh;
  // background-color: $color-grey-1;
  background-color: #ffedff;
}

@media (max-width: 768px) {
  .checkOutWrapper #payment-form {
    width: 90% !important;
  }
}

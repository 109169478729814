@import "../../Styles/variables";

.logo-header {
  height: 8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;

  img {
    cursor: pointer;
  }
}

@media (max-width:767px) {

  .logo img {
      width: 140px;
      padding: 8px;
  }
  
}

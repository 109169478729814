@import "../../Styles/variables";

.helpCard {
  border-radius: 2rem;
  border: 0.1rem solid #dbdbdb;
  box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
  max-width: 38rem;
  height: auto;
  padding: 5.1rem 2.6rem 3.6rem 4rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;

  * {
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background: $color-white;
    border-radius: 2rem;
  }
  &:hover {
    transform: scale(1.02);
    transition: $transition-2;
  }
  .label {
    color: $kd-color-black-1;
    font-size: 1.7rem;
    font-weight: $f-semi-bold;
    line-height: 2.64rem;
  }

  .message {
    flex: 1;
    color: #808080;
    font-size: 1.7rem;
    font-weight: $f-regular;
    line-height: 2.558rem;
    padding-top: 1.4rem;
    padding-bottom: 1.2rem;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2.2rem;
    border-top: 0.1rem solid #bfbfbf;
  }
}

@media (max-width: 768px) {
  .helpCard {
    padding: 20px;
  }

  .helpCard .container img {
    width: 40px;
    height: 40px;
  }

  .helpCard .message {
    font-size: 1.4rem;
  }
}

.tab-container {
    margin: auto;
}

.tab-buttons {
    display: flex;
    margin-left: 16px;
    flex-wrap: wrap;
}

.tab-button {
    background-color: #f1f1f1;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.referral-in-image {
    height: 250px !important;
}

.tab-button:hover {
    background-color: #ddd;
}

button.tab-button.active {
    background-color: black !important;
    color: #fff !important;
}

.tab-content .tab:target,
.tab-content .tab:focus {
    display: block;
}

.tab {
    display: block;
}

.tab-bg {
    background-color: #e6f0ff;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
}

.refer-title {
    font-weight: bold;
    font-size: 15px;
}

.main-text {
    font-size: 24px;
    font-weight: bold;
}

.highlight {
    color: #a73ff3;
}

.sub-text {
    font-size: 14px;
    margin-top: 10px;
}

h2.text-start.referral-program {
    margin-left: 16px;
    padding-top: 10px;
}

.referral-container {
    width: 60%;
    // margin: 0 auto;
    position: relative;
    padding-top: 4%;
    padding-left: 1%;
}

.tab-step {
    border: 2px solid #a73ff3;
    border-radius: 10px;
    padding: 20px;
    // margin-bottom: 20px;
    position: relative;
    width: 96%;
}

.tab-step1 {
    margin-left: 0;
}

.tab-step2 {
    margin-left: 12%;
}

.tab-step3 {
    margin-left: 24%;
}

.tab-step4 {
    margin-left: 36%;
}

.tab-step5 {
    margin-left: 48%;
}

.tab-step h2 {
    font-size: 1.2em;
    color: #a73ff3;
    margin-bottom: 10px;
    text-align: start;
}

.tab-step p {
    font-size: 1.2em;
    color: #333;
    text-align: justify;
    font-family: "Poppins", sans-serif;
}

.arrow-ref {
    text-align: center;
    font-size: 2em;
    color: #a73ff3;
    margin-bottom: 20px;
    position: relative;
}

.arrow1 {
    margin-left: 20%;
}

.arrow2 {
    margin-left: 40%;
}

.arrow3 {
    margin-left: 60%;
}

.arrow4 {
    margin-left: 80%;
}

.arrow-ref::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #a73ff3;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.step:not(:last-child)::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #a73ff3;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

ul.social-icon.social-default.justify-content-start {
    list-style: none;
    display: flex;
    font-size: 27px;
    padding-left: 0;
}

button#button-addon2 {
    --bs-btn-border-color: #e6e3f1;
    --bs-btn-hover-bg: #a73ff3;
    --bs-btn-hover-border-color: #a73ff3;
    background-color: #a73ff3;
    color: #fff;
    font-size: 13px;
}

a.inside-img:hover {
    background: none !important;
    width: 40px !important;
    background-color: #fff !important;
}

.social-default li a::after {
    background: none !important
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
    .tab-bg {
        width: 100%;
        margin-left: 0;
    }

    .tab-buttons {
        margin-left: 0;
    }

    .tab-button {
        margin: 5px;
        margin-left: 10px;
    }

    .main-text {
        font-size: 16px;
    }

    .sub-text {
        font-size: 14px;
    }

    h2.text-start.referral-program {
        margin-left: 10px;
        padding-top: 10px;
    }

    .referral-container {
        width: 100%;
    }

    .arrow1 {
        margin-left: 0%;
    }

    .tab-step2 {
        margin-left: 0%;
    }

    .arrow2 {
        margin-left: 0%;
    }

    .tab-step3 {
        margin-left: 0%;
    }

    .arrow3 {
        margin-left: 0%;
    }

    .tab-step4 {
        margin-left: 0%;
    }

    .arrow4 {
        margin-left: 0%;
    }

    .tab-step5 {
        margin-left: 0%;
    }
}
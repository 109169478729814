.first-section-ref {
    padding: 40px 0;
    background-color: #fff0fd;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.heading-content {
    font-size: 24px;
    font-weight: bold;
}

.assignment-image-row-1 {
    max-width: 100%;
    height: 150px;
    padding: 16px 21px;
}

.assignment-image-row-2-3 {
    max-width: 100%;
    height: 150px;
    padding: 10px 21px;
}

.card {
    height: auto
}

.card-area {
    padding: 3rem 5rem 3rem 5rem;
}

.text-1 {
    font-weight: 500;
}

.text-2 {
    font-weight: bold;
}

.line-space {
    line-height: 2;
    text-align: start;
    font-size: 1.5rem !important;
}

.card-box-re {
    background-color: #fff;
}

.card-title-rw {
    text-align: start;
    font-size: 1.8em !important;
}

.box-dis {
    text-align: start;
    font-size: 1.5rem !important;
}
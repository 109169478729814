@import "./Styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Public+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  text-align: center;
  font-family: $font-primary;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-logo {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: -60px;
  left: 0px;
}

.center-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.background-shadow {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    height: 50%;
    aspect-ratio: 1;
    flex-shrink: 0;
    border-radius: 72.1rem;
    opacity: 0.25;
    background: $color-primary;
    filter: blur(10rem);
    position: absolute;
    bottom: 30%;
    left: 40%;
  }

  &::before {
    content: "";
    display: block;
    height: 50%;
    aspect-ratio: 1;
    flex-shrink: 0;
    border-radius: 72.1rem;
    opacity: 0.25;
    background: $color-secondary;
    filter: blur(10rem);
    position: absolute;
    top: 30%;
    right: 40%;
  }
}

span.text-danger {
  font-size: 1.6rem;
}

.Toastify__toast-body>div:last-child {
  word-break: break-word;
  flex: 1 1;
  font-size: 13px !important;
}


.assignment-modal {
  .modal-header-content {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;

    .modal-circle {
      background-color: #ff6bcb;
      color: white;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }

  .modal-files {
    .modal-files-title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .modal-file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .modal-file-name {
      font-size: 1.3rem;
    }

    .modal-file-name a {
      text-decoration: none !important;
      color: #000 !important;
    }

    .modal-file-name a:hover {
      color: #ff6bcb !important;
    }

    .modal-file-button {
      border: none;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      color: var(--color-white);
      background-size: 300% 100%;
      background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-secondary), var(--color-primary));
      height: 25px;
      width: 25px;
    }
  }

  .download-all-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    i {
      margin-left: 5px;
    }
  }
}

.assignment-modal .modal-content {
  height: auto !important;
}

.assignment-modal .modal-footer {
  justify-content: center !important;
  font-size: 15px !important;
}

.assignment-modal .modal-header {
  border: none !important;
}

img.download-all-img {
  height: 20px !important;
}

td,
.wp-block-calendar tfoot td {
  border: 1px solid #dee2e6 !important;
}
p {
  margin-bottom: 0;
}

.search-que {
  padding: 0.5rem 1.5rem;

  p img {
    height: 100px;
    width: 100px;
  }
}

.que {
  font-size: 1.4rem;
}

.text-gray {
  color: #777777;
  font-weight: 400;
}

.library-card {
  padding: 1.5rem 1.5rem;
  border-radius: 1.6rem;
}

.card-odd {
  background-color: #f1f1ff;
  border: 2px solid #d7d7ed;
}

.card-even {
  background-color: #fff1fe;
  border: 2px solid #edcfec;
}

.search-width {
  width: 26%;
}

.search-sidebar {
  background: linear-gradient(to bottom, #caebea, #d0cfff);
  height: fit-content;
  border-radius: 12px;
  margin-left: 2rem;
}

.search-sidebar-que {
  background: linear-gradient(to bottom, #caebea, #d0cfff);
  height: fit-content;
  border-radius: 12px;
  width: 100%;
}


.side-cont {
  padding: 1rem;
}

.side-help {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 1rem;
}

.side-head {
  font-size: 1.5rem;
  border: 1px solid #5956e9;
  padding: 0.7rem;
  background: linear-gradient(to bottom, #5956e9, #b5b4ea);
  border-radius: 8px;
}

.looking {
  color: #e541ed;
}

.help {
  color: #5956e9;
}

.ass-button {
  margin-top: 2rem;
}

.ass-button-que {
  margin-top: 15px;
}

button.do-my-ass {
  border: 1px solid #e541ed;
  background: linear-gradient(to bottom,
      #a7a7ff 0%,
      #cfd3fb 100%,
      #d8feff 100%);
  color: #000;
  font-size: 2rem;
  padding: 0rem 1rem;
  border-radius: 1rem;
}

button.do-my-ass.que {
  font-size: 1.4rem;
}

.main-class {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}

.main-class-que.Layer {
  text-align: start;
  padding: 2rem;
}

.main-class.Layer {
  text-align: start;
  padding: 2rem;
}

.side-title {
  font-size: 1.8rem;
  margin-left: 2rem;
}

.side-title-que {
  font-size: 1.6rem;
  margin-top: 10px;
}

.side-subtitle {
  font-size: 1.6rem;
  margin-left: 2rem;
}

.Layer-btn {
  margin-left: 6rem;
}

.all-subjects.mt-4 {
  padding: 0.5rem 1.5rem;
  margin-top: 6rem !important;
}

.question-color {
  background-color: #f1f1ff;
  padding: 1rem;
  border-radius: 5px;
}

p.mx-2.d-flex p img {
  width: 50%;
  margin-top: 6px;
}

@media only screen and (max-width: 450px) {
  .searchmobile {
    width: 100% !important;
  }

  .no-que img {
    width: 100%;
  }

  .search-width {
    width: 100%;
  }

  .verified-solutions {
    font-size: 11px !important;
    margin-top: -27px !important;
  }

  .verified-solutions.inside {
    font-size: 13px !important;
    margin-top: 16px !important;
    text-align: start !important;
    margin-left: 14px;
  }

}

.searchmobile {
  width: 70%;
}

.top-que-banner {
  margin-bottom: 22rem !important;
}

.verified-solutions {
  text-align: end;
  font-size: 14px;
  margin-top: 24px;
}
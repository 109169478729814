@import "../../Styles/variables";

.wrapper {
  min-height: 100vh;
  // background-color: $color-grey-1;
  background-color: #F7F7F7;

  .logo-header {
    height: 8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
  }

  .content-wrapper {
    max-width: 85%;
    padding: 2rem;
    margin: 0 auto;

    .title {
      font-weight: $f-semi-bold;
      font-size: 2rem;
    }

    .subTitle {
      font-weight: $f-medium;
      font-size: 1.2rem;
      min-width: fit-content;
    }

    .greyTitle {
      color: #868282;
      font-weight: $f-medium;
      font-size: 1.5rem;
      min-width: fit-content;
    }

    .content-card {
      border-radius: 1rem;
      margin-bottom: 2rem;
      font-weight: 600;
      border: 1px solid #E1E1E1;
    }

    .lineSeprator {
      border: 1px solid #cbc1c1;
      width: -webkit-fill-available;
      height: 1px;
      margin: auto;
    }

    .accordian-Wrapper {
      .accordion-item {
        border-radius: 1rem;
        padding: 0rem;

        .accordion-header {
          border-radius: 1rem;
        }

        .accordion-button {
          background-color: $color-white;
          box-shadow: none;
          font-size: 2rem;
          font-weight: 600;
          // font-weight: $f-semi-bold;;
          border-radius: inherit;
        }

        .accordion-body {
          padding: 1rem;
          line-height: 35px;
        }
      }
    }
  }
}

.disabled {
  background-color: #efefef;
  cursor: not-allowed;

  .accordion-button {
    background-color: transparent !important;
  }
}

.oops-heading {
  color: $color-secondary;
}

.com-whatsapp {
  display: grid;
  justify-content: end;
  margin-left: -18px;
}

@media (max-width: 768px) {
  .wrapper .content-wrapper {
    max-width: 100% !important;
  }
}

@media (max-width: 425px) {
  .wrapper .content-wrapper {
    .greyTitle {
      font-size: 1rem;
    }

    .accordion-body {
      padding: 1rem;
    }

    .accordian-Wrapper .accordion-item .accordion-button {
      font-size: 1.5rem;
    }
  }
}

button.back-btn-payment.btn.btn-secondary {
  background-color: #fff;
  color: #000;
  font-size: 1.6rem;
  padding: 10px 30px;
  border: 1px solid #E541ED;
}

.whatsapp-text {
  font-size: 13px;
  margin-bottom: 0;
  padding-bottom: 0px;
  font-weight: 600;
  padding-top: 4px;
  color: #432c65;
}

li.page-item button {
  padding: 15px;
  line-height: 6px;
  font-size: 16px;
}
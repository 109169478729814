@import "../Styles/variables";

.assignmentsection {
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding-left: 15rem;
  padding-right: 15rem;
  padding-top: 7rem;

  margin: 0 auto;

  .description {
    color: $color-gray-500;
    font-size: 1.8rem;
    font-weight: $f-regular;
    padding-bottom: 2.5rem;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.8rem;
    padding-bottom: 8rem;
  }
}

.title {
  color: $kd-color-black-1;
  font-size: 2.8rem;
  font-weight: $f-semi-bold;
  line-height: $line-height-b4;
  padding-bottom: 0.5rem;
}


@media only screen and (max-width: 768px) {

  .description {
    font-size: 1.5rem !important;
  }

  .assignmentsection {
    padding-top: 3rem !important;
  }

  .card-wrapper {
    padding-bottom: 4rem !important;
  }
  .title {
    font-weight: 600;
    font-size: 2.3rem;
    line-height: $line-height-b4;
    padding-bottom: 0.5px;
  }
}

@media only screen and (max-width: 1400px) {
  .assignmentsection {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }
}
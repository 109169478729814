.aMpM {
  height: 40px;
  width: 40px;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 10px;
}
.active {
  background: linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%);
  color: white;
}
.timeSelection {
  width: 60px;
  height: 60px;
  font-size: 20px;
  padding: 10px;
  border: 1px solid #5956e9;
  box-shadow: 1px 1px 5px 0px black;
}

.authentication-container {
  position: absolute;
  top: 50%;
  width: 100%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;

  .card::before {
    content: none;
  }

  .authentication-card-sidebar-icon {
    svg {
      width: 4rem;
    }
  }

  .authentication-card-sidebar-title,
  .authentication-card-sidebar-title+b {
    font-size: 2rem;
  }

  .authentication-sidebar-title,
  .authentication-sidebar-description,
  .authentication-sidebar-title+b {
    font-size: 2rem;
  }

  .authentication-sidebar-description {
    color: #717171;
  }

  .authentication-sidebar-title,
  .authentication-sidebar-description,
  .subtitle+b {
    font-size: 1.2rem;
  }

  .card {
    background-color: white !important;
  }
}

.policy-text {
  font-size: 13px;
}

@media only screen and (max-width: 767px) {

  /* Styles for mobile phones */
  .card {
    padding: 10px;

    .signInStaticData {
      border-right: 0px !important;
    }
  }

  .authentication-container {
    overflow: scroll;
  }
}
// Color
$color-primary: #5956e9;
$color-secondary: #e541ed;
$color-primary-900: #06241b;
$color-gray-500: #6d737a;
$color-white: #ffffff;
$color-blue: #5956e9;

// Shades of Purple
$color-purple-1: #a786f8;
$color-purple-2: #7544eb;
$color-purple-3: #7976ee;

// Shades of Violet
$color-violet-1: #c845ec;

// Shades of Black
$color-black-1: #1c0b0b;
$color-black-2: #494949;

// Shades of Black
$kd-color-black-1: #2f2f2f;

// Shades of Grey
$color-grey-1: #e0e0e0;

$color-lightblue: #7c79f4;
$color-lightviolet: #e369e9;
$color-pink: #db7093;
$color-pink-secondary: #e541ed;
$color-heading: #192335;
$color-body: #666666;
$color-footer: #89909f;
$color-white: #ffffff;
$color-white-off: #ffffffab;
$color-bodyest: #273041;
$color-dark: #27272e;
$color-darker: #192335;
$color-black: #111113;
$color-grey: rgba(207, 207, 207, 0.24);
$color-grey2: #f4f5f7;
$color-gray: #a1a9ac;
$color-gray-light: #f6f6f6;
$color-gray-lighter: #ebebeb;
$color-light: #edeffc;
$color-lighter: #f2f5f9;
$primary-opacity: #2f57ef21;
$secondary-opacity: #b966e721;
$coral-opacity: #e9967a21;
$violet-opacity: #80008021;
$pink-opacity: #db709321;
$white-opacity: #ffffff21;
$danger-opacity: #ff000310;
$warning-opacity: #ff8f3c10;
$success-opacity: #3eb75e10;
$black-opacity: rgba(0, 0, 0, 0.04);
$heading-opacity: #19233550;
$color-success: #3eb75e;
$color-danger: #ff0003;
$color-warning: #ff8f3c;
$color-info: #1ba2db;
$color-primary-alt: #d1ebe6;
$color-primary-light: #f1f7ff;
$color-primary-lighter: #eeeeff;
$color-secondary-alt: #fef4f4;
$color-extra: rgb(249, 249, 255);
$color-extra2: #f9f9ff;

$color-border-2: #e6e3f14f;
$color-badge-1: #303f5b;
$color-badge-2: #f2c94c;
$color-facebook: #3b5997;
$color-twitter: #1ba1f2;
$color-youtube: #ed4141;
$color-linkedin: #0077b5;
$color-pinterest: #e60022;
$color-instagram: #c231a1;
$color-vimeo: #00adef;
$color-twitch: #6441a3;
$color-discord: #7289da;

// Border colour
$color-border: #e6e3f1;

// Radius
$radius-10: 10px;
$radius-big: 16px;
$radius-small: 6px;
$border-width: 2px;
$radius: 6px;

// Font-weight
$f-light: 300;
$f-regular: 400;
$f-medium: 500;
$f-semi-bold: 600;
$f-bold: 700;
$f-extra-bold: 800;
$f-black: 900;

// Shadow
$shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
$shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
$shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
$shadow-1: 0px 6px 34px rgba(215, 216, 222, 0.41);
$shadow-slide-item: 0px 0 10px 0 rgba(215, 216, 222, 0.2);
$shadow-2: 0 15px 34px 0 rgba(175, 181, 204, 0.32);
$shadow-3: 0 5px 15px 0 rgba(85, 74, 101, 0.37);
$shadow-5: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
$shadow-7: 0px 15px 30px -2px rgba(0, 0, 0, 0.1);
$shadow-8: -5px 18px 60px 0 rgba(210, 210, 234, 0.4);
$shadow-9: 0 13px 48px 0 rgba(215, 216, 222, 0.44);
$shadow-10: 0 13px 14px 0 rgba(129, 104, 145, 0.05);
$shadow-11: 0 0 10px 0 rgba(0, 0, 0, 0.06);

// Animation
$transition: 0.3s;
$transition-2: 256ms ease-in-out;
$transition-3: transform 0.85s cubic-bezier(0.23, 1, 0.32, 1), background 0.3s,
  border 0.3s, border-radius 0.3s, box-shadow 0.3s;
$transition-4: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

// Font Family
$font-primary: "Poppins", sans-serif;
$font-secondary: "Poppins", sans-serif;

// Font-Size
$font-size-b1: 1.2rem;
$font-size-b2: 1.5rem;
$font-size-b3: 1.8rem;
$font-size-b4: 2.4rem;
$font-size-b5: 3rem;
$font-size-b6: 3.2rem;

// Line Height
$line-height-b1: 1rem;
$line-height-b2: 2rem;
$line-height-b3: 3rem;
$line-height-b4: 4rem;
$line-height-b5: 5rem;

// Heading Element
$h1: 4.4rem;
$h2: 4rem;
$h3: 3.2rem;
$h4: 2.6rem;
$h5: 2rem;
$h6: 1.6rem;

// Gap
$div-gap-1: 10px;

// Gradient
$gradient-1: linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 255) 100%);
$gradient-4: linear-gradient(180deg, #fff, #f6f9ff);
$gradient-2: linear-gradient(
  0deg,
  rgb(255, 255, 255) 0%,
  rgb(240, 242, 255) 100%
);
$gradient-3: linear-gradient(90deg, #8da9fc 0%, #b48dd5 100%);
$gradient-4: linear-gradient(180deg, #eff1ff 0%, #ffffff 100%);
$gradient-5: linear-gradient(rgb(239, 241, 255) 0%, rgb(239, 241, 255) 100%);
$gradient-6: linear-gradient(
  to right,
  rgba(255, 255, 255, 0.15),
  rgba(251, 199, 226, 0.15),
  rgba(220, 217, 254, 0.15)
);
$gradient-7: linear-gradient(
  218.15deg,
  var($color-secondary) 0%,
  var($color-primary) 100%
);
$gradient-8: linear-gradient(270deg, #9e77ff 0%, #4460ff 100%);
$gradient-9: linear-gradient(180deg, #6827ff 0%, #e3f0ff 100%);

$primary-gradient: linear-gradient(
  90deg,
  $color-secondary 0%,
  $color-primary 100%
);
$secondary-gradient: linear-gradient(
  90deg,
  $color-purple-3 100%,
  $color-violet-1 94.22%
);
$tutor-gradient: linear-gradient(
  180deg,
  $color-purple-1 0%,
  $color-purple-2 100%
);
$whatsapp-gradient: linear-gradient(0deg, #25cf43 0%, #61fd7d 100%);

.w-85{
  width: 85%;
}

.w-90{
  width: 90%;
}

@media only screen and (max-width: 600px) {
  .w-85{
    width: 95%;
  }
}
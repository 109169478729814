@import "../../Styles/variables.scss";

.sidebar-left {
  width: 35rem;
}

.sidebar-right {
  width: 45rem;
}

.library-top,
.faq-top {
  background-color: #eee6fb;
}

.library-top-card {
  width: 100% !important;
  background-color: #f7e9f6 !important;
  box-shadow: none !important;
}

.subject-active {
  border: 1px solid #e541ed;
}

.card-number {
  color: $color-secondary;
}

.contact-card {
  width: 100% !important;
  margin-top: -23% !important;
  background-color: white !important;
  height: 150px !important;
  margin-bottom: 50px;
}

.search-container {
  width: 50%;
  margin: 0 auto;
}

.contactus-card,
.history-card {
  width: 100% !important;
  height: 100% !important;
}

.home-card {
  background-color: #7c79f4 !important;
}

.bonus {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #e541ed;
  width: 9.8rem;
  height: 3.4rem;
  position: absolute;
  top: -1.7rem;
  right: auto;
  left: auto;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2rem;
  border-radius: 10px;
}

.dashboard-dic {
  color: #5956e9;
  font-weight: 600;
}

button.button.button-.mt-5.w-100.order-btn {
  background-color: #fff;
  color: #000;
  border: 1px solid #e541ed;
}

.payment-status {
  font-size: 2rem;
}

span.payment-status.paid {
  padding: 10px 40px;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border: 1px solid #e5e5e5;
  border-right: none;
  cursor: pointer;
  font-size: 16px;
}

span.payment-status.unpaid {
  padding: 10px 40px;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid #e5e5e5;
  border-left: none;
  cursor: pointer;
  font-size: 16px;
}

.active-pay {
  background-color: #6354e9;
  color: #fff;
}

.Dashboard-Questions {
  text-align: start;
  border: 1px solid #d1d1d1;
  width: 30%;
  padding: 1rem;
  height: fit-content;
  border-radius: 7px;
  background-color: #fff;
}

.Dashboard-title {
  background-color: #e3e3ff;
  padding: 1.5rem 1rem;
}

nav.mainmenu-nav {
  font-size: 1.6rem;
}

small.badge.color-body {
  color: #666666;
}

li.myquestions.sidenav {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
  border-bottom: 1px solid #ccc;
}

a.text-decoration-none {
  color: #000;
}

a.text-decoration-none:hover {
  color: #000;
}

.questions-list {
  border: 1px solid #d1d1d1;
  width: 70%;
  text-align: start;
  margin-left: 15px;
  border-radius: 7px;
  background-color: #fff;
}

.answer-list {
  border: 1px solid #424040;
  // width: 100%;
  text-align: start;
  margin-left: 15px;
  border-radius: 7px;
  margin-bottom: 15px;
}

input#inputFromDate {
  font-size: 1.8rem;
}

select#exampleSelect {
  font-size: 1.8rem;
}

.student-question-list.row {
  font-size: 1.8rem;
}

span.question-hr {
  color: #b5b2b2;
}

.subject-name {
  background-color: #e0f7fc;
  color: #5956e9;
  padding: 0 1rem;
  border-radius: 0.7rem;
  font-size: 16px;
  line-height: 32px;
}

.question-type {
  background-color: #ffe7e3;
  color: #ff6448;
  margin-left: 10px;
  padding: 0 1rem;
  border-radius: 0.7rem;
  font-size: 16px;
  line-height: 32px;
}

p img {
  width: 100%;
}

.earnmoney-page {
  font-size: 1.6rem;
}

button.myBtn.btn.btn-secondary {
  border: 1px solid #e541ed;
  color: #e541ed;
  padding: 0.6rem;
  border-radius: 10px;
  background-color: #fff;
  font-size: 1.6rem;
}

.question-border {
  border-bottom: 1px solid #cdcaca;
  padding-bottom: 1.8rem;
}

.add-contact {
  width: 85%;
}

.category-Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 2.8rem 2rem;
  position: relative;
  width: 90% !important;
  height: 100% !important;
  background-color: #fff !important;
  cursor: pointer;

  &::before {
    content: "";
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    border-radius: 2rem;
  }
}

.ques-subjects {
  margin-left: 18px;
  font-size: 1.6rem;
  margin-bottom: 19px;
}

.question-title {
  margin-left: 18px;
  font-size: 1.6rem;
  margin-bottom: 9px;
  color: black;
  font-weight: 600;
}

button.answer-btn.btn.btn-secondary {
  margin-left: 16px;
  padding: 1rem 2.3rem;
  font-size: 1.4rem;
  background-color: #fff;
  color: #000;
}

.s-upvote {
  padding: 0.7rem 2rem;
  border: 1px solid gray;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 17px;
}

.s-downvote.mx-4 {
  padding: 0.7rem 2rem;
  border: 1px solid gray;
  border-radius: 6px;
  cursor: pointer;
}

.vote-icon {
  transition: transform 0.2s;
  /* Add transition for smooth effect */
}

.vote-icon:hover {
  transform: scale(1.2);
  /* Scale up the icon on hover */
}

.ask-que {
  background-color: #e541ed;
}

.arrow-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: 3px solid #ffffff;
  background-color: #7c79f4;
  position: absolute;
  top: auto;
  bottom: auto;
  right: -2rem;
  top: 5rem;
}

button.myBtn.myBtnDisabled.btn.btn-secondary.disabled {
  border: 1px solid blue;
  color: #000;
}

.active {
  color: #e541ed !important;
  background: transparent !important;
}

@media only screen and (min-width: 320px) and (max-width: 1023px) {
  .sidebar-right {
    display: none;
  }
}

@media (max-width: 1024px) {
  .dash-mobile {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .sidebar-left {
    width: 6rem;

    li {
      padding: 1rem !important;
    }

    .link-text {
      display: none;
    }
  }

  .py-5.w-75.dashboard.container,
  .w-75.dashboard {
    width: 100% !important;
  }

  .Dashboard-Questions {
    width: 100%;
    margin-bottom: 1rem;
  }

  .questions-list {
    width: 100%;
    margin-left: 0px;
  }

  .subject-name {
    font-size: 1.5rem;
    display: block;
  }

  .question-type {
    background-color: #ffe7e3;
    color: #ff6448;
    margin-left: 10px;
    padding: 0 1rem;
    border-radius: 0.7rem;
    font-size: 16px;
    line-height: 32px;
  }

  button.answer-btn.btn.btn-secondary {
    margin-top: 13px;
  }

  .faq-image {
    width: 86%;
  }

  .earnmoney-page.p-3 {
    overflow: scroll;
    font-size: 1.1rem;
    width: 800px;
  }

  .contact-card {
    margin-top: -15% !important;
  }

  .add-contact {
    width: 100%;
  }

  .contactus-card-m.card {
    border-radius: 7px;
    margin-top: 16px;
  }
}

.p-2.px-2.cancel-order {
  color: #000;
  background-color: #ffff;
  border: 1px solid #e541ed;
  padding: 4px !important;
  font-size: 15px;
}

.no-question-found {
  border: 1px solid #e541ed;
  padding: 2rem;
  border-radius: 3rem;
}

.for-faq {
  width: 100%;
}

tr.table-bg th {
  background-color: #5956e9;
  color: #fff;
}

.reward-box-pop {
  text-align: start;
  border: 1px solid #dee2e6;
  padding: 4px 10px;
  margin-top: 5%;
}

th.rewards-box {
  width: 25%;
}

th.Questions-box {
  width: 20%;
}

input.p-4.font-size.form-control {
  font-size: 1.5rem;
  padding: 1rem !important;
}

textarea#exampleAddress\ pay {
  font-size: 1.5rem;
}

.border-time {
  width: 50px;
  height: 50px;
  border-radius: 1px solid #fcb2ff;
  background-color: #d2d1ff;
  border-radius: 5px;
  margin-left: 1rem;
  font-size: 1.4rem;
}

.timer-live {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

button.live-session.btn.btn-secondary {
  background-color: #ed4160;
  padding: 1rem 3rem;
  border: #ed4160;
  border-radius: 4px;
  font-size: 1.2rem;
}

.content-card-live {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-top: none;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.line-design.mb-4 {
  font-size: 1.6rem;
}

svg.plus-icon {
  border: 2px solid;
  border-radius: 14px;
  font-size: 22px;
  color: #5956e9;
  padding: 3px;
}

button.Form_session_pay_button.btn.btn-secondary {
  padding: 5px 30px;
  color: #fff;
  background-color: #e541ed;
  border: none;
  font-size: 1.5rem;
}

h2.mt-2.thank-you {
  color: #e541ed;
  font-weight: 600;
  padding-top: 2px;
}

.border-buttom-live {
  border-bottom: 1px solid #cbc1c1;
  padding-bottom: 2rem;
}

.zoom-whatsapp {
  margin-top: 10px;
}

.zoommyclass {
  color: #00aaff;
}

.qa-wrapper {
  padding: 10px;

  p {
    margin: 0;
  }
}

span.link-text {
  font-size: 15px;
}

.disabled-element {
  // pointer-events: none;
  cursor: not-allowed !important;
}

.Paginationbox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

h2.heading.accordion-header button {
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1.5rem;
}

.accordion-body {
  font-size: 1.5rem;
}

.category-Card img {
  width: 80px;
  height: 80px;
}

@media only screen and (max-width: 600px) {

  .contact-card {
    height: 120px !important;
  }

  .category-Card img {
    width: 100px;
    height: 50px;
  }

  .ask-que {
    width: 88% !important;
  }

  .place-your-writing-container {
    height: auto;
  }

  .reference-section.mb-1.mt-4 {
    padding-top: 31px;
  }

}

.button-linear-gradient-primary.dashboard {
  background: #e541ed;
}


.our-location {
  font-weight: 600;
  font-size: 1.6rem !important;
}


@media (min-width: 1200px) {
  .our-location {
    font-size: 1.3rem !important;
  }

  .contact-add {
    font-size: 1.15rem !important;
  }
}

.review-dashboard {
  display: flex;
  gap: 16px;
  margin-top: 3rem;
}
@import "../Styles/variables";

.step-header {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Poppins";
}

.step-sub-header {
  padding-top: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.stap-form-1 {
  padding-top: 1rem;
}

.form-label {
  font-size: 2rem;
}

.form-check-input {
  margin-top: 0%;
  width: 3rem;
  height: 3rem;
  border: 1px solid $color-primary !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("../Assets/images/check.svg") !important;
}

.form-check-input:checked {
  background: linear-gradient(180deg, #e541ed 0%, #5956e9 100%),
    linear-gradient(0deg, #e5e5fe, #e5e5fe) !important;
}

.question-textarea {
  height: 25rem;
  font-size: 2rem;
  border-radius: 1.5rem;
}

.form-select {
  font-size: 2.5rem;

  &:focus {
    box-shadow: none !important;
  }
}

.type-select,
.type-select:focus-visible {
  border: 2px solid #e541ed;
}

.card {
  width: 50% !important;
  height: 100% !important;
  background-color: transparent !important;
}

.card::before {
  background-color: transparent !important;
}

.card-title {
  font-size: 2.5rem !important;
}

.card-body {
  width: 100%;
}

.card-text {
  font-size: 2rem !important;
}

.librarybanner-title {
  font-size: 3.2rem;
  line-height: 5rem;
  color: #06241b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

// above Delete
.banner-main-wrapper {
  height: calc(75vh - 80px);
  position: relative;
  display: flex;
  justify-content: center;

  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vh - 120px);
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10px;
    width: 75rem;

    &-title-wrapper {
      .title {
        font-size: 3.6rem;
        line-height: 5rem;
        color: $color-primary-900;
        font-family: $font-secondary;

        .gradient-title {
          font-weight: $f-semi-bold;
          ;
          font-family: $font-primary;
          background: $primary-gradient;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
        }
      }
    }

    &-subtitle {
      font-size: 1.5rem;
      font-weight: $f-medium;
      line-height: 2.5rem;
      color: $color-gray-500;
    }

    &-available-text {
      font-size: 3rem;
      line-height: 7.2rem;
      color: $color-primary-900;
      font-weight: $f-semi-bold;
      ;
    }
  }

  @media only screen and (max-width: 425px) {

    .title h1 {
      // padding-top: 10px;
      line-height: 30px;
    }

    /* Styles for mobile phones */
    .banner-content-title-wrapper .title {
      // font-size: 3rem;
      // line-height: 3rem;
      font-size: 1.7rem !important;
      line-height: 2.5rem;

    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 1.6rem !important;
    }

    .banner-content-subtitle {
      font-size: 1.3rem;
    }

    .banner-content-available-text {
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 425px) and (max-width: 768px) {

    .banner-content {
      padding: 3rem 1rem;
    }

    .banner-content-title-wrapper .title {
      font-size: 4.2rem;
      line-height: 5.1rem;
    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .banner-content-subtitle {
      font-size: 1.2rem;
    }

    .banner-content-available-text {
      font-size: 3rem;
    }
    .title strong {
      font-weight: 600;
      font-size: 1.6rem !important;
    }
    button {
      padding: 0.7rem 4.3rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {

    .banner-content-title-wrapper .title {
      font-size: 6rem;
      line-height: 6rem;
    }

    .banner-content-subtitle {
      font-size: 2.2rem;
    }

    button {
      padding: 0.8rem 4.5rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {

    .banner-content-title-wrapper .title {
      font-size: 6.5rem;
      line-height: 6.5rem;
    }

    .banner-content-subtitle {
      font-size: 2.4rem;
    }
  }
}


.title strong {
  font-weight: 600;
}



.img-fluid-home {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .img-fluid-home {
    max-width: 100%;
  }
}

.title.mt-4 {
  margin-top: 10rem !important;
}
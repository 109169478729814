@import "../../Styles/variables";

.modal {
  font-family: 'Poppins';
}

.authentication-bg {
  height: 100%;
  width: 100%;
  position: fixed;
  background: url("../../Assets/images/background/authentication-bg.svg") no-repeat center bottom/contain;
}

.closBtn {
  margin-right: auto;
  font-size: 16px;
  border: none;
  background: none;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: #e541ed;
  color: white;
}

.btnClose {
  margin-right: auto;
  font-size: 16px;
  border: none;
  background: none;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  color: #e541ed;
}

.step-header {
  padding-top: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.step-sub-header {
  padding-top: 2rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.stap-form-1 {
  padding-top: 1rem;
  display: flex;
}

.form-label {
  font-size: 1.5rem;
}

.form-check-input {
  margin-top: 0%;
  width: 2rem;
  height: 2rem;
  border: 1px solid $color-primary !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked[type="radio"]::after {
  --bs-form-check-bg-image: url("../../Assets/images/check.svg") !important;
}

.form-check-input:checked {
  background: linear-gradient(180deg, #e541ed 0%, #5956e9 100%),
    linear-gradient(0deg, #e5e5fe, #e5e5fe) !important;
}

.question-textarea {
  height: 25rem;
  font-size: 2rem;
  border-radius: 1.5rem;

  .ql-container {
    height: 80%;
  }
}

.form-select {
  font-size: 1.5rem;

  &:focus {
    box-shadow: none !important;
  }
}

.select-subject {
  font-size: 1.8rem;
}

.type-select,
.type-select:focus-visible {
  border: 2px solid #e541ed;
}

.card {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
}

.card::before {
  background-color: transparent !important;
}

.card-title {
  font-size: 2.5rem !important;
}

.card-body {
  width: 100%;
}

.card-text {
  font-size: 1.5rem !important;
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .card-text {
    font-size: 1.2rem !important;
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .card-text {
    font-size: 2rem !important;
    margin-top: 3rem;
  }
}

.nestedPopup {
  font-size: 2.7rem;
  font-weight: 500;
}

.resumeBtn {
  font-size: 2.2rem;
  cursor: pointer;
}

.exitBtn {
  font-size: 2.2rem;
  color: #0000008f;
  cursor: pointer;
}

.auto-width {
  display: inline-block;
  width: max-content;
}

.path-contents {
  display: contents;
}

.modal-fullscreen {
  width: 100% !important;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
  width: 100%;
}
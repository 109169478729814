@import "../../Styles/variables";

.whatsappCard {
  width: 48%;
  height: 5.2rem;
  border: 1px solid $color-secondary;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-weight: $f-medium;
  font-size: 1.9rem;
  justify-content: space-between;
  padding: 0px 2rem;
  text-align: start;
  .whatsappIcon {
    background: $whatsapp-gradient;
    padding: 0.5rem;
    border-radius: 1rem;
    height: 3.4rem;
    width: 3.4rem;
    display: flex;
    cursor: pointer;
  }
}

@media (max-width: 1155px) {
  .whatsappCard {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .whatsappCard {
    width: 100%;
  }
}

@import '../Styles/variables';

.advantages-of-questions-container {
    width: 100%;
    margin: 0 auto;
    padding: 4rem 0;
    max-width: 1500px;


    .advantages-of-questions-header {
        margin: 2rem 0;

        span,
        b {
            font-size: 3rem;
        }
    }

    .advantages-of-questions {
        padding-top: 5rem;
        text-align: start;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5rem;
        justify-content: space-around;

        @media (max-width:1155px) {
            padding: 1rem;
            justify-content: center;

            .advantages-of-questions-img {
                display: none;
            }
        }

        ul {
            list-style-position: inside;

            li {
                list-style-image: url('../Assets/icons/arrow-right-pink.png');

                sup {
                    color: #2F2F2F;
                    font-weight: 500;
                }

                padding: 1rem 0;
                font-weight: 500;
                font-size: 2rem;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .advantages-of-questions-container .advantages-of-questions-header span,
    .advantages-of-questions-container .advantages-of-questions-header b {
        font-size: 2.3rem;
    }
}
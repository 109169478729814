@import '../Styles/variables';

.footer-section {
    background-color: #222744;
    color: white;
    padding: 3rem 0;

    .footer-container {
        text-align: start;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 1470px;
        margin: 0 auto;

        .footer-col {
            margin: 4rem 0;

            h3 {
                font-weight: 600;
                padding: 1.5rem 0;
                font-size: 1.5rem;
                color: white;
            }

            ul {
                list-style-type: none;

                .li-icon {
                    display: flex;
                    gap: .5rem;

                    img {
                        padding-top: 0px;
                        margin-right: 8px;
                        width: 16px;
                        height: 16px;
                    }
                }

                li {
                    font-size: 1.5rem;
                    padding: .5rem 0;
                    font-weight: 400;
                    color: #A4A4A4;

                    a {
                        font-size: 1.5rem;
                        padding: .5rem 0;
                        font-weight: 400;
                        color: #A4A4A4;
                    }

                    span {
                        display: flex;
                        gap: 1rem;

                        img {
                            width: 50%;
                            height: auto;
                        }
                    }
                }
            }
        }

        hr {
            width: 100%;
            border: 1px solid #A4A4A4
        }

        .footer-end-section {
            padding: 2rem 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            span {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.footer-end-section p {
    font-size: 12px;
}

ul.social-media-footer {
    margin: 0;
    padding: 0;
}

ul.social-media-footer li a img {
    width: 30px !important;
    height: 30px !important;
}


@media (max-width:768px) {

    .footer-section .footer-container .footer-col h3 {
        padding: 1.2rem 0;
        font-size: 1.3rem;
    }

    .footer-section .footer-container .footer-col ul li a {
        font-size: 1.3rem;
        padding: 0;
    }

    .footer-section .footer-container .footer-col ul li {
        font-size: 1.3rem;
        padding: 0.2rem;
    }

    ul.social-media-footer {
        padding-bottom: 20px;
    }

    .footer-container {
        width: 90% !important;
    }

    .footer-section .footer-container .footer-col {
        margin: 0rem 0;
    }

    ul.social-media-footer.mob {
        display: none;
    }

    ul.social-media-footer.des {
        display: block !important;
    }
}

ul.social-media-footer.des {
    display: none;
}

.modal-content {
    height: auto;
}

.become-text {
    font-size: 17px;
    padding-top: 12px;
    padding-bottom: 12px;
}

img.footer-icon {
    border-radius: 14px;
}
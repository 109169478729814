@import '../Styles/variables';

.student-stories-container {
    margin: 7rem auto;
    width: 100%;
    max-width: 1500px;

    .student-stories-header {
        padding: 2rem 0;
        font-size: 2.4rem;
        font-weight: 600;
    }

    .student-stories-description {
        margin-bottom: 2rem;
        display: block;
        font-size: 1.6rem;
    }

    .carousel-container {
        margin: 2rem;

        .carousel {
            text-align: start;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 3rem;
            margin: 1.4rem;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.082) 0px 4px 12px;
            border-radius: 2rem;
            height: auto;

            .carousel-header {
                display: flex;
                align-items: center;
                gap: 2rem;

                .carousel-title {
                    font-size: 1.6rem;
                    font-weight: 600;
                }

                .carousel-icon {
                    margin-left: auto;
                }
            }

            .carousel-description {
                font-size: 1.2rem;
                line-height: 2.5rem;
            }

            .carousel-img {
                border-radius: 100px;
                width: 80px;
                height: 80px;
            }

        }

        .react-multi-carousel-list {

            .react-multi-carousel-track {
                min-height: 240px;
                height: 100%;
            }

            .react-multi-carousel-dot-list {

                .react-multi-carousel-dot button {
                    background-color: #CFD3D6;
                    border: 0;
                }

                .react-multi-carousel-dot--active button {
                    background-color: $color-pink-secondary;
                    border: 0;
                }
            }
        }
    }
}

.carousel-icon img {
    width: 50px !important;
}

.react-multi-carousel-dot button {
    height: 8px;
    width: 8px;
}

.carousel-subtitle {
    font-size: 1.4rem;
    color: #645757;
}

.carousel.story:hover {
    border: 1px solid rgb(204, 200, 200);
}

.modal-dialog.video-modal {
    margin-top: 13rem;
}

.popup-modal.modal-header {
    border-bottom: none;
}

.modal-dialog.video-modal .modal-content {
    background-color: transparent;
    border: none;
}

.popup-modal.modal-header button.btn-close {
    background-color: #fff;
    border-radius: 11px;
    font-size: 14px;
}
@import "../Styles/variables";

.bonus-section-container {
    background-color: white;
    padding-bottom: 4rem;

    .bonus-container {
        margin: 0 auto;
        width: 100%;
        padding-top: 4rem;

        @media (max-width:600px) {
            width: 100%;
        }

        .bonus-section-title {
            font-size: $font-size-b2;
        }

        .bonus-section-description {
            font-size: 2rem;
            font-weight: $f-semi-bold;
            padding: 1rem;
            display: block;
        }

        .bonus-card-container {
            display: flex;
            position: relative;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            z-index: 99;
            padding: 2rem 0 3rem 0;

            .bonus-section-bg-overlay {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.06);
                height: 40%;
                filter: blur(10px);
                width: 70%;
            }

            .bonus-card {
                z-index: 9;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 30rem;
                height: 17rem;
                background-color: $color-white;
                border-radius: 20px;
                text-align: left;
                border: 1px solid #E5E5E5;

                @media (max-width:600px) {
                    width: 16rem;
                }

                .bonus-card-icon {
                    img {
                        height: auto;
                        width: 4.5rem;
                    }
                }

                .bonus-card-title {
                    font-size: 1.7rem;
                    font-weight: $f-semi-bold;
                    margin: 1rem 0 .5rem 0;
                }

                .bonus-card-description {
                    color: #828282;
                    font-size: 1.3rem;
                }
            }
        }
    }
}




@media (max-width:600px) {
    .bonus-card-title {
        font-size: 1.4rem !important;
    }
}

.bonus-card-title {
    // font-size: 1.2rem;
    font-size: 1.8rem;
    font-weight: $f-semi-bold;
    margin: 1rem 0 .5rem 0;
}
@import "../Styles/variables";

.bonussection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 7.4rem;

  .title {
    color: $kd-color-black-1;
    font-size: 2.8rem;
    font-weight: $f-semi-bold;
    line-height: $line-height-b4;
    padding-bottom: 0.5rem;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding-bottom: 7rem;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 5%);
  width: 44.6rem;
  height: 44.5rem;
  // padding: 5rem 2.8rem 6rem;
  padding: 2rem 2.8rem 2rem;
  position: relative;

  &* {
    z-index: 1;
  }

  .label {
    color: #2f2f2f;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 2rem;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }

  .discount-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: -webkit-fill-available;
    border-radius: 1.8rem;
    background: $color-lightblue;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    text-transform: capitalize;
    width: 39rem;
    height: 12.4rem;

    .bonus {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      background: $color-secondary;
      width: 9.8rem;
      height: 3.4rem;
      position: absolute;
      top: -1.7rem;
      right: auto;
      left: auto;
      font-size: $font-size-b2;
      font-weight: $f-bold;
      line-height: $line-height-b2;
      border-radius: $radius-10;
    }

    .discount {
      color: $color-white;
      text-align: center;
      font-size: 4.5rem;
      font-weight: $f-semi-bold;
      line-height: 7.1re;
    }

    .off {
      color: $color-white;
      text-align: center;
      font-size: 2.2rem;
      font-weight: $f-semi-bold;
      line-height: 3rem;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      border: 3px solid $color-white;
      background-color: $color-lightblue;
      position: absolute;
      top: auto;
      bottom: auto;
      right: -2rem;
    }
  }

  .message {
    margin-top: 0.5rem;
    color: #5956e9;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 3rem;
    text-transform: capitalize;
  }

  &.blue {
    .discount-wrapper {
      background: $color-lightviolet;

      .bonus {
        background: $color-lightblue;
      }

      .arrow {
        background: $color-lightviolet;
      }
    }

    .message {
      color: $color-lightviolet;
    }
  }

  .tag1 {
    position: absolute;
    top: 4.4rem;
    right: 4rem;
  }

  .tag2 {
    position: absolute;
    left: 6rem;
    bottom: 3.6rem;
    z-index: 0;
  }
}

.card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #e5e4ff;
  border-radius: 2rem;
}

.blue::before {
  background: #f7e3f8;
}

@media only screen and (max-width: 1400px) {
  .bonussection {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    padding-top: 3rem;
  }
}

@media screen and (max-width: 767px) {

  .discount {
    font-size: 3.5rem !important;
    line-height: 3rem;
  }

  .bonussection {
    padding-bottom: 10px;
  }

  .card .discount-wrapper {
    width: 30rem;
    height: auto;
    padding: 3rem 1rem 1rem 1rem;
  }
}
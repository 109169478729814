@import "../../Styles/variables";

.billingWrapper {
  min-height: 100vh;
  // background-color: $color-grey-1;
  background-color: #ffedff;

  .billingCard {
    width: 50%;
    margin: 2rem auto;
    padding: 3.2rem;
    background-color: $color-white;
    border-radius: 1rem;
    text-align: left;
    border: 1px solid #ed2bfc;

    .title {
      font-weight: 600;
      font-size: 2.5rem;
    }

    .subtitle {
      font-size: 1.8rem;
    }

    .form-control {
      padding: 1rem;
      // background: linear-gradient(0deg, #d2d2d2, #d2d2d2),
      //   linear-gradient(0deg, #f8f8f8, #f8f8f8);
      border: 1px solid #d2d2d2;
    }

    select#paymentSelect {
      padding: 0.6rem;
      background: linear-gradient(0deg, #d2d2d2, #d2d2d2), linear-gradient(0deg, #f8f8f8, #f8f8f8);
      border: 1px solid #d2d2d2;
      font-size: 1.6rem;
    }
  }
}

@media (max-width: 768px) {
  .billingWrapper .billingCard {
    width: 90%;
  }

  .billingWrapper .billingCard {
    padding: 1.2rem;
  }
}

.invalid-feedback {
  font-size: 1.6rem;
}
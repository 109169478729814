.mainHeading {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  text-align: center;
}

.category-card {
  height: 20.8rem;
  text-align: center;
  box-shadow: 10px 13px 28px 5px #00000014;
}

.category-label {
  font-weight: 600;
  font-size: 1.6rem;
}

.category-sublabel {
  color: #808080;
  font-weight: 400;
  font-size: 1.5rem;
}
.whatsapp-form {
    position: fixed;
    bottom: 14px;
    left: 45px;
    z-index: 9999;
    transform: translateY(100%);
    transition: transform 0.8s ease-in-out;
}

.whatsapp-form.visible {
    transform: translateY(0);
}

.whatsapp-button.btn-sm.p-0.mb-0.mr-0.text-center.w-100 {
    border: none;
    font-size: 16px;
    padding: 8px !important;
    border-radius: 34px;
    text-align: start !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.whatsapp-icon {
    margin-right: 10px;
    z-index: 99;
}

.whatsapp-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.whatsapp-heading,
.whatsapp-subheading {
    transform: translateX(-100%);
    transition: transform 0.9s ease-in-out;
}

.whatsapp-heading.visible,
.whatsapp-subheading.visible {
    transform: translateX(0);
}


.whatsapp-heading {
    font-size: 18px;
    margin: 0;
    padding-right: 20px;
}

.whatsapp-subheading {
    font-size: 14px;
    margin: 0;
}

@media only screen and (max-width: 450px) {
    .whatsapp-form {
        left: 0 !important;
    }
}
@import '../../../Styles/variables';

.advantages-of-definition-container {
    width: 100%;
    margin: 0 auto;
    padding: 4rem 0;
    max-width: 1360px;

    .advantages-of-definition-header {
        .advantages-of-definition-title {
            margin: 0rem 0;

            span,
            b {
                font-size: 3rem;
            }
        }

        .advantages-of-definition-description {
            font-size: 1.4rem;
            padding: 1rem;
            text-align: center;
            width: 100%;

            @media (max-width:1155px) {
                width: 100%;
            }
        }
    }

    .advantages-of-definition {

        .advantages-of-definition-img-container {
            display: flex;
            gap: 2rem;

            img {
                width: 100%;
            }

            @media (max-width:1155px) {
                flex-wrap: wrap;
            }
        }


        .advantages-of-definition-action {
            width: 100%;
            text-align: center;
            float: right;
            font-size: 1.4rem;
            line-height: 3rem;
            padding: 2rem;

            @media (max-width:1155px) {
                width: 100%;
                padding: 2rem 1rem;
            }
        }

        @media (max-width:1155px) {
            padding: 1rem;
            justify-content: center;
        }
    }
}

.advantages-of-definition-header {
    padding-bottom: 2rem;
}

.advantages-of-definition-img {
    width: 100%;
}
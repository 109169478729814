@import "../Styles/variables";

.help-container {
  background-color: #f1f2f8;

  .help-steps-container {
    width: 100%;
    padding-top: 7rem;
    padding-bottom: 8rem;

    .help-steps-title {
      font-size: $font-size-b4;
      padding: 1rem 0;
    }

    .help-steps-description {
      font-size: $font-size-b2;
      padding: 1rem 0;
    }

    .row {
      display: flex;

      .col-5 {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .help-steps-card {
      background-color: $color-white;
      border-radius: 1.5rem;
      margin: 3rem 0;
      padding: 2rem 2rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;

      .help-steps-title-parent {
        width: 40%;
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .help-steps-card-index {
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        font-weight: $f-semi-bold;
        background: $secondary-gradient;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;

        .help-steps-card-down-arrow {
          border: 1.5rem solid transparent;
          border-bottom: 0;
          border-top: 1rem solid #ef18ff;
        }
      }

      .help-steps-card-title {
        color: $kd-color-black-1;
        font-weight: $f-bold;
        font-size: $font-size-b3;
        text-align: left;
      }

      .help-steps-card-description {
        color: #505050;
        text-align: left;
        font-size: 1.4rem;
        padding-left: 2rem;
        border-left: 1px solid #ccc;
        width: 80%;
      }
    }

    @media only screen and (max-width: 767px) {
      // .help-steps-container {
      width: 90vw;
      margin: 0 auto;
      // }
    }
  }

  .bonus-section-container {
    background-color: white;
    padding-bottom: 4rem;

    .bonus-container {
      margin: 0 auto;
      padding-top: 4rem;

      .bonus-section-title {
        font-size: $font-size-b2;
      }

      .bonus-section-description {
        font-size: 2rem;
        font-weight: $f-semi-bold;
        padding: 1rem;
        display: block;
      }

      .bonus-card-container {
        display: flex;
        position: relative;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        z-index: 99;
        padding: 2rem 0 3rem 0;

        .bonus-section-bg-overlay {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.06);
          height: 40%;
          filter: blur(10px);
          width: 70%;
        }

        .bonus-card {
          z-index: 9;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 24rem;
          height: 15rem;
          background-color: $color-white;
          border-radius: 20px;
          text-align: left;
          border: 1px solid #e5e5e5;

          .bonus-card-icon {
            img {
              height: auto;
              width: 4.5rem;
            }
          }

          .bonus-card-title {
            font-size: 1.2rem;
            font-weight: $f-semi-bold;
            margin: 1rem 0 0.5rem 0;
          }

          .bonus-card-description {
            color: #828282;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.container-fluid .top-header {
  max-width: 1600px;
  margin: auto;
}

// .title p {
//   padding-bottom: 10px;
// }


@media (max-width: 768px) {

  .help-steps-title-parent {
    width: 50% !important;
  }

  .help-steps-card {
    padding: 1.5rem 1.5rem !important;
  }

  .help-steps-card-title {
    font-size: 1.3rem !important;
  }

  .help-steps-card-description {
    font-size: 1.2rem !important;
  }

  .help-steps-card {
    margin: 1rem 0 !important;
  }

  .help-steps-container {
    margin-top: 3rem !important;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
.header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  // .bg-color-darker.pt--10.pb--10.pt-3.pb-3.header-space-betwween {
  //   display: none;
  // }
  .Whatsapp-any-query {
    font-size: 12px;
  }
}
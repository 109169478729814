@import "../Styles/variables";

.askSection {
  min-height: 10.2rem;
  padding: 20px;
  color: rgb(255, 255, 255);
  background-size: 170% 100%;
  background-image: $secondary-gradient;
  outline: 1px solid #ffffff6e;
  outline-offset: -7px;
  display: flex;
  align-items: center;
  justify-content: center;

  .askSectionWrapper {
    display: flex;
    align-items: center;
    gap: 4rem;

    .contentWrapper {
      display: flex;
      gap: 1rem;

      .bigLabel {
        font-family: $font-secondary;
        font-weight: $f-semi-bold;
        font-size: 2.3rem;
        line-height: 2.8rem;
      }

      .smallLabel {
        font-family: $font-secondary;
        font-weight: $f-regular;
        line-height: 2.8rem;
        font-size: 2.3rem;
      }
    }

    .content-seprator {
      height: 5rem;
      border: 0.1rem solid $color-white;
    }
  }

  @media only screen and (max-width: 767px) {
    .askSectionWrapper {
      gap: 1rem;
      flex-direction: column;

      .contentWrapper {
        flex-direction: column;
        gap: 1rem;

        .bigLabel {
          font-size: 2.5rem;
          line-height: 2rem;
        }

        .smallLabel {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      .content-seprator {
        display: none;
      }

      .buttonWrapper {
        button {
          padding: 0.8rem 3.5rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .askSectionWrapper {
      gap: 1rem;
      flex-direction: column;

      .contentWrapper {
        flex-direction: column;
        gap: 1rem;

        .bigLabel {
          font-size: 2rem;
        }

        .smallLabel {
          font-size: 1.3rem;
        }
      }

      .content-seprator {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .askSectionWrapper {
      gap: 1rem;

      .contentWrapper {
        gap: 1rem;

        .bigLabel {
          font-size: 2rem;
        }

        .smallLabel {
          font-size: 1.3rem;
        }
      }

      .content-seprator {
        display: none;
      }
    }
  }
}
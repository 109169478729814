.read-more-section-container {
    width: 100%;
    text-align: start;
    padding: 5rem 3rem;
    background-color: white;
    margin: 0 auto;
    max-width: 1500px;

    .read-more-section-title {
        font-weight: 600;
        font-size: 1.9rem;
        color: #2F2F2F;
        padding-bottom: 1rem;
    }

    .read-more-section-description {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 3rem;
        text-align: justify;
    }

    .read-more-btn {
        padding: 2rem;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {

    .read-more-section-description {
        font-size: 1.2rem !important;
        line-height: 2.2rem !important;
    }
}
@import "../../Styles/variables";

.assignment-selection-container {
  .card {
    font-weight: 500;
    &.selected {
      border: 1px solid $color-lightblue;
    }

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100% !important;
    height: 11rem !important;

    .assignment-selection-icon {
      img {
        height: 3rem;
      }
    }
    .assignment-subcategory-text {
      font-size: 1.3rem;
      text-align: center;
      font-family: 'Poppins';
      font-weight: 500;
    }
  }
}

.place-your-writing-container {
  border: 1px dashed $color-primary;
  border-radius: 1rem;
  height: 13.5rem;
  overflow: hidden;
  .place-your-writing-label {
    width: 100%;
    height: 100%;

    .upload-file-container {
      position: relative;
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 20px;
      font-size: 1.6rem;
      border-radius: 10px;
      cursor: pointer;
      overflow-y: auto;
    }
  }

  #typeYourQuestion {
    padding: 2rem;
    font-size: 1.6rem;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    resize: none;
  }
}

.reference-section {
  display: flex;
  justify-content: center;
  align-items: center;

  .place-your-writing-container {
    border: none;
    border-left: 1px solid #dee2e6;
    border-radius: 0;
  }

  .form-check-input:checked,
  .form-check-input:checked[type="radio"] {
    background: $color-secondary !important;
  }
}

.select-your-subject-container {
  .form-check-input:checked,
  .form-check-input:checked[type="radio"] {
    background: $color-secondary !important;
  }
}

.no-of-words-container {
  // border: 1px solid #dee2e6;
  font-size: 2.4rem;

  input {
    text-align: center;
    font-size: 2.4rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span {
    display: flex;
    margin: 1rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 1rem;
    background-color: #f7f7f7;
  }
}

.estimatedPriceLabel {
  font-size: 2.4rem;
  font-weight: 600;
}

.estimatedPrice {
  font-size: 2.4rem;
  font-weight: 400;
}

.date-time-container {
  display: flex;
  font-size: 1.6rem;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid $color-primary;
  cursor: pointer;
  height: 200px;
  border-radius: 10px;
}

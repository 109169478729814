@import '../Styles/variables';

.world-wide-container {
    display: flex;
    align-items: center;
    text-align: start;
    padding: 7rem 2rem;
    gap: 5rem;
    background-color: #F1F2F8;
    max-width: 1500px;
    margin: auto;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 5rem 2rem;
    }

    .world-wide-img {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .world-wide-details {
        width: 100%;
        gap: 1rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .world-wide-details-title {
            font-weight: 600;
            font-size: 2.8rem;
            color: #06241B;

            span {
                color: #4518FF;
            }

            @media only screen and (max-width: 768px) {
                flex-direction: column;
                font-size: 2.4rem;
            }
        }

        .world-wide-details-description {
            font-weight: 400;
            font-size: 1.7rem;
            color: #06241B;
        }

        .world-wide-details-details {
            font-weight: 400;
            font-size: 1.5rem;
            padding: 1rem 1rem 1rem 0rem;
            text-align: justify;
            color: #06241B;
        }
    }
}


@media screen and (max-width: 767px) {
    .world-wide-container .world-wide-details .world-wide-details-details {
        font-weight: 400;
        font-size: 1.2rem;
        padding: 0rem;
        color: #06241B;
        text-align: justify;
    }
}

.world-wide-container-bg {
    width: 100%;
    margin: auto;
    background-color: #F1F2F8;
}
@import "../Styles/variables";

.helpsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7rem;
  margin: 0 auto;
  background-color: #fff;

  .card-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.8rem;
    padding-bottom: 8rem;
  }
}


.title {
  color: $kd-color-black-1;
  font-size: 2.8rem;
  font-weight: $f-semi-bold;
  line-height: $line-height-b4;
  padding-bottom: 0.5rem;
}

.description {
  color: $color-gray-500;
  font-size: 1.8rem;
  font-weight: $f-regular;
  padding-bottom: 4.8rem;
}
button.navbar-toggler {
  padding: 10px;
}


@media only screen and (max-width: 768px) {

  .helpsection {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
}
  .description {
    font-size: 1.5rem;
    padding-bottom: 20px;
  }
 
}

@media only screen and (max-width: 1400px) {
 
}
@import "../Styles/variables";

.nav-link {
  font-size: 2rem;
  color: $kd-color-black-1 !important;
  font-weight: 600;
}

.navbar-nav li:first-child {
  z-index: 99;
}

.navbar-nav li:nth-child(2) {
  z-index: 88;
}

.navbar-nav li:last-child {
  z-index: 77;
}

.top-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.menu {
  position: relative;
  transition: $transition;
  animation: fade 0.3s linear forwards;

  &:hover {
    color: $color-secondary !important;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 0%;
    height: 7%;

    background-color: var(--primary);
    transition: $transition;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0%;
    right: 0%;
    width: 0%;
    height: 7%;

    background-color: var(--primary);
    transition: $transition;
  }

  &:hover::before,
  &:hover::after {
    width: 100%;
  }
}

.sub-dropdown-menu {
  width: 32rem;
  border-radius: 1.5rem;
  background-color: wheat;
  display: none !important;
  text-align: left;
  box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
}

.dropdown-menu:hover .sub-dropdown-menu {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0%;
  opacity: 0;
  animation: subMenuAnimation 0.5s linear forwards;
}

.sub-menu {
  transition: $transition;
  border-bottom: 1px solid #ccc;

  &:hover,
  &.active {
    border-right: 2px solid $color-secondary;
  }

  &:first-child {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  &:last-child {
    border-bottom: 0px solid $color-gray-500;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
}

.form-control:focus {
  box-shadow: none;
}

.form-control.search {
  border: 1px solid $color-blue;
}

@media only screen and (min-width: 992px) {
  .search-input {
    width: 250px;
  }
}

@media only screen and (max-width: 992px) {
  .search-input {
    width: auto;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .dropdown-menu:hover .sub-dropdown-menu {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: -65% !important;
    opacity: 0;
    animation: subMenuAnimation 0.5s linear forwards;
  }
}

.search-icon {
  width: 28px;
  height: 28px;
  background-color: #5956e9;
  position: absolute;
  top: 3px;
  right: 11px;
  cursor: pointer;
}

.signInButton {
  background-color: $color-blue;
  font-size: 1.5rem;
  padding: 7px 16px;
  line-height: 30px;

  &:hover {
    background-color: $color-blue;
  }
}

@keyframes subMenuAnimation {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .sub-menu-dropdown {
    padding-left: 25px;
  }

  .dropdown-menu:hover .sub-menu-dropdown {
    position: static;
  }

  .menu::before,
  .menu::after {
    height: 0px;
  }
}

.after-login-btn {
  right: 12px;
}
@import '../../Styles/variables';
.example-container {
    background: url('../../Assets/images/background/example-bg.svg') no-repeat center center/cover;
    padding: 3rem 0rem;

    .example-img-container {
        width: 100%;
        padding-bottom: 4rem;
        max-width: 1250px;
        margin: 0 auto;

        .example-title {
            padding: 2rem 0;
            font-size: 1.4rem;
            h2{
                font-size: 2.8rem;
                
                b{
                    font-weight: 600;
                }
            }

            span{
                font-size: 2rem;
            }
        }

        .example-card {
            text-align: start;
            background-color: white;
            padding: 5rem;
            border-radius: 1rem;

            .example-question {
                font-size: 1.3rem;
            }

            .example-header {
                color: $color-pink-secondary;
            }

            .example-question {
                padding: 1rem 0;
            }

            .example-answer {
                h2 {
                    color: $color-pink-secondary;
                    padding: 1rem 0;
                }

                span {
                    font-size: 1.4rem;
                }
            }

            .example-explanation {
                h2 {
                    color: $color-pink-secondary;
                    padding: 2.5rem 0 1rem 0;
                }

                span {
                    font-size: 1.4rem;
                }

                .explanation-read-more {
                    color: #5956E9;
                    border-bottom: 2px solid $color-pink-secondary;
                }
            }

            .option-name {
                color: #5956E9;
                font-weight: bolder;
                font-size: 1.2rem;
            }

            .option-container {
                display: flex;
                flex-wrap: wrap;
                // min-width: 50%;
                gap: 1rem;

                .option {
                    padding: 1rem;
                    border: 1px solid #A1A1A1;
                    border-radius: .5rem;
                    font-weight: bolder;
                    font-size: 1.2rem;

                    span {
                        color: #5956E9;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .example-container .example-img-container .example-card {
        padding: 3rem;
        margin: 2rem;
    }
    .example-container .example-img-container .example-title h2 {
        font-size: 2.2rem;
    }

    }



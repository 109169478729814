@import "../Styles/variables";

.help-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  margin: 0 auto;
  width: 100%;

  .title {
    color: $kd-color-black-1;
    font-size: $font-size-b4;
    font-weight: $f-semi-bold;
  }

  .description {
    color: $color-gray-500;
    font-size: 1.8rem;
    font-weight: $f-regular;
    line-height: 1rem;
    padding-bottom: 4.8rem;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.8rem;
    padding-bottom: 8rem;
  }

  .help-service-card-container {
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding-top: 4rem;
    max-width: 1400px;

    .help-service-container {
      display: flex;
      position: relative;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      z-index: 99;
      padding: 2rem 0 3rem 0;

      .help-card {
        z-index: 9;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 33rem;
        height: 28rem;
        background-color: $color-white;
        border-radius: 20px;
        text-align: left;
        border: 1px solid #e5e5e5;

        .help-card-icon {
          height: auto;
          width: 3rem;
        }

        hr {
          margin: 1rem 0;
        }

        .help-card-title {
          font-size: 2rem;
          font-weight: $f-semi-bold;
          margin: 0rem 0 0.5rem 0;
        }

        .help-card-description {
          color: #828282;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .help-card {
    height:auto !important;
  }
  .help-service .help-service-card-container {
      padding-top: 1rem;
  }

  .example-container .example-img-container .example-title h2 {
    font-size: 2.2rem;
}
  }
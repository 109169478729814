.scrollTop-button {
  background-color: white;
  padding: 10px 15px;
  border: none;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  border-radius: 10px;
  font-size: 20px;
  // bottom: 4rem;
  bottom: 10rem;
  right: 3rem;
}

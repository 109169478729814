@import "../Styles/variables";

.livesection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15rem;
  padding-right: 15rem;
  padding-top: 7.4rem;

  .title {
    color: $kd-color-black-1;
    font-size: 2.8rem;
    font-weight: $f-semi-bold;
    line-height: $line-height-b4;
    padding-bottom: 0.5rem;
  }

  .description {
    color: $color-gray-500;
    font-size: 1.8rem;
    font-weight: $f-regular;
    padding-bottom: 4.8rem;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    padding-bottom: 6rem;
  }
}

@media only screen and (max-width: 600px) {

  .title {
    color: $kd-color-black-1;
    font-size: 2.3rem !important;
    font-weight: $f-semi-bold;
    line-height: 3.2rem;
    padding-bottom: 0.5rem;
  }

  .livesection {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    padding-top: 3rem;
  }

  .description {
    padding-bottom: 2rem !important;
  }
}
.main-section {
  font-family: sans-serif;
  background-color: rgba(241, 242, 248, 1);
  text-align: center;
  padding: 2rem 0;
}

.main-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.subtitle {
  font-size: 1rem;
}

.image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(214, 208, 249);
  border-radius: 10px;
  width: 600px;
}

.main-image {
  height: auto;
  border-radius: 0.5rem;
  padding: 40px 0px 20px;
  max-width: 500px;
}

.icon-container {
  background-image: radial-gradient(rgba(228, 174, 228, 0.5),
      rgb(255, 255, 255));
  border-radius: 50%;
  padding: 2rem;
  gap: 1.5rem;
}

.icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.icon-border {
  width: 135px;
  height: 135px;
}

.icon {
  position: absolute;
  width: 50px;
  height: 50px;
}

@media (max-width: 840px) {

  .icon-container {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .icon-border {
    width: 90px;
    height: 90px;
  }

  .icon {
    position: absolute;
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 768px) {

  .image-wrapper {
    width: auto;
  }

  .main-image {
    padding: 30px 30px 20px 30px;
    max-width: 364px;
  }

  .icon-container {
    padding: 2.5rem;
    gap: 0.5rem;
  }
}
.comingsoon-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.comingsoon-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
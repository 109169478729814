.mx-auto.col-lg-7 {
    background-color: #ffff;
    padding: 40px;
    margin-top: 10%;
    border: 1px solid #e1dada;
}

.login-button {
    background: linear-gradient(to right, #5956E9, #E541ED);
    border: none;
    padding: 10px 26px;
    font-size: 15px;
}

.forgot-page {
    justify-content: center;
    display: flex;
}

.reset-page {
    padding: 0px 50px 0px 50px;
}

p.error-msg {
    color: red;
    font-size: 15px;
    text-align: start;
    margin-left: 4px;
}

.password-wrapper {
    position: relative;
    width: 100%;
}

.password-wrapper input {
    padding-right: 40px;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #000;
}
@import "../../Styles/variables";

.button {
  padding: 1.5rem 3.5rem;
  background-color: $color-blue;
  border: 0;
  border-radius: 0.7rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: $color-white;
  font-weight: $f-regular;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  height: fit-content;

  &-primary {
    color: $color-white;
    background-color: $color-blue;
  }

  &-secondary {
    color: $color-black-1;
    background-color: $color-white;
  }

  &-outlined {
    background-color: transparent;
    color: $color-black-2;
    border: 1px solid $color-border;

    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }

  &-outline-secondaty {
    background-color: transparent;
    color: $color-black-2;
    padding: 1.2rem 4rem;
    border: 2px solid $color-secondary;
  }

  &-outline-secondaty-disabled {
    cursor: not-allowed !important;
    color: #6c757d;
    background-color: transparent;
    border-color: #6c757d;
  }

  &-linear-gradient-primary {
    background: linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%);
  }

  &-linear-gradient-primary-disabled {
    background: linear-gradient(90deg,
        rgba(89, 86, 233, 0.5) 0%,
        rgba(229, 65, 237, 0.5) 92.31%),
      linear-gradient(0deg, #d2d2d2, #d2d2d2);
    cursor: not-allowed !important;
  }

  &-search {
    height: 3.2rem;
    width: 10.4rem;
    padding: 0;
    background-color: white;
    color: #e541ed;
    border: 1px solid #e541ed;
    border-radius: 2rem !important;
  }
}

.loader {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border: 5px solid #fff !important;
  border-bottom-color: transparent !important;
  border-radius: 50% !important;
  display: inline-block !important;
  box-sizing: border-box !important;
  animation: rotation 1s linear infinite !important;
}

.button-outline-secondaty-disabled {
  border: 1px solid gray;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {

.button {
  padding: 1.1rem 2.5rem;
}
}
.heading-content {
    text-align: center;
    font-size: 1.5rem;
}

.first-section {
    background-color: #fff0fd;
    padding-top: 4rem;
    margin-top: 7rem !important;
    padding-bottom: 6rem;
}

.reward-img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.layer-img {
    width: 100%;
    max-width: 150px;
    height: 470px;
}

.title-second {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    text-align: start
}

.title-third {
    font-size: 1.2rem;
    text-align: start;
}

.layout-info {
    gap: 69px;
}

.line-height {
    line-height: 1;
}

@media (max-width: 768px) {
    .reward-box {
        flex-direction: column;
    }

    .reward-img-box,
    .layer-img {
        margin-bottom: 1rem;
    }
}
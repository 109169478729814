.active-Card {
  border: 1px solid #e541ed;
}

.library-quespost {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #d5d5d5;
}

@media only screen and (max-width: 767px) {
  .library-quespost.card {
    padding: 10px;
    padding-bottom: 2rem !important;
  }

  .library-quespost-btn {
    margin-top: 10rem;
  }
}